import Cancel from '../assets/svg/CircleCancel.svg';
import Success from '../assets/svg/CircleSuccess.svg';

export const addToCartSuccessPDP = {
  labels: {
    primary: 'Ir al carro de compras',
    secondary: 'Ver más productos',
    state: 'Producto agregado al carro',
    fontColor: 'black',
    backgroundColor: 'white',
  },
  icon: Success,
  onclick: () => {},
};

export const addToCartSuccessPDPpromo4x3 = (quantity, promo) => ({
  labels: {
    primary: `Agregar ${promo} productos`,
    secondary: `Agregar ${quantity} producto${quantity > 1 ? 's' : ''}`,
    state: 'Producto agregado al carro',
    fontColor: 'black',
    backgroundColor: 'white',
  },
  icon: Success,
  onclick: () => {},
});

export const addToCartSuccess = {
  labels: {
    primary: 'Ir al carro de compras',
    secondary: 'Ver más productos',
    state: 'Producto agregado al carro',
    fontColor: 'black',
    backgroundColor: 'white',
  },
  success: {
    state: '',
    product: {
      id: 111111,
      variants: [
        {
          name: 'Nombre del producto',
          price: '00.00',
          symbol: '$',
          und: 'UND',
          normalPrice: '00.00',
        },
      ],
    },
    count: 1,
    selectorlabel: '¿Desea agregar más?',
  },
  icon: Success,
  onclick: () => {},
};

export const addToCartError = {
  labels: {
    primary: 'Cancelar',
    secondary: 'Intentar de nuevo',
    state: 'Ocurrió un error al agregar el producto al carro',
    fontColor: '#dd0021',
    backgroundColor: 'white',
  },
  icon: Cancel,
  onclick: () => {},
};

export const addToCartErrorExceded = {
  labels: {
    primary: 'Entendido',
    secondary: '',
    state:
      'Lo sentimos, llegaste al máximo de unidades que puedes agregar por compra. Después de pagar, puedes agregar los productos que te falten y hacer otra compra.',
    fontColor: '#dd0021',
    backgroundColor: 'white',
  },
  icon: Cancel,
  onclick: () => {},
};

export const addToCartExtraAdding = {
  labels: {
    primary: 'Agregar e ir al carro de compras',
    secondary: 'Ver más productos',
    state: 'Producto agregado al carro',
    fontColor: 'black',
    backgroundColor: 'white',
  },
  icon: Success,
  onclick: () => {},
};
