/* eslint-disable no-unused-vars */
export default function listenForOutsideClicks(
  listening,
  setListening,
  menuRef,
  setIsOpen,
) {
  return () => {
    if (listening) return;
    setListening(true);
    ['mousedown', 'touchstart'].forEach((type) => {
      document.addEventListener('click', (evt) => {
        if (!menuRef.current) return;
        const cur = menuRef.current;
        const node = evt.target;
        if (cur.contains(node)) return;
        setIsOpen(false);
      });
    });
  };
}
