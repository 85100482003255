import { createSlice } from '@reduxjs/toolkit';

/**
 * Replaces a special character in a string with another.
 * @param {*} str The original string.
 * @param {*} search The search word.
 * @param {*} replace The replacement word.
 * @returns The string with the special character replaced.
 */
const replaceSpecialCharacter = (str, search, replace) => (str.includes(search)
  ? str.replace(search, replace) : str);

/**
 * Replaces multiple special characters in a string according to defined rules.
 * @param {*} str The original string.
 * @returns The string with the special characters replaced.
 */
const replaceSpecialCharacters = (str) => {
  const replacements = [
    { search: 'Bogot�', replace: 'Bogotá' },
    { search: 'Cajic�', replace: 'Cajicá' },
  ];

  return replacements.reduce(
    (result, { search, replace }) => replaceSpecialCharacter(result, search, replace),
    str,
  );
};

const getStockMappedList = (list) => {
  const mappedList = list.map(
    ({
      storeName, quantity, storeAddress, stockStatus, googleAddress,
    }) => (
      {
        place: replaceSpecialCharacters(storeName),
        isCurrentPlace: false,
        storeAddress,
        stockStatus,
        googleAddress,
        quantity: quantity.toString(),
      }
    ),
  );
  return mappedList;
};

const convertToNewStockList = (list) => [{ list }, { list: [] }, { list: [] }];

const getAvailabilities = (list) => list[0]?.list?.length;

const deliverySlice = createSlice({
  name: 'deliveryStates',
  initialState: {
    stockResult: [],
    stockListMapped: [],
    availabilities: '',
    openModalType: '',
    isOpenModal: false,
    googleAddress: '',
  },
  reducers: {
    setOpenDeliveryModal: (state, { payload }) => {
      const { type, isOpen } = payload;
      state.openModalType = type;
      state.isOpenModal = isOpen;
    },
    setStockResult: (state, { payload }) => {
      const { result } = payload;
      state.stockResult = result;
      state.stockListMapped = convertToNewStockList(getStockMappedList(result));
      state.availabilities = getAvailabilities(convertToNewStockList(getStockMappedList(result)));
    },
  },
});

export const { setOpenDeliveryModal, setStockResult } = deliverySlice.actions;
export default deliverySlice.reducer;
