/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-simply-carousel';
import style from './SimilarProductsPDP.module.scss';
import ProductCard from '../ProductCard/ProductCard';
import { ReactComponent as ArrowLeft2 } from '../../../../assets/svg/ArrowLeft2.svg';
import { ReactComponent as ArrowRight2 } from '../../../../assets/svg/ArrowRight2.svg';
import Typography from '../../atoms/Typography/Typography';

const SimilarProductsPDP = ({ ...props }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  if (!props?.state || !props.products[0].price?.COP) {
    return null;
  }

  return (
    <div className={style.featuredProducts}>
      <div className={style.featuredProducts__header}>
        <Typography
          variant={props?.title?.variant}
          style={
            props?.title?.strong ? { fontWeight: '700' } : { fontWeight: '400' }
          }
        >
          {props?.title?.value}
        </Typography>
      </div>
      <div className={style.featuredProducts__content}>
        <Carousel
          activeSlideIndex={activeSlide}
          onRequestChange={setActiveSlide}
          infinite={false}
          center
          disableNavIfAllVisible
          // preventScrollOnSwipe
          responsiveProps={[
            {
              minWidth: 1205,
              itemsToScroll: 1,
              itemsToShow: 8,
            },
            {
              maxWidth: 1200,
              minWidth: 1020,
              itemsToScroll: 1,
              itemsToShow: 5,
            },
            {
              minWidth: 768,
              maxWidth: 1018,
              itemsToScroll: 1,
              itemsToShow: 5,
            },
            {
              minWidth: 321,
              maxWidth: 767,
              itemsToShow: 4,
              itemsToScroll: 1,
            },
            {
              maxWidth: 320,
              itemsToShow: 2,
              itemsToScroll: 1,
            },
          ]}
          itemsListProps={{
            style: {
              touchAction: 'none',
            },
          }}
          containerProps={{
            style: {
              position: 'relative',
            },
          }}
          forwardBtnProps={{
            children: <ArrowRight2 />,
            className: style.nextButton,
          }}
          backwardBtnProps={{
            children: <ArrowLeft2 />,
            className: style.prevButton,
          }}
          // itemsToShow={items}
          speed={300}
        >
          {props?.products?.map((product, index) => (
            <ProductCard
              key={index}
              type="similar"
              name={product?.name}
              urlImage={product?.image_url}
              // urlBadge={product.urlBadge}
              brand={product?.brand}
              productId={product?.item_id}
              // rating={product.rating}
              // totalReviews={product.totalReviews}
              price={product?.price?.COP}
              discount={product?.discount?.COP}
              normalPrice={product?.original_price?.COP}
              // timerTime={product.timerTime}
              onClick={() => props?.handleClickNavigate(product?.item_id)}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

SimilarProductsPDP.propTypes = {
  state: PropTypes.bool,
  handleClickNavigate: PropTypes.func,
  variant: PropTypes.oneOf(['featured', 'timed']),
  title: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
  }),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      urlImage: PropTypes.string,
      urlBadge: PropTypes.string,
      brand: PropTypes.string,
      rating: PropTypes.number,
      totalReviews: PropTypes.number,
      price: PropTypes.string,
      normalPrice: PropTypes.string,
      timerTime: PropTypes.number,
    }),
  ),
};

export default SimilarProductsPDP;
