import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './SuggestedProductList.module.scss';

const SuggestedProductList = ({
  items,
  renderItem,
  className,
  onHandleChange,
  initialIndex,
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(initialIndex);
  const onChangeSelectedIndex = (index, item) => {
    const selectedItem = selectedIndex === index ? initialIndex : index;
    const changeItemValue = selectedIndex === index ? { id: '', value: '' } : { ...item };
    setSelectedIndex(selectedItem);
    onHandleChange({ ...changeItemValue, index: selectedItem });
  };

  const findOkProducts = () => items.filter((item) => item.status === 'OK');
  return (
    <div
      className={classNames(
        findOkProducts().length <= 4 ? style.root__scrolled : style.root,
        className,
      )}
    >
      {findOkProducts()?.map((item, index) => {
        const isHighlighted = index === selectedIndex;
        return renderItem(item, isHighlighted, index, onChangeSelectedIndex);
      })}
    </div>
  );
};
SuggestedProductList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  renderItem: PropTypes.func,
  className: PropTypes.string,
  initialIndex: PropTypes.number,
  onHandleChange: PropTypes.func,
};

export default SuggestedProductList;
