/* eslint-disable no-restricted-syntax */
export const arrayInArray = (list, cutIndex) => {
  if (cutIndex && list) {
    const pages = [];
    let page = [];
    list.forEach((v, i) => {
      if (i === 0 || i % cutIndex !== 0) {
        page.push(v);
      } else {
        pages.push([...page]);
        page = [v];
      }
    });
    if (page.length >= 1) {
      pages.push([...page]);
    }
    return pages;
  }
  return null;
};

export const convertMillisecondsToTime = (milliseconds) => {
  let hours;
  let minutes;
  let seconds;
  seconds = Math.floor(milliseconds / 1000);
  minutes = Math.floor(seconds / 60);
  seconds %= 60;
  hours = Math.floor(minutes / 60);
  minutes %= 60;
  const days = Math.floor(hours / 24);
  hours %= 24;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export const reorderData = (rawData, item) => {
  const dataRaw = [...rawData];
  if (item + 1 === 1 || item === 1) {
    return dataRaw;
  }
  const one = dataRaw?.splice(item - 1, 1);
  const reorderedData = [one[0], ...dataRaw];
  return reorderedData;
};

export const isSVG = (data) => data.indexOf('.svg') !== -1;

export const getAppliedFiltersFromFacets = (data) => data?.filter((d) => d.values)
  .map((d) => d.values.map((v) => ({
    url: v.url,
    label: v.label,
    selected: v.selected,
    type: d.type,
  })))
  .flat()
  .filter(({ selected }) => selected);

export const getIndexOfSelectedCategory = (arr, label) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].caption === label) {
      return i;
    }
  }
  return -1; // si no se encontró ninguna coincidencia, devolvemos -1
};

// Función para obtener un valor de un objeto en base a una clave y una palabra clave
export const getDataFromItem = (categories, keyWord, key) => {
  const dt = categories.filter((it) => it.cta === keyWord);
  return dt[0][key];
};

// Función para filtrar un array de objetos por una clave y un valor específico
export const filterArrayByString = (array, key, cat) => {
  let rr = array.filter((it) => it[key]);
  rr = rr.filter((ty) => ty.url === cat);
  return rr;
};

export const getSubcategories = (cat, sId) => {
  const key = 'subcategories';
  const df = cat.filter((it) => it[key]);
  const rf = df.filter((it) => it.url === sId);
  if (rf.length <= 0) {
    return { items: cat, hasSub: false };
  }
  return {
    items: rf[0][key],
    hasSub: true,
    parentData: rf[0],
  };
};

// const createNumberArray = (num) => [...Array(num).keys()].map((i) => i + 1);

export const createPagesFromTotal = (num) => {
  const pages = Number(num) / 28;
  return [...Array(Math.ceil(pages)).keys()].map((i) => i + 1);
};

export const replaceCurrentPage = (url, data) => {
  if (url === '') {
    return `&currentpage=${data}`;
  }
  const rgx1 = /&currentpage=\d*/;
  const rgx2 = /&currentpage=/;
  const filterCurrentNum = url?.match(rgx1);
  const filterCurrent = filterCurrentNum[0]?.match(rgx2);
  const rest = url?.replace(filterCurrentNum, '');
  const newCurrentPageUrl = `${filterCurrent[0]}${data}${rest}`;
  return newCurrentPageUrl;
};

export const followHeight = (targetEl, updateEl) => {
  const observer = new ResizeObserver((entries) => {
    for (const entry of entries) {
      if (entry.target === targetEl) {
        const { height } = entry.contentRect;
        updateEl.style.height = `${height}px`;
      }
    }
  });
  observer.observe(targetEl);
};

/**
 * Extracts filters from the url query params
 * @param {String} url
 * @returns Array of filters
 */
export const extractFilters = (url) => {
  const prefix = 'f.';
  const urlParams = new URLSearchParams(url);
  const filters = [];

  for (const [name, value] of urlParams.entries()) {
    if (name.startsWith(prefix)) {
      const decodedValue = decodeURIComponent(value);
      const parts = decodedValue.split('::');

      if (parts.length > 1) {
        parts.forEach((part) => {
          filters.push({
            name: name.substring(prefix.length),
            value: part,
          });
        });
      } else {
        filters.push({
          name: name.substring(prefix.length),
          value: decodedValue,
        });
      }
    }
  }

  return filters;
};

// Patron pipe para componer funciones
export const pipe = (...fns) => (x) => fns.reduce((v, f) => f(v), x);

// Patron curry para crear funciones parcialmente aplicadas
export const curry = (fn) => (a) => (b) => fn(a, b);

// Función auxiliar para aplicar un solo filtro a un producto por marca
export const filterByBrandName = curry((filters, products) => products.filter((product) => {
  const brandFilters = filters.filter((filter) => filter.name === 'product.brandName');
  if (brandFilters.length === 0) {
    return true;
  }
  const productBrand = product.brand;
  const result = brandFilters.some((filter) => productBrand === filter.value);
  return result;
}));

// Función auxiliar para aplicar un solo filtro a un producto por rating
export const filterByRating = curry((filters, products) => products.filter((product) => {
  const ratingFilters = filters.filter((filter) => filter.name === 'range.product.averageOverallRating');
  if (ratingFilters.length === 0) {
    return true;
  }
  const productRating = product.rating;
  const productRatingFloor = Math.floor(parseFloat(productRating));
  const result = ratingFilters.some((filter) => productRatingFloor === parseFloat(filter.value));
  return result;
}));

export const filterProducts = (products, filters) => products.filter(
  (product) => filters.some(
    (filter) => {
      if (filter.name === 'product.brandName') {
        return product.brand === filter.value;
      }
      if (filter.name === 'range.product.averageOverallRating') {
        return product.rating === filter.value;
      }
      return null;
    },
  ),
);

// pagination
export const splitArray = (array, n) => {
  const firstArray = array?.slice(0, n);
  const secondArray = array?.slice(n);
  return [firstArray, secondArray];
};

// handle the first character from the url
export const handleFirstCharacter = (url) => {
  if (!url.startsWith('&')) {
    return `&${url}`;
  }
  return url;
};

export const numberOfDaysPast = (dateString) => {
  const now = new Date();
  const endDate = new Date(dateString);
  const daysPast = now.getTime() - endDate.getTime();
  return Math.round(daysPast / (1000 * 60 * 60 * 24));
};

export const convertStringIntoNumber = (string) => {
  const num = parseInt(string.replace(/\./g, ''), 10);
  return num;
};

export const convertFromCamelCase = (text) => {
  const splited = text.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
  const capitalizadas = splited.map((s, i) => (i === 0
    ? s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    : s.toLowerCase()));
  return capitalizadas.join(' ');
};

export const transformBlobtoImage = (data) => {
  const binaryData = [];
  binaryData.push(data);
  const transformed = URL.createObjectURL(
    new Blob(binaryData, { type: 'application/text' }),
  );
  const cleanedStr = transformed.replace(/^blob:/, '');
  return cleanedStr;
};

export const cleanJson = (data) => {
  const deletejsonp = JSON.parse(
    data.split('/*jsonp*/s7Res({"set":')[1].split('},"imgSet")')[0],
  );
  if (!deletejsonp?.item?.length) {
    return null;
  }
  const imagesUrl = deletejsonp.item.map((it) => it.i?.n);
  return imagesUrl;
};

export const hasHttp = (str) => str.toLowerCase().includes('http');

export function sortByAttribute(array, attribute) {
  return array.sort((a, b) => a[attribute] - b[attribute]);
}

export const getClostestMultipleofFour = (number) => {
  if (number < 4) {
    return 4;
  }
  if (number % 4 === 0) {
    return number;
  }
  return Math.ceil(number / 4) * 4;
};

export const logicalBadge4x3 = (badges) => {
  const badge = badges.filter((it) => it.type === 'MXN_PROMOTION');
  return badge[0].value;
};

export const logicalPromoPrice = (quantity, price) => (
  getClostestMultipleofFour(quantity) / 4
) * convertStringIntoNumber(price);

export const compareTwoObjects = (original, variant) => {
  const mergedObject = {};

  // Obtener todas las claves únicas de ambas versiones
  const allKeys = [
    ...new Set([...Object.keys(original), ...Object.keys(variant)]),
  ];

  allKeys.forEach((key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (variant.hasOwnProperty(key)) {
      // Si la clave está en el "variante", usar el valor del "variante"
      mergedObject[key] = variant[key];
    } else {
      // Si la clave no está en el "variante", usar el valor del "original"
      mergedObject[key] = original[key];
    }
  });

  return mergedObject;
};

export const renderBadges = (badgesArray) => badgesArray?.map((it) => {
  if (hasHttp(it.value)) {
    return (
      <img
        src={it.value}
        alt={it.type}
        className="http-badge"
      />
    );
  }
  if (it.type === 'services' || it.type === 'warranties') {
    return (
      <div className="services-badge">
        <img src={it.image} alt={it.type} className="services-badge__image" />
        <span>{it.value}</span>
      </div>
    );
  }
  if (it.type === 'NEW') {
    return (
      <span className="new-badge">
        {it.value}
      </span>
    );
  }
  if (it.type === 'MXN_PROMOTION') {
    return (
      <span className="mxn-badge">
        {it.value}
      </span>
    );
  }

  return null;
});

export const isHexColor = (data) => data.includes('#');

export const isOverflown = (element) => element?.scrollWidth > element?.clientWidth;
