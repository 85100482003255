import React from 'react';
import {
  isMobile,
} from 'react-device-detect';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import style from './BannerPLP.module.scss';
import { hasHttp } from '../../../../utils';

const BannerPLP = ({
  name, imageUrlDesktop, imageUrlMobile, url, state, ...props
}) => {
  const navigate = useNavigate();
  const handleClick = (urlLink) => (hasHttp(urlLink)
    ? window.open(urlLink, '_blank', 'noreferrer')
    : navigate(urlLink, { replace: false }));

  if (!state) {
    return null;
  }
  return (
    <div
      className={style.bannerPlp}
      data-testid={isMobile ? 'mobile' : 'desktop'}
      style={props.style}
    >
      <button
        type="button"
        onClick={() => handleClick(url)}
        style={{ width: '100%' }}
      >
        <img
          data-testid={hasHttp(url) ? 'external' : 'internal'}
          className={`${style.bannerPlp__image}`}
          alt={name}
          src={isMobile ? imageUrlMobile : imageUrlDesktop}
        />
      </button>
    </div>
  );
};

BannerPLP.propTypes = {
  /**
   * State "true" to turn on component card and "false" to turn off component
   */
  state: PropTypes.bool,
  /**
   * Name of the advertising
   */
  name: PropTypes.string,
  /**
   * image URL Desktop of the advertising
   */
  imageUrlDesktop: PropTypes.string,
  /**
   * image URL Mobile of the advertising
   */
  imageUrlMobile: PropTypes.string,
  /**
   * button URL of the CTA's advertising
   */
  url: PropTypes.string,
  style: PropTypes.shape({}),
};

export default BannerPLP;
