/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setProductDetailsResponse } from './alertModalPlpSlice';

export const productDetailsApi = createApi({
  reducerPath: 'productDetails',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.homecenter.com.co/',
  }),
  endpoints: (builder) => ({
    getProductDetails: builder.query({
      query: ({ productId, departamento, cityId }) => `s/products/v1/soco?&productId=${productId}&zone=${cityId}&priceGroup=${departamento}`,
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(setProductDetailsResponse(data));
      },
    }),
    getCrossSellProductIds: builder.query({
      query: ({ productIds, departamento, cityId }) => `s/search/v1/soco/product-details?productId=${productIds}&zone=${cityId}&priceGroup=${departamento}`,
    }),
    getProductInventory: builder.query({
      query: ({ productId, departamento, cityId }) => `s/products/v1/soco/zone-facility?variantId=${productId}&zone=${cityId}&priceGroup=${departamento}`,
    }),
    getRelatedProducts: builder.query({
      query: ({ productId, departamento, cityId }) => `s/products/v2/soco/related-products?productId=${productId}&zone=${cityId}&priceGroup=${departamento}`,
    }),
    getProductInfo: builder.query({
      query: ({ productId, departamento, cityId }) => `s/products/v1/soco/product-info?productId=${productId}&zone=${cityId}&priceGroup=${departamento}`,
    }),
    // getProductPrice: builder.query({
    //   query: (productId) =>
    //  `s/products/v1/soco/productDetails?variantId=${productId}&priceGroup=1`,
    //   async onQueryStarted(body, {
    //     // dispatch,
    //     queryFulfilled,
    //   }) {
    //     const { data } = await queryFulfilled;
    //     // dispatch(setProductDetailsResponse(data));
    //     console.log(data);
    //   },
    // }),
    getParentProductInfo: builder.query({
      query: ({ productId, departamento, cityId }) => `s/search/v1/soco/?q=${productId}&zone=${cityId}&priceGroup=${departamento}`,
    }),
  }),
});

export const {
  useGetProductDetailsQuery,
  useLazyGetProductDetailsQuery,
  useGetProductInventoryQuery,
  useLazyGetProductInventoryQuery,
  useGetRelatedProductsQuery,
  useLazyGetRelatedProductsQuery,
  useLazyGetCrossSellProductIdsQuery,
  useGetProductInfoQuery,
  useGetProductPriceQuery,
  useGetParentProductInfoQuery,
  useLazyGetParentProductInfoQuery,
} = productDetailsApi;
