import React from 'react';
import PropTypes from 'prop-types';
import style from './StorePickupStep.module.scss';
import Kappa14 from '../../atoms/Kappa14/Kappa14';

const StorePickupStep = ({ icon, children }) => (
  <li className={style.step}>
    <div className={style.step__icon}>
      {icon}
    </div>
    <Kappa14>{children}</Kappa14>
  </li>
);

StorePickupStep.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node,
};

export default StorePickupStep;
