import React from 'react';
import PropTypes from 'prop-types';
import style from './BadgeIndicator.module.scss';

const BadgeIndicator = ({ value }) => (
  <span className={style.badge}>
    {value}
  </span>
);

BadgeIndicator.propTypes = {
  value: PropTypes.string,
};

export default BadgeIndicator;
