import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Alert.module.scss';
import { ReactComponent as Warning } from '../../../../assets/svg/Warning.svg';

const Alert = ({ children, className }) => (
  <div className={classNames(style.alert, className)}>
    <div>
      <Warning />
    </div>
    {children}
  </div>
);

Alert.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Alert;
