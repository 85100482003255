/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import classNames from 'classnames';
import IconButton from '../../atoms/IconButton/IconButton';
import { ReactComponent as ArrowLeft2 } from '../../../../assets/svg/ArrowLeft2.svg';
import { ReactComponent as ArrowRight2 } from '../../../../assets/svg/ArrowRight2.svg';
import style from './SliderHeader.module.scss';
import 'animate.css';

const SliderHeader = ({ images, handleClick, ...props }) => (
  <>
    <Carousel
      className={style.slider__mobile}
      {...props}
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      preventMovementUntilSwipeScrollTolerance
      autoPlay
      renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && (
      <IconButton
        onClick={onClickHandler}
        title={label}
        className={classNames(
          style.slider__arrow,
          style.slider__arrow_left,
          'animate__animated animate__fadeIn',
        )}
        icon={<ArrowLeft2 />}
        size="responsive"
        color={props.arrowsColor}
      />
      )}
      renderArrowNext={(onClickHandler, hasNext, label) => hasNext && (
      <IconButton
        onClick={onClickHandler}
        title={label}
        className={classNames(
          style.slider__arrow,
          style.slider__arrow_right,
          'animate__animated animate__fadeIn',
        )}
        icon={<ArrowRight2 />}
        size="responsive"
        color={props.arrowsColor}
      />
      )}
    >
      {images?.mobile.map((image, index) => (
        <button
          type="button"
          key={index}
          className={`${style.slider__image} ${image.linkTo && style.cursor}`}
          onClick={() => {
            if (!image.linkTo) {
              console.log('Sin redireccionamiento');
            } else {
              handleClick(image.linkTo);
            }
          }}
        >
          <img
            className={style.slider__image}
            src={image.url}
            alt={image.alt}
          />
        </button>
      ))}
    </Carousel>
    <Carousel
      className={style.slider__desktop}
      {...props}
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      autoPlay
      renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && (
      <IconButton
        onClick={onClickHandler}
        title={label}
        className={classNames(
          style.slider__arrow,
          style.slider__arrow_left,
          'animate__animated animate__fadeIn',
        )}
        icon={<ArrowLeft2 />}
        size="responsive"
        color={props.arrowsColor}
      />
      )}
      renderArrowNext={(onClickHandler, hasNext, label) => hasNext && (
      <IconButton
        onClick={onClickHandler}
        title={label}
        className={classNames(
          style.slider__arrow,
          style.slider__arrow_right,
          'animate__animated animate__fadeIn',
        )}
        icon={<ArrowRight2 />}
        size="responsive"
        color={props.arrowsColor}
      />
      )}
    >
      {images?.desktop.map((image, index) => (
        <button
          type="button"
          key={index}
          className={`${style.slider__image} ${image.linkTo && style.cursor}`}
          onClick={() => {
            if (!image.linkTo) {
              console.log('Sin redireccionamiento');
            } else {
              handleClick(image.linkTo);
            }
          }}
        >
          <img
            className={style.slider__image}
            src={image.url}
            alt={image.alt}
          />
        </button>
      ))}
    </Carousel>
  </>
);

SliderHeader.propTypes = {
  arrowsColor: PropTypes.string,
  handleClick: PropTypes.func,
  images: PropTypes.shape({
    mobile: PropTypes.arrayOf(PropTypes.shape({})),
    desktop: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default SliderHeader;
