import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SliderHeader from '../SliderHeader/SliderHeader';
import style from './HomeHeader.module.scss';
import Container from '../../../layout/Container/Container';
import BannerCard from '../../molecules/BannerCard/BannerCard';
import { followHeight } from '../../../../utils';

const HomeHeader = ({
  backgroundColor, handleClick, theme, cardClick, ...props
}) => {
  const targetHeight = useRef(null);
  const toUpdateHeight = useRef(null);

  useEffect(() => {
    if (targetHeight.current && toUpdateHeight.current) {
      followHeight(targetHeight.current, toUpdateHeight.current);
    }
  }, []);

  if (!props.state) {
    return null;
  }
  let content;
  if (props.variant === '0-advertising') {
    content = (
      <div
        style={backgroundColor && { backgroundColor }}
        className={classNames(style.home_header)}
      >
        <div className={classNames(
          style.home_header__slider,
          style.home_header__slider_0advertising,
        )}
        >
          <SliderHeader
            handleClick={handleClick}
            images={props.imagesCarousel}
            arrowsColor="#000"
          />
        </div>
      </div>
    );
  }
  if (props.variant === '1-advertising') {
    content = (
      <div
        style={backgroundColor && { backgroundColor }}
        className={classNames(
          style.home_header,
          style.home_header_1advertising,
          { [style.home_header_reverse]: props.direction === 'reverse' },
        )}
      >
        <div
          className={classNames(
            style.home_header__slider,
            style.home_header__slider_1advertising,
          )}
          ref={targetHeight}
        >
          <SliderHeader
            handleClick={handleClick}
            images={props.imagesCarousel}
            arrowsColor="#000"
          />
        </div>
        <div
          className={
            classNames(
              style.home_header__banners,
              style.home_header__banners_1advertesing,
            )
}
          ref={toUpdateHeight}
        >
          <BannerCard
            theme={theme}
            content={props.banner1}
            cardClick={cardClick}
          />
        </div>
      </div>
    );
  }

  if (props.variant === '2-advertising') {
    content = (
      <div
        style={backgroundColor && { backgroundColor }}
        className={classNames(
          style.home_header,
          { [style.home_header_reverse]: props.direction === 'reverse' },
        )}
      >
        <div
          className={classNames(
            style.home_header__slider,
          )}
          ref={targetHeight}
        >
          <SliderHeader
            handleClick={handleClick}
            images={props.imagesCarousel}
            arrowsColor="#000"
          />
        </div>
        <div
          className={style.home_header__banners}
          // ref={toUpdateHeight}
        >
          <BannerCard
            theme={theme}
            content={props.banner1}
            cardClick={cardClick}
          />
          <BannerCard
            theme={theme}
            content={props.banner2}
            cardClick={cardClick}
          />
        </div>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: backgroundColor && backgroundColor }}>
      <Container>
        {content}
      </Container>
    </div>
  );
};

HomeHeader.propTypes = {
  /**
   * State "true" to turn on the home header and "false" to turn off home header
   */
  state: PropTypes.bool,
  /**
   * Varaint of home header
   */
  variant: PropTypes.oneOf(['0-advertising', '1-advertising', '2-advertising']),
  /**
   * Varaint of home header
   */
  direction: PropTypes.oneOf(['no-reverse', 'reverse']),
  /**
   * List of images to show in the slider
   */
  imagesCarousel: PropTypes.shape({}),
  /**
   * image banner 1
   */
  banner1: PropTypes.shape({
    linkTo: PropTypes.string,
    title: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      strong: PropTypes.bool,
    }),
    content: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      strong: PropTypes.bool,
    }),
    button: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      color: PropTypes.string,
      startIcon: PropTypes.string,
      endIcon: PropTypes.string,
    }),
    backgroundImage: PropTypes.string,
  }),
  /**
   * image banner 2
   */
  banner2: PropTypes.shape({
    linkTo: PropTypes.string,
    title: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      strong: PropTypes.bool,
    }),
    content: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      strong: PropTypes.bool,
    }),
    button: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      color: PropTypes.string,
      startIcon: PropTypes.string,
      endIcon: PropTypes.string,
    }),
    backgroundImage: PropTypes.string,
  }),
  /**
   * Background color of home header
   */
  backgroundColor: PropTypes.string,
  handleClick: PropTypes.func,
  cardClick: PropTypes.func,
  theme: PropTypes.shape(PropTypes.shape({})),
};

export default React.memo(HomeHeader);
