/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  setAddExtraToCart,
  setAddSuggestedProductToCart,
  setAddWarrantyToCart,
  setCrossSellProductIds,
  setLabelsModalPlp, setOpenModalPlp, setProductDetailsResponse, setRedirectToCart,
} from '../PDP/alertModalPlpSlice';
import { addToCartError, addToCartErrorExceded, addToCartSuccess } from '../../../mock/alertModalsPDP';
import { CART_URL } from '../../../constants';
import { setQuantity, setService, setSuggestedProduct } from './cartSlice';

// add service
export const mapBodyServicesStructure = (productId, warrantyId) => ({
  data: {
    cart: {
      cartLines: [
        {
          item: {
            variantId: warrantyId,
          },
          quantity: {
            quantityUnit: 'UND',
            quantityNumber: '1',
            minSaleUnit: '1',
            actualValue: '1',
          },
          priceGroup: '10',
          cartLineNumber: warrantyId,
          compatibilityGroup: 0,
          parentLineNumber: productId,
        },
      ],
    },
  },
  metadata: {
    priceGroup: '10',
    zoneId: '1',
  },
});
// adding product + suggested
export const mapBodyProductSuggestedStructure = (
  productId,
  suggestedId,
  quantity = 1,
) => ({
  data: {
    cart: {
      cartLines: [
        {
          cartLineNumber: productId,
          compatibilityGroup: 0,
          item: {
            variantId: productId,
          },
          quantity: {
            quantityNumber: quantity,
            quantityUnit: 'und',
          },
          priceGroup: 10,
        },
        {
          cartLineNumber: suggestedId,
          compatibilityGroup: 0,
          item: {
            variantId: suggestedId,
          },
          quantity: {
            quantityNumber: 1,
            quantityUnit: 'und',
          },
          priceGroup: 10,
        },
      ],
    },
  },
  metadata: {
    zoneId: 1,
    priceGroup: 10,
  },
});

// adding product + service
export const mapBodyProductServicesStructure = (productId, serviceId, quantity = 1) => ({
  data: {
    cart: {
      cartLines: [
        {
          cartLineNumber: productId,
          compatibilityGroup: 0,
          item: {
            variantId: productId,
          },
          quantity: {
            quantityNumber: quantity,
            quantityUnit: 'und',
          },
          priceGroup: 10,
        },
        {
          cartLineNumber: serviceId,
          parentLineNumber: productId,
          compatibilityGroup: 0,
          item: {
            variantId: serviceId,
          },
          quantity: {
            quantityNumber: 1,
            quantityUnit: 'und',
          },
          priceGroup: 10,
        },
      ],
    },
  },
  metadata: {
    zoneId: 1,
    priceGroup: 10,
  },
});

// adding product
export const mapBodyToAddProduct = (productId, quantity = 1, isCalculator = false) => {
  if (isCalculator) {
    return ({
      item: {
        variantId: productId,
      },
      cartLineNumber: productId,
      compatibilityGroup: 0,
      priceGroup: null,
      quantity: {
        quantityNumber: quantity,
        quantityUnit: 'und',
      },
    });
  }
  return (
    {
      data: {
        cart: {
          cartLines: [
            {
              cartLineNumber: productId,
              compatibilityGroup: 0,
              item: {
                variantId: productId,
              },
              quantity: {
                quantityNumber: quantity,
                quantityUnit: 'und',
              },
              priceGroup: '10',
            },
          ],
        },
      },
      metadata: {
        zoneId: '1',
        priceGroup: '10',
      },
    }
  );
};

// adding several products
const cartLines = (arrayProductIds) => arrayProductIds.map((productId) => ({
  cartLineNumber: productId,
  compatibilityGroup: 0,
  item: {
    variantId: productId,
  },
  quantity: {
    quantityNumber: 1,
    quantityUnit: 'und',
  },
  priceGroup: '10',
}));

export const mapBodyToAddSeveralProducts = (arrayProductIds) => ({
  data: {
    cart: {
      cartLines: cartLines(arrayProductIds),
    },
  },
  metadata: {
    zoneId: '1',
    priceGroup: '10',
  },
});

export const mapBodyToSubstractProduct = (cartlineID, quantity) => ({
  data: {
    cart: {
      patchCart: [
        {
          op: 'replace',
          path: `${cartlineID}/quantityNumber`,
          value: quantity > 1 ? quantity : '1',
        },
      ],
    },
  },
  metadata: {
    zoneId: '1',
    priceGroup: '10',
  },
});

const getProductIdFromBody = (body) => body?.data?.cart?.cartLines[0]?.cartLineNumber;

const getParentLineNumberFromBody = (body) => body?.data?.cart?.cartLines[0]?.parentLineNumber;

export const checkoutApi = createApi({
  reducerPath: 'checkout',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.homecenter.com.co/',
    prepareHeaders: (headers) => {
      headers.set('Access-Control-Allow-Origin', '*');
      headers.set(
        'Access-Control-Allow-Headers',
        'Origin, X-Requested-With, Content-Type, Accept, Z-Key',
      );
      headers.set(
        'Access-Control-Allow-Methods',
        'GET, HEAD, POST, PUT, DELETE, OPTIONS, PATCH',
      );
      headers.set('x-channel-id', 'WEB');
      headers.set('x-ecomm-name', 'homecenter-co');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addCartLines: builder.mutation({
      query: (body) => ({
        url: 's/checkout/v1/carts',
        method: 'POST',
        headers: {
          rebuildDependencies: false,
        },
        body,
        timeout: 10000,
      }),
    }),
    addToCart: builder.mutation({
      query: ({ cartId, body }) => ({
        url: `s/checkout/v1/carts?cartId=${cartId}`,
        method: 'POST',
        body,
        timeout: 10000,
      }),
      async onQueryStarted({ body, redirect, isPDP }, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((res) => {
            dispatch(setCrossSellProductIds(res));
            dispatch(
              setOpenModalPlp({
                productId: getProductIdFromBody(body),
                isOpen: true,
                isError: false,
              }),
            );
            dispatch(
              setLabelsModalPlp({
                alertLabels: addToCartSuccess.labels,
                alertIcon: addToCartSuccess.icon,
              }),
            );
            dispatch(setRedirectToCart({ redirect: true }));
            if (!isPDP) dispatch(setQuantity(1));
          })
          .then(() => {
            if (redirect) {
              dispatch(setRedirectToCart({ redirect: false }));
              dispatch(setAddExtraToCart({ addExtraToCart: false }));
              dispatch(
                setOpenModalPlp({
                  productId: '',
                  isOpen: false,
                  isError: false,
                }),
              );
              window.open(CART_URL, '_blank');
            }
          })
          .catch((error) => {
            dispatch(setRedirectToCart({ redirect }));
            if (!isPDP) dispatch(setAddExtraToCart({ addExtraToCart: redirect }));
            dispatch(
              setOpenModalPlp({
                productId: getProductIdFromBody(body),
                isOpen: true,
                isError: true,
              }),
            );
            dispatch(
              error?.error?.data?.errors[0]?.property === 'CART_CONFIG_BREACH'
                ? setLabelsModalPlp({
                  alertLabels: addToCartErrorExceded.labels,
                  alertIcon: addToCartErrorExceded.icon,
                })
                : setLabelsModalPlp({
                  alertLabels: addToCartError.labels,
                  alertIcon: addToCartError.icon,
                }),
            );
          });
      },
    }),
    suggestedToCart: builder.mutation({
      query: ({ cartId, body }) => ({
        url: `s/checkout/v1/carts?cartId=${cartId}`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(
        { body },
        { dispatch, queryFulfilled },
      ) {
        queryFulfilled
          .then(() => {
            dispatch(setSuggestedProduct(''));
            dispatch(setRedirectToCart({ redirect: false }));
            dispatch(
              setAddSuggestedProductToCart({ addSuggestedProductToCart: false }),
            );
            dispatch(setAddExtraToCart({ addExtraToCart: false }));
            dispatch(
              setOpenModalPlp({
                productId: '',
                isOpen: false,
                isError: false,
              }),
            );
            window.open(CART_URL, '_blank');
          })
          .catch((error) => {
            dispatch(
              setOpenModalPlp({
                productId: getProductIdFromBody(body),
                isOpen: true,
                isError: true,
              }),
            );
            dispatch(
              error?.error?.data?.errors[0]?.property === 'CART_CONFIG_BREACH'
                ? setLabelsModalPlp({
                  alertLabels: addToCartErrorExceded.labels,
                  alertIcon: addToCartErrorExceded.icon,
                })
                : setLabelsModalPlp({
                  alertLabels: addToCartError.labels,
                  alertIcon: addToCartError.icon,
                }),
            );
          });
      },
    }),
    createWarranty: builder.mutation({
      query: ({ cartId, body }) => ({
        url: `s/checkout/v1/carts?cartId=${cartId}`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(
        { body },
        { dispatch, queryFulfilled },
      ) {
        queryFulfilled
          .then(() => {
            dispatch(setService(''));
            dispatch(setRedirectToCart({ redirect: false }));
            dispatch(setAddWarrantyToCart({ addAnyServiceToCart: false }));
            dispatch(
              setOpenModalPlp({
                productId: '',
                isOpen: false,
                isError: false,
              }),
            );
            window.open(CART_URL, '_blank');
          })
          .catch((error) => {
            // dispatch(setRedirectToCart({ redirect: false }));
            // dispatch(setAddWarrantyToCart({ addAnyServiceToCart: false }));
            dispatch(
              setOpenModalPlp({
                productId: getParentLineNumberFromBody(body),
                isOpen: true,
                isError: true,
              }),
            );
            dispatch(
              error?.error?.data?.errors[0]?.property === 'CART_CONFIG_BREACH'
                ? setLabelsModalPlp({
                  alertLabels: addToCartErrorExceded.labels,
                  alertIcon: addToCartErrorExceded.icon,
                })
                : setLabelsModalPlp({
                  alertLabels: addToCartError.labels,
                  alertIcon: addToCartError.icon,
                }),
            );
          });
      },
    }),
    checkCart: builder.query({
      query: ({ cartId }) => ({
        url: `s/checkout/v1/carts?zoneId=1&cartId=${cartId}`,
        method: 'GET',
      }),
      transformResponse: (response, meta, { PID }) => {
        const cartLine = response.data.cart.cartLines.find(
          ({ productId }) => productId === PID,
        );
        return cartLine.cartLineId;
      },
    }),
    checkCartQuantity: builder.query({
      query: ({ location, cartId }) => ({
        url: `s/checkout/v1/carts?priceGroup=${location}&zoneId=1&processAlerts=true&cartId=${cartId}`,
      }),
    }),
    subtractToCart: builder.mutation({
      query: ({ cartId, body }) => ({
        url: `s/checkout/v1/carts/${cartId}/cart-lines`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
        redirect: 'follow',
      }),
    }),
  }),
});

export const {
  useAddToCartMutation,
  useCreateWarrantyMutation,
  useSuggestedToCartMutation,
  useLazyCheckCartQuery,
  useSubtractToCartMutation,
  useAddCartLinesMutation,
  useLazyCheckCartQuantityQuery,
} = checkoutApi;
