import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cartState',
  initialState: {
    quantity: [],
    sku: [],
    ciudad: [],
    departamento: [],
    selectedService: '',
    suggestedProduct: '',
  },
  reducers: {
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
    setService: (state, action) => {
      state.selectedService = action.payload;
    },
    setSuggestedProduct: (state, action) => {
      state.suggestedProduct = action.payload;
    },
  },
});

export const { setQuantity, setService, setSuggestedProduct } = cartSlice.actions;
export default cartSlice.reducer;
