/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Container from '../../../layout/Container/Container';

import styles from './FrequentQuestionsComponent.module.scss';
import Typography from '../../atoms/Typography/Typography';

import { ReactComponent as ArrowUp } from '../../../../assets/svg/ArrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/svg/ArrowDown.svg';
import { hasHttp } from '../../../../utils';

const FrequentQuestionsComponent = ({ data, itemSelected, ...props }) => {
  const [preguntaAbierta, setPreguntaAbierta] = useState(null);
  const { theme } = useSelector((State) => State.transversalSlice);

  const togglePregunta = (pregunta) => {
    setPreguntaAbierta(preguntaAbierta === pregunta ? null : pregunta);
  };

  const handleClick = (key) => {
    if (hasHttp(key)) {
      window.open(key, '_blank');
    }
  };

  useEffect(() => {
    if (data) {
      togglePregunta(data[itemSelected].pregunta);
    }
  }, [data]);

  if (!props.state) {
    return null;
  }

  return (
    <Container>
      <div className={styles.FrequentQuestionsComponent}>
        <Typography
          variant="epsilon24"
          style={{ fontWeight: 'bold', marginBottom: '20px' }}
        >
          Preguntas Frecuentes
        </Typography>
        <div>
          {data.map((pr) => (
            <div>
              <button
                className={styles.FrequentQuestionsComponent__button}
                type="button"
                onClick={() => togglePregunta(pr.pregunta)}
              >
                <Typography variant="omega17">{pr.pregunta}</Typography>
                {preguntaAbierta === pr.pregunta ? <ArrowDown /> : <ArrowUp />}
              </button>
              {preguntaAbierta === pr.pregunta && (
                <div className={styles.FrequentQuestionsComponent__request}>
                  {pr.respuesta}
                  {pr.button.state && (
                    <button
                      type="button"
                      onClick={() => handleClick(pr.linkTo)}
                      style={{
                        backgroundColor: theme.primary.primary,
                        color: pr?.button?.color,
                        cursor: 'pointer',
                      }}
                    >
                      {pr?.button.value}
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

// FrequentQuestionsComponent.propTypes = {
//   state: PropTypes.bool.isRequired,
//   itemSelected: PropTypes.number,
// };

export default FrequentQuestionsComponent;
