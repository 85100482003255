/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import style from './Modal.module.scss';

const Modal = ({
  children, isOpen, closeModal, className, classNameModal, ...props
}) => {
  useEffect(() => {
    const body = document.querySelector('body');
    if (isOpen) {
      body.style.overflowY = 'hidden';
    } else {
      body.style.overflowY = '';
    }
    return () => {
      body.style.overflowY = '';
    };
  }, [isOpen]);
  const handleModalContainerClick = (e) => e.stopPropagation();
  return ReactDOM.createPortal(
    <article
      className={classNames(style.modal, classNameModal, {
        [style.modal_isOpen]: isOpen,
      })}
      onClick={closeModal}
    >
      {!props.isGeo
        && props.renderCloseButton
        && (() => props.renderCloseButton())()}
      <div
        className={classNames(style.modalContainer, className)}
        onClick={handleModalContainerClick}
      >
        {props.isGeo
          && props.renderCloseButton
          && (() => props.renderCloseButton())()}
        {children}
      </div>
    </article>,
    document.body,
  );
};

export default Modal;
