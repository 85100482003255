/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import DiscountBadge from '../DiscountBadge/DiscountBadge';
import { CloudeFlare } from '../../../../constants';
import styles from './ImageBox.module.scss';
import { renderBadges } from '../../../../utils';

const ImageBox = ({
  name, id, badges,
}) => (
  <div className={styles.root}>
    <img
      className={styles.root__image}
      src={`${CloudeFlare}/${id}/thumbnail`}
      alt={name}
    />
    {badges && renderBadges(badges)}
  </div>
);

ImageBox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  badges: PropTypes.shape(PropTypes.shape({})),
};

export default ImageBox;
