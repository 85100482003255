import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ColorPick.module.scss';

const ColorPick = ({ backgroundColor, isHighlighted, ...props }) => (
  <button
    {...props}
    type="button"
    className={classNames(styles.color_pick, {
      [styles.color_pick_selected]: isHighlighted,
    })}
    style={{
      backgroundColor,
    //   borderColor: isHighlighted ? backgroundColor : 'transparent',
    }}
    data-testid="#8C8C8C"
  >
    <span>color</span>
  </button>
);

ColorPick.propTypes = {
  backgroundColor: PropTypes.string,
  isHighlighted: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ColorPick;
