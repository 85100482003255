import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputSearch from '../../atoms/InputSearch/InputSearch';
import CheckboxFilter from '../../atoms/CheckboxFilter/CheckboxFilter';
import style from './MultiSelect.module.scss';
import { handleFirstCharacter } from '../../../../utils';

const MultiSelect = ({ state, list, onInputChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  if (!state) {
    return null;
  }

  const filtered = !searchTerm
    ? list
    : list.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className={style.multiSelect}>
      <InputSearch
        state={list?.length > 10}
        value={searchTerm}
        onChange={handleChange}
      />
      <ul className={style.multiSelect__list}>
        {filtered?.map(({
          label, count, url, selected, isSelected,
        }) => (
          <li className={style.multiSelect__item} key={url}>
            <CheckboxFilter
              name={label}
              label={label}
              indicator={count}
              checked={selected || isSelected}
              onHandleChange={
                (e) => onInputChange({ url: handleFirstCharacter(url), checked: e.target.checked })
              }
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
MultiSelect.propTypes = {
  state: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onInputChange: PropTypes.func,
};

export default MultiSelect;
