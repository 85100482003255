import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';
import Accordion2 from '../../molecules/Accordion2/Accordion2';
import MultiSelectRender from '../MultiSelectRender/MultiSelectRender';
import MultiSelectAsLink from '../../molecules/MultiSelectAsLink/MultiSelectAsLink';
import MultiSelect from '../../molecules/MultiSelect/MultiSelect';
import MultiSelectWithRatings from '../../molecules/MultiSelectWithRatings/MultiSelectWithRatings';
import MultiSelectWithRange from '../../molecules/MultiSelectWithRange/MultiSelectWithRange';
import style from './FacetsFilterPLP.module.scss';

const FacetsFilterPLP = ({
  facets, filteredValues, renderViews, renderByOrder, ...props
}) => (
  <form className={classNames(style.facets, props.className)}>
    <MediaQuery maxWidth={1023}>
      <Accordion2
        state
        initialToggle
      >
        <Accordion2.Header>Vista de Productos</Accordion2.Header>
        <Accordion2.Content>
          {renderViews}
        </Accordion2.Content>
      </Accordion2>
      <Accordion2 state>
        <Accordion2.Header>Ordenar Por</Accordion2.Header>
        <Accordion2.Content>
          {renderByOrder}
        </Accordion2.Content>
      </Accordion2>
    </MediaQuery>
    {facets?.map(({
      displayName, values, type, range, currencySymbol,
    }) => (
      <Accordion2
        state={values?.length > 0}
        initialToggle={props.accordionToggleInitial}
        key={displayName}
      >
        <Accordion2.Header>
          {displayName}
        </Accordion2.Header>
        <Accordion2.Content>
          <MultiSelectRender type={type}>
            {({
              isMultiSelectAsLink,
              isMultiSelect,
              isMultiSelectWithRatings,
              isMultiSelectWithRange,
            }) => (
              <>
                <MultiSelectAsLink
                  state={isMultiSelectAsLink}
                  list={values}
                  onInputChange={props.onFilterChange && props.onFilterChange}
                />
                <MultiSelect
                  state={isMultiSelect}
                  list={values}
                  onInputChange={props.onFilterChange && props.onFilterChange}
                />
                <MultiSelectWithRatings
                  state={isMultiSelectWithRatings}
                  list={values}
                  onInputChange={props.onFilterChange && props.onFilterChange}
                />
                <MultiSelectWithRange
                  state={isMultiSelectWithRange}
                  list={values}
                  onInputChange={props.onFilterChange && props.onFilterChange}
                  currencySymbol={currencySymbol}
                  priceRange={range}
                />
              </>
            )}
          </MultiSelectRender>
        </Accordion2.Content>
      </Accordion2>
    ))}
  </form>
);

FacetsFilterPLP.propTypes = {
  facets: PropTypes.arrayOf(PropTypes.shape({})),
  filteredValues: PropTypes.arrayOf(PropTypes.shape({})),
  styles: PropTypes.shape({}),
  accordionToggleInitial: PropTypes.bool,
  onFilterChange: PropTypes.func,
  renderViews: PropTypes.node,
  renderByOrder: PropTypes.node,
  className: PropTypes.string,
};

export default FacetsFilterPLP;
