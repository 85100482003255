import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '../../atoms/Typography/Typography';
import ButtonStyled from '../../atoms/ButtonStyled/Button';
import style from './CalculatorCard.module.scss';

/**
 * Calculator Card component
 */
const CalculatorCard = ({
  title, content, onClick, titleCardTop, titleCardBottom, ...props
}) => {
  if (!props.state) {
    return null;
  }
  return (

    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div style={{ width: '100%' }} onClick={() => onClick(props.button?.linkto)}>
      {titleCardTop && (
      <Typography
        variant={titleCardTop?.variant}
        className={style.calculatorCard__CardTitle}
        style={
          titleCardTop?.strong
            ? { fontWeight: '700' }
            : { fontWeight: '400' }
        }
      >
        {titleCardTop?.value}
      </Typography>
      )}
      <article
        data-testid="calculator-card"
        style={{
          '--background-image-desktop': `url(${props.backgroundImageDesktop})`,
          '--background-image-mobile': `url(${props.backgroundImageMobile})`,
          width: `${props.width}px`,
        }}
        className={style.calculatorCard}
      >
        <div
          className={classNames(style.calculatorCard__content, {
            [style.calculatorCard__content_positionCenter]:
              props.position === 'center',
            [style.calculatorCard__content_positionRight]:
              props.position === 'right',
          })}
        >
          <div style={{ width: '100%' }}>
            {title && (
            <Typography
              variant={title?.variant}
              style={
                    title?.strong ? { fontWeight: '700' } : { fontWeight: '400' }
                  }
              className={style.calculatorCard__title}
            >
              {title?.value}
            </Typography>
            )}
            {content && (
            <Typography
              variant={content?.variant}
              style={
                    content?.strong
                      ? { fontWeight: '700' }
                      : { fontWeight: '400' }
                  }
              className={style.calculatorCard__body}
            >
              {content?.value}
            </Typography>
            )}
          </div>
          <div className={style.calculatorCard__button}>
            {props.button && (
              <ButtonStyled
                variant={props?.button?.variant}
                color={props.button?.color}
                onClick={() => onClick(props.button?.linkto)}
                theme={props.theme}
              >
                {props.button?.value}
              </ButtonStyled>
            )}
          </div>
        </div>
      </article>
      {titleCardBottom && (
      <Typography
        variant={titleCardBottom?.variant}
        className={style.calculatorCard__CardTitle}
        style={
          titleCardBottom?.strong
            ? { fontWeight: '700' }
            : { fontWeight: '400' }
        }
      >
        {titleCardBottom?.value}
      </Typography>
      )}
    </div>
  );
};

CalculatorCard.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  /**
   * State "true" to turn on the calculator card and "false" to turn off calculator card
   */
  state: PropTypes.bool,
  /**
   * Title of calculator card
   */
  title: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
  }),
  /**
   * Text description of calculator card
   */
  content: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
  }),
  /**
   * Background image Desktop of calculator card
   */
  backgroundImageDesktop: PropTypes.string,
  /**
   * Background image Mobile of calculator card
   */
  backgroundImageMobile: PropTypes.string,
  /**
   * Content position of calculator card
   */
  position: PropTypes.oneOf(['left', 'center', 'right']),
  /**
   * Width of calculator card
   */
  width: PropTypes.number,
  /**
   * Button of calculator card
   */
  button: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    linkto: PropTypes.string,
  }),
  onClick: PropTypes.func,
  titleCardTop: PropTypes.string,
  titleCardBottom: PropTypes.string,
};

CalculatorCard.defaultProps = {
  position: 'left',
  state: false,
};

export default CalculatorCard;
