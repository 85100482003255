import { useState } from 'react';
import PropTypes from 'prop-types';
import TabButton from '../../atoms/TabButton/TabButton';
import SectionTitle from '../../atoms/SectionTitle/SectionTitle';
import TabBody from '../TabBody/TabBody';
import styles from './Tabs.module.scss';

const Tabs = ({
  // eslint-disable-next-line no-unused-vars
  state, size, icons, type, data, title, ...props
}) => {
  const initialState = {
    selection: data[0]?.label,
    isOpen: true,
  };
  // eslint-disable-next-line no-unused-vars
  const [activeState, setActiveState] = useState(initialState);

  const handleClick = (value) => {
    setActiveState((prev) => ({
      selection: value,
      isOpen: !prev.isOpen || prev?.selection !== value,
    }));
  };

  const renderMobile = (items) => items.map((item) => (
    <div
      className={type === 'tab' ? styles.tabs__item : styles.tabs__acc__item}
      key={item.id}
    >
      <TabButton
        size={size}
        icons={icons}
        label={item.label}
        ico={item.ico}
        onClick={handleClick}
        selection={activeState}
        theme={props.theme}
      />
      <TabBody
        type={type}
        content={item.body.content}
        label={item.label}
        selection={activeState}
        video={item.body.video}
        image={item.body.image}
        button={item.body.button}
        theme={props.theme}
        onClick={props.onClick}
      />
    </div>
  ));
  const renderDesktop = (items) => items.map((item) => (
    <TabBody
      isDesktop
      type={type}
      key={item.id}
      content={item.body.content}
      image={item.body.image}
      video={item.body.video}
      button={item.body.button}
      label={item.label}
      selection={activeState.selection}
      theme={props.theme}
      onClick={props.onClick}
    />
  ));

  if (!state) {
    return null;
  }

  return (
    <div className={styles.section__container} style={props.style}>
      <SectionTitle text={title} />
      <div
        className={
          type === 'tab' ? styles.tabs__container : styles.tabs__acc__container
        }
      >
        <div className="mobile">{renderMobile(data)}</div>
        <div className="desktop">{renderDesktop(data)}</div>
      </div>
    </div>
  );
};

Tabs.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  onClick: PropTypes.func,

  /**
   * State "true" to turn on component card and "false" to turn off component
   */
  state: PropTypes.bool,
  /**
   * Size "small" or "large"
   */
  size: PropTypes.oneOf(['large', 'small']),
  /**
   * Title for component
   */
  title: PropTypes.string,
  /**
   * Icons "icons" to show tabs icons "buttons" without icons
   */
  icons: PropTypes.bool,
  /**
   * type "icons" to show tabs icons "buttons" without icons
   */
  type: PropTypes.oneOf(['tab', 'accordion']),
  /**
   * type "icons" to show tabs icons "buttons" without icons
   */
  button: PropTypes.shape({}),
  // /**
  //  * Default click
  //  */
  // onClick: PropTypes.func,
  /**
   * Data to populate
   */
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  style: PropTypes.shape({}),
};

export default Tabs;
