import React from 'react';
import PropTypes from 'prop-types';
import Kappa14 from '../../atoms/Kappa14/Kappa14';
import Star from '../../atoms/Star/Star';
import ProgressBar from '../../atoms/ProgressBar/ProgressBar';
import style from './OpinionStatisticsItem.module.scss';

const OpinionStatisticsItem = ({ rating, percent, numberOfPersons }) => (
  <div className={style.item}>
    <Kappa14 color="#006AC1" strong>{rating}</Kappa14>
    <Star style={{ paddingBottom: '2px' }} width={100} color="#006AC1" star="star4" />
    <ProgressBar className={style.item__progressBar} value={percent} color="#006AC1" />
    <Kappa14 color="#8C8C8C" strong>{numberOfPersons}</Kappa14>
  </div>
);
OpinionStatisticsItem.propTypes = {
  rating: PropTypes.string,
  numberOfPersons: PropTypes.string,
  percent: PropTypes.number,
};

export default OpinionStatisticsItem;
