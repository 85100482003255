import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './CardComparison.module.scss';
import { ReactComponent as Xmark } from '../../../../assets/svg/Xmark.svg';
import Kappa14 from '../../atoms/Kappa14/Kappa14';
import { CloudeFlare } from '../../../../constants';

const CardComparison = ({
  title, sku, onCloseClick, className,
}) => (
  <div className={classNames(style.root, className)}>
    <div className={classNames(style.root__image)}>
      <img src={`${CloudeFlare}/${sku}/thumbnail`} alt={title} />
    </div>
    <button type="button" onClick={onCloseClick} aria-label="Xmark">
      <Xmark />
    </button>
    <div className={style.root__content}>
      <Kappa14>{title}</Kappa14>
    </div>
  </div>
);
CardComparison.propTypes = {
  title: PropTypes.string,
  sku: PropTypes.string,
  className: PropTypes.string,
  onCloseClick: PropTypes.func,
};

export default CardComparison;
