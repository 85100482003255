/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { useMediaQuery } from 'react-responsive';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactSimplyCarousel from 'react-simply-carousel';
import { useNavigate } from 'react-router-dom';
import BrandImage from '../../atoms/BrandImage/BrandImage';
import IconButton from '../../atoms/IconButton/IconButton';
import { ReactComponent as ArrowLeft } from '../../../../assets/svg/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/ArrowRight.svg';
import style from './BrandsCarousel.module.scss';
import Container from '../../../layout/Container/Container';
import { ReactComponent as ArrowLeft2 } from '../../../../assets/svg/ArrowLeft2.svg';
import { ReactComponent as ArrowRight2 } from '../../../../assets/svg/ArrowRight2.svg';
import { arrayInArray } from '../../../../utils';
import Typography from '../../atoms/Typography/Typography';
import { setCurrentCategory } from '../../../../redux/slices/transversalSlice';

const BrandsCarousel = ({
  state,
  arrowsPosition,
  brands,
  currentCategory,
  mainCategory,
  ...props
}) => {
  if (!state) {
    return null;
  }
  const [currentSlider, setCurrentSlider] = useState(0);
  const [activeSlide2, setActiveSlide2] = useState(0);

  const brandsOneRowInArray = arrayInArray(brands, 6);
  const brandsTwoRowInArray = arrayInArray(brands, 12);

  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasMoreThanACarousel = () => {
    if (props.carouselRows === '1') {
      return brandsOneRowInArray?.length > 1;
    }
    if (props.carouselRows === '2') {
      return brandsTwoRowInArray?.length > 1;
    }
    return null;
  };

  const next = () => {
    setCurrentSlider(currentSlider + 1);
  };

  const prev = () => {
    setCurrentSlider(currentSlider - 1);
  };

  const updateCurrentSlide = (index) => {
    if (currentSlider !== index) {
      setCurrentSlider(index);
    }
  };

  const handleClick = (filter, currCategory) => {
    navigate(`/categories/${filter}`);
    dispatch(setCurrentCategory(currCategory));
  };

  const isTablet = useMediaQuery({
    query: '(min-width: 769px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  let contentOfTheCarousel;
  let contentOfSimplyCarousel;
  if (props.carouselRows === '1') {
    contentOfTheCarousel = brandsOneRowInArray?.map((resBrands, index) => (
      <div key={index} className={style.brands_carousel__brandsOneRow}>
        {resBrands.map((brand, id) => (
          <BrandImage
            key={id}
            imgBrand={brand.url}
            altbrand="this is a brand"
            onclick={() => handleClick(brand.linkto, currentCategory)}
          />
        ))}
      </div>
    ));
    contentOfSimplyCarousel = brands?.map((brand, id) => (
      <div key={id} style={{ width: isMobile ? '150px' : '200px' }}>
        <BrandImage
          imgBrand={brand.url}
          altbrand="this is a brand"
          onclick={() => handleClick(brand.linkto, currentCategory)}
        />
      </div>
    ));
  }
  if (props.carouselRows === '2') {
    contentOfTheCarousel = brandsTwoRowInArray?.map((resBrands, index) => (
      <div key={index} className={style.brands_carousel__brandsTwoRow}>
        {resBrands.map((brand, id) => (
          <BrandImage
            key={id}
            imgBrand={brand.url}
            altbrand="this is a brand"
            onclick={() => handleClick(brand.linkto, mainCategory)}
          />
        ))}
      </div>
    ));
    contentOfSimplyCarousel = arrayInArray(brands, 2)?.map(
      (twoBrands, index) => (
        <div
          key={index}
          style={{
            width: '200px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            paddingRight: '16px',
          }}
        >
          {twoBrands.map((brand, indexBrand) => (
            <BrandImage
              key={`${index}${indexBrand}`}
              imgBrand={brand.url}
              altbrand="this is a brand"
              onclick={() => handleClick(brand.linkto, mainCategory)}
            />
          ))}
        </div>
      ),
    );
  }

  useEffect(() => {
    setCurrentSlider(0);
  }, [currentCategory]);

  return (
    <div className={style.brands_carousel}>
      <Container>
        <div className={style.brands_carousel__header}>
          <Typography
            variant={props.title?.variant}
            style={{
              color: props.title?.color,
              ...(props.title?.strong
                ? { fontWeight: '700' }
                : { fontWeight: '400' }),
            }}
          >
            {props.title?.value}
          </Typography>
          {isMobile && arrowsPosition === 'top' && hasMoreThanACarousel() && (
            <div className={style.brands_carousel__actions}>
              <IconButton
                onClick={prev}
                icon={<ArrowLeft />}
                size="small"
                color="#000"
              />
              <IconButton
                className={style.brands_carousel__arrow}
                onClick={next}
                icon={<ArrowRight />}
                size="small"
                color="#000"
              />
            </div>
          )}
        </div>
        {isTablet && (
          <div className={style.brands_carousel__content}>
            {props.carouselRows === '2' && (
              <Carousel
                autoPlay
                showIndicators={false}
                showStatus={false}
                showArrows={arrowsPosition === 'middle'}
                renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && (
                <IconButton
                  onClick={onClickHandler}
                  title={label}
                  className={classNames(
                    style.brands_carousel__arrowMiddle,
                    style.brands_carousel__arrowMiddle_left,
                  )}
                  icon={<ArrowLeft2 />}
                  size="responsive"
                  color="#000"
                />
                )}
                renderArrowNext={(onClickHandler, hasNext, label) => hasNext && (
                <IconButton
                  onClick={onClickHandler}
                  title={label}
                  className={classNames(
                    style.brands_carousel__arrowMiddle,
                    style.brands_carousel__arrowMiddle_right,
                  )}
                  icon={<ArrowRight2 />}
                  size="responsive"
                  color="#000"
                />
                )}
                selectedItem={currentSlider}
                onChange={updateCurrentSlide}
                infiniteLoop
                showThumbs={false}
              >
                {contentOfTheCarousel}
              </Carousel>
            )}
            {props.carouselRows === '1' && (
              <ReactSimplyCarousel
                activeSlideIndex={activeSlide2}
                onRequestChange={setActiveSlide2}
                infinite
                autoplay
                autoplayDelay={1000}
                delay={100}
                easing="linear"
                forwardBtnProps={{
                  children: <ArrowRight />,
                  className: style.nextButtonBra,
                }}
                backwardBtnProps={{
                  children: <ArrowLeft />,
                  className: style.prevButtonBra,
                }}
                dotsNav={{
                  show: false,
                }}
                speed={400}
                responsiveProps={[
                  {
                    minWidth: 1024,
                    itemsToScroll: 1,
                    itemsToShow: 7,
                  },
                  {
                    minWidth: 768,
                    maxWidth: 1023,
                    itemsToScroll: 1,
                    itemsToShow: 6,
                  },
                  {
                    minWidth: 321,
                    maxWidth: 767,
                    itemsToShow: 4,
                    itemsToScroll: 1,
                  },
                  {
                    maxWidth: 320,
                    itemsToShow: 2,
                    itemsToScroll: 1,
                  },
                ]}
              >
                {contentOfSimplyCarousel}
              </ReactSimplyCarousel>
            )}
          </div>
        )}
        {isMobile && (
          <div className={style.brands_carousel__mobileContent}>
              {contentOfSimplyCarousel}
          </div>
        )}
      </Container>
    </div>
  );
};

BrandsCarousel.propTypes = {
  /**
   * Number of rows to show in the carousel
   */
  carouselRows: PropTypes.oneOf(['1', '2']),
  /**
   * Arrows position in the carousel section
   */
  arrowsPosition: PropTypes.oneOf(['top', 'middle']),
  /**
   * Title of the carousel section
   */
  title: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
    color: PropTypes.string,
  }),
  /**
   * Brands list of carousel
   */
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
      linkto: PropTypes.string,
    }),
  ),
  currentCategory: PropTypes.string,
  mainCategory: PropTypes.string,
  state: PropTypes.bool,
};

BrandsCarousel.defaultProps = {
  carouselRows: '1',
  arrowsPosition: 'top',
};

export default BrandsCarousel;
