/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../../atoms/Typography/Typography';
import Modal from '../../organisms/Modal/Modal';

import { useGetProductQuery } from '../../../../redux/slices/PDP/miduApi';

import styles from './ViewButtons.module.scss';

import QR from '../../../../assets/images/Detalle_RA.png';
import { ReactComponent as Project } from '../../../../assets/svg/project.svg';
import { ReactComponent as View3D } from '../../../../assets/svg/3d.svg';
import { ReactComponent as MySpace } from '../../../../assets/svg/my_space.svg';

const ViewButtons = ({ productId, price }) => {
  const [isOpenMidu, setIsOpenMidu] = useState(false);
  const [isOpenQR, setIsOpenQR] = useState(false);
  const [isOpenMySpace, setIsOpenMySpace] = useState(false);

  const [withInfo, setWithInfo] = useState(false);

  const body = { skus: [productId] };

  const { data: midudata, error } = useGetProductQuery(body);

  fetch(`https://api.tangiblee.com/api/tngimpr?ids=${productId}&domain=www.homecenter.com.co/homecenter-co/`)
    .then((response) => response.json())
    .then((dataexample) => {
      if (dataexample?.products[productId]?.approved) {
        setWithInfo(true);
      } else {
        setWithInfo(false);
      }
    });

  if (!midudata?.data?.length && !withInfo) {
    return null;
  }

  return (
    <>
      {midudata && midudata?.data?.length > 0 && (
        <>
          <Modal
            classNameModal={styles.ModalQuantity}
            className={classNames(
              styles.ModalQuantity_container,
            )}
            isOpen={isOpenMidu}
            closeModal={() => setIsOpenMidu(false)}
          >
            <div className={styles.ModalQuantity__content}>
              <iframe
                style={{ border: 'none' }}
                title={midudata?.data[0]?.NAME}
                src={midudata?.data[0]?.URL_WEB}
                width="100%"
                height="99%"
              />
            </div>
          </Modal>

          <Modal
            classNameModal={styles.ModalQuantity}
            className={classNames(
              styles.ModalQuantity_container,
            )}
            isOpen={isOpenQR}
            closeModal={() => setIsOpenQR(false)}
          >
            <div className={styles.ModalQuantity__content}>
              <Typography variant="h3" className={styles.bannerCard__title}>
                Recomendaciones para ver el producto en relidad aumentada
              </Typography>

              <section className={styles.ModalQuantity__contentQR}>
                <img src={QR} alt="DEtalle escaeno QR" />
                <div>
                  <Typography
                    variant="omega17"
                    className={styles.ModalQuantity__contentQR_text}
                  >
                    Escanee este código QR con su teléfono para ver el producto en
                    realidad aumentada.
                  </Typography>
                  <iframe
                    title={midudata?.data[0]?.NAME}
                    src={midudata?.data[0]?.URL_QR}
                    style={{ border: 'none' }}
                    width="305px"
                    height="305px"
                  />
                </div>
              </section>
            </div>
          </Modal>
        </>
      )}

      {withInfo && (
      <Modal
        classNameModal={styles.ModalQuantity}
        className={classNames(
          styles.ModalQuantity_container,
        )}
        isOpen={isOpenMySpace}
        closeModal={() => setIsOpenMySpace(false)}
      >
        <div className={styles.ModalQuantity__content}>
          <iframe
            style={{ border: 'none' }}
            src={`https://cdn.tangiblee.com/widget/index.html?id=${productId}&domain=www.homecenter.com.co/homecenter-co/&useCookies=1&price=${price || 0}&currency=$&version=3.1.1.219&ux4=1&outOfStock=0&externalClasses=`}
            title="Ver en mi espacio"
            width="100%"
            height="99%"
          />
        </div>
      </Modal>
      )}

      <div className={styles.styleLookButtons}>
        <div className={styles.styleLookButtons__text}>
          Interactúa con tu producto: Visualiza, mide y compara aquí:
        </div>
        <div className={styles.styleLookButtons__contentButtons}>
          {midudata && midudata?.data?.length > 0
          && (
            <>
              <button
                type="button"
                onClick={() => setIsOpenMidu(true)}
                className={`${styles.styleLookButtons__button}
                ${styles.styleLookButtons__button3D}`}
              >
                <View3D style={{ width: '50px' }} />
                <span className={styles.styleLookButtons__span}>Ver en 3D</span>
              </button>

              <button
                type="button"
                onClick={() => setIsOpenQR(true)}
                className={`${styles.styleLookButtons__button}
                ${styles.styleLookButtons__buttonSpace}`}
              >
                <MySpace style={{ width: '50px' }} />
                <span className={styles.styleLookButtons__span}>
                  Ver en mi espacio
                </span>
              </button>
            </>
          )}

          {withInfo
          && (
          <button
            type="button"
            onClick={() => setIsOpenMySpace(true)}
            className={`${styles.styleLookButtons__button}
            ${styles.styleLookButtons__buttonProyect}`}
          >
            <Project style={{ width: '50px' }} />
            <span className={styles.styleLookButtons__span}>
              Ver en proyecto
            </span>
          </button>
          )}

        </div>
      </div>
    </>
  );
};

ViewButtons.propTypes = {
  productId: PropTypes.string,
  price: PropTypes.string,
};

export default ViewButtons;
