import React from 'react';
import PropTypes from 'prop-types';
import Omega17 from '../../atoms/Omega17/Omega17';
import Kappa14 from '../../atoms/Kappa14/Kappa14';
import style from './DeliveryItem.module.scss';

const DeliveryItem = ({ title, content, icon }) => (
  <div className={style.deliveryItem}>
    <div>
      {icon}
    </div>
    <div className={style.deliveryItem__container}>
      <Omega17 strong>{title}</Omega17>
      <Kappa14>{content}</Kappa14>
    </div>
  </div>
);
DeliveryItem.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  content: PropTypes.node,
};

export default DeliveryItem;
