/* eslint-disable import/prefer-default-export */
const mapDropdownOptions = ({ options, selectedKey }) => options.map((option) => ({
  ...option,
  selected: option[selectedKey],
  isSelected: option[selectedKey],
}));

const onDropdownChange = ({ options, selectedOption }) => {
  const updatedOptions = options.map((option) => {
    const updatedSelection = option.label === selectedOption.label;
    return {
      ...option,
      selected: updatedSelection,
      isSelected: updatedSelection,
    };
  });
  return updatedOptions;
};

const getSelectedOption = (options) => options
  . find(({ isSelected }) => isSelected) || { label: 'Selecciona una opción' };
// const getSelectedOption = (options) => options.find(
//   ({ isSelected }) => isSelected,
// ) || options[0];

const filterObjectsByString = (arrayOfObjects, filterString) => {
  const escapedFilterString = filterString.replace(
    /[.*+?^${}()|[\]\\]/g,
    '\\$&',
  );

  const filterRegex = new RegExp(escapedFilterString.toUpperCase());

  return arrayOfObjects.filter((obj) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const propertyValue = obj[key];

        if (
          typeof propertyValue === 'string'
          && propertyValue.toUpperCase().match(filterRegex)
        ) {
          return true;
        }
      }
    }
    return false;
  });
};

export const dropdownUtils = {
  mapDropdownOptions,
  onDropdownChange,
  getSelectedOption,
  filterObjectsByString,
};
