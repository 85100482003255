import React from 'react';
import PropTypes from 'prop-types';
import style from './TimeDisplay.module.scss';

const TimeDisplay = ({ value, label }) => (
  <div className={style.timeDisplay}>
    <div className={style.timeDisplay__valueContent}>
      <span className={style.timeDisplay__value}>
        {value}
      </span>
    </div>
    <h5 className={style.timeDisplay__label}>{label}</h5>
  </div>
);

TimeDisplay.propTypes = {
  /**
   * Number value of the time display
   */
  value: PropTypes.number,
  /**
   * Label of the time display
   */
  label: PropTypes.string,
};

export default TimeDisplay;
