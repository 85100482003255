/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import style from './SmallHeader.module.scss';
import Container from '../../../layout/Container/Container';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import GeolocalizationButton from '../../molecules/GeolocalizationButton/GeolocalizationButton';
import { setOpenModal } from '../../../../redux/slices/PLP/geolocalizationSlice';
import { isHexColor } from '../../../../utils';

/**
 * Small header of store
 */
const SmallHeader = ({
  name,
  background,
  align,
  state,
  nameStore,
  breadcrumbs,
  breadcrumbsPLP,
  ...props
}) => {
  const dispatch = useDispatch();
  const isObject = (value) => typeof value === 'object';
  const {
    store,
    ciudad: { name: cityName },
    mainCategory,
    // eslint-disable-next-line no-shadow
  } = useSelector((state) => state.transversalSlice);

  const isMobile = useMediaQuery({
    query: '(max-width: 719px)',
  });

  if (!state) {
    return null;
  }

  const validateUrl = (event, storeName) => {
    const stores = {
      constructor: 'https://www.homecenter.com.co/homecenter-co/tiendas/constructor/?store=constructor',
      petcenter: 'https://www.homecenter.com.co/homecenter-co/tiendas/tienda-mascotas-pet-center/?store=petcenter',
      carcenter: 'https://www.homecenter.com.co/homecenter-co/tiendas/servicio-automotriz-carcenter/?store=carcenter',
      grandesvolumenes: 'https://www.homecenter.com.co/homecenter-co/tiendas/compras-por-mayor-grandes-volumenes/?store=grandesvolumenes',
    };

    const storeUrl = stores[storeName];

    if (storeUrl) {
      event.preventDefault();
      window.location.href = storeUrl;
    } else {
      console.error('Nombre de tienda no válido');
    }
  };

  return (
    <>
      {!props.withoutBreadcrumbs && (
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          nameStore={nameStore}
          name={name}
          isMobile={isMobile}
          mainCategory={mainCategory}
        />
      )}
      {!props.withoutBreadcrumbs && isMobile && (
        <section className={style.containerButton}>
          <GeolocalizationButton
            city={cityName}
            onClick={() => {
              dispatch(
                setOpenModal({
                  isOpen: true,
                }),
              );
            }}
          />
        </section>
      )}

      <div
        data-testid={name}
        className={props.className}
        style={
          background && {
            ...(isHexColor(background)
              ? { backgroundColor: background }
              : { backgroundImage: `url(${background})` }),
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            ...props.style,
          }
        }
      >
        <Container>
          <div className={`${style.header} ${align ? `${style[align]}` : ''}`}>
            {isObject(props.logo) ? (
              props.logo
            ) : (
              <img src={props.logo} alt={name} style={{ cursor: 'pointer' }} onClick={(e) => validateUrl(e, store)} />
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

SmallHeader.propTypes = {
  /**
   * State "true" to turn on component card and "false" to turn off component
   */
  state: PropTypes.bool,
  /**
   * Name of store
   */
  name: PropTypes.string,
  /**
   * Logo aligment: Choose left, center or right
   */
  align: PropTypes.oneOf(['izquierda', 'centro', 'derecha']),
  /**
   * backgroundColor in hexadecimal or backgroundImage (url) of store
   */
  background: PropTypes.string,
  /**
   * logo of store (url)
   */
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  nameStore: PropTypes.string,
  style: PropTypes.shape({}),
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
  breadcrumbsPLP: PropTypes.arrayOf(PropTypes.shape({})),
  withoutBreadcrumbs: PropTypes.bool,
};

export default React.memo(SmallHeader);
