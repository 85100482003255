import { PropTypes } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import Button from '../../atoms/Button/Button';
import Alert from '../../atoms/Alert/Alert';
import ProductBrand from '../../atoms/ProductBrand/ProductBrand';
import ProductName from '../../atoms/ProductName/ProductName';
import styles from './NotAvailableProductBox.module.scss';
import ProductPrice from '../../atoms/ProductPrice/ProductPrice';
import ImageBox from '../../atoms/ImageBox/ImageBox';
import InputText from '../../atoms/InputText/InputText';

const NotAvailableProductBox = ({
  branch,
  name,
  img,
  error,
  price,
  badge,
  badges,
  isScrolled,
  ...props
}) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1200px)',
  });
  return (
    <div
      className={classNames(styles.container, isScrolled && styles.scrolled)}
    >
      {!isScrolled && <Alert className={styles.notStock}>{error}</Alert>}
      <div
        className={classNames(
          styles.productInfo,
          isScrolled && styles.scrolled,
        )}
      >
        <ImageBox
          badges={badges}
          state={badge?.state}
          label={badge?.label}
          isPromotion4x3
          name={name}
          id={img}
        />
        <section
          className={classNames(
            styles.productDetails,
            isScrolled && styles.scrolled,
          )}
        >
          {isScrolled && (
            <Alert
              className={classNames(
                styles.notStock,
                isScrolled && styles.scrolled,
              )}
            >
              {error}
            </Alert>
          )}
          <div>
            <ProductBrand content={branch} />
            <ProductName
              content={name}
              className={classNames(
                styles.productName,
                isScrolled && styles.scrolled,
              )}
            />
          </div>
          {isDesktop && !isScrolled && (
            <div>
              <ProductPrice content={price} className={styles.productPrice} />
              <span className={styles.tag}>Precio sujeto a cambios</span>
            </div>
          )}
        </section>
      </div>
      {!isScrolled && (
        <>
          {!isDesktop && (
            <>
              <ProductPrice content={price} className={styles.productPrice} />
              <span className={styles.tag}>Precio sujeto a cambios</span>
            </>
          )}
          <form action="" className={styles.form}>
            <InputText
              placeholder="mail.ejemplo@gmail.com"
              state
              label="Correo electrónico"
            />
            <Button variant="contained" color="secondary" type="submit">
              Avísame cuando este disponible
            </Button>
            <Button
              className={styles.saveButton}
              variant="outlined"
              color="secondary"
              {...props}
            >
              Guardar en mi lista
            </Button>
          </form>
        </>
      )}
      {isScrolled && (
        <Button
          className={styles.saveButton}
          variant="outlined"
          color="secondary"
          {...props}
        >
          Guardar en mi lista
        </Button>
      )}
    </div>
  );
};

NotAvailableProductBox.propTypes = {
  branch: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.string,
  img: PropTypes.string,
  isScrolled: PropTypes.bool,
  badge: PropTypes.shape(PropTypes.string),
  badges: PropTypes.shape(PropTypes.shape({})),
};

export default NotAvailableProductBox;
