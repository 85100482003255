import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Container from '../../../layout/Container/Container';
import BlogCard from '../BlogCard/BlogCard';
import style from './LastBlogSection.module.scss';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/ArrowRight.svg';
import IconButton from '../../atoms/IconButton/IconButton';
import Typography from '../../atoms/Typography/Typography';
import useScrollEndDetection from '../../../Hooks/useScrollEndDetection/useScrollEndDetection';

const LastBlogSection = ({
  title, variant, theme, onClick, ...props
}) => {
  const containerRef = useRef(null);
  const { reachedEnd, reachedStart } = useScrollEndDetection(containerRef);

  if (!props.state) {
    return null;
  }
  const filteredBlogs = props.blogs?.filter((blog, index) => index <= 2);
  return (
    <div className={style.lastBlog} style={props.styles}>
      <Container>
        <div className={style.lastBlog__title}>
          <Typography variant="epsilon24" style={{ fontWeight: '700' }}>
            {title}
          </Typography>
          <div className={style.lastBlog__actionsDesktop}>
            <IconButton
              onClick={() => onClick(props.buttonUrl)}
              icon={<ArrowRight />}
              size="small"
              color="#000"
            >
              <span>{props.text}</span>
            </IconButton>
          </div>
        </div>
        <div className={style.lastBlog__mask} ref={containerRef}>
          <div className={style.lastBlog__content}>
            {filteredBlogs?.map((blog) => (
              <div key={blog.id} className={style.lastBlog__card}>
                <BlogCard
                  variant={variant}
                  title={{
                    value: blog.title,
                    variant: 'h5',
                    strong: props.cardsStrongTitle,
                  }}
                  description={{
                    value: blog.description,
                    variant: props.cardsFontSizeDescription,
                    strong: props.cardsStrongDescription,
                    complexity: blog.descriptionComplexity,
                    time: blog.descriptionTime,
                  }}
                  urlImage={blog.urlImage}
                  badges={blog.badges}
                  button={{
                    value: blog.button,
                    variant: props.cardsButtonVariant,
                    color: props.cardsButtonColor,
                    rounded: props.cardsButtonRounded,
                    redirect: blog?.redirect,
                  }}
                  theme={theme}
                  onClick={onClick}
                  colorContent={props.colorContent}
                />
              </div>
            ))}
          </div>
        </div>

        {!reachedEnd && (
        <div
          style={{
            background: `linear-gradient( 270deg, ${theme.lineScroll?.color} 0%, rgba(255, 255, 255, 0) 100%)`,
          }}
          className={style.lastBlog__linea}
        />
        )}
        {!reachedStart && (
        <div
          style={{
            background: `linear-gradient( 90deg, ${theme.lineScroll?.color} 0%, rgba(255, 255, 255, 0) 100%)`,
          }}
          className={style.lastBlog__linea__inicio}
        />
        )}

        <div className={style.lastBlog__actionsMobile}>
          <span>{props.text}</span>
          <IconButton onClick={() => onClick(props.buttonUrl)} icon={<ArrowRight />} size="small" color="#000" />
        </div>
      </Container>
    </div>
  );
};

LastBlogSection.propTypes = {
  state: PropTypes.bool.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  buttonUrl: PropTypes.string,
  colorContent: PropTypes.string,
  variant: PropTypes.oneOf(['vertical', 'horizontal']),
  cardsFontSizeTitle: PropTypes.oneOf([
    'gamma35',
    'delta29',
    'epsilon24',
    'omega17',
    'kappa14',
  ]),
  cardsStrongTitle: PropTypes.bool,
  cardsFontSizeDescription: PropTypes.oneOf([
    'gamma35',
    'delta29',
    'epsilon24',
    'omega17',
    'kappa14',
  ]),
  cardsStrongDescription: PropTypes.bool,
  cardsButtonVariant: PropTypes.oneOf(['contained', 'outlined']),
  cardsButtonColor: PropTypes.oneOf(['primary', 'secondary']),
  cardsButtonRounded: PropTypes.bool,
  theme: PropTypes.shape(PropTypes.shape({})),
  onClick: PropTypes.func,
  styles: PropTypes.shape(PropTypes.shape({})),
  blogs: PropTypes.arrayOf(
    PropTypes.shape({
      urlImage: PropTypes.string,
      badges: PropTypes.arrayOf(
        PropTypes.shape({
          urlicon: PropTypes.string,
          text: PropTypes.string,
          color: PropTypes.string,
        }),
      ),
      title: PropTypes.string,
      description: PropTypes.string,
      descriptionComplexity: PropTypes.string,
      descriptionTime: PropTypes.string,
      button: PropTypes.string,
      redirect: PropTypes.string,
    }),
  ),
};

LastBlogSection.defaultProps = {
  variant: 'vertical',
  cardsFontSizeTitle: 'epsilon24',
  cardsStrongTitle: true,
  cardsFontSizeDescription: 'omega17',
  cardsStrongDescription: false,
  cardsButtonVariant: 'contained',
  cardsButtonColor: 'primary',
  cardsButtonRounded: false,
};

export default LastBlogSection;
