import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './ProductName.module.scss';

const ProductName = ({ content, ...props }) => (
  <h3 className={classNames(style.productName, props.className)}>{content}</h3>
);

ProductName.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
};

ProductName.defaultProps = {
  content: 'Product Name',
};

export default ProductName;
