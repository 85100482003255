import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './TableRow.module.scss';

const TableRow = ({ items, renderCell, isOdd }) => (
  <tr className={classNames(style.root, {
    [style.root_backgroundGray]: isOdd,
  })}
  >
    {items?.map(({ value, isEmpty }, index) => {
      const isFirstItem = index === 0;
      return renderCell(value, index, isFirstItem, isEmpty);
    })}
  </tr>
);

TableRow.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  isOdd: PropTypes.bool,
  renderCell: PropTypes.func,
};

export default TableRow;
