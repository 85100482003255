import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '../../atoms/Typography/Typography';

import Modal from '../../organisms/Modal/Modal';

import styles from './PriceSet.module.scss';
import ModalOtherQuantities from './ModalOtherQuantities';
import ToolTip from '../FloorCalculator/ToolTip';

const calcularTotal = (precio, cantidad) => {
  let valuePrecio = typeof precio === 'string' ? precio.replace(/[^\d]/g, '') : precio;
  const precioNum = parseFloat(valuePrecio);

  if (cantidad) {
    valuePrecio = precioNum * cantidad;
  }

  const priceString = String(valuePrecio);
  const totalPrice = priceString.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  return totalPrice;
};

const PriceSet = ({
  options, onClick, sku, multiPurposeBadgeImage,
}) => {
  const reverseArray = options.slice().reverse();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(sku);

  return (
    <>
      <Modal
        classNameModal={styles.ModalQuantity}
        className={classNames(
          styles.ModalQuantity_container,
          'animate__animated animate__fadeInRight animate__faster',
        )}
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      >
        <ModalOtherQuantities
          calcularTotal={calcularTotal}
          isOpen={isOpen}
          sets={options}
          multiPurposeBadgeImage={multiPurposeBadgeImage}
        />
      </Modal>

      <div className={styles.contentPriceSet}>
        {reverseArray.map((item) => (
          <button
            type="button"
            className={styles.PriceUnitesBox}
            onClick={() => {
              setSelectedCard(item.id);
              onClick(item?.id);
            }}
            style={{
              border:
                selectedCard === item.id
                  ? '2px solid #0072CE'
                  : '1px solid #BFBFBF',
            }}
          >
            <Typography variant="kappa14">{`${item.nameItem}`}</Typography>
            <Typography
              variant="omega17"
              style={{ marginBlock: '5px' }}
              className={styles.PriceUnitesBox__price}
            >
              {`$ ${item.price}`}
            </Typography>

            <Typography variant="kappa14">{`und $ ${item.priceUnid}`}</Typography>
          </button>
        ))}

        <button
          type="button"
          className={styles.PriceUnitesBox}
          onClick={() => setIsOpen(true)}
          style={{ border: '1px solid #BFBFBF' }}
        >
          <Typography
            variant="omega17"
            style={{ marginBlock: '5px' }}
            className={styles.PriceUnitesBox__price}
          >
            ¿Necesita otra cantidad?
          </Typography>
          <ToolTip text="Calcular Aquí">
            <div>
              <div className={styles.Tooltip}>
                <Typography
                  variant="kappa14"
                  style={{
                    fontWeight: 'bold',
                  }}
                  className={styles.fontWeigth}
                >
                  ¡Nuevo!
                </Typography>
                <Typography variant="kappa14" style={{ color: 'rgb(118, 118, 118)' }}>
                  Te ayudamos a calcular la cantidad que necesitas, al mejor precio.
                </Typography>

              </div>
            </div>
          </ToolTip>
        </button>
      </div>

      <Typography
        variant="kappa14"
        style={{ color: '#8C8C8C' }}
        className={styles.PriceUnitesBox__valueProx}
      >
        *Valor aproximado sujeto a disponibilidad y destino del envío
      </Typography>
    </>
  );
};

PriceSet.propTypes = {
  onClick: PropTypes.func,
  sku: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  multiPurposeBadgeImage: PropTypes.arrayOf(PropTypes.shape({})),
};

export default PriceSet;
