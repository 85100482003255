import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../../atoms/Button/Button';
import Typography from '../../atoms/Typography/Typography';
import style from './ProductComparisonHeader.module.scss';
import { productComparisonStringsData } from './ProductComparisonHeader.mocks';
import Xmark from '../../../../assets/svg/Xmark.svg';
import Delete from '../../../../assets/svg/Delete.svg';

const ProductComparisonHeader = ({
  buttonColor, dividerColor, onCleanAll, onClose, className,
}) => {
  const { title, cleanAllText, closeText } = productComparisonStringsData;
  return (
    <div className={classNames(style.root, className)}>
      <div className={style.root__divider} style={{ backgroundColor: dividerColor }} />
      <div className={style.root__body}>
        <Typography
          variant="zeta20"
          className={style.root__title}
          strong
        >
          {title}
        </Typography>
        <div className={style.root__actions}>
          <Button
            variant="outlined"
            color={buttonColor}
            startIcon={Delete}
            onClick={onCleanAll}
          >
            {cleanAllText}
          </Button>
          <Button
            variant="contained"
            color={buttonColor}
            startIcon={Xmark}
            onClick={onClose}
          >
            {closeText}
          </Button>
        </div>
      </div>
    </div>
  );
};

ProductComparisonHeader.propTypes = {
  buttonColor: PropTypes.oneOf(['black', 'secondary']),
  dividerColor: PropTypes.string,
  onCleanAll: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default ProductComparisonHeader;
