import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Chip from '../../atoms/Chip/Chip';
import style from './AppliedFilterPLP.module.scss';
import ButtonLink from '../../atoms/ButtonLink/ButtonLink';

const AppliedFilterPLP = ({
  state, filters, onChipClick, className,
}) => {
  if (!state) {
    return null;
  }
  return (
    <div className={classNames(style.appliedFilter, className)}>
      <h6 className={style.appliedFilter__title}>Filtros aplicados</h6>
      <div className={style.appliedFilter__content}>
        {filters?.map(({ label, url }) => (
          <Chip key={url} label={label} onChipClick={() => onChipClick({ label, url })} />
        ))}
      </div>
      <div className={style.appliedFilter__action}>
        <ButtonLink
          className={style.appliedFilter__button}
          onLinkClick={() => onChipClick({ url: '&currentpage=1' })}
        >
          Limpiar todos
        </ButtonLink>
      </div>
    </div>
  );
};

AppliedFilterPLP.propTypes = {
  state: PropTypes.bool,
  filters: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
  onChipClick: PropTypes.func,
  className: PropTypes.string,
};

export default AppliedFilterPLP;
