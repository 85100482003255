import React from 'react';
import PropTypes from 'prop-types';
import style from './CategoryIcon.module.scss';

const CategoryIcon = ({
  imgBrand,
  altbrand,
  onClick,
  titlePosition,
  ...props
}) => (
  <button
    value={props.catId}
    type="button"
    onClick={onClick}
    className={`${style.categories__button} ${
      titlePosition === 'top' ? 'top' : ''
    }`}
  >
    {titlePosition === 'top' && (
      <span className={style.categories__caption}>{altbrand}</span>
    )}
    <figure className={style.categories__container}>
      <img src={imgBrand} alt={altbrand} className={style.categories__image} />
      {titlePosition === 'bottom' && altbrand && (
      <span className={style.categories__caption}>{altbrand}</span>
      )}
    </figure>
  </button>
);

CategoryIcon.propTypes = {
  imgBrand: PropTypes.string,
  catId: PropTypes.string,
  altbrand: PropTypes.string,
  onClick: PropTypes.func,
  titlePosition: PropTypes.oneOf(['top', 'bottom']),
};

export default CategoryIcon;
