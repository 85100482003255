/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '../../atoms/Typography/Typography';
import style from './SuggestedProductCard.module.scss';
import Button from '../../atoms/Button/Button';
// import Success from '../../../../assets/svg/CircleSuccess.svg';
import { CloudeFlare } from '../../../../constants';

function truncate(str, n) {
  return (str.length > n) ? `${str.substr(0, n - 1)}...` : str;
}

const SuggestedProductCard = ({ name, isHighlighted, ...props }) => (
  <div className={classNames(style.root, props.className)}>
    <div className={style.root__content}>
      <div className={style.root__image}>
        <img
          src={`${CloudeFlare}/${props.productId}/thumbnail`}
          alt={name}
        />
      </div>
      <div className={style.info}>
        <Typography className="name">{truncate(name, 18)}</Typography>
        {props.M2 && (
          <Typography className="price">{`$ ${props.M2} ${props.PriceFormat}`}</Typography>
        )}
        <Typography className="normal">
          Normal:
          {' '}
          {`$ ${props.NORMAL}`}
        </Typography>
      </div>
    </div>
    <Button
      className={style.root__button}
      variant={isHighlighted ? 'contained' : 'outlined'}
      color={isHighlighted ? 'secondary' : 'primary'}
      onClick={props.onClick}
    >
      Agregar al carro
    </Button>
  </div>
);

SuggestedProductCard.propTypes = {
  productId: PropTypes.string,
  name: PropTypes.string,
  NORMAL: PropTypes.string,
  M2: PropTypes.string,
  // symbol: PropTypes.string,
  PriceFormat: PropTypes.string,
  normalPrice: PropTypes.string,
  isSuccess: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isHighlighted: PropTypes.bool,
};

export default SuggestedProductCard;
