/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
// import classNames from 'classnames';
import { ReactComponent as ArrowLeft } from '../../../../assets/svg/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/ArrowRight.svg';
import Typography from '../../atoms/Typography/Typography';
import style from './BrandsFilterPLP.module.scss';
import IconButton from '../../atoms/IconButton/IconButton';
import { arrayInArray } from '../../../../utils';

const BrandsFilterPLP = ({
  state, title, brands, onBrandClick,
}) => {
  const [currentSlider, setCurrentSlider] = useState(0);

  const brandsOneRowInArray = arrayInArray(brands, 7);

  const hasMoreThanACarousel = () => brandsOneRowInArray.length > 1;

  const next = () => {
    setCurrentSlider(currentSlider + 1);
  };

  const prev = () => {
    setCurrentSlider(currentSlider - 1);
  };

  const updateCurrentSlide = (index) => {
    if (currentSlider !== index) {
      setCurrentSlider(index);
    }
  };

  if (!state) {
    return null;
  }

  return (
    <div className={style.brandsFilter}>
      <Typography
        className={style.brandsFilter__title}
        variant="omega17"
      >
        {title}
      </Typography>
      <div className={style.brandsFilter__content}>
        {hasMoreThanACarousel() && <IconButton onClick={prev} icon={<ArrowLeft />} size="small" color="#005DAD" />}
        <div className={style.brandsFilter__carousel}>
          <Carousel
            autoPlay
            showIndicators={false}
            showStatus={false}
            showArrows={false}
            selectedItem={currentSlider}
            onChange={updateCurrentSlide}
            infiniteLoop
            showThumbs={false}
          >
            {brandsOneRowInArray?.map((resBrands, index) => (
              <div key={index} className={style.brandsFilter__brands}>
                {resBrands.map((brand, id) => {
                  if (brand?.imgbrand?.length > 0) {
                    return (
                      <button
                        key={`${resBrands}${id}`}
                        type="button"
                        onClick={() => onBrandClick(brand)}
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          className={style.brandsFilter__brand}
                          src={brand?.imgbrand}
                          alt={brand?.name}
                        />
                      </button>
                    );
                  }
                  return (
                    <button
                      className={style.brandsFilter__buttonLabel}
                      key={`${resBrands}${id}`}
                      type="button"
                      onClick={() => onBrandClick(brand)}
                      style={{ cursor: 'pointer' }}
                    >
                      {brand?.name}
                    </button>
                  );
                })}
              </div>
            ))}
          </Carousel>
        </div>
        {hasMoreThanACarousel() && <IconButton onClick={next} icon={<ArrowRight />} size="small" color="#005DAD" />}
      </div>
      <div className={style.brandsFilter__mobile}>
        {brands?.map((brand, index) => (
          <button
            key={index}
            className={style.brandsFilter__mobile_container}
            type="button"
            onClick={() => onBrandClick(brand)}
          >
            {brand?.imgbrand?.length > 0 ? (
              <img
                className={style.brandsFilter__mobile_img}
                src={brand?.imgbrand}
                alt={brand?.name}
              />
            ) : (
              <div className={style.brandsFilter__mobile_label}>
                <span>{brand?.name}</span>
              </div>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

BrandsFilterPLP.propTypes = {
  state: PropTypes.bool,
  title: PropTypes.string,
  brands: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    imgbrand: PropTypes.string,
    linkto: PropTypes.string,
  })),
  onBrandClick: PropTypes.func,
};

export default BrandsFilterPLP;
