import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Small.module.scss';

const Small = ({ content, bold, className }) => (
  <small className={classNames(style.small, className, {
    [style.small_bold]: bold,
  })}
  >
    {content}
  </small>
);

Small.propTypes = {
  content: PropTypes.string,
  bold: PropTypes.bool,
  className: PropTypes.string,
};

export default Small;
