/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import ProductCardVertical from '../ProductCardVertical/ProductCardVertical';
import style from './ProductCardList.module.scss';
import ProductCardHorizontal from '../ProductCardHorizontal/ProductCardHorizontal';
import { productDetailsApi } from '../../../../redux/slices/PDP/productDetailsApi';
import {
  deleteHeadData,
  deleteProductComparison,
  setHeadData,
  setProductComparison,
} from '../../../../redux/slices/PLP/productComparisonSlice';

const ProductCardList = ({
  type,
  products,
  productsWithMerchantCategory,
  banner,
  onClick,
  onAddCartClick,
  isAddToCartLoading,
  selectedProduct,
  onClickSaveList,
}) => {
  const dispatch = useDispatch();
  const { productsComparison, addedProducts } = useSelector(
    (state) => state.productComparison,
  );

  const handleCheckboxClick = (e, product) => {
    const sku = product?.skuId;
    if (e.target.checked && addedProducts?.length !== 4) {
      dispatch(productDetailsApi.endpoints.getProductInfo.initiate(sku)).then(
        ({ data, isSuccess }) => {
          if (isSuccess) {
            const { result } = data;
            const { name, id } = result;
            dispatch(setProductComparison({ name, id, isEmpty: false }));
            dispatch(setHeadData({ result, value: product, isEmpty: false }));
          }
        },
      );
    } else {
      dispatch(deleteProductComparison({ sku }));
      dispatch(deleteHeadData({ sku }));
    }
  };

  const findProductWithM2 = (productId) => {
    const productFilter = productsWithMerchantCategory?.find(
      (producto) => producto?.productId === productId,
    );

    const productFilterPrice = productFilter?.prices?.find(
      (producto) => producto?.type === 'M2' && producto?.unit === 'm2',
    );

    return productFilterPrice?.price;
  };

  return (
    <>
      <div
        className={classNames({
          [style.productList]: type === 'GRID',
          [style.productListGrid]: type === 'LIST',
        })}
      >
        {products?.map((product, index) => {
          const hasChecked = productsComparison.find(
            ({ sku }) => sku === product?.skuId,
          );
          if (type === 'GRID' && index <= 7) {
            return (
              <div
                key={product?.id}
                className={classNames(style.productList__item, {
                  [style.productList_paddingLeft]: !(index % 2 === 0),
                  [style.productList_paddingRight]: index % 2 === 0,
                })}
              >
                <ProductCardVertical
                  onClickSaveList={onClickSaveList}
                  isAddToCartLoading={
                    isAddToCartLoading && product?.productId === selectedProduct
                  }
                  {...product}
                  priceM2={findProductWithM2(product?.productId)}
                  name={product?.displayName}
                  onClick={onClick}
                  onAddCartClick={onAddCartClick}
                  onCheckboxClick={(e) => handleCheckboxClick(e, product)}
                  isChecked={hasChecked && !hasChecked?.isEmpty}
                />
              </div>
            );
          }
          if (type === 'LIST' && index <= 7) {
            return (
              <div
                key={product?.id}
                className={style.productListGrid__gridItem}
              >
                <ProductCardHorizontal
                  onClickSaveList={onClickSaveList}
                  isAddToCartLoading={
                    isAddToCartLoading && product?.productId === selectedProduct
                  }
                  {...product}
                  priceM2={findProductWithM2(product?.productId)}
                  name={product?.displayName}
                  onClick={onClick}
                  onCheckboxClick={(e) => handleCheckboxClick(e, product)}
                  onAddCartClick={onAddCartClick}
                  isChecked={hasChecked && !hasChecked?.isEmpty}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
      {banner}
      <div
        className={classNames({
          [style.productList]: type === 'GRID',
          [style.productListGrid]: type === 'LIST',
        })}
      >
        {products?.map((product, index) => {
          const hasChecked = productsComparison.find(
            ({ sku }) => sku === product?.skuId,
          );
          if (type === 'GRID' && index > 7) {
            return (
              <div
                key={product?.id}
                className={classNames(style.productList__item, {
                  [style.productList_paddingLeft]: !(index % 2 === 0),
                  [style.productList_paddingRight]: index % 2 === 0,
                })}
              >
                <ProductCardVertical
                  onClickSaveList={onClickSaveList}
                  isAddToCartLoading={
                    isAddToCartLoading && product?.productId === selectedProduct
                  }
                  {...product}
                  onClick={onClick}
                  priceM2={findProductWithM2(product?.productId)}
                  name={product?.displayName}
                  onCheckboxClick={(e) => handleCheckboxClick(e, product)}
                  onAddCartClick={onAddCartClick}
                  isChecked={hasChecked && !hasChecked?.isEmpty}
                />
              </div>
            );
          }
          if (type === 'LIST' && index > 7) {
            return (
              <div
                key={product?.productId}
                className={style.productListGrid__gridItem}
              >
                <ProductCardHorizontal
                  onClickSaveList={onClickSaveList}
                  isAddToCartLoading={
                    isAddToCartLoading && product?.productId === selectedProduct
                  }
                  {...product}
                  priceM2={findProductWithM2(product?.productId)}
                  onClick={onClick}
                  name={product?.displayName}
                  onCheckboxClick={(e) => handleCheckboxClick(e, product)}
                  onAddCartClick={onAddCartClick}
                  isChecked={hasChecked && !hasChecked?.isEmpty}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

ProductCardList.propTypes = {
  type: PropTypes.oneOf(['GRID', 'LIST']),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  productsWithMerchantCategory: PropTypes.arrayOf(PropTypes.shape({})),
  banner: PropTypes.node,
  onClick: PropTypes.func,
  onAddCartClick: PropTypes.func,
  onClickSaveList: PropTypes.func,
  isAddToCartLoading: PropTypes.bool,
  selectedProduct: PropTypes.string,
};

export default ProductCardList;
