import React from 'react';
import PropTypes from 'prop-types';
import InputRange from '../InputRange/InputRange';
import CheckboxFilter from '../../atoms/CheckboxFilter/CheckboxFilter';
import style from './MultiSelectWithRange.module.scss';

const MultiSelectWithRange = ({
  state, list, priceRange, currencySymbol, onInputChange,
}) => {
  if (!state) {
    return null;
  }
  return (
    <div className={style.multiSelectWithRange}>
      <InputRange
        state
        className={style.multiSelectWithRange__inputRange}
        priceRange={priceRange}
        currencySymbol={currencySymbol}
        onSendClick={(range) => onInputChange(range)}
      />
      <ul className={style.multiSelectWithRange__list}>
        {list?.map(({
          label, count, url, selected,
        }) => (
          <li className={style.multiSelectWithRange__item} key={url}>
            <CheckboxFilter
              name={label}
              label={label}
              indicator={count}
              checked={selected}
              onHandleChange={(e) => onInputChange({ url, checked: e.target.checked })}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
MultiSelectWithRange.propTypes = {
  state: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  currencySymbol: PropTypes.string,
  priceRange: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
  onInputChange: PropTypes.func,
};

export default MultiSelectWithRange;
