import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Omega17.module.scss';

const Omega17 = ({
  children, color, strong, className,
}) => (
  <p
    className={classNames(style.omega17, className)}
    style={{ color: color || '', fontWeight: strong ? '900' : '' }}
  >
    {children}
  </p>
);

Omega17.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  strong: PropTypes.bool,
  className: PropTypes.string,
};

export default Omega17;
