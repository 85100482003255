import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './CardImage.module.scss';
import TagBlog from '../../atoms/TagBlog/TagBlog';

const CardImage = ({ urlImage, ...props }) => (
  <div className={classNames(style.cardImage, props.className)} style={props.style}>
    <img className={style.cardImage__mainImage} src={urlImage} alt={props.name} />
    <div className={style.cardImage__badges}>
      {props?.badges?.map((badge) => (
        <TagBlog
          className={style.cardImage__badge}
          key={badge.id}
          urlicon={badge.urlicon}
          text={badge.text}
          color={badge.color}
        />
      ))}
    </div>
  </div>
);

CardImage.propTypes = {
  urlImage: PropTypes.string,
  name: PropTypes.string,
  badges: PropTypes.arrayOf(PropTypes.shape({
    urlicon: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.string,
  })),
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

export default CardImage;
