/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import DropdownListItem from '../DropdownListItem/DropdownListItem';
import style from './DropdownList.module.scss';

const DropdownList = ({
  options,
  id,
  isDropdownListAbove,
  maxHeight,
  onDropdownChange,
  onSearchChange,
  isGeo,
}) => {
  const testId = `testId-DropdownList-${id}`;
  const showOnTopClass = isDropdownListAbove ? style.show_on_top : '';
  return (
    <ul
      id={testId}
      className={`${style.dropdown_list} ${showOnTopClass}`}
      style={{ maxHeight }}
    >
      {isGeo && (
        <div className={style.search_input}>
          <input
            type="text"
            placeholder="Selecciona una opción"
            onChange={onSearchChange}
          />
        </div>
      )}
      {options.map((option, index) => (
        <DropdownListItem
          id={`${testId}-item-${index}`}
          option={option}
          key={`${testId}-item-${index}`}
          onChange={onDropdownChange}
        />
      ))}
    </ul>
  );
};

DropdownList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string.isRequired,
  isDropdownListAbove: PropTypes.bool.isRequired,
  maxHeight: PropTypes.number.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  isGeo: PropTypes.bool,
};

export default DropdownList;
