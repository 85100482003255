/* eslint-disable no-underscore-dangle */
const updateDigitalData = (data) => {
  const entryData = {
    ...data,
  };
  localStorage.setItem('digitalData', JSON.stringify(entryData));
  window.digitalData = entryData;
};

const trackPageView = ({
  store,
  pageName,
  pageType,
  siteSection,
  subSection,
  catid,
  price,
  productName,
  sku,
}) => {
  const isMobile = /Mobi/.test(navigator.userAgent);

  localStorage.setItem('digitalData', {});
  if (window.s) {
    window.s.pageName = pageName;
    window.s.pageType = pageType;
    window.s.t('sis-interaction');
  }

  updateDigitalData({
    page: {
      ...localStorage.getItem('digitalData').page,
      pageName,
      pageType,
      canal: isMobile ? 'mobile' : 'internet',
      seller: `store in store: ${store}`,
      ...(siteSection && { siteSection }),
      ...(subSection && { subSection }),
    },
    ecom: {
      ...(catid && { catid }),
      ...(price && { price }),
      ...(productName && { productName }),
      ...(sku && { sku }),
    },
  });
};

const addToCardMarcation = ({
  catId, price, productName, sku,
}) => {
  const digitalData = JSON.parse(localStorage.getItem('digitalData'));

  const addData = {
    ecom: {
      catId: catId || digitalData?.data?.page?.catId,
      price: price || digitalData?.data?.page?.price,
      productName: productName || digitalData?.data?.page?.productName,
      sku: sku || digitalData?.data?.page?.sku,
    },
  };

  const entryData = {
    ...digitalData,
    ...addData,
  };

  localStorage.setItem('digitalData', JSON.stringify(entryData));
  window.digitalData = entryData;

  if (window._satellite) {
    window._satellite.track(
      'add-to-cart',
      entryData,
    );
  }
};

export { trackPageView, updateDigitalData, addToCardMarcation };
