/* eslint-disable import/prefer-default-export */
import { ReactComponent as DeliveryStandar } from '../../../../assets/svg/DeliveryStandar.svg';
import { ReactComponent as Calendar } from '../../../../assets/svg/Calendar.svg';
import { ReactComponent as HomeDelivery } from '../../../../assets/svg/HomeDelivery.svg';
import { ReactComponent as StorePickUp } from '../../../../assets/svg/StorePickUp.svg';
import { ReactComponent as Cart } from '../../../../assets/svg/Cart.svg';
import { ReactComponent as GpsMark } from '../../../../assets/svg/GpsMark.svg';
import { ReactComponent as Email } from '../../../../assets/svg/Email.svg';
import { ReactComponent as PickupQr } from '../../../../assets/svg/PickupQr.svg';

// STRINGS
export const homeDeliveryInformationStringData = {
  iconTitle: <DeliveryStandar />,
  title: 'Información de Envío a domicilio',
  alert: 'Fechas y costos para',
  information: [
    {
      icon: <DeliveryStandar />,
      title: 'Envío Estándar:',
      content: 'Tu pedido será entregado en un rango de 3 días, a partir de la fecha prometida, la entrega se hará en cualquiera de los días.',
    },
    {
      icon: <Calendar />,
      title: 'Envío Mismo día:',
      content: 'Selecciona un día específico para la entrega de tu pedido.',
    },
    {
      icon: <HomeDelivery />,
      title: 'Envío Express:',
      content: 'Promesa visible para compras realizadas antes de las 4pm. La entrega de tu pedido se realizara máximo a las 3 horas de registrado el pago.',
    },
    {
      icon: <HomeDelivery />,
      title: 'Envío Programado:',
      content: 'Realiza tu compra antes de las 11:00 am y podrás escoger la entrega para el mismo día o para los próximos 15 días siguientes, entre las 2pm y las 8pm. Válido para ciudades principales.',
    },
  ],
};

export const homeDeliveryExpressInformationStringData = {
  iconTitle: <DeliveryStandar />,
  title: 'Despacho Express',
  alert: 'Condiciones para tener en cuenta:',
  information: [
    {
      steps: [
        {
          icon: <Cart />,
          text: 'Aplica sólo para compras antes de las 4pm.',
        },
        {
          icon: <GpsMark />,
          text: 'La opción aparece de acuerdo a la disponibilidad de inventario en nuestras tiendas habilitadas para "Despacho Express".',
        },
        {
          icon: <Email />,
          text: 'Aplica para productos seleccionados y cantidades de compra que no superen 25kg de peso y su dimensión no sea mayor a 60cm.',
        },
        {
          icon: <StorePickUp />,
          text: 'Ten a la mano el correo de confirmación de compra para recibir tu pedido.',
        },
        {
          icon: <PickupQr />,
          text: 'Si no estas satisfecho con tu compra, puedes hacer cambio o devolución de acuerdo a las Políticas de devolución y reembolsos.',
        },
      ],
    },
  ],
};

export const pickupInformationInStoreStringData = {
  iconTitle: <StorePickUp />,
  title: 'Retiro en tienda',
  alert: 'Opciones disponibles para',
  information: [
    {
      steps: [
        {
          icon: <Cart />,
          text: 'Aplica sólo para compras antes de las 4pm.',
        },
        {
          icon: <GpsMark />,
          text: 'La opción aparece de acuerdo a la disponibilidad de inventario en nuestras tiendas habilitadas para "Despacho Express".',
        },
        {
          icon: <Email />,
          text: 'Aplica para productos seleccionados y cantidades de compra que no superen 25kg de peso y su dimensión no sea mayor a 60cm.',
        },
        {
          icon: <StorePickUp />,
          text: 'Ten a la mano el correo de confirmación de compra para recibir tu pedido.',
        },
        {
          icon: <PickupQr />,
          text: 'Si no estas satisfecho con tu compra, puedes hacer cambio o devolución de acuerdo a las Políticas de devolución y reembolsos.',
        },
      ],
    },
  ],
};

export const availabilityOtherStoresStringData = {
  iconTitle: <StorePickUp />,
  title: 'Stock en Tienda',
  alert: 'Stock en tienda referencial y puede variar durante el día.',
  information: [
    {
      steps: [
        {
          icon: <Cart />,
          text: 'Aplica sólo para compras antes de las 4pm.',
        },
        {
          icon: <GpsMark />,
          text: 'La opción aparece de acuerdo a la disponibilidad de inventario en nuestras tiendas habilitadas para "Despacho Express".',
        },
        {
          icon: <Email />,
          text: 'Aplica para productos seleccionados y cantidades de compra que no superen 25kg de peso y su dimensión no sea mayor a 60cm.',
        },
        {
          icon: <StorePickUp />,
          text: 'Ten a la mano el correo de confirmación de compra para recibir tu pedido.',
        },
        {
          icon: <PickupQr />,
          text: 'Si no estas satisfecho con tu compra, puedes hacer cambio o devolución de acuerdo a las Políticas de devolución y reembolsos.',
        },
      ],
    },
  ],
};

// MOCK DATA PROPS
export const homeDeliveryInformationModalMockData = {
  type: 'HOME_DELIVERY_INFORMATION',
  isOpen: true,
};

export const homeDeliveryExpressInformationModalMockData = {
  type: 'HOME_DELIVERY_ESPRESS_INFORMATION',
  isOpen: true,
};

export const pickupInformationInStoreModalMockData = {
  type: 'PICKUP_INFORMATION_IN_STORE',
  isOpen: true,
};

export const availabilityOtherStoresModalMockData = {
  type: 'AVAILABILITY_OTHER_STORES',
  isOpen: true,
};
