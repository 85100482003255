/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './CheckboxFilter.module.scss';

const CheckboxFilter = ({
  label, checked, onHandleChange, ...props
}) => {
  const defaultChecked = checked || false;
  // const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleChange = (event) => {
    // setIsChecked((prev) => !prev);
    onHandleChange(event);
  };
  return (
    <div className={style.checkboxFilter}>
      <input
        id={props.name}
        type="checkbox"
        name={props.name}
        checked={defaultChecked}
        onChange={onHandleChange && handleChange}
        className={classNames({ [style.checkboxFilter__input]: defaultChecked })}
        {...props}
      />
      <label className={style.checkboxFilter__label} htmlFor={props.name}>
        {label}
        <span className={style.checkboxFilter__indicator}>{props.indicator}</span>
      </label>
    </div>
  );
};

CheckboxFilter.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onHandleChange: PropTypes.func,
  indicator: PropTypes.string,
};

export default CheckboxFilter;
