import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './DiscountBadge.module.scss';

const DiscountBadge = ({ state, label, className }) => {
  if (!state) {
    return null;
  }
  return (
    <div className={classNames(style.discountBadge, className)}>
      {label}
    </div>
  );
};
DiscountBadge.propTypes = {
  state: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default DiscountBadge;
