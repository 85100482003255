/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import Typography from '../../atoms/Typography/Typography';
import styles from './PriceSet.module.scss';
import {
  mapBodyToAddProduct,
  useAddToCartMutation,
} from '../../../../redux/slices/Cart/checkoutApi';
import Loader from '../../atoms/Loader/Loader';
import ButtonStyled from '../../atoms/ButtonStyled/Button';

const ModalOtherQuantities = ({
  calcularTotal,
  isOpen,
  sets,
  multiPurposeBadgeImage,
}) => {
  const { theme, cartId } = useSelector((state) => state.transversalSlice);
  const [units, setUnits] = useState(0);
  const [arrayItems, setArrayItems] = useState([]);
  const [selectedSets, setSelectedSets] = useState([]);
  const [selectedItemsToAddSets, setSelectedItemsToAdd] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);
  const [loader, setLoader] = useState(false);
  const [price, setPrice] = useState('0');

  const [addToCart, { isLoading: isAddToCartLoading }] = useAddToCartMutation();

  const handleUnitsChange = (event) => {
    setUnits(event.target.value);
  };

  const mapArrayToAddProducts = (products) => {
    // eslint-disable-next-line max-len
    const items = products.map((product) => mapBodyToAddProduct(product.id, product.cantidad, true));

    const result = {
      data: {
        cart: {
          cartLines: items,
        },
      },
      metadata: {
        priceGroup: 10,
        zoneId: 1,
      },
    };

    setSelectedItemsToAdd(result);
  };

  const selectedSetsTotal = selectedSets
    .map((item) => +item.price.replace(/\./g, ''))
    .reduce((a, b) => a + b, 0);

  const suggestSets = (itemsDeseados) => {
    setSelectedSets([]);
    setArrayItems([]);
    setTotalUnits(0);

    const suggestedSets = [...sets];

    const productosOrdenados = suggestedSets.sort((a, b) => b.units - a.units);

    const sugerencia = [];
    let itemsRestantes = itemsDeseados;

    productosOrdenados.forEach((producto) => {
      const cantidadProducto = Math.floor(itemsRestantes / producto.units);
      if (cantidadProducto > 0) {
        const precioTotal = cantidadProducto * parseInt(producto.price.replace(/\./g, ''), 10);
        sugerencia.push({
          ...producto,
          cantidad: cantidadProducto,
          price: precioTotal.toLocaleString('es-CL'),
        });
        itemsRestantes -= cantidadProducto * producto.units;
      }
    });

    setArrayItems(sugerencia);

    const totalUnidades = sugerencia.reduce(
      (acumulador, objeto) => acumulador + objeto.units,
      0,
    );

    setSelectedSets(sugerencia);
    setTotalUnits(totalUnidades);
    mapArrayToAddProducts(sugerencia);
    setPrice(selectedSetsTotal);
  };

  const handleAddToCart = () => {
    setLoader(true);
    addToCart({
      cartId,
      body: selectedItemsToAddSets,
      isPDP: true,
    })
      .then(() => {
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const calculateDiscount = (precioProducto, cantidad, descuento) => {
    const priceItems = calcularTotal(precioProducto, cantidad);
    const priceString = typeof priceItems === 'string'
      ? priceItems.replace(/[^\d]/g, '')
      : priceItems;
    const precioNum = parseFloat(priceString);

    const discountValue = Math.round(precioNum * (descuento / 100));
    const priceWithDiscount = precioNum - discountValue;

    const unitPriceWithDiscount = Math.round(priceWithDiscount / cantidad);

    return {
      priceWithDiscount: calcularTotal(priceWithDiscount),
      discountValue: calcularTotal(discountValue),
      UnitPrice: calcularTotal(unitPriceWithDiscount),
    };
  };

  const multiPurposeValues = multiPurposeBadgeImage
    ? calculateDiscount(
      multiPurposeBadgeImage?.priceUnid,
      multiPurposeBadgeImage?.unidades,
      multiPurposeBadgeImage?.descuento,
    )
    : '';

  const handleApplyDiscount = (discount) => {
    suggestSets(discount);
    setUnits(discount);
    setPrice(multiPurposeValues.priceWithDiscount);
  };

  useEffect(() => {
    setArrayItems([]);
    setUnits(0);
    setSelectedSets([]);
    setTotalUnits(0);
    setPrice('0');
  }, [isOpen]);

  return (
    <div className={styles.ModalQuantity__content}>
      <div className={styles.ModalQuantity__content__firstBox}>
        <section
          className={`${styles.ModalQuantity__content__firstBox__units} ${styles.padding}`}
        >
          <Typography
            variant="epsilon24"
            className={`${styles.bold} ${styles.margin}`}
          >
            Cantidad de unidades
          </Typography>

          <Typography variant="omega17" className={`${styles.margin}`}>
            Cantidad de unidades Indica la cantidad de unidades que necesitas
          </Typography>

          <label
            id="label-calcualdora"
            htmlFor="units"
            className={styles.label}
          >
            <Typography variant="omega17" className={`${styles.bold}`}>
              Unidades
            </Typography>
          </label>
          <input
            className={styles.input}
            type="number"
            id="units"
            name="units"
            value={units}
            onChange={handleUnitsChange}
          />
          <button
            id="button-calcualdora"
            type="button"
            onClick={() => suggestSets(units)}
            style={{
              backgroundColor: theme.primary.shaded,
              color: theme.primary.text,
            }}
          >
            Sugerir
          </button>
        </section>

        <section
          className={`${styles.padding} ${styles.ModalQuantity__content__firstBox__units}`}
        >
          <div className={`${styles.ModalQuantity__content__firstBox__values}`}>
            <Typography variant="omega17" className={`${styles.bold}`}>
              Tipos
            </Typography>
            <Typography variant="omega17" className={`${styles.bold}`}>
              Cantidad
            </Typography>
            <Typography variant="omega17" className={`${styles.bold}`}>
              Valor
            </Typography>
          </div>

          {arrayItems.map((item) => (
            <div
              key={item.id}
              className={`${styles.ModalQuantity__content__firstBox__values}`}
            >
              <div className={styles.label2}>
                <label
                  htmlFor={`OtherCheck-${item.id}`}
                  aria-label="input"
                  className={styles.label2}
                >
                  <input
                    className={styles.input2}
                    type="checkbox"
                    checked={selectedSets.some((set) => set.id === item.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedSets([...selectedSets, item]);
                      } else {
                        setSelectedSets(
                          selectedSets.filter((set) => set.id !== item.id),
                        );
                      }
                      setTotalUnits(
                        e.target.checked
                          ? totalUnits + item.units
                          : totalUnits - item.units,
                      );
                    }}
                  />
                  <span className={styles.span} />
                </label>
                <span>{item.nameItem}</span>
              </div>

              <span>{item.cantidad}</span>
              <span>{`$ ${item.price}`}</span>
            </div>
          ))}
        </section>

        <section
          className={`${styles.padding} ${styles.ModalQuantity__content__firstBox__units}`}
        >
          <Typography
            variant="omega17"
            className={`${styles.margin} ${styles.bold}`}
          >
            Resumen de Compra
          </Typography>

          <section
            className={`${styles.ModalQuantity__content__firstBox__values}`}
          >
            <Typography variant="omega17">Cantidad de productos</Typography>
            <Typography variant="omega17">{totalUnits}</Typography>
          </section>

          <section
            className={`${styles.ModalQuantity__content__firstBox__values}`}
          >
            <Typography variant="omega17">Valor de compra</Typography>
            <Typography variant="omega17">
              {`$ ${calcularTotal(multiPurposeValues.length === 0 ? selectedSetsTotal : price)}`}
            </Typography>
          </section>
        </section>

        {multiPurposeBadgeImage && (
          <section className={`${styles.Range}`}>
            <div className={`${styles.Range__content}`}>
              <Typography variant="kappa14" className={`${styles.margin}`}>
                Puedes ahorrar aún más
              </Typography>

              <div className={`${styles.Range__text}`}>
                <Typography
                  variant="omega17"
                  style={{ marginRight: '5px' }}
                >
                  {`Lleva ${multiPurposeBadgeImage?.unidades} productos y `}
                </Typography>
                <Typography
                  variant="omega17"
                  style={{ color: '#008A00' }}
                  className={`${styles.margin} ${styles.bold}`}
                >
                  {`ahorrarás $${multiPurposeValues?.discountValue}`}
                </Typography>
              </div>

              <div className={`${styles.Range__text}`}>
                <Typography
                  variant="kappa14"
                  style={{ color: '#8C8C8C', marginRight: '5px' }}
                  className={`${styles.margin}`}
                >
                  {`$ ${multiPurposeValues?.UnitPrice} und -`}
                </Typography>
                <Typography
                  variant="kappa14"
                  style={{ color: '#8C8C8C', textDecoration: 'line-through' }}
                  className={`${styles.margin}`}
                >
                  {`Normal $ ${multiPurposeBadgeImage?.priceUnid} und`}
                </Typography>
              </div>

            </div>

            <div>
              <button
                type="button"
                onClick={() => handleApplyDiscount(multiPurposeBadgeImage?.unidades)}
                className={`${styles.Range__button}`}
              >
                <Typography variant="kappa14">Aplicar</Typography>
              </button>
            </div>
          </section>
        )}
      </div>

      <div
        className={`${styles.ModalQuantity__content__secondBox} ${styles.padding}`}
      >
        <Typography
          variant="zeta20"
          className={`${styles.margin} ${styles.bold}`}
        >
          Resumen
        </Typography>

        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
          className={`${styles.margin}`}
        >
          <Typography variant="omega17">Subtotal</Typography>
          <Typography variant="omega17">
            {`$ ${calcularTotal(selectedSetsTotal)}`}
          </Typography>
        </div>

        <ButtonStyled
          color={loader ? 'secondary' : 'primary'}
          variant={loader ? 'loading' : 'contained'}
          onClick={loader ? null : handleAddToCart}
          theme={theme}
          isPDP
        >
          {loader ? <Loader /> : 'Agregar al carro'}
        </ButtonStyled>

        <Typography
          variant="kappa14"
          style={{ color: '#8C8C8C' }}
          className={styles.PriceUnitesBox__valueProx}
        >
          *Valor aproximado sujeto a disponibilidad y destino del envío
        </Typography>
      </div>
    </div>
  );
};
ModalOtherQuantities.propTypes = {
  calcularTotal: PropTypes.func,
  isOpen: PropTypes.bool,
  sets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      Units: PropTypes.number,
    }),
  ),
  multiPurposeBadgeImage: PropTypes.shape({
    unidades: PropTypes.string,
    descuento: PropTypes.string,
    priceUnid: PropTypes.string,
  }),
};

export default ModalOtherQuantities;
