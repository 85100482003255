/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  // eslint-disable-next-line no-unused-vars
  extractFilters, filterByBrandName, filterByRating, pipe,
} from '../../../utils';

export const categoriesData = createApi({
  reducerPath: 'categoriesData',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.homecenter.com.co',
  }),
  keepUnusedDataFor: false,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getFilterProducts: builder.query({
      query: ({
        categoryId, currentpage = 1, filter = '', departamento = '10', cityId = '1', q = '',
      }) => `/s/search/v1/soco/category/products?categoryId=${categoryId}&currentpage=${currentpage}${filter}&zoneId=${cityId}&priceGroup=${departamento}&q=${q}`,
      // eslint-disable-next-line no-unused-vars
      transformResponse: (response, meta, args) => {
        // console.log(response); // TODO fix filters
        if (args.filter) {
          const filters = extractFilters(args.filter);
          const filteredProducts = pipe(
            // filterByBrandName(filters),
            filterByRating(filters),
            // Puedes seguir componiendo funciones aquí si necesitas más procesamiento
          )(response?.data?.results);
          return {
            ...response,
            data: { ...response.data, results: filteredProducts },
          };
        }
        return response;
      },
    }),
    getFilterCollections: builder.query({
      query: (collectionId) => `/s/search/v1/soco/collections/products?collectionId=${collectionId}`,
      transformResponse: (response) => response,
    }),
  }),
});

export const {
  useGetFilterProductsQuery,
  useLazyGetFilterProductsQuery,
  useGetFilterCollectionsQuery,
  useLazyGetFilterCollectionsQuery,
} = categoriesData;
