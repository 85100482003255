/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Kappa14 from '../../atoms/Kappa14/Kappa14';
import style from './AvailabilityStoreItem.module.scss';
import Omega17 from '../../atoms/Omega17/Omega17';

const AvailabilityStoreItem = ({
  place,
  quantity,
  storeAddress,
  stockStatus,
  googleAddress,
  onClickItem,
}) => {
  const isCriticalStock = parseFloat(quantity) < 4;

  const statusColor = {
    InStock: '#000000',
    LowStock: '#FFA500',
    CriticalStock: '#DD0021',
  };

  return (
    <button
      type="button"
      className={style.item}
      onClick={() => onClickItem(googleAddress)}
    >
      <div className={style.item__store}>
        <Omega17 strong>{place}</Omega17>
      </div>

      <div className={style.item__store}>
        <Kappa14 color="#8C8C8C">{storeAddress}</Kappa14>
      </div>

      <div
        className={style.item__quantity}
        style={{ backgroundColor: isCriticalStock ? '#F5F5F5' : '#F5F5F5' }}
      >
        <Kappa14 strong color={statusColor[stockStatus]}>
          {isCriticalStock
            ? `Quedan solo ${quantity} unidades`
            : `${quantity} unidades disponibles`}
        </Kappa14>
      </div>
    </button>
  );
};
AvailabilityStoreItem.propTypes = {
  place: PropTypes.string,
  quantity: PropTypes.string,
  storeAddress: PropTypes.string,
  googleAddress: PropTypes.string,
  stockStatus: PropTypes.string,
  onClickItem: PropTypes.func,
};

export default AvailabilityStoreItem;
