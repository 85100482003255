/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { useNavigate } from 'react-router';
import styles from './TabBodyMaps.module.scss';
import ButtonStyled from '../../atoms/ButtonStyled/Button';
import { ReactComponent as Marker } from '../../../../assets/svg/Marker.svg';
import { hasHttp } from '../../../../utils';

const MarkerContainer = () => <Marker className={styles.marker} />;

const TabBodyMaps = ({
  buttonUrl,
  buttonIcon,
  buttonLabel,
  mapData: data,
  selectedMap,
  order,
  selection,
  isDektop,
  ...props
}) => {
  const navigate = useNavigate();

  const handleClick = (key) => {
    if (hasHttp(key)) {
      window.open(key, '_blank');
    } else {
      navigate(key);
    }
  };

  return (
    <div style={{ order, display: selection ? '' : isDektop ? '' : 'none' }}>
      <div className={`${styles.tab__body}`}>
        <div
          style={{ height: '100vh', width: '100%', maxHeight: '500px' }}
          data-testid={process.env.NODE_ENV}
        >
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyDjEzRjkG68hXQdYsC9mgs1V4HBrNkpYPQ' }}
            center={selectedMap?.mapData?.center}
            zoom={selectedMap?.mapData?.zoom}
          >
            {data?.map((it, index) => (
              <MarkerContainer
                key={index}
                lat={it?.mapData?.center?.lat}
                lng={it?.mapData?.center.lng}
              />
            ))}
          </GoogleMapReact>
        </div>
        <figure>
          <ButtonStyled
            variant="contained"
            color="primary"
            startIcon={buttonIcon}
            onClick={() => handleClick(buttonUrl)}
            theme={props.theme}
          >
            {buttonLabel}
          </ButtonStyled>
        </figure>
      </div>
    </div>
  );
};

TabBodyMaps.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  buttonUrl: PropTypes.string,
  order: PropTypes.number,
  buttonLabel: PropTypes.string,
  buttonIcon: PropTypes.string,
  mapData: PropTypes.arrayOf({
    center: PropTypes.shape({}),
    zoom: PropTypes.number,
  }),
  selectedMap: PropTypes.shape({
    mapData: PropTypes.shape({
      center: PropTypes.shape({}),
      zoom: PropTypes.number,
    }),
  }),
  selection: PropTypes.bool,
  isDektop: PropTypes.bool,
};

export default TabBodyMaps;
