import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CardImage from '../../molecules/CardImage/CardImage';
import ButtonStyled from '../../atoms/ButtonStyled/Button';
import style from './BlogCard.module.scss';
import Typography from '../../atoms/Typography/Typography';

const BlogCard = ({
  title, theme, onClick, ...props
}) => {
  const isEasy = props.description?.complexity === 'Fácil';
  const filteredBadges = props.badges?.filter((badge, index) => index <= 2);
  return (
    <button
      type="button"
      onClick={() => onClick(props.button?.redirect)}
      data-testid="blog-card"
      className={classNames(style.blogCard, {
        [style.blogCard_vertical]: props.variant === 'vertical',
        [style.blogCard_horizontal]: props.variant === 'horizontal',
      })}
    >
      <CardImage
        className={classNames(style.blogCard__image, {
          [style.blogCard__image_vertical]: props.variant === 'vertical',
          [style.blogCard__image_horizontal]: props.variant === 'horizontal',
        })}
        urlImage={props.urlImage}
        name={title?.value}
        badges={filteredBadges}
      />
      <div
        className={classNames(style.blogCard__content, {
          [style.blogCard__content_vertical]: props.variant === 'vertical',
          [style.blogCard__content_horizontal]: props.variant === 'horizontal',
        })}
        style={{
          height: '100%',
          backgroundColor: props.colorContent ? props.colorContent : '#202221',
          width: props.variant === 'vertical' && '100%',
        }}
      >
        <Typography
          className={style.blogCard__title}
          variant={title?.variant}
          style={title?.strong ? { fontWeight: '700' } : { fontWeight: '400' }}
        >
          {title?.value}
        </Typography>
        <Typography
          className={style.blogCard__text}
          variant={props.description?.variant}
          style={
            props.description?.strong
              ? { fontWeight: '700' }
              : { fontWeight: '400' }
          }
        >
          {props.description?.value}
        </Typography>
        <ul className={style.blogCard__complexity}>
          {props.description?.complexity
            ? (
              <li>
                Dificultad:
                {' '}
                <span style={isEasy ? { color: '#008A00' } : { color: 'red' }}>
                  {props.description?.complexity}
                </span>
              </li>
            )
            : <li />}
          {props.description?.time ? (
            <li>
              Tiempo de ejecución:
              {' '}
              <span style={{ color: '#FBD600' }}>{props.description?.time}</span>
            </li>
          ) : <li />}
        </ul>
        <div className={style.blogCard__button}>
          <ButtonStyled
            variant={props.button?.variant}
            color={props.button?.color}
            rounded={props.button?.rounded}
            theme={theme}
            onClick={() => onClick(props.button?.redirect)}
          >
            {props.button?.value}
          </ButtonStyled>
        </div>
      </div>
    </button>
  );
};

BlogCard.propTypes = {
  variant: PropTypes.oneOf(['vertical', 'horizontal']),
  title: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
  }),
  description: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
    complexity: PropTypes.oneOf(['Fácil', 'Difícil']),
    time: PropTypes.string,
  }),
  urlImage: PropTypes.string,
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      urlicon: PropTypes.string,
      text: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
  button: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    rounded: PropTypes.bool,
    redirect: PropTypes.string,
  }),
  theme: PropTypes.shape(PropTypes.shape({})),
  onClick: PropTypes.func,
  colorContent: PropTypes.string,
};

BlogCard.defaultProps = {
  variant: 'vertical',
};

export default BlogCard;
