import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Localization } from '../../../../assets/svg/Localization.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/svg/ArrowDown.svg';

const StyledButton = styled.button`
  margin-bottom: 16px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  padding: 8px;
  display: grid;
  gap: 8px;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  cursor: pointer;
  font-size: 12px;
  color: #595959;
  line-height: 1.5;
  strong {
    font-weight: 700;
    margin-left: 4px;
    color: #0072ce;
  }
`;

const GeolocalizationButton = ({ ...props }) => (
  <StyledButton type="button" onClick={props.onClick}>
    <Localization />
    <span>
      El retiro y despacho varían según la ciudad.
      <br />
      Estás en:
      <strong>{props.city}</strong>
    </span>
    <ArrowDown />
  </StyledButton>
);

GeolocalizationButton.propTypes = {
  city: PropTypes.string,

  onClick: PropTypes.func,
};

export default GeolocalizationButton;
