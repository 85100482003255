/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProductSatisfactionBoxPDP.module.scss';

import { ReactComponent as IconSatisfaction } from '../../../../assets/svg/satisfaccion-garantizada.svg';

const ProductSatisfactionBoxPDP = ({ ...props }) => (
  <div className={styles.satisfactionBoxPDP__container}>
    <IconSatisfaction style={{ width: '100%' }} />
    <div className={styles.satisfactionBoxPDP__info}>
      <header className={styles.satisfactionBoxPDP__header}>
        <h3>{props.title}</h3>
        <a target="_blank" href={props.url} rel="noreferrer">{props.label}</a>
      </header>
      <p>{props.text}</p>
    </div>
  </div>
);

ProductSatisfactionBoxPDP.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
  text: PropTypes.string,
};

export default ProductSatisfactionBoxPDP;
