/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-simply-carousel';
import { useNavigate } from 'react-router';
import style from './SliderFeaturedProductsInsider.module.scss';
import ProductCard from '../ProductCard/ProductCard';
import { ReactComponent as ArrowLeft2 } from '../../../../assets/svg/ArrowLeft2.svg';
import { ReactComponent as ArrowRight2 } from '../../../../assets/svg/ArrowRight2.svg';
import Typography from '../../atoms/Typography/Typography';
import { useLazyGetSimilarProductDetailsHomeQuery } from '../../../../redux/slices/PDP/insiderApi';
import Container from '../../../layout/Container/Container';

const SliderFeaturedProductsInsider = ({ ...props }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const navigate = useNavigate();
  const [getSimilarProductDetailsHome,
    { data, isSuccess }] = useLazyGetSimilarProductDetailsHomeQuery();

  const handleClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  if (!props?.state) {
    return null;
  }

  useEffect(() => {
    getSimilarProductDetailsHome(props?.products);
  }, [props?.products]);

  return (
    <Container>
      <div className={style.featuredProducts}>
        <div className={style.featuredProducts__header}>
          <Typography
            variant={props?.title?.variant}
            style={
              props?.title?.strong ? { fontWeight: '700' } : { fontWeight: '400' }
            }
          >
            {props?.title?.value}
          </Typography>
        </div>
        {isSuccess && (
        <div className={style.featuredProducts__content}>
          <Carousel
            activeSlideIndex={activeSlide}
            onRequestChange={setActiveSlide}
            infinite={false}
            center
            disableNavIfAllVisible
            responsiveProps={[
              {
                minWidth: 1205,
                itemsToScroll: 1,
                itemsToShow: 7,
              },
              {
                maxWidth: 1200,
                minWidth: 1020,
                itemsToScroll: 1,
                itemsToShow: 6,
              },
              {
                minWidth: 768,
                maxWidth: 1018,
                itemsToScroll: 1,
                itemsToShow: 5,
              },
              {
                minWidth: 321,
                maxWidth: 767,
                itemsToShow: 4,
                itemsToScroll: 1,
              },
              {
                maxWidth: 320,
                itemsToShow: 3,
                itemsToScroll: 1,
              },
            ]}
            itemsListProps={{
              style: {
                touchAction: 'none',
              },
            }}
            containerProps={{
              style: {
                position: 'relative',
              },
            }}
            forwardBtnProps={{
              children: <ArrowRight2 />,
              className: style.nextButton,
            }}
            backwardBtnProps={{
              children: <ArrowLeft2 />,
              className: style.prevButton,
            }}
            // itemsToShow={items}
            speed={300}
          >
            {data?.data.map((product, index) => (
              <ProductCard
                key={index}
                type="similar"
                name={product.name}
                urlImage={product.image_url}
                // urlBadge={product.urlBadge}
                brand={product.brand}
                productId={product.item_id}
                // rating={product.rating}
                // totalReviews={product.totalReviews}
                price={product.price.COP}
                discount={product.discount.COP}
                normalPrice={product.original_price.COP}
                // timerTime={product.timerTime}
                onClick={() => handleClick(product.item_id)}
              />
            ))}
          </Carousel>
        </div>
        )}
      </div>
    </Container>
  );
};

SliderFeaturedProductsInsider.propTypes = {
  state: PropTypes.bool,
  variant: PropTypes.oneOf(['featured', 'timed']),
  title: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
  }),
  products: PropTypes.arrayOf({
    data: PropTypes.shape({}),
  }),
};

export default SliderFeaturedProductsInsider;
