import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Small from '../../atoms/Small/Small';
import Caption12 from '../../atoms/Caption12/Caption12';
import Typography from '../../atoms/Typography/Typography';
import RatingStars from '../RatingStars/RatingStars';
import style from './ProductPrimaryInfo.module.scss';

const ProductPrimaryInfo = ({
  brand, sku, displayName, rating, totalReviews, onRatingClick, className, variant,
}) => (
  <div className={classNames(style.info, className)}>
    <div className={style.info__header}>
      <Small content={brand} />
      <Caption12 content={`Código ${sku}`} />
    </div>
    <Typography variant={variant}>
      {displayName}
    </Typography>
    <button type="button" className={style.info__ratingActions} aria-label="Rating" onClick={onRatingClick}>
      <RatingStars color="#006AC1" rating={parseFloat(rating)} name={displayName} showReviews={false} />
      <Small content={parseFloat(rating).toFixed(1)} />
      <Small content={`(${totalReviews})`} />
    </button>
  </div>
);

ProductPrimaryInfo.propTypes = {
  brand: PropTypes.string,
  sku: PropTypes.string,
  displayName: PropTypes.string,
  rating: PropTypes.string,
  totalReviews: PropTypes.string,
  onRatingClick: PropTypes.func,
  className: PropTypes.string,
  variant: PropTypes.string,
};

export default ProductPrimaryInfo;
