import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import styles from './TabButtonMap.module.scss';

const ButtonWrapper = styled.button`
  strong {
    background-color: ${(props) => props.theme.primary.primary};
  }
`;
const TabButtonMap = ({
  label,
  id,
  onClick,
  selection,
  address,
  time,
  ...props
}) => {
  const handleClick = (event) => {
    onClick(event.currentTarget.value);
  };

  useEffect(() => {}, [selection]);

  const trueActive = selection === label;

  return (
    <ButtonWrapper
      value={label}
      type="button"
      onClick={handleClick}
      theme={props.theme}
      className={`${styles.tab__button} ${
        trueActive ? `${styles.tab__button__active}` : ''
      }`}
    >
      <span className={styles.point__title}>
        <strong>{id}</strong>
        {label}
      </span>
      <span className={styles.point__info}>
        <address>{address}</address>
        <time>{time}</time>
      </span>
    </ButtonWrapper>
  );
};

TabButtonMap.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selection: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

export default TabButtonMap;
