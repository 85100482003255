import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const miduApi = createApi({
  reducerPath: 'miduApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://mudiview.mudi.com.co:7443/' }),
  endpoints: (builder) => ({
    getProduct: builder.query({
      query: (body) => ({
        url: 'product/getProductsUrl',
        method: 'POST',
        headers: {
          email: 'mmorenoz@homecenter.co',
          password: 'Mudi2022',
          tokenapi: 'XDpFeZH5H98fJUdAEkwP',
        },
        body,
      }),
    }),
  }),
});

export const { useGetProductQuery } = miduApi;
