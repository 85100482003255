import servicesImage from '../../../assets/svg/Services.svg';
import warrantiesImage from '../../../assets/images/warranty.png';
/* eslint-disable no-restricted-syntax */
const useBadges = (data, services, warranties) => {
  const {
    badges: badgesObject,
  } = data;
  const notAllowedBadge = ['discountBadge', 'multiPurposeBadge', 'priceBadge'];
  const items = {};
  for (const key in badgesObject) {
    if (!notAllowedBadge.includes(key)) {
      items[key] = badgesObject[key];
    }
  }
  let badges;
  if (services.length) {
    badges = [
      ...Object.values(items).map((it) => it),
      { type: 'services', value: 'Servicios hogar', image: servicesImage },
    ];
  } else if (warranties.length) {
    badges = [
      ...Object.values(items).map((it) => it),
      {
        type: 'warranties',
        value: 'Garantía extendida',
        image: warrantiesImage,
      },
    ];
  } else {
    badges = [...Object.values(items).map((it) => it)];
  }
  return { badges };
};

export default useBadges;
