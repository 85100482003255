import PropTypes from 'prop-types';
import style from './InputText.module.scss';

const InputText = ({
  state, value, onChange, placeholder, id, label,
}) => {
  if (!state) {
    return null;
  }
  return (
    <label htmlFor="mail" className={style.InputText}>
      <span className={style.label}>
        {label}
      </span>

      <input
        id={id}
        name={id}
        type="text"
        className={style.InputText__input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </label>
  );
};
InputText.propTypes = {
  state: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
};

export default InputText;
