import React from 'react';
import PropTypes from 'prop-types';
import style from './FilterAndSort.module.scss';
import { ReactComponent as Filter } from '../../../../assets/svg/Filter.svg';

const FilterAndSort = ({ onHandleClick, quantity }) => (
  <button className={style.filterAndSort} type="button" onClick={onHandleClick}>
    <Filter />
    <span>Filtrar y ordenar</span>
    <span>
      {`(${quantity})`}
    </span>
  </button>
);

FilterAndSort.propTypes = {
  onHandleClick: PropTypes.func,
  quantity: PropTypes.string,
};

export default FilterAndSort;
