import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import style from './TagBlog.module.scss';

const TagBlog = ({
  urlicon, text, color, className,
}) => (
  <div className={className} style={{ display: 'inline-block' }}>
    <div className={style.tagBlog} style={color && { backgroundColor: color }}>
      {urlicon && <SVG src={urlicon} width={14} height={14} />}
      <span className={style.tagBlog__text}>{text}</span>
    </div>
  </div>
);

TagBlog.propTypes = {
  urlicon: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default TagBlog;
