import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Container.module.scss';

const Container = ({ children, fullScreenMobile, fullScreenTablet }) => (
  <div
    className={classNames(style.container, {
      [style.container_fullMobile]: fullScreenMobile,
      [style.container_fullTablet]: fullScreenTablet,
    })}
  >
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node,
  fullScreenMobile: PropTypes.bool,
  fullScreenTablet: PropTypes.bool,
};

export default Container;
