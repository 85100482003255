import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { isSVG } from '../../../../utils';
import styles from './CardFilterPLP.module.scss';
import 'animate.css';

const CardFilter = ({
  imgUrl,
  caption,
  backgroundColor,
  selection,
  text,
  onClick,
  cta,
  defaultItem,
}) => {
  const handleClick = (event) => {
    onClick(
      event.currentTarget.value,
      backgroundColor,
      event.currentTarget.dataset.cta,
    );
  };

  const detectarDispositivoMovil = () => {
    const stylesMobile = {
      iOS: {
        height: '30px',
        width: '30px',
      },
      Android: {
        height: '40px',
        width: '40px',
      },
    };
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Detectar dispositivos iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return stylesMobile.iOS;
    }

    // Detectar dispositivos Android
    return stylesMobile.Android;
  };

  // Ejemplo de uso
  const estilosDispositivo = detectarDispositivoMovil();

  const trueActive = selection ? selection === cta : defaultItem === cta;

  return (
    <button
      value={caption}
      className={`${styles.card} ${trueActive ? styles.card__active : ''}`}
      type="button"
      onClick={handleClick}
      data-testid={backgroundColor?.selected}
      style={{
        backgroundColor:
        trueActive
          ? backgroundColor?.selected
          : backgroundColor?.unSelected,
      }}
      data-cta={cta}
    >
      {isSVG(imgUrl) ? (
        <SVG src={imgUrl} style={{ ...estilosDispositivo }} />
      ) : (
        <img
          className={styles.image}
          style={{ ...estilosDispositivo }}
          src={imgUrl}
          alt={caption}
        />
      )}

      <span
        style={{
          color:
          trueActive
            ? text.selected
            : text.unSelected,
        }}
        className={styles.caption}
      >
        {caption}
      </span>
    </button>
  );
};
CardFilter.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  text: PropTypes.string,
  defaultItem: PropTypes.string,
  caption: PropTypes.string.isRequired,
  cta: PropTypes.string,
  selection: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CardFilter;
