import React from 'react';
import PropTypes from 'prop-types';
import Container from '../../../layout/Container/Container';
import PetizoosInfoCard from '../../molecules/PetizoosInfoCard/PetizoosInfoCard';
import style from './StoreDescription.module.scss';
import Typography from '../../atoms/Typography/Typography';

const StoreDescription = ({
  title, variant, content, state,
}) => {
  if (!state) {
    return null;
  }
  const filteredContent = content?.filter((el, index) => index <= 2);
  return (
    <div className={style.petizoosDescription}>
      <Container>
        <Typography
          className={style.petizoosDescription__title}
          variant="delta29"
          style={{ fontWeight: '700' }}
        >
          {title}
        </Typography>
        <div className={style.petizoosDescription__content}>
          {filteredContent?.map((infoCard) => (
            <PetizoosInfoCard
              key={infoCard.id}
              variant={variant}
              imageurl={infoCard?.imageurl}
              title={infoCard?.title}
              description={infoCard?.description}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

StoreDescription.propTypes = {
  state: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['vertical', 'horizontal']),
  content: PropTypes.arrayOf(PropTypes.shape({
    imageurl: PropTypes.string,
    title: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      strong: PropTypes.bool,
    }),
    description: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      strong: PropTypes.bool,
    }),
  })),
};

StoreDescription.defaultProps = {
  state: false,
  variant: 'vertical',
};

export default StoreDescription;
