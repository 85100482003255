import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const insiderApi = createApi({
  reducerPath: 'insiderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),
  endpoints: (builder) => ({
    getSimilarProductDetails: builder.query({
      query: ({ productId, categoryList, size }) => `https://recommendation.api.useinsider.com/10008298/es_CO/similar/product/${productId}/?categoryList=${categoryList}&details=true&size=${size}&currency=COP&filter=*([item_id][!=][${productId}])&`,
    }),
    getSimilarProductDetailsHome: builder.query({
      query: (code) => `${code}`,
    }),
  }),
});
// https://recommendation.api.useinsider.com/10008298/es_CO/

export const {
  useGetSimilarProductDetailsQuery,
  useLazyGetSimilarProductDetailsQuery,
  useLazyGetSimilarProductDetailsHomeQuery,
} = insiderApi;
