import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './InputRange.module.scss';
import { ReactComponent as ArrowRight2 } from '../../../../assets/svg/ArrowRight2.svg';

const InputRange = ({
  state, priceRange, currencySymbol, onSendClick, className,
}) => {
  const [range, setRange] = useState({
    min: '',
    max: '',
    label: priceRange?.label,
  });
  if (!state) {
    return null;
  }

  const handleChange = (event) => {
    setRange({ ...range, [event.target.name]: event.target.value });
  };

  return (
    <div className={classNames(style.inputRange, className)}>
      <label htmlFor="min" className={style.inputRange__label}>
        {currencySymbol}
        <input
          id="min"
          type="text"
          className={style.inputRange__input}
          placeholder={priceRange?.min}
          name="min"
          value={range.min}
          onChange={handleChange}
        />
      </label>
      <span className={style.inputRange__divider}>-</span>
      <label htmlFor="max" className={style.inputRange__label}>
        {currencySymbol}
        <input
          id="max"
          type="text"
          className={style.inputRange__input}
          placeholder={priceRange?.max}
          name="max"
          value={range.max}
          onChange={handleChange}
        />
      </label>
      <button
        className={style.inputRange__button}
        type="button"
        onClick={() => onSendClick(range)}
        aria-label="ArrowRight"
      >
        <ArrowRight2 />
      </button>
    </div>
  );
};
InputRange.propTypes = {
  state: PropTypes.bool,
  priceRange: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
    label: PropTypes.string,
  }),
  currencySymbol: PropTypes.string,
  className: PropTypes.string,
  onSendClick: PropTypes.func,
};

export default InputRange;
