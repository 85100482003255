// STRINGS DATA
export const opinionStatisticsStringsData = {
  title: 'Opiniones de clientes',
  buttonLabel: 'Escribir opinión',
};

// MOCKS PROPS DATA
export const opinionStatisticsMockData = {
  ratingAverage: '4,3',
  totalReviews: '7',
  statistics: [
    { rating: 1, numberOfPersons: 4, percent: 40 },
    { rating: 2, numberOfPersons: 6, percent: 30 },
    { rating: 3, numberOfPersons: 0, percent: 0 },
    { rating: 4, numberOfPersons: 1, percent: 10 },
    { rating: 5, numberOfPersons: 1, percent: 20 },
  ],
};
