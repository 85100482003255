// STRINGS DATA
export const productComparisonStringsData = {
  title: 'Encuentra la opción correcta',
  cleanAllText: 'Limpiar todo',
  closeText: 'Cerrar',
};
// MOCKS PROPS DATA
export const productComparisonHeaderCarcenter = {
  buttonColor: 'black',
  dividerColor: '#C30039',
};

export const productComparisonHeaderPetcenter = {
  buttonColor: 'secondary',
  dividerColor: '#5EA1DB',
};
