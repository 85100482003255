import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BLOB_STORAGE } from '../../../constants/index';

export const blobStorageApi = createApi({
  reducerPath: 'blobStorageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BLOB_STORAGE,
  }),
  endpoints: (builder) => ({
    getBlobStorageData: builder.query({
      query: (param) => `/${param}/home_general.json`,
    }),
    getBlobStorageCampaing: builder.query({
      query: (param) => `/${param}/home_campaign.json`,
    }),
    getThemeData: builder.query({
      query: (param) => `/${param}/theme.json`,
    }),
    getBlobStorageCategoriesData: builder.query({
      query: ({ store, key }) => `/${store}/home_categorias_${key}.json`,
    }),
  }),
});

export const {
  useGetBlobStorageDataQuery,
  useGetBlobStorageCampaingQuery,
  useGetThemeDataQuery,
  useLazyGetBlobStorageCategoriesDataQuery,
} = blobStorageApi;
