import { createSlice } from '@reduxjs/toolkit';

const transversalSlice = createSlice({
  name: 'transversalSliceReducer',
  initialState: {
    store: '',
    campaing: {},
    selectedFilter: {},
    smallHeader: {
      logo: '',
    },
    theme: {},
    currentCategory: '',
    mainCategory: '',
    departamento: '',
    ciudad: {
      name: '',
      id: '',
    },
    barrio: '',
    location: '',
    cartId: '',
    whatsAppCta: '',
  },
  reducers: {
    setStore: (state, action) => {
      state.store = action.payload;
    },
    setCampaing: (state, action) => {
      state.campaing = action.payload;
    },
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    setMainCategory: (state, action) => {
      state.mainCategory = action.payload;
    },
    setSmallHeader: (state, action) => {
      state.smallHeader = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setDepartamento: (state, action) => {
      state.departamento = action.payload;
    },
    setCiudad: (state, { payload }) => {
      const { name, id } = payload;
      state.ciudad.name = name;
      state.ciudad.id = id;
    },
    setBarrio: (state, action) => {
      state.barrio = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setCartId: (state, action) => {
      state.cartId = action.payload;
    },
    setWhatsAppCta: (state, action) => {
      state.whatsAppCta = action.payload;
    },
  },
});

export const {
  setStore,
  setCampaing,
  setSelectedFilter,
  setCurrentCategory,
  setMainCategory,
  setSmallHeader,
  setTheme,
  setBarrio,
  setLocation,
  setCiudad,
  setDepartamento,
  setCartId,
  setNombreCiudad,
  setWhatsAppCta,
} = transversalSlice.actions;
export default transversalSlice.reducer;
