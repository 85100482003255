import { createSlice } from '@reduxjs/toolkit';

const geolocalizationModalSlice = createSlice({
  name: 'geolocalizationModal',
  initialState: {
    isOpen: false,
  },
  reducers: {
    setOpenModal(state, { payload }) {
      state.isOpen = payload.isOpen;
    },
  },
});

export const { setOpenModal } = geolocalizationModalSlice.actions;

export default geolocalizationModalSlice.reducer;
