import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Typography2.module.scss';

const Typography2 = ({ children, variant, ...props }) => (
  <span
    className={classNames(
      style.typography,
      props.className,
      { [style.typography__epsilon24]: variant === 'epsilon24' },
    )}
    style={props.style}
  >
    {children}
  </span>
);

Typography2.propTypes = {
  /**
   * Content of the component
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Applies theme and component used for the root node
   */
  variant: PropTypes.oneOf([
    'gamma35',
    'delta29',
    'epsilon24',
    'zeta20',
    'omega17',
    'kappa14',
    'h4',
  ]),
  /**
   * Styles to add to component
   */
  style: PropTypes.shape({}),
  className: PropTypes.string,
};
export default Typography2;
