import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './PetizoosInfoCard.module.scss';
import Typography from '../../atoms/Typography/Typography';

const PetizoosInfoCard = ({ imageurl, ...props }) => {
  const isVertical = props.variant === 'vertical';
  const isHorizontal = props.variant === 'horizontal';

  const titleClassName = classNames(style.infoCard__title, {
    [style.infoCard__title_vertical]: isVertical,
    [style.infoCard__title_horizontal]: isHorizontal,
  });

  const descriptionClassName = classNames(style.infoCard__description, {
    [style.infoCard__description_vertical]: isVertical,
    [style.infoCard__description_horizontal]: isHorizontal,
  });

  const titleStyle = props.title?.strong ? { fontWeight: '700' } : { fontWeight: '400' };
  const descriptionStyle = props.description?.strong ? { fontWeight: '700' } : { fontWeight: '400' };

  return (
    <div
      data-testid="info-card"
      className={classNames(style.infoCard, {
        [style.infoCard_vertical]: isVertical,
        [style.infoCard_horizontal]: isHorizontal,
      })}
    >
      <div className={classNames(style.infoCard__image, {
        [style.infoCard__image_vertical]: isVertical,
        [style.infoCard__image_horizontal]: isHorizontal,
      })}
      >
        <img src={imageurl} alt={props.title?.value} />
      </div>
      <div>
        <Typography
          variant={props.title?.variant}
          style={titleStyle}
          className={titleClassName}
        >
          {props.title?.value}
        </Typography>
        <Typography
          variant={props.description?.variant}
          style={descriptionStyle}
          className={descriptionClassName}
        >
          {props.description?.value}
        </Typography>
      </div>
    </div>
  );
};

PetizoosInfoCard.propTypes = {
  /**
   * Variant of the Info Card component
   */
  variant: PropTypes.oneOf(['vertical', 'horizontal']),
  /**
   * Url image of the Info Card component
   */
  imageurl: PropTypes.string,
  /**
   * Title of the Info Card component
   */
  title: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
  }),
  /**
   * Description of the Info Card component
   */
  description: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
  }),
};

PetizoosInfoCard.defaultProps = {
  variant: 'vertical',
  title: PropTypes.shape({
    strong: false,
  }),
  description: PropTypes.shape({
    strong: false,
  }),
};

export default PetizoosInfoCard;
