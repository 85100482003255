import React from 'react';
import PropTypes from 'prop-types';
import Small from '../../atoms/Small/Small';
import style from './SizeSelector.module.scss';

const SizeSelector = ({ state, children, title }) => {
  if (!state) {
    return null;
  }
  return (
    <div className={style.sizeSelector__container}>
      <Small
        className={style.sizeSelector__title}
        content={title}
        bold
      />
      {children}
    </div>
  );
};
SizeSelector.propTypes = {
  state: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default SizeSelector;
