/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../../atoms/SectionTitle/SectionTitle';
import CalculatorCard from '../../molecules/CalculatorCard/CalculatorCard';
import style from './CalculatorSection.module.scss';
import Container from '../../../layout/Container/Container';

const CalculatorSection = ({
  state, title, cards, onClick, renderItems, ...props
}) => {
  const [initialItems, setInitialItems] = useState(renderItems);
  const copyCards = cards?.slice(0, 5);
  if (!state) {
    return null;
  }
  return (
    <div className={style.calculatorSection}>
      <Container>
        <div className={style.calculatorSection__title}>
          <SectionTitle text={title} />
        </div>
        <div className={style.calculatorSection__content}>
          {copyCards?.map((card) => {
            if (card.id <= initialItems) {
              return (
                <CalculatorCard
                  key={card?.id}
                  state={card?.state}
                  titleCardTop={card?.titleCardTop}
                  titleCardBottom={card?.titleCardBottom}
                  title={card?.title}
                  content={card?.content}
                  backgroundImageDesktop={card?.backgroundImageDesktop}
                  backgroundImageMobile={card?.backgroundImageMobile}
                  position={card?.position}
                  button={card?.button}
                  onClick={onClick}
                  theme={props.theme}
                />
              );
            }
          })}
        </div>
        {renderItems !== copyCards.length
        && (
        <div className={style.calculatorSection__button}>
          <button
            type="button"
            className={style.calculatorSection__button__text}
            onClick={() => {
              if (initialItems === cards.length) {
                setInitialItems(renderItems);
              } else {
                setInitialItems(cards.length);
              }
            }}
          >
            {`${initialItems === cards.length ? 'Ver Menos...' : 'Ver Más...'}`}
          </button>
        </div>
        )}

      </Container>
    </div>
  );
};

CalculatorSection.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  state: PropTypes.bool,
  onClick: PropTypes.func,
  renderItems: PropTypes.number,
  /**
   * The title of the section
   */
  title: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      state: PropTypes.bool,
      title: PropTypes.shape({
        value: PropTypes.string,
        variant: PropTypes.string,
        strong: PropTypes.bool,
      }),
      content: PropTypes.shape({
        value: PropTypes.string,
        variant: PropTypes.string,
        strong: PropTypes.bool,
      }),
      backgroundImageDesktop: PropTypes.string,
      backgroundImageMobile: PropTypes.string,
      position: PropTypes.oneOf(['left', 'center', 'right']),
      button: PropTypes.shape({
        value: PropTypes.string,
        variant: PropTypes.string,
        color: PropTypes.string,
        linkto: PropTypes.string,
      }),
    }),
  ),
};

CalculatorSection.defaultProps = {
  state: false,
};

export default CalculatorSection;
