import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import style from './EmbedVideo.module.scss';

const EmbedVideo = ({ videoUrl, ...props }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 768px)',
  });

  if (!videoUrl) {
    return null;
  }

  const getYoutubeUrl = (url) => {
    const arrayVideoUrl = url?.split('=');
    const embed = 'https://www.youtube.com/embed/';
    return `${embed}${arrayVideoUrl[arrayVideoUrl.length - 1]}`;
  };

  return (
    <div
      data-testid="embed-video"
      style={{
        ...props.style,
        height: isDesktopOrLaptop
          ? props?.videoHeigth?.desktop
          : props?.videoHeigth?.mobile,
      }}
      className={classNames(style.embedVideo, props.className)}
    >
      <iframe
        width="100%"
        height="100%"
        src={getYoutubeUrl(videoUrl)}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </div>
  );
};

EmbedVideo.propTypes = {
  /**
   * The URL of the YouTube video
   */
  videoUrl: PropTypes.string,
  /**
   * The Heigth of the YouTube video
   */
  videoHeigth: PropTypes.shape({
    mobile: PropTypes.number,
    desktop: PropTypes.number,
  }),
  /**
   * css styles of the video
   */
  className: PropTypes.string,
  /**
   * css styles of the video
   */
  style: PropTypes.shape({}),
};

export default React.memo(EmbedVideo);
