/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import PropTypes from 'prop-types';
import ReviewsTabButtonPDP from '../../atoms/ReviewsTabButtonPDP/ReviewsTabButtonPDP';
import CustomerReviewsCardPDP from '../../molecules/CustomerReviewsCardPDP/CustomerReviewsCardPDP';
import styles from './ReviewsTabsPDP.module.scss';

const ReviewsTabsPDP = ({ ...props }) => {
  const [activeState, setActiveState] = useState(props?.buttons[0]?.label);

  const handleClick = (value) => {
    setActiveState(value);
  };

  const renderButtons = (buttonsData) => buttonsData.map((item, index) => (
    <ReviewsTabButtonPDP key={index} {...item} selection={activeState} onClick={handleClick} />
  ));
  /* const renderTabs = (tabsData) => tabsData.map((item) => (
    <CustomerReviewsCardPDP {...item} />
  )); */
  const renderTabs = (tabsData) => tabsData[activeState].map((item, index) => (
    <CustomerReviewsCardPDP key={index} {...item} />
  ));
  return (
    <div className={styles.container}>
      <div className={styles.buttons}>{renderButtons(props.buttons)}</div>
      <div className={styles.cards}>{renderTabs(props.data)}</div>
    </div>
  );
};

ReviewsTabsPDP.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    amount: PropTypes.number,
  })),
  data: PropTypes.shape({}),
};

export default ReviewsTabsPDP;
