import React from 'react';
import PropTypes from 'prop-types';
// import Typography from '../../atoms/Typography/Typography';
// import ButtonStyled from '../../atoms/ButtonStyled/Button';
import style from './BannerCard.module.scss';

const BannerCard = ({ content, cardClick }) => (
  <>
    <button
      type="button"
      className={style.bannerCard__mobile}
      onClick={() => cardClick(content.mobile?.button?.linkto)}
    >
      <img
        className={style.bannerCard__img_mobile}
        src={content?.mobile?.backgroundImage}
        alt={content?.mobile?.backgroundImage}
      />
    </button>
    <button
      type="button"
      style={{ cursor: content.desktop?.button?.linkto ? 'pointer' : 'none' }}
      className={style.bannerCard__desktop}
      onClick={() => cardClick(content.desktop?.button?.linkto)}
    >
      <img
        className={style.bannerCard__img__desktop}
        src={content?.desktop?.backgroundImage}
        alt={content?.desktop?.backgroundImage}
      />
    </button>
  </>
);

BannerCard.propTypes = {
  cardClick: PropTypes.func,
  /**
   * Content of the Banner Card component
   */
  content: PropTypes.shape({
    mobile: PropTypes.shape({
      backgroundImage: PropTypes.node,
      button: PropTypes.shape({
        value: PropTypes.string,
        variant: PropTypes.string,
        color: PropTypes.string,
        startIcon: PropTypes.string,
        endIcon: PropTypes.string,
        linkto: PropTypes.string,
      }),
    }),
    desktop: PropTypes.shape({
      backgroundImage: PropTypes.node,
      button: PropTypes.shape({
        value: PropTypes.string,
        variant: PropTypes.string,
        color: PropTypes.string,
        startIcon: PropTypes.string,
        endIcon: PropTypes.string,
        linkto: PropTypes.string,
      }),
    }),
  }),
};

export default BannerCard;
