/* eslint-disable react/no-danger */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../../atoms/Button/Button';
import ArrowUp from '../../../../assets/svg/ArrowUp.svg';
import ArrowDown from '../../../../assets/svg/ArrowDown.svg';
import style from './Collapse.module.scss';

const Collapse = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      const height = contentRef.current.clientHeight;
      if (height > 336) {
        setIsButtonVisible(true);
      }
    }, 100);
  }, []);

  const toggleCollapse = () => setIsOpen(!isOpen);

  return (
    <div className={style.collapse}>
      <div style={{ maxHeight: isOpen ? 'none' : 336, overflow: 'hidden' }}>
        <div ref={contentRef}>
          {children}
        </div>
      </div>
      {isButtonVisible && (
        <div className={style.collapse__actions}>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            startIcon={isOpen ? ArrowUp : ArrowDown}
            onClick={toggleCollapse}
          >
            {isOpen ? 'Ver menos' : 'Ver más'}
          </Button>
        </div>
      )}
    </div>
  );
};

Collapse.propTypes = {
  children: PropTypes.node,
};

export default Collapse;
