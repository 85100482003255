/* eslint-disable react/prop-types */
import React from 'react';
// import PropTypes from 'prop-types';
import Container from '../../../layout/Container/Container';

import styles from './LegalComponent.module.scss';

const LegalComponent = ({ content, backgroundColor, ...props }) => {
  if (!props.state) {
    return null;
  }

  return (
    <Container>
      <div className={styles.LegalComponent} style={{ backgroundColor: backgroundColor || '' }}>
        <content className={styles.LegalComponent__content}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </content>
      </div>
    </Container>
  );
};

// LegalComponent.defaultProps = {
//   state: PropTypes.bool,
//   content: PropTypes.string,
//   backgroundColor: PropTypes.string,
// };

export default LegalComponent;
