/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import Typography from '../../atoms/Typography/Typography';
import ButtonStyled from '../../atoms/ButtonStyled/Button';
import style from './BannerCard2.module.scss';
import { hasHttp } from '../../../../utils';

// const hasContent = () => title || content || props.button;
const BannerCard2 = ({
  title, content, align, ...props
}) => {
  const navigate = useNavigate();

  const handleClick = (key) => {
    if (hasHttp(key)) {
      window.open(key, '_blank');
    } else {
      navigate(key);
    }
  };

  return (
    <div
      data-testid="banner-card"
      style={{
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        className={style.bannerCard}
        style={{
          padding: '24px',
          height: '100%',
          width: '100%',
          textAlign: align,
          alignItems: align,
        }}
      >
        {title && (
          <Typography
            variant="h1"
            className={style.bannerCard__title}
            style={{ fontWeight: title?.strong ? '700' : '400', color: title?.color || '' }}
          >
            {title?.value}
          </Typography>
        )}
        {content && (
          <Typography
            variant={content?.variant}
            className={style.bannerCard__content}
            style={{ fontWeight: content?.strong ? '700' : '400', color: content?.color || '' }}
          >
            {content?.value}
          </Typography>
        )}
        {props.button && (
          <div className={style.bannerCard__button}>
            <ButtonStyled
              variant={props?.button?.variant}
              color={props?.button?.color}
              startIcon={props?.button?.startIcon}
              endIcon={props?.button?.endIcon}
              onClick={() => handleClick(props?.button?.link)}
              theme={props.theme}
            >
              {props?.button?.value}
            </ButtonStyled>
          </div>
        )}
      </div>
    </div>
  );
};

BannerCard2.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  /**
   * Title of the Banner Card component
   */
  title: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
    color: PropTypes.string,
  }),
  /**
   * Content of the Banner Card component
   */
  content: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
    color: PropTypes.string,
  }),
  /**
   * Button of the Banner Card component
   */
  button: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    startIcon: PropTypes.string,
    endIcon: PropTypes.string,
    onClick: PropTypes.string,
    link: PropTypes.string,
  }),
  /**
   * Varaint of home header
   */
  align: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
  /**
   * Background Image of Banner Component
   */
  backgroundImage: PropTypes.node,
};

export default BannerCard2;
