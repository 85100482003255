// STRINGS DATA
export const reviewsTemplateStringsData = {
  title: 'Opiniones de clientes',
};

// MOCKS PROPS DATA
export const reviewsTemplateMockData = {
  ratingAverage: '4.3',
  totalReviews: '7',
  statistics: [
    { rating: 1, numberOfPersons: 4, percent: 40 },
    { rating: 2, numberOfPersons: 6, percent: 30 },
    { rating: 3, numberOfPersons: 0, percent: 0 },
    { rating: 4, numberOfPersons: 1, percent: 10 },
    { rating: 5, numberOfPersons: 1, percent: 20 },
  ],
  buttons: [
    {
      label: 'Todas',
      amount: 3,
    },
    {
      label: 'Positivas',
      amount: 2,
    },
    {
      label: 'Negativas',
      amount: 1,
    },
  ],
  data: {
    Todas: [
      {
        review: {
          title: 'un buen producto para mis amigas fieles',
          date: 'Hace 2 meses',
          message:
          'Me encanta comprar este producto porque siempre está fresco y le ayuda mucho a mis dos amigas Luna y Estrella',
        },
        recommend: true,
        author: 'wizzecar',
        ratingAverage: '3',
      },
      {
        review: {
          title: 'otro buen producto para mis amigas fieles',
          date: 'Hace 3 meses',
          message:
          'También Me encanta comprar este producto porque siempre está fresco y le ayuda mucho a mis dos amigas Luna y Estrella',
        },
        recommend: true,
        author: 'wizzecár',
        ratingAverage: '2',
      },
      {
        review: {
          title: 'un buen producto para mis amigas fieles',
          date: 'Hace 11 meses',
          message:
          'Me encanta comprar este producto porque siempre está fresco y le ayuda mucho a mis dos amigas Luna y Estrella',
        },
        recommend: false,
        author: 'wizzecaar',
        ratingAverage: '3.5',
      },
    ],
    Positivas: [
      {
        review: {
          title: 'un buen producto para mis amigas fieles',
          date: 'Hace 2 meses',
          message:
          'Me encanta comprar este producto porque siempre está fresco y le ayuda mucho a mis dos amigas Luna y Estrella',
        },
        recommend: true,
        author: 'wizzecar',
        ratingAverage: '3',
      },
      {
        review: {
          title: 'otro buen producto para mis amigas fieles',
          date: 'Hace 3 meses',
          message:
          'También Me encanta comprar este producto porque siempre está fresco y le ayuda mucho a mis dos amigas Luna y Estrella',
        },
        recommend: true,
        author: 'wizzecár',
        ratingAverage: '2',
      },
    ],
    Negativas: [
      {
        review: {
          title: 'un buen producto para mis amigas fieles',
          date: 'Hace 11 meses',
          message:
          'Me encanta comprar este producto porque siempre está fresco y le ayuda mucho a mis dos amigas Luna y Estrella',
        },
        recommend: false,
        author: 'wizzecaar',
        ratingAverage: '3.5',
      },
    ],

  },
};
