import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import style from './MessagePromotion.module.scss';
import Typography from '../../atoms/Typography/Typography';

const MessagePromotion = ({ text, extra, className }) => (
  <div className={classNames(style.root, className)}>
    <Typography variant="kappa14">
      Este producto tiene promoción 4x3.
    </Typography>
    <Typography className={style.root__content} variant="omega17">
      <strong className={style.root__strong}>
        Lleva
        {' '}
        {extra}
        {' '}
        productos,
      </strong>
      {' '}
      <span className={style.root__green}>{text}</span>
      {' '}
    </Typography>
    <Typography className={style.root__small}>
      El descuento se realizará en el carro de compras
    </Typography>
  </div>
);

MessagePromotion.propTypes = {
  text: PropTypes.string,
  extra: PropTypes.string,
  className: PropTypes.string,
};

export default MessagePromotion;
