/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { setProductDetailsResponse } from '../PDP/alertModalPlpSlice';

const isSelected = (code, selected) => Number(code) === Number(selected);

const newMap = (data, selected) => {
  const newData = data.map(({ name, code }) => ({
    isSelected: isSelected(code, selected),
    label: name,
    selected: isSelected(code, selected),
    value: code,
  }));
  return newData;
};
export const geolocalizationApi = createApi({
  reducerPath: 'geoData',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.homecenter.com.co/',
    prepareHeaders: (headers) => {
      headers.set('x-channel-id', 'WEB');
      headers.set('x-ecomm-name', 'homecenter-co');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getStates: builder.query({
      query: () => 's/account/v2/geo',
      transformResponse: (response, meta, { selected }) => newMap(response, selected),
    }),
    getCities: builder.query({
      query: ({ departamento }) => `s/account/v2/geo/get-selected-locations?state=${departamento}`,
      transformResponse: (response, meta, { selected }) => newMap(response, selected),
    }),
    getNeighborhoods: builder.query({
      query: ({ departamento, cityId }) => `s/account/v2/geo/get-selected-locations?state=${departamento}&city=${cityId}&primaryLocation=true`,
      transformResponse: (response, meta, { selected }) => newMap(response, selected),
    }),
  }),
});

export const {
  useLazyGetStatesQuery,
  useLazyGetCitiesQuery,
  useLazyGetNeighborhoodsQuery,
} = geolocalizationApi;
