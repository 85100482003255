import React from 'react';
import PropTypes from 'prop-types';
import style from './Caption12.module.scss';

const Caption12 = ({ content }) => (
  <small className={style.small}>
    {content}
  </small>
);

Caption12.propTypes = {
  content: PropTypes.string,
};

export default Caption12;
