import React from 'react';
import { useMediaQuery } from 'react-responsive';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import Container from '../../../layout/Container/Container';
import style from './Advertising.module.scss';
import { hasHttp } from '../../../../utils';

const Advertising = ({
  name,
  imageUrlDesktop,
  imageUrlMobile,
  ext,
  url,
  state,
  ...props
}) => {
  const navigate = useNavigate();

  const isDesktopOrmobile = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const handleClick = (urlLink) => {
    if (!urlLink || urlLink === 'https://google.com.co') {
      return null;
    }
    return hasHttp(urlLink)
      ? window.open(urlLink, '_blank', 'noreferrer')
      : navigate(`${urlLink}`);
  };

  if (!state) {
    return null;
  }
  return (
    <Container>
      <div
        className={style.advertising}
        data-testid={!isDesktopOrmobile ? 'mobile' : 'desktop'}
        style={props.style}
      >
        <button
          style={{
            width: '100%',
            cursor:
              !url || url === 'https://google.com.co' ? 'auto' : 'pointer',
          }}
          type="button"
          onClick={() => handleClick(url)}
        >
          <img
            data-testid={ext ? 'external' : 'internal'}
            className={`${style.advertising__image}`}
            alt={name}
            src={!isDesktopOrmobile ? imageUrlMobile : imageUrlDesktop}
          />
        </button>
      </div>
    </Container>
  );
};

Advertising.propTypes = {
  /**
   * State "true" to turn on component card and "false" to turn off component
   */
  state: PropTypes.bool,
  /**
   * Name of the advertising
   */
  name: PropTypes.string,
  /**
   * image URL Desktop of the advertising
   */
  imageUrlDesktop: PropTypes.string,
  /**
   * image URL Mobile of the advertising
   */
  imageUrlMobile: PropTypes.string,
  /**
   * Boolen of external or interna Url Link
   */
  ext: PropTypes.bool,
  /**
   * button URL of the CTA's advertising
   */
  url: PropTypes.string,
  style: PropTypes.shape({}),
};

export default Advertising;
