import React from 'react';
import PropTypes from 'prop-types';
import style from './ProductBrand.module.scss';

const ProductBrand = ({ content }) => (
  <small className={style.productBrand}>
    {content}
  </small>
);

ProductBrand.propTypes = {
  content: PropTypes.string,
};

ProductBrand.defaultProps = {
  content: 'Product Brand',
};

export default ProductBrand;
