const BadgesContainer = ({
  bankBadge, eventBadge, children, ...props
}) => {
  const hasEventBadge = eventBadge[0]?.value?.length > 0;
  const hasBankBadge = bankBadge?.value?.length > 0;
  const hasGarantieBadge = props.garantieBadge?.value?.length > 0;
  const hasDiscountBadge = props.discountBadge[0]?.type === 'MXN' && props.discountBadge[0]?.value?.length > 0;
  const hasMultiPurposeIcon = props.multiPurposeIcon?.iconUrl?.length > 0;
  return children({
    hasBankBadge, hasGarantieBadge, hasDiscountBadge, hasEventBadge, hasMultiPurposeIcon,
  });
};

export default BadgesContainer;
