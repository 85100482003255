/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowRight2 } from '../../../../assets/svg/ArrowRight2.svg';
import { ReactComponent as ArrowLeft2 } from '../../../../assets/svg/ArrowLeft2.svg';
import IconButton from '../../atoms/IconButton/IconButton';
import style from './Pager.module.scss';

const Pager = ({
  state, index, maxPages, onChange,
}) => {
  if (!state) return null;

  const page = index + 1;

  const previousPage = () => {
    if (page > 1) onChange(page - 1);
  };
  const nextPage = () => {
    if (page < maxPages) onChange(page + 1);
  };

  const renderPagesIcons = () => {
    if (maxPages > 5) {
      return (
        <>
          {page > 2 && <IconButton className={style.pager__buttonPage} onClick={() => onChange(1)} icon={1} />}
          {page > 3 && <p>...</p>}
          {page > maxPages - 1 && (
          <IconButton className={style.pager__buttonPage} onClick={() => onChange(page - 3)} icon={page - 3} />
          )}
          {page > maxPages - 2 && (
          <IconButton className={style.pager__buttonPage} onClick={() => onChange(page - 2)} icon={page - 2} />
          )}
          {page > 1 && (
          <IconButton className={style.pager__buttonPage} onClick={() => onChange(page - 1)} icon={page - 1} />
          )}
          <IconButton className={classNames(style.pager__buttonPage, style.pager__buttonPage_active)} icon={page} />
          {page < maxPages - 1 && (
          <IconButton className={style.pager__buttonPage} onClick={() => onChange(page + 1)} icon={page + 1} />
          )}
          {page < 3 && (
          <IconButton className={style.pager__buttonPage} onClick={() => onChange(page + 2)} icon={page + 2} />
          )}
          {page < 2 && (
          <IconButton className={style.pager__buttonPage} onClick={() => onChange(page + 3)} icon={page + 3} />
          )}
          {page < maxPages - 2 && <p>...</p>}
          {page < maxPages && (
          <IconButton className={style.pager__buttonPage} onClick={() => onChange(maxPages)} icon={maxPages} />
          )}
        </>
      );
    }
    const items = [];
    for (let i = 0; i < maxPages; i++) {
      items.push(
        <IconButton
          key={i}
          onClick={() => onChange(i + 1)}
          className={classNames(style.pager__buttonPage, { [style.pager__buttonPage_active]: i === index })}
          icon={i + 1}
        >
          {i + 1}
        </IconButton>,
      );
    }
    return items;
  };
  return (
    <div className={style.pager}>
      <IconButton
        onClick={previousPage}
        className={classNames(style.pager__button, { [style.pager__button_disabled]: page === 1 })}
        icon={<ArrowLeft2 />}
        color="#fff"
      />
      {renderPagesIcons()}
      <IconButton
        onClick={nextPage}
        className={classNames(style.pager__button, { [style.pager__button_disabled]: page === maxPages })}
        icon={<ArrowRight2 />}
        color="#fff"
      />
    </div>
  );
};
Pager.propTypes = {
  state: PropTypes.bool,
  index: PropTypes.number,
  maxPages: PropTypes.number,
  onChange: PropTypes.func,
};

export default Pager;
