import PropTypes from 'prop-types';
import styles from './ReviewsTabButtonPDP.module.scss';

const ReviewsTabButtonPDP = ({ onClick, ...props }) => {
  const handleClick = (event) => {
    onClick(event.currentTarget.value);
  };
  const trueActive = props.selection === props.label;

  return (
    <button
      value={props.label}
      type="button"
      onClick={handleClick}
      className={`${styles.reviewButton} ${
        trueActive ? `${styles.reviewButton__active}` : ''
      }`}
    >
      {props.label}
      {' '}
      (
      {props.amount}
      )
    </button>
  );
};

ReviewsTabButtonPDP.propTypes = {
  label: PropTypes.string,
  selection: PropTypes.string,
  amount: PropTypes.number,
  onClick: PropTypes.func,
};

export default ReviewsTabButtonPDP;
