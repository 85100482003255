import React from 'react';
import PropTypes from 'prop-types';

const Star = ({ star, ...props }) => (
  <svg height="14" viewBox="0 -2 20 20" width="14" style={props.style}>
    <defs>
      <linearGradient id={`myGradient${star}`}>
        <stop offset="0%" stopColor={`${props.color}`} />
        <stop offset={`${props.width}%`} stopColor={`${props.color}`} />
        <stop offset={`${props.width}%`} stopColor="#BFBFBF" />
        <stop offset="100%" stopColor="#BFBFBF" />
      </linearGradient>
    </defs>
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      fill={`url('#myGradient${star}')`}
      stroke="#BFBFBF"
      strokeWidth="1"
      d="M9.5.22A.72.72,0,0,0,8.88.6l-2.47,5L.9,6.4a.68.68,0,0,0-.59.7.67.67,0,0,0,.21.49l4,3.88L3.56,17a.7.7,0,0,0,0,.38.64.64,0,0,0,.24.3.66.66,0,0,0,.36.13.67.67,0,0,0,.37-.07L9.5,15.1 M9.5,15.1h0l4.93,2.6a.66.66,0,0,0,.44.06.69.69,0,0,0,.57-.8l-.94-5.49,4-3.88A.7.7,0,0,0,18.1,6.4l-5.51-.8L10.12.6A.67.67,0,0,0,9.81.29.7.7,0,0,0,9.5.22"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

Star.propTypes = {
  star: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  style: PropTypes.shape({}),
  stroke: PropTypes.string,
};

export default Star;
