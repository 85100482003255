import PropTypes from 'prop-types';

const MultiSelectRender = ({ type, children }) => {
  const isMultiSelectAsLink = type === 'SINGLE_SELECT_AS_LINK';
  const isMultiSelect = type === 'MULTI_SELECT';
  const isMultiSelectWithRatings = type === 'MULTI_SELECT_WITH_RATINGS';
  const isMultiSelectWithRange = type === 'MULTI_SELECT_WITH_RANGE';
  return children({
    isMultiSelectAsLink,
    isMultiSelect,
    isMultiSelectWithRatings,
    isMultiSelectWithRange,
  });
};

MultiSelectRender.propTypes = {
  children: PropTypes.func,
  type: PropTypes.string,
};

export default MultiSelectRender;
