import React from 'react';
import PropTypes from 'prop-types';
import style from './Chip.module.scss';
import { ReactComponent as Xmark } from '../../../../assets/svg/Xmark.svg';

const Chip = ({ label, onChipClick }) => (
  <button type="button" className={style.chip} onClick={onChipClick}>
    {label}
    <Xmark />
  </button>
);
Chip.propTypes = {
  label: PropTypes.string,
  onChipClick: PropTypes.func,
};

export default Chip;
