import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ProductSelectorBoxPDP.module.scss';
import Button from '../../atoms/Button/Button';
import ButtonStyled from '../../atoms/ButtonStyled/Button';
import Loader from '../../atoms/Loader/Loader';

const ProductSelectorBoxPDP = ({
  onClick, onClickAdd, isAdding, ...props
}) => {
  const [count, setCount] = useState(0);

  const handleIncrement = () => {
    setCount(() => count + 1);
    onClick(count + 1);
  };
  const handleDecrement = () => {
    if (count > 1) {
      setCount(() => count - 1);
      onClick(count - 1);
    }
  };
  const handleChange = (evento) => {
    const regex = /[^0-9]/;
    if (
      evento.target.value === ''
      || evento.target.value === '0'
      || regex.test(evento.target.value)
    ) {
      setCount('');
    } else {
      setCount(Number(evento.target.value));
      onClick(Number(evento.target.value));
    }
  };
  const handleBlur = (evento) => {
    if (evento.target.value === '') {
      setCount(1);
      onClick(1);
    } else {
      setCount(Number(evento.target.value));
      onClick(Number(evento.target.value));
    }
  };

  const handleAddToCart = () => {
    onClickAdd(count);
  };

  useEffect(() => {
    handleIncrement();
  }, []);

  return (
    <div
      className={`${styles.productSelector__container} ${styles.productSelector__container__2fr}`}
    >
      <div className={styles.productSelector__amount}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleDecrement}
          data-testid="decrease"
          isDisabled={count === 1}
        >
          -
        </Button>
        <label htmlFor="amount">
          <span>Cantidad</span>
          <input
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            id="amount"
            data-testid="amount"
            value={count}
          />
        </label>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleIncrement}
          data-testid="increase"
        >
          +
        </Button>
      </div>

      <ButtonStyled
        color={isAdding ? 'secondary' : 'primary'}
        variant={isAdding ? 'loading' : 'contained'}
        onClick={isAdding ? null : handleAddToCart}
        theme={props.theme}
        isPDP
      >
        {isAdding ? (
          <>
            <Loader />
            {' '}
            Agregando
            {' '}
          </>
        ) : (
          'Agregar al carro'
        )}
      </ButtonStyled>
    </div>
  );
};

ProductSelectorBoxPDP.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  onClick: PropTypes.func,
  onClickAdd: PropTypes.func,
  isAdding: PropTypes.bool,
};

export default ProductSelectorBoxPDP;
