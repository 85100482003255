import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './IconButton.module.scss';

const IconButton = ({
  icon, size, children, ...props
}) => (
  <button
    {...props}
    onClick={props.onClick}
    className={classNames(style.btn, props.className, {
      [style.btn_size_small]: size === 'small',
      [style.btn_size_big]: size === 'big',
      [style.btn_size_responsive]: size === 'responsive',
      [style.btn_size_withChildren]: children,
    })}
    type="button"
    style={{ '--icon-color': props.color }}
  >
    {children}
    {icon.$$typeof && icon}
  </button>
);

IconButton.propTypes = {
  icon: PropTypes.node,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'big', 'responsive']),
  color: PropTypes.string,
};

export default IconButton;
