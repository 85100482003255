import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconSearch } from '../../../../assets/svg/Search.svg';
import { ReactComponent as IconClose } from '../../../../assets/svg/Close.svg';
import style from './InputSearch.module.scss';

const InputSearch = ({ state, value, onChange }) => {
  const [toggleButton, setToggleButton] = useState(false);
  if (!state) {
    return null;
  }
  return (
    <div className={style.inputSearch}>
      <input
        type="text"
        className={style.inputSearch__input}
        placeholder="Buscar"
        value={value}
        onChange={onChange}
        onFocus={() => setToggleButton(true)}
        onBlur={() => setToggleButton(false)}
      />
      {toggleButton
        ? (
          <button
            className={style.inputSearch__button}
            type="button"
            aria-label="IconClose"
            onClick={() => setToggleButton(false)}
          >
            <IconClose />
          </button>
        )
        : (
          <button className={style.inputSearch__button} aria-label="IconSearch" type="button">
            <IconSearch />
          </button>
        )}
    </div>
  );
};
InputSearch.propTypes = {
  state: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default InputSearch;
