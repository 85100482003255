/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import Container from '../../../layout/Container/Container';
import style from './ImageMatrix.module.scss';
import Typography from '../../atoms/Typography/Typography';
import useImageMatrix from './useImageMatrix';

const ImageMatrix = ({
  withOutDisplay,
  state,
  title,
  styles,
  onClick,
  content,
}) => {
  if (!state) {
    return null;
  }
  const [gridLogic] = useImageMatrix(content);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  const isMobil = useMediaQuery({
    query: '(max-width: 990px)',
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth <= 900);
    }, 200);

    window.addEventListener('resize', handleResize);
  }, []);

  const calcularTamanoContenedores = (arr) => {
    const longitudArray = arr.length;

    // Caso especial para cuando hay solo dos contenedores
    // Se ajusta el tamaño para que no se dañe
    if (longitudArray === 2) {
      return '49%';
    }

    const tamanoPorcentaje = `${100 / longitudArray}%`;
    return tamanoPorcentaje;
  };

  return (
    <Container fullScreenMobile>
      <div
        style={styles || {}}
        className={style.infoCardGroup}
      >
        {title?.value && (
          <div style={{ width: '100%' }}>
            <Typography
              className={style.infoCardGroup__title}
              variant="gamma35"
              style={{ textAlign: title?.variant, color: title?.color }}
            >
              {title?.value}
            </Typography>
          </div>
        )}

        <div className={`${style.infoCardGroup__content} ${isMobil ? style.wrap : ''}`}>
          {content?.slice(0, 6).map(
            ({
              imageUrlDeskTop, imageUrlMobile, link, description,
            }, index) => (
              <button
                key={index}
                type="button"
                onClick={() => onClick(link)}
                style={
                  isMobil
                    ? gridLogic()
                    : {
                      width: calcularTamanoContenedores(content),
                    }
                  }
              >
                <img
                  style={{ width: '100%' }}
                  src={isMobile ? imageUrlMobile : imageUrlDeskTop}
                  alt={description}
                />
              </button>
            ),
          )}
        </div>
      </div>
    </Container>
  );
};

ImageMatrix.propTypes = {
  withOutDisplay: PropTypes.bool,
  state: PropTypes.bool,
  title: PropTypes.shape({
    value: PropTypes.string,
    strong: PropTypes.bool,
    color: PropTypes.string,
    variant: PropTypes.oneOf(['left', 'center', 'right']),
  }),
  styles: PropTypes.shape({}),
  onClick: PropTypes.func,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrlDeskTop: PropTypes.string,
      imageUrlMobile: PropTypes.string,
      link: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};

ImageMatrix.defaultProps = {
  state: false,
  title: {
    value: '',
    strong: true,
    color: 'black',
    variant: 'left',
  },
};

export default ImageMatrix;
