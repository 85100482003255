/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import ToggleGroupButton from './ToggleGroupButton';
import style from './ToggleGroup.module.scss';
// import PropTypes from 'prop-types';

const toogleGroup = ({ viewOptions, onToogleGrid }) => {
  const [activeState, setActiveState] = useState('GRID');

  const handleClick = (value) => {
    setActiveState(value);
    onToogleGrid(value);
  };

  return (
    <>
      <MediaQuery maxWidth={1023}>
        <div className={classNames(style.toggleGroup, style.toggleGroup_withLabel)}>
          {viewOptions && [...viewOptions].reverse()?.map((i) => (
            <ToggleGroupButton
              viewOptions={i}
              onClick={handleClick}
              selection={activeState}
              caption={i.type}
              label={i.type === 'GRID' ? 'Mosaico' : 'Listado'}
            />
          ))}
        </div>
      </MediaQuery>
      <MediaQuery minWidth={1024}>
        <div className={style.toggleGroup}>
          {viewOptions && [...viewOptions].reverse()?.map((i) => (
            <ToggleGroupButton
              viewOptions={i}
              onClick={handleClick}
              selection={activeState}
              caption={i.type}
            />
          ))}
        </div>
      </MediaQuery>
    </>
  );
};

toogleGroup.propTypes = {};

export default toogleGroup;
