/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import style from './DropdownListItem.module.scss';

const DropdownListItem = ({ id, option, onChange }) => {
  const { label, isSelected } = option;

  const handleDropdownChange = () => {
    onChange(option);
  };

  const selectedItemClass = isSelected ? style.selected : '';

  return (
    <li
      id={`testId-li-${id}`}
      className={`${style.dropdown_item} ${selectedItemClass}`}
      value={label}
      onClick={handleDropdownChange}
      role="presentation"
      title={label}
    >
      {label}
    </li>
  );
};

DropdownListItem.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
};

export default DropdownListItem;
