import PropTypes from 'prop-types';
import React from 'react';

import styles from './ArrowDown.module.scss';

const ArrowDown = ({ state, color }) => {
  if (!state) {
    return null;
  }

  return (
    <div className={styles.ArrowDown}>
      <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z" fill={`${color}`} />
      </svg>
    </div>
  );
};

ArrowDown.propTypes = {
  state: PropTypes.bool,
  color: PropTypes.string,
};

export default ArrowDown;
