import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { styled } from 'styled-components';
import styles from './TabButton.module.scss';
import { ReactComponent as Close } from '../../../../assets/svg/Close.svg';
import { ReactComponent as Plus } from '../../../../assets/svg/Plus.svg';

import { ReactComponent as ArrowUp } from '../../../../assets/svg/ArrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/svg/ArrowDown.svg';

const ButtonWrapper = styled.button`
  &.tab__button__active {
    box-shadow: 0px 3px 4px 0px #0000000d;
    margin-bottom: 0;
    @media (min-width: 768px) {
      background-color: white;
      margin-bottom: 8px;
      width: 100%;
      &:hover {
        background-color: white;
        & > svg:first-of-type {
          border-color: ${(props) => props.theme.primary.primary};
        }
      }
    }
    & > div > span {
      font-weight: bold;
    }

    & > svg:first-of-type {
      border: 0px solid ${(props) => props.theme.primary.primary};
    }
    &.tab__button__active_noico {
      border-bottom-color: ${(props) => props.theme.primary.primary};
      @media (min-width: 768px) {
        background-color: white;
        &:hover {
          background-color: #fff !important;
        }
      }
      & > svg:first-of-type {
        border: none;
      }
    }
  }
`;

const TabButton = ({
  label,
  ico,
  onClick,
  selection,
  size,
  icons,
  ...props
}) => {
  const handleClick = (event) => {
    onClick(event.currentTarget.value);
  };

  const trueActive = selection.selection === label && selection.isOpen;
  const renderFirstIcon = () => {
    if (icons) {
      return <SVG src={ico} />;
    }
    if (trueActive) {
      return <Close />;
    }
    return <Plus />;
  };
  const renderLastIcon = () => {
    if (icons) {
      if (size?.toLowerCase() === 'small') {
        if (trueActive) {
          return <ArrowUp style={{ width: '15px' }} />;
        }
        return <ArrowDown style={{ width: '15px' }} />;
      }
      return null;
    }
    return null;
  };
  const renderClasses = (ic) => {
    let classes = `${styles.tab__button}`;
    if (!ic) {
      classes += ` ${styles.tab__button_noico}`;
    }
    if (trueActive && !ic) {
      classes += ` ${styles.tab__button__active} ${styles.tab__button__active_noico}`;
    }
    if (trueActive && ic) {
      classes += ' tab__button__active';
    }
    return classes;
  };

  return (
    <ButtonWrapper
      value={label}
      type="button"
      onClick={handleClick}
      className={renderClasses(icons)}
      theme={props.theme}
    >
      {renderFirstIcon()}
      <div style={{ width: '70%' }}>
        <span>{label}</span>
      </div>
      {renderLastIcon()}
    </ButtonWrapper>
  );
};

TabButton.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  label: PropTypes.string,
  ico: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selection: PropTypes.string.isRequired,
  /**
   * Icons "icons" to show tabs icons "buttons" without icons
   */
  icons: PropTypes.bool,
  /**
   * Size "small" or "large"
   */
  size: PropTypes.oneOf(['large', 'small']),
};

export default TabButton;
