/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';
import ProductComparisonHeader from '../../molecules/ProductComparisonHeader/ProductComparisonHeader';
import Table from '../Table/Table';
import TableHead from '../TableHead/TableHead';
import TableRow from '../../molecules/TableRow/TableRow';
import TableCellBody from '../../atoms/TableCellBody/TableCellBody';
import Kappa14 from '../../atoms/Kappa14/Kappa14';
import TableBody from '../../molecules/TableBody/TableBody';
import style from './ProductComparison.module.scss';
import TableCellHead from '../../atoms/TableCellHead/TableCellHead';
import ProductCardWithXmark from '../ProductCardWithXmark/ProductCardWithXmark';
import EmptyComparison from '../../molecules/EmptyComparison/EmptyComparison';
import EmptyCardComparison from '../../molecules/EmptyCardComparison/EmptyCardComparison';
import CardComparison from '../../molecules/CardComparison/CardComparison';

const ProductComparison = ({
  state,
  buttonColor, dividerColor, headData, rowsData, onCleanAll, onClose, onCloseProduct, ...props
}) => {
  const [inView, setInView] = React.useState(false);
  if (!state) return null;
  return (
    <div className={style.root}>
      <div className={style.root__header}>
        <ProductComparisonHeader
          buttonColor={buttonColor}
          dividerColor={dividerColor}
          onCleanAll={onCleanAll}
          onClose={onClose}
        />
      </div>
      <div style={{ height: '100%', overflow: 'auto' }}>
        <div className={style.root__content}>
          <InView onChange={setInView} threshold={1} />
          <Table>
            <TableHead
              headData={headData}
              renderRow={(items, isOdd, currentPosition) => (
                <TableRow
                  key={currentPosition}
                  items={items}
                  isOdd={false}
                  renderCell={(value, index, isFirstItem, isEmpty) => (
                    <TableCellHead
                      key={index}
                      isSticky={isFirstItem}
                      style={{
                        // backgroundColor: !isOdd ? 'rgb(242, 242, 242)' : '',
                        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        backgroundColor: '#fff',
                        position: !inView ? 'sticky' : '',
                        top: '0',
                        zIndex: '2',
                      }}
                    >
                      {inView ? (
                        isFirstItem ? <div style={{ width: 'auto' }} /> : isEmpty ? (
                          <EmptyComparison quantity={index.toString()} />
                        ) : (
                          <ProductCardWithXmark
                            {...value}
                            className="type-comparision"
                            onClose={() => onCloseProduct(value?.id)}
                            onClick={() => props.onProductClick(value?.id)}
                          />
                        )
                      ) : (isFirstItem ? <div style={{ width: 'auto' }} /> : isEmpty ? (
                        <EmptyCardComparison quantity={index.toString()} />
                      ) : (
                        <CardComparison
                          title={value?.name}
                          sku={value?.id}
                          onCloseClick={() => onCloseProduct(value?.id)}
                        />
                      ))}
                    </TableCellHead>
                  )}
                />
              )}
            />
            <TableBody
              rowsData={rowsData}
              renderRows={(items, isOdd, currentPosition) => (
                <TableRow
                  key={currentPosition}
                  items={items}
                  isOdd={isOdd}
                  renderCell={(value, index, isFirstItem) => (
                    <TableCellBody
                      key={index}
                      isSticky={isFirstItem}
                      style={{ backgroundColor: isOdd ? 'rgb(242, 242, 242)' : '#fff' }}
                    >
                      <Kappa14 strong={isFirstItem}>{value}</Kappa14>
                    </TableCellBody>
                  )}
                />
              )}
            />
          </Table>
        </div>
      </div>
    </div>
  );
};
ProductComparison.propTypes = {
  state: PropTypes.bool,
  buttonColor: PropTypes.string,
  dividerColor: PropTypes.string,
  headData: PropTypes.arrayOf(PropTypes.shape({})),
  rowsData: PropTypes.arrayOf(PropTypes.shape({})),
  onCleanAll: PropTypes.func,
  onClose: PropTypes.func,
  onCloseProduct: PropTypes.func,
  onProductClick: PropTypes.func,
};

export default ProductComparison;
