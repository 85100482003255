import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SliderHeader from '../SliderHeader/SliderHeader';
import style from './FullWidthInfoHome.module.scss';
import BannerCard2 from '../../molecules/BannerCard2/BannerCard2';
import { followHeight } from '../../../../utils';
import Container from '../../../layout/Container/Container';

const FullWidthInfoHome = ({ backgroundColor, ...props }) => {
  const targetHeight = useRef(null);
  const toUpdateHeight = useRef(null);

  useEffect(() => {
    if (targetHeight.current && toUpdateHeight.current) {
      followHeight(targetHeight.current, toUpdateHeight.current);
    }
  }, []);

  if (!props.state) {
    return null;
  }
  let content;

  if (props.variant === 'un-banner') {
    content = (
      <div
        style={backgroundColor && { backgroundColor }}
        className={classNames(style.home_header)}
      >
        <div
          className={classNames(
            style.home_header__slider,
            style.home_header__slider_0advertising,
          )}
          data-testid="slider-full"
        >
          <SliderHeader images={props.imagesCarousel} arrowsColor="#000" />
        </div>
      </div>
    );
  }
  if (props.variant === 'un-anuncio') {
    content = (
      <div
        style={backgroundColor && { backgroundColor }}
        className={classNames(style.home_header, style.home_header_un_anuncio, {
          [style.home_header_reverse]: props.direction === 'reverse',
        })}
      >
        <div className={classNames(style.home_header__banners)}>
          <BannerCard2
            align={props.banner1?.align}
            title={props.banner1?.title}
            content={props.banner1?.content}
            backgroundImage={props.banner1?.backgroundImage}
            button={props.banner1?.button}
            theme={props.theme}
          />
        </div>
      </div>
    );
  }
  if (props.variant === 'un-banner-un-anuncio') {
    content = (
      <div
        style={backgroundColor && { backgroundColor }}
        className={classNames(
          style.home_header,
          style.home_header_1advertising,
          { [style.home_header_reverse]: props.direction === 'reverse' },
        )}
      >
        <div
          className={classNames(
            style.home_header__slider,
            style.home_header__slider_1advertising,
          )}
          ref={targetHeight}
        >
          <SliderHeader images={props.imagesCarousel} arrowsColor="#000" />
        </div>
        <div
          className={classNames(style.home_header__banners)}
          ref={toUpdateHeight}
        >
          <BannerCard2
            align={props.banner1?.align}
            title={props.banner1?.title}
            content={props.banner1?.content}
            backgroundImage={props.banner1?.backgroundImage}
            button={props.banner1?.button}
            theme={props.theme}
          />
        </div>
      </div>
    );
  }
  if (props.variant === 'dos-banner') {
    content = (
      <div
        style={backgroundColor && { backgroundColor }}
        className={classNames(style.home_header, style.home_header_dos_banner, {
          [style.home_header_reverse]: props.direction === 'reverse',
        })}
      >
        <div
          className={classNames(
            style.home_header__slider,
            style.home_header__slider_dos_banner,
          )}
        >
          <SliderHeader images={props.imagesCarousel} arrowsColor="#000" />
        </div>
        <div
          className={classNames(
            style.home_header__slider,
            style.home_header__slider_dos_banner,
          )}
        >
          <SliderHeader images={props.imagesCarouselB} arrowsColor="#000" />
        </div>
      </div>
    );
  }

  return (
    <Container>
      <div style={backgroundColor && { backgroundColor, marginBlock: '54px' }}>
        {content}
      </div>
    </Container>
  );
};

FullWidthInfoHome.propTypes = {
  /**
   * State "true" to turn on the home header and "false" to turn off home header
   */
  state: PropTypes.bool,
  /**
   * Varaint of home header
   */
  variant: PropTypes.oneOf([
    'un-anuncio',
    'un-banner',
    'un-banner-un-anuncio',
    'dos-banner',
  ]),
  /**
   * Varaint of home header
   */
  direction: PropTypes.oneOf(['no-reverse', 'reverse']),
  /**
   * List of images to show in the slider
   */
  imagesCarousel: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
      linkTo: PropTypes.string,
    }),
  ),
  /**
   * List of images to show in the slider
   */
  imagesCarouselB: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
      linkTo: PropTypes.string,
    }),
  ),
  /**
   * image banner 1
   */
  banner1: PropTypes.shape({
    linkTo: PropTypes.string,
    align: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
    title: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      strong: PropTypes.bool,
    }),
    content: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      strong: PropTypes.bool,
    }),
    button: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      color: PropTypes.string,
      startIcon: PropTypes.string,
      endIcon: PropTypes.string,
    }),
    backgroundImage: PropTypes.string,
  }),
  /**
   * image banner 2
   */
  banner2: PropTypes.shape({
    linkTo: PropTypes.string,
    align: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
    title: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      strong: PropTypes.bool,
    }),
    content: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      strong: PropTypes.bool,
    }),
    button: PropTypes.shape({
      value: PropTypes.string,
      variant: PropTypes.string,
      color: PropTypes.string,
      startIcon: PropTypes.string,
      endIcon: PropTypes.string,
    }),
    backgroundImage: PropTypes.string,
  }),
  /**
   * Background color of home header
   */
  backgroundColor: PropTypes.string,
  theme: PropTypes.shape(PropTypes.shape({})),
};

FullWidthInfoHome.defaultProps = {
  state: false,
  direction: 'no-reverse',
};

export default FullWidthInfoHome;
