/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import style from './withXmark.module.scss';
import { ReactComponent as ThinXmark } from '../../../assets/svg/ThinXmark.svg';

const withXmark = (WrappedComponent) => {
  const WithXmark = (props) => (
    <div className={style.root}>
      <div className={style.root__header}>
        <button type="button" onClick={props.onClose} aria-label="Cerrar">
          <ThinXmark />
        </button>
      </div>
      <WrappedComponent {...props} className={style.root__card} />
    </div>
  );

  return WithXmark;
};

export default withXmark;
