/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Typography from '../../atoms/Typography/Typography';
import ToggleButtonList from '../../molecules/ToggleButtonList/ToggleButtonList';
import ToggleButton from '../../atoms/ToggleButton/ToggleButton';
import style from './DeliveryOptionsInformation.module.scss';
import Typography2 from '../../atoms/Typography2/Typography2';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const DeliveryOptionsInformation = ({
  state,
  iconTitle,
  title,
  alert,
  information,
  children,
  pills,
  data,
  delivery,
  maps,
  stock,
  stockData,
  googleAddress,
}) => {
  const [indexType, setIndexType] = useState(0);
  const fechaActual = new Date().getDate() + 1;
  const [selectedAddress, setSelectedAddress] = useState(stock ? stockData[0]?.googleMapsAddress : '');

  const result = stock
    ? stockData.map((store) => ({
      storeId: store.storeId,
      storeName: store.storeName,
      address: store.address.addressLine1,
      deliverySlot: store?.deliverySlots?.find(
        (slot) => slot?.slotDate?.date === String(fechaActual),
      ),
      googleMapsAddress: store.googleMapsAddress,
    }))
    : {};

  const handleClick = (address) => {
    setSelectedAddress(address);
  };

  if (!state) {
    return null;
  }

  return (
    <div className={style.content}>
      <div
        className={style.information}
        style={{ width: maps ? '40%' : '100%' }}
      >
        <section className={style.information__sectionTitle}>
          <span className={style.information__sectionTitle_icon}>
            {iconTitle}
          </span>
          <div className={style.information__sectionTitle_header}>
            <Typography variant="zeta20" className={style.bold}>
              {title}
            </Typography>
            <Typography variant="omega17">{alert}</Typography>
          </div>
        </section>

        {delivery && (
          <>
            <Typography
              variant="zeta20"
              style={{ marginBottom: '10px' }}
              className={style.bold}
            >
              {data[indexType]?.slotDate?.month}
            </Typography>

            <span className={style.information__divider} />

            <ToggleButtonList
              className={style.information__actions}
              items={data}
              initialIndex={0}
              onHandleChange={(item) => setIndexType(item?.id)}
              renderItem={(
                item,
                isHighlighted,
                index,
                onChangeSelectedIndex,
              ) => (
                <ToggleButton
                  className={style.information__button}
                  key={item?.customTimeSlots[0]?.slotId}
                  isHighlighted={isHighlighted}
                  onClick={() => {
                    onChangeSelectedIndex(index, item);
                    setIndexType(index);
                  }}
                >
                  <div className={style.information__ToggleButton}>
                    <Typography variant="omega17" className={style.bold}>
                      {item?.slotDate?.day}
                    </Typography>
                    <Typography variant="omega17">
                      {item?.slotDate?.date}
                    </Typography>
                  </div>
                </ToggleButton>
              )}
            />

            {data[indexType].customTimeSlots.map((item, index) => {
              const color = index % 2 === 0 ? '#F5F5F5' : '#FFFF';

              return (
                <div
                  key={item.slotId}
                  style={{ backgroundColor: color }}
                  className={style.information__priceAndSchedule}
                >
                  <Typography2 variant="zeta20">{item?.timeRange}</Typography2>
                  <Typography2 variant="zeta20">{item?.cost}</Typography2>
                </div>
              );
            })}
          </>
        )}

        {pills && (
          <>
            <span className={style.information__divider} />

            <div className={style.information__details}>
              {(() => children(information[indexType]))()}
            </div>
          </>
        )}

        {stock && (
          <div>
            {result.map((item) => (
              <button
                type="button"
                key={item.storeId}
                onClick={() => handleClick(item.googleMapsAddress)}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <div className={style.stockDate}>
                  <Typography
                    style={{ color: 'black' }}
                    variant="omega17"
                    className={style.bold}
                  >
                    {item?.deliverySlot?.slotDate?.date}
                  </Typography>
                  <Typography
                    style={{ color: 'black' }}
                    variant="omega17"
                    className={style.bold}
                  >
                    {item?.deliverySlot?.slotDate?.day}
                  </Typography>
                </div>

                <div className={style.stockInfo}>
                  <Typography
                    style={{ color: 'black' }}
                    variant="omega17"
                    className={style.bold}
                  >
                    {item?.storeName}
                  </Typography>

                  <Typography style={{ color: '#8C8C8C' }} variant="kappa14">
                    {item?.address}
                  </Typography>

                  <Typography style={{ color: '#8C8C8C' }} variant="kappa14">
                    {item?.deliverySlot?.description?.value}
                  </Typography>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>

      {maps && (
        <div style={{ width: '100%', minWidth: '500px', minHeight: '400px' }}>
          <iframe
            src={selectedAddress || googleAddress}
            style={{ border: 'none' }}
            width="100%"
            height="100%"
          />
        </div>
      )}
    </div>
  );
};

DeliveryOptionsInformation.propTypes = {
  state: PropTypes.bool,
  pills: PropTypes.bool,
  stock: PropTypes.bool,
  delivery: PropTypes.bool,
  maps: PropTypes.bool,
  iconTitle: PropTypes.node,
  title: PropTypes.string,
  googleAddress: PropTypes.string,
  alert: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  stockData: PropTypes.arrayOf(PropTypes.shape({})),
  information: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.func,
};

export default DeliveryOptionsInformation;
