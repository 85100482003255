/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import style from './PLPtemplate.module.scss';
import Container from '../../../layout/Container/Container';
import BrandsFilterPLP from '../../organisms/BrandsFilterPLP/BrandsFilterPLP';
import FacetsFilterPLP from '../../organisms/FacetsFilterPLP/FacetsFilterPLP';
import ProductCardList from '../../organisms/ProductCardList/ProductCardList';
import BannerPLP from '../../organisms/BannerPLP/BannerPLP';
import Modal from '../../organisms/Modal/Modal';
import BackButton from '../../molecules/BackButton/BackButton';
import FilterAndSort from '../../molecules/FilterAndSort/FilterAndSort';
import ToggleGroup from '../../atoms/ToggleGroup/ToggleGroup';
import { ReactComponent as ThinXmark } from '../../../../assets/svg/ThinXmark.svg';
import 'animate.css';
import Loader from '../../atoms/Loader/Loader';
// Componentes personalizados
import CategoriesMenuPLP from '../../organisms/CategoriesMenuPLP/CategoriesMenuPLP';

// Importaciones de Redux
import {
  useLazyGetFilterCollectionsQuery,
  useLazyGetFilterProductsQuery,
} from '../../../../redux/slices/PLP/categoriesApi';
import { useLazyGetBlobCategoriesDataQuery } from '../../../../redux/slices/PLP/blobStorageCategoriesApi';

// Utilidades
import {
  getDataFromItem,
  replaceCurrentPage,
  splitArray,
} from '../../../../utils';
import AppliedFilterPLP from '../../molecules/AppliedFiltersPLP/AppliedFilterPLP';
import SortByFilterPLP from '../../organisms/SortByFilterPLP/SortByFilterPLP';
import Pagination from '../../atoms/Pagination/Pagination';
import MultiSelect from '../../molecules/MultiSelect/MultiSelect';
import Typography from '../../atoms/Typography/Typography';
import {
  mapBodyToAddProduct,
  useAddCartLinesMutation,
  useAddToCartMutation,
  useLazyCheckCartQuantityQuery,
} from '../../../../redux/slices/Cart/checkoutApi';
import { setQuantity } from '../../../../redux/slices/Cart/cartSlice';
import { useLazyGetProductDetailsQuery } from '../../../../redux/slices/PDP/productDetailsApi';
import GeolocalizationButton from '../../molecules/GeolocalizationButton/GeolocalizationButton';
import { setOpenModal } from '../../../../redux/slices/PLP/geolocalizationSlice';
import {
  addToCardMarcation,
  trackPageView,
} from '../../../../utils/omnitureConection';
import { setCartId } from '../../../../redux/slices/transversalSlice';
import MetaTags from '../../atoms/MetaTags/MetaTags';
import { useLazyGetProductsM2Query } from '../../../../redux/slices/PLP/m2Api';

const PLPtemplate = ({ store, selectedFilterProp, mainCategoryId }) => {
  const dispatch = useDispatch();
  const { search, hash, pathname } = useLocation();
  const navigate = useNavigate();
  const { catId: categoryId, filter } = useParams();
  const isCollection = categoryId.startsWith('cat');

  const searchParams = new URLSearchParams(search);
  const sTerm = searchParams.get('sTerm');

  const [addCartLines] = useAddCartLinesMutation();

  const global = useSelector((state) => state.categoriesData);
  const {
    smallHeader,
    departamento,
    selectedFilter,
    ciudad: { name: cityName, id: cityId },
    cartId,
    location,
    mainCategory,
  } = useSelector((state) => state.transversalSlice);

  const isMobile = useMediaQuery({
    query: '(max-width: 719px)',
  });

  const data = Object.values(global.queries);

  // // State hooks
  const [isOpen, setIsOpen] = useState(false);
  const [typeView, setTypeView] = useState('GRID');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [changingProduct, setChangingProduct] = useState([]);
  const [exceededProducts, setExceededProducts] = useState([]);
  const [newTotalPagination, setNewTotalPagination] = useState();
  const [newCount, setNewCount] = useState();
  const [filterBrands, setFilterBrands] = useState([]);

  const [selectedCtaFilter, setSelectedCtaFilter] = useState(selectedFilter);
  const [loader, setLoader] = useState(false);

  // // Queries de Redux
  const [getFilterCollections, { data: collections }] = useLazyGetFilterCollectionsQuery();

  const [getFilterProducts, {
    data: products, isLoading, error, isSuccess,
  }] = useLazyGetFilterProductsQuery();

  const [getM2Products, { data: m2Products }] = useLazyGetProductsM2Query();

  const [getProductDetails, { data: productData }] = useLazyGetProductDetailsQuery();

  const [
    getCategoriesData,
    { data: blobData, isLoading: blobisLoading, error: blobError },
  ] = useLazyGetBlobCategoriesDataQuery();

  const [addToCart, { isLoading: isAddToCartLoading }] = useAddToCartMutation();

  const [getCartData, { data: cartData }] = useLazyCheckCartQuantityQuery({
    location,
    cartId,
  });

  useEffect(() => {
    if (isSuccess || collections) {
      const paginationVoid = {
        pagination: {
          count: 1,
          perPage: 1,
          currentPage: 1,
        },
      };

      const dataProducts = products?.data?.results || collections?.data?.results;
      const paginationData = products?.data?.pagination
        || collections?.data?.pagination
        || paginationVoid?.pagination;
      const { count, currentPage, perPage } = paginationData;
      setNewCount(count);
      setChangingProduct(dataProducts);
      if (exceededProducts?.length > 0 && currentPage < newTotalPagination) {
        const unir = [...exceededProducts, ...dataProducts];
        const [newProducts, exceeded] = splitArray(unir, 28);
        setChangingProduct(newProducts);
        setExceededProducts(exceeded);
      }
      if (dataProducts?.length > 28) {
        const [newProducts, exceeded] = splitArray(dataProducts, 28);
        const totalPaginationCeil = Math.ceil(
          (count + exceeded.length) / perPage,
        );
        setNewCount(count + exceeded.length);
        setNewTotalPagination(totalPaginationCeil);
        setChangingProduct(newProducts);
        setExceededProducts(exceeded);
      }
    }
  }, [products, collections]);

  // useEffect para cargar los productos filtrados al montar el componente
  useEffect(() => {
    dispatch(setQuantity(1));
    if (!isCollection) {
      dispatch(setQuantity(1));
      getFilterCollections(categoryId);
    } else {
      if (sTerm && sTerm !== undefined) {
        getFilterProducts({
          categoryId,
          departamento,
          cityId,
          q: sTerm,
        }).then((res) => {
          if (res.data.results.length === 0) {
            getFilterProducts({
              categoryId,
              departamento,
              cityId,
            });
          }
        });
      } else {
        getFilterProducts(
          filter
            ? {
              categoryId,
              filter,
              departamento,
              cityId,
            }
            : {
              categoryId,
              filter: '',
              departamento,
              cityId,
            },
        );
      }
      getM2Products({
        categoryId,
        departamento,
        cityId,
      });
    }
    getCategoriesData({
      store,
      filterByGroup: selectedFilterProp?.selection || selectedFilter?.selection,
      selectedCatID: categoryId,
    }).then(({ data: blob }) => {
      if (blob) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    });
  }, [selectedFilter, filter, store]);

  useEffect(() => {
    trackPageView({
      store,
      pageName: `plp: ${selectedFilter?.selection}`,
      pageType: 'product List page',
      siteSection: `${
        products?.categoryData?.results?.displayName
        || collections?.collectionData?.displayName
      }`,
      subSection: `${
        products?.categoryData?.results?.displayName
        || collections?.collectionData?.displayName
      }`,
    });
  }, [selectedFilter, products]);

  useEffect(() => {
    const fullURL = `${
      !isCollection ? '/collection' : '/categories'
    }/${categoryId}${search}${hash}`;
    navigate(fullURL, { replace: true });
  }, []);

  const handleClick = (ev) => {
    setLoader(true);
    setExceededProducts([]);
    localStorage.setItem('searchforSiS', '');
    const url = getDataFromItem(blobData?.data, ev, 'url');
    const group = getDataFromItem(blobData?.data, ev, 'group');
    const subs = getDataFromItem(blobData?.data, ev, 'subcategories');

    getFilterProducts({
      categoryId: url,
      filter: '',
      departamento,
      cityId,
    });

    getM2Products({
      categoryId: url,
      departamento,
      cityId,
    });

    if (subs?.length > 0) {
      getCategoriesData({
        store,
        filterByGroup: group,
        selectedCatID: url,
      });
    }

    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const handleFilterOnClick = async (cta) => {
    setSelectedCtaFilter(cta);
    setFilterBrands(
      blobData?.generalFilterBrands ? blobData?.generalFilterBrands[cta] : [],
    );
    let newUrl = '';
    await getCategoriesData({
      store,
      filterByGroup: cta,
      selectedCatID: mainCategoryId,
    }).then((newData) => {
      newUrl = newData.data.data[0].url;
      console.log(newData);
      setExceededProducts([]);
      getFilterProducts({
        categoryId: newUrl,
        filter: '',
        departamento,
        cityId,
      });
    });
    navigate(`/categories/${newUrl}`);
  };

  const handleBreadcrumbsOnClick = () => {
    getCategoriesData({
      store,
      filterByGroup: selectedFilter?.selection,
      selectedCatID: blobData.isSub ? categoryId : blobData.parentData.url,
    });
    getFilterProducts({
      categoryId,
      filter: '',
      departamento,
      cityId,
    });
    getM2Products({
      categoryId,
      departamento,
      cityId,
    });
  };

  const handleBrandFilter = (brand) => {
    setExceededProducts([]);
    getFilterProducts({
      categoryId: brand?.url,
      filter: brand?.linkto,
      departamento,
      cityId,
    });
    getM2Products({
      categoryId: brand?.url,
      departamento,
      cityId,
    });
  };

  const handleFacetsFilter = (facets) => {
    const newUrl = facets?.url.replace(/&currentpage=1\b/, '');
    setExceededProducts([]);

    if (
      sTerm
      && sTerm !== undefined
    ) {
      getFilterProducts({
        categoryId,
        filter: newUrl,
        q: sTerm,
      }).then((res) => {
        if (!res?.data?.data?.pagination) {
          getFilterProducts({
            categoryId,
            filter: newUrl,
          });
        }
      });
    } else {
      getFilterProducts({
        categoryId,
        filter: newUrl,
      });
    }

    getM2Products({
      categoryId,
      departamento,
      cityId,
    });
    if (isSuccess) setIsOpen(false);
  };

  const handlePaginatorChange = (ev) => {
    setLoader(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    getFilterProducts({
      categoryId: products?.categoryData?.results?.id,
      currentpage: ev,
      departamento,
      cityId,
    });
    getM2Products({
      categoryId: products?.categoryData?.results?.id,
      departamento,
      cityId,
      currentpage: ev,
    });
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const handleToogleGrid = (ev) => {
    setIsOpen(false);
    setTypeView(ev);
  };

  const handleDropChange = (ev) => {
    const newUrl = ev?.url.replace(/&currentpage=1\b/, '');
    console.log(newUrl);
    getFilterProducts({
      categoryId: products?.categoryData?.results?.id,
      filter: newUrl,
      departamento,
      cityId,
    });
    getM2Products({
      categoryId: products?.categoryData?.results?.id,
      departamento,
      cityId,
    });
  };

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const handleAddCart = (productId) => {
    getProductDetails({ productId, departamento, cityId }).then(
      ({ data: infoProduct }) => {
        addToCardMarcation({
          catId: infoProduct?.result?.defaultParentCategoryId,
          price: infoProduct?.result?.variants[0]?.price[0]?.price,
          productName: infoProduct?.result?.variants[0]?.name,
          sku: infoProduct?.result?.currentVariant,
        });
      },
    );
    addToCart({
      cartId,
      body: mapBodyToAddProduct(productId),
    }).then(() => {
      getCartData({
        location,
        cartId,
      }).then((cart) => {
        if (cart?.error?.status === 404) {
          Cookies.remove('cartId', { path: '' });

          addCartLines(mapBodyToAddProduct('000000', 1)).then((res) => {
            const cardIdNew = res.data.data.cart.cartId;
            dispatch(setCartId(cardIdNew));
            Cookies.set('cartId', cardIdNew);

            addToCart({
              cartId,
              body: mapBodyToAddProduct(productId),
            });
          });
        }
        const buttonCartQuantity = document.querySelector(
          '.MiniCart-module__quantity___15GMp',
        );
        if (buttonCartQuantity && cart.data) {
          buttonCartQuantity.textContent = cart?.data?.cart?.totalProductsQuantityCount;
        }
      });
    });

    setSelectedProduct(productId);
  };

  const handleClickSaveList = () => {
    window.open(blobData?.saveListUrl, '_blank', 'noreferrer');
  };

  if (blobisLoading && isLoading) {
    return <h1> Cargando... </h1>;
  }

  if (blobError || error) {
    console.log(blobError);
    console.log(error);
    return (
      <h1>
        Error
        {blobError?.message || error?.message}
      </h1>
    );
  }
  const extractCatNumber = (str) => {
    const regex = /cat(\d+)/;
    const match = str.match(regex);

    if (match && match[0]) {
      return match[0];
    }

    return null;
  };
  return (
    <div className={style.plp}>
      <MetaTags
        store={store}
        restUrl={`${!isCollection ? 'collection' : 'categories'}/${extractCatNumber(categoryId)}`}
        description={`${store} plp page`}
        page="plp"
        catId={products?.categoryData?.results?.displayName
          || collections?.collectionData?.displayName}
      />

      {loader && (
        <div className={style.loader}>
          <Loader />
        </div>
      )}
      {products || collections ? (
        <>
          <Modal
            classNameModal={style.plp__filterAndSort}
            className={classNames(
              style.plp__filterAndSort_container,
              'animate__animated animate__fadeInLeft animate__faster',
            )}
            isOpen={isOpen}
            closeModal={() => setIsOpen(false)}
            renderCloseButton={() => (
              <button
                className={style.plp__filterAndSort_button}
                type="button"
                onClick={() => setIsOpen(false)}
                aria-label="Cerrar"
              >
                <ThinXmark />
              </button>
            )}
          >
            <BackButton onBackClick={() => setIsOpen(false)} />
            <AppliedFilterPLP
              state={
                !!products?.data?.selectedFacets?.length
                || !!collections?.data?.selectedFacets?.length
              }
              filters={
                products?.data?.selectedFacets
                || collections?.data?.selectedFacets
              }
              onChipClick={handleFacetsFilter}
            />
            <FacetsFilterPLP
              facets={products?.data?.facets || collections?.data?.facets}
              onFilterChange={handleFacetsFilter}
              renderViews={(
                <ToggleGroup
                  viewOptions={
                    products?.data?.viewOptions
                    || collections?.data?.viewOptions
                  }
                  onToogleGrid={handleToogleGrid}
                />
              )}
              renderByOrder={[
                <MultiSelect
                  state
                  list={
                    products?.data?.sortOptions
                    || collections?.data?.sortOptions
                  }
                  onInputChange={handleFacetsFilter}
                />,
              ]}
            />
          </Modal>
          <div className={style.plp__container}>
            <header className={style.plp__header}>
              <CategoriesMenuPLP
                nameStore={store}
                data={blobData?.data}
                dataParent={{
                  imgUrl: blobData?.parentData?.imgUrl,
                  alt: blobData?.parentData?.cta,
                }}
                isMobile={isMobile}
                breadcrumbs={data[0].data?.categoryData?.results?.breadcrumbs}
                mainCategory={mainCategory}
                state={blobData?.state}
                background={blobData?.background}
                logo={smallHeader}
                buttonsColor={blobData?.buttonsColor}
                arrowsColor={blobData?.arrowsColor}
                fontColor={blobData?.fontColor}
                withFilter={blobData?.withFilter}
                withSubcategory={blobData?.isSub}
                dataFilter={blobData?.dataFilter}
                onClick={handleClick}
                breadcrumbsOnClick={handleBreadcrumbsOnClick}
                filterOnClick={handleFilterOnClick}
                selectedFilter={blobData?.indexCurrentFilter}
                selectedItem={blobData?.indexCurrentCategory}
              />
            </header>
            <Container>
              <div
                className={
                  blobData?.bannerPlp[selectedCtaFilter]?.firstBanner?.state
                  && style.plp__banner
                }
              >
                <BannerPLP
                  state={
                    blobData?.bannerPlp[selectedCtaFilter]?.firstBanner?.state
                  }
                  name={
                    blobData?.bannerPlp[selectedCtaFilter]?.firstBanner?.name
                  }
                  imageUrlDesktop={
                    blobData?.bannerPlp[selectedCtaFilter]?.firstBanner
                      ?.imageUrlDesktop
                  }
                  imageUrlMobile={
                    blobData?.bannerPlp[selectedCtaFilter]?.firstBanner
                      ?.imageUrlMobile
                  }
                  url={
                    blobData?.bannerPlp[selectedCtaFilter]?.firstBanner?.linkto
                  }
                />
                {isMobile && (
                  <GeolocalizationButton
                    city={cityName}
                    onClick={() => {
                      dispatch(
                        setOpenModal({
                          isOpen: true,
                        }),
                      );
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  gap: '5px',
                  flexWrap: 'wrap',
                  marginBottom: '20px',
                }}
              >
                <Typography variant="h1">
                  {products?.categoryData?.results?.displayName
                    || collections?.collectionData?.displayName}
                </Typography>
                <Typography variant="omega17">
                  {`(${
                    products?.data?.pagination?.count
                    || collections?.data?.pagination?.count
                  } prod.)`}
                </Typography>
              </div>
              <main className={style.plp__main}>
                <aside className={style.plp__filters}>
                  <div className={style.plp__filters_desktop}>
                    <AppliedFilterPLP
                      state={
                        !!products?.data?.selectedFacets?.length
                        || !!collections?.data?.selectedFacets?.length
                      }
                      filters={
                        products?.data?.selectedFacets
                        || collections?.data?.selectedFacets
                      }
                      onChipClick={handleFacetsFilter}
                    />
                  </div>
                  <FacetsFilterPLP
                    className={style.plp__filters_desktop}
                    facets={products?.data?.facets || collections?.data?.facets}
                    accordionToggleInitial
                    onFilterChange={handleFacetsFilter}
                  />
                </aside>
                <section className={style.plp__cards}>
                  <div
                    className={
                      blobData?.bannerPlp[selectedCtaFilter]?.secondBanner
                        .state && style.plp__banner__top
                    }
                  >
                    <BannerPLP
                      state={
                        blobData?.bannerPlp[selectedCtaFilter]?.secondBanner
                          .state
                      }
                      name={
                        blobData?.bannerPlp[selectedCtaFilter]?.secondBanner
                          .name
                      }
                      imageUrlDesktop={
                        blobData?.bannerPlp[selectedCtaFilter]?.secondBanner
                          .imageUrlDesktop
                      }
                      imageUrlMobile={
                        blobData?.bannerPlp[selectedCtaFilter]?.secondBanner
                          .imageUrlMobile
                      }
                      url={
                        blobData?.bannerPlp[selectedCtaFilter]?.secondBanner
                          .linkto
                      }
                    />
                  </div>
                  {blobData?.generalFilterBrands
                    && blobData?.generalFilterBrands[selectedCtaFilter] && (
                      <div
                        className={
                          blobData?.generalFilterBrands[selectedCtaFilter]
                            !== 0 && style.plp__filter__brands
                        }
                      >
                        <BrandsFilterPLP
                          state={
                            blobData?.generalFilterBrands[selectedCtaFilter]
                            !== 0
                          }
                          title="Filtro"
                          brands={
                            blobData?.generalFilterBrands[selectedCtaFilter]
                          }
                          onBrandClick={handleBrandFilter}
                        />
                      </div>
                  )}
                  <div
                    className={
                      products?.data?.selectedFacets?.length > 0
                      && style.plp__filters_sort
                    }
                  >
                    <FilterAndSort
                      quantity={
                        products?.data?.selectedFacets?.length
                        || collections?.data?.selectedFacets?.length
                      }
                      onHandleClick={() => setIsOpen(true)}
                    />
                  </div>
                  <div className={style.plp__filter__orderby}>
                    <SortByFilterPLP
                      selectData={
                        products?.data?.sortOptions
                        || collections?.data?.sortOptions
                      }
                      paginationData={
                        products?.data?.pagination
                        || collections?.data?.pagination
                      }
                      viewOptions={
                        products?.data?.viewOptions
                        || collections?.data?.viewOptions
                      }
                      onChangePaginator={handlePaginatorChange}
                      onDropdownChange={handleDropChange}
                      onToogleGrid={handleToogleGrid}
                    />
                  </div>

                  <div className={style.plp__cards__container}>
                    <ProductCardList
                      onClickSaveList={handleClickSaveList}
                      selectedProduct={selectedProduct}
                      type={typeView}
                      products={changingProduct}
                      productsWithMerchantCategory={m2Products}
                      onClick={handleProductClick}
                      onAddCartClick={handleAddCart}
                      isAddToCartLoading={isAddToCartLoading}
                      banner={(
                        <div className={style.plp__banner__middle}>
                          <BannerPLP
                            state={
                              blobData?.bannerPlp[selectedCtaFilter]
                                ?.thirdBanner.state
                            }
                            name={
                              blobData?.bannerPlp[selectedCtaFilter]
                                ?.thirdBanner.name
                            }
                            imageUrlDesktop={
                              blobData?.bannerPlp[selectedCtaFilter]
                                ?.thirdBanner.imageUrlDesktop
                            }
                            imageUrlMobile={
                              blobData?.bannerPlp[selectedCtaFilter]
                                ?.thirdBanner.imageUrlMobile
                            }
                            url={
                              blobData?.bannerPlp[selectedCtaFilter]
                                ?.thirdBanner.linkto
                            }
                          />
                        </div>
                      )}
                    />
                  </div>
                  {products && (
                    <div className={style.plp__paginator__mobile}>
                      {products?.data?.pagination && (
                        <Pagination
                          {...products?.data?.pagination}
                          onChangePaginator={handlePaginatorChange}
                        />
                      )}
                    </div>
                  )}
                  {collections && (
                    <div className={style.plp__paginator__mobile}>
                      {collections?.data?.pagination && (
                        <Pagination
                          {...collections?.data?.pagination}
                          onChangePaginator={handlePaginatorChange}
                        />
                      )}
                    </div>
                  )}
                </section>
              </main>
            </Container>
          </div>
        </>
      ) : (
        <div className={style.loader}>
          <Loader />
        </div>
      )}
    </div>
  );
};

PLPtemplate.propTypes = {
  categoryId: PropTypes.string,
  selectedFilter: PropTypes.shape({
    selection: PropTypes.string,
    color: PropTypes.string,
  }),
  selectedFilterProp: PropTypes.shape({
    selection: PropTypes.string,
    color: PropTypes.string,
  }),
  mainCategoryId: PropTypes.string,
  store: PropTypes.string,
};

export default PLPtemplate;
