/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowBack } from '../../../../assets/svg/ArrowBack.svg';
import style from './Breadcrumbs.module.scss';

// remove FirstE lement
const removeFirstElement = (array) => {
  const newArray = array.slice(1);
  return newArray;
};

// remove all elements except last one
const removeElements = (array) => {
  const newArray = [array[array.length - 2]];
  return newArray;
};

// Breadcrumbs render
const renderBreadcrumbs = (array, isMobileBool, nameStore, name) => {
  let newArray;
  if (isMobileBool) {
    const withoutFirstElement = removeFirstElement(array);
    newArray = removeElements(withoutFirstElement);
    // if (withoutFirstElement.length < 2) {
    return (
      <li className={style.mobileBreadcrum}>
        <Link className={style.active} to={`/?store=${nameStore}`}>
          {name}
        </Link>
      </li>
    );
    // }
  }
  newArray = removeFirstElement(array);

  return newArray.map((ele, index) => (
    <li key={index} className={isMobileBool ? style.mobileBreadcrum : ''}>
      <Link className={style.active} to={`/categories/${ele.categoryId}`}>
        {ele.name}
      </Link>
    </li>
  ));
};

const Breadcrumbs = ({ nameStore, name, ...props }) => {
  const validateUrl = (event) => {
    const stores = {
      constructor: 'https://www.homecenter.com.co/homecenter-co/tiendas/constructor/?store=constructor',
      petcenter: 'https://www.homecenter.com.co/homecenter-co/tiendas/tienda-mascotas-pet-center/?store=petcenter',
      carcenter: 'https://www.homecenter.com.co/homecenter-co/tiendas/servicio-automotriz-carcenter/?store=carcenter',
      grandesvolumenes: 'https://www.homecenter.com.co/homecenter-co/tiendas/compras-por-mayor-grandes-volumenes/',
    };

    console.log(stores[nameStore]);

    event.preventDefault();
    window.location.href = stores[nameStore];
  };

  // const thereIsTheMainCategory = props.breadcrumbs?.some(
  //   ({ categoryId }) => categoryId === props.mainCategory,
  // );
  const thereIsTheMainCategory = true;

  return (
    <div
      className={`${
        props.isSubcategory ? '' : style.breadcrumbs_gen_container
      } `}
      id="categoriesMenu"
    >
      <nav
        className={`${
          props.isSubcategory
            ? style.breadcrumbs_container_subcategory
            : style.breadcrumbs_container
        } `}
      >
        {props.isSubcategory ? (
          <ul
            className={`${props.hasFilter ? style.breadcrumbs_hasFilter : ''} ${
              style.breadcrumbs_subcategory
            }`}
          >
            <li>
              <button
                type="button"
                className={style.active}
                onClick={props.onClick}
              >
                <ArrowBack />
                <img src={props.image.imgUrl} alt={props.image.alt} />
                {props.image.alt}
              </button>
            </li>
          </ul>
        ) : (
          <ul className={style.breadcrumbs_menu}>
            {!props.isMobile && (
              <li>
                <button
                  type="button"
                  className={style.notMainCategory}
                  onClick={validateUrl}
                >
                  {name}
                </button>
              </li>
            )}
            {!thereIsTheMainCategory && props.isMobile && (
              <li>
                <Link
                  className={style.notMainCategory}
                  to={`/?store=${nameStore}`}
                >
                  {name}
                </Link>
              </li>
            )}
            {props.breadcrumbs
              && thereIsTheMainCategory
              && renderBreadcrumbs(
                props.breadcrumbs,
                props.isMobile,
                nameStore,
                name,
              )}
          </ul>
        )}
      </nav>
    </div>
  );
};

Breadcrumbs.propTypes = {
  isMobile: PropTypes.bool,
  isSubcategory: PropTypes.bool,
  hasFilter: PropTypes.bool,
  onClick: PropTypes.func,
  image: PropTypes.shape({
    imgUrl: PropTypes.string,
    alt: PropTypes.string,
  }),
  nameStore: PropTypes.string,
  name: PropTypes.string,
  mainCategory: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
  breadcrumbsPLP: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Breadcrumbs;
