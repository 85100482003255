/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint jsx-a11y/label-has-for: 0 */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import listenForOutsideClicks from './listener';
import { dropdownUtils } from '../../../../utils/dropdownUtils';
import DropdownList from '../DropdownList/DropdownList';
import style from './DropdownGeo.module.scss';
import { ReactComponent as ArrowDown } from '../../../../assets/svg/ArrowDown.svg';

const DropdownGeo = ({
  id,
  label,
  hideBorder,
  options,
  maxHeight,
  onDropdownChange,
}) => {
  if (!options || !options.length) {
    return null;
  }

  const getLabel = (options) => {
    // console.log(options);
    const { label: selectedValueLabel = '' } = dropdownUtils.getSelectedOption(options);
    return selectedValueLabel;
  };
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [dropdownOptions, setDropdownOptions] = useState(options);
  const [expanded, setExpanded] = useState(false);
  const [showDropdownListAbove, setShowDropdownListAbove] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(getLabel(options));

  const toggle = () => setIsOpen(!isOpen);

  const toggleDropdownList = (e = {}) => {
    const dropdownElement = e.target;
    if (dropdownElement) {
      const { top } = dropdownElement.getBoundingClientRect();
      const { innerHeight: windowHeight } = window;
      // eslint-disable-next-line max-len
      const isListOnAbove = windowHeight - (top + dropdownElement.clientHeight) < maxHeight;
      if (isListOnAbove !== showDropdownListAbove) {
        setShowDropdownListAbove(isListOnAbove);
      }
    }
    setExpanded(!expanded);
    toggle();
    setDropdownOptions(dropdownUtils.filterObjectsByString(options, ''));
  };

  const handleOnDropdownChange = (selectedValue) => {
    const updatedOptions = dropdownUtils.onDropdownChange({
      options: dropdownOptions,
      selectedOption: selectedValue,
    });

    onDropdownChange(selectedValue);

    setDropdownOptions(updatedOptions);
    toggleDropdownList();
    setSelectedLabel(getLabel(updatedOptions));
  };
  const handleOnSearchChange = (typedValue) => {
    setTimeout(() => {
      setDropdownOptions(
        dropdownUtils.filterObjectsByString(options, typedValue.target.value),
      );
    }, 1000);
  };

  useEffect(
    listenForOutsideClicks(listening, setListening, menuRef, setIsOpen),
  );

  useEffect(() => {
    setDropdownOptions(options);
    setSelectedLabel(getLabel(options));
  }, [options]);

  const dropdownExpandedClass = expanded ? style.expanded : '';
  const hideBorderClass = hideBorder ? '' : style.border;
  const tooltipClass = showDropdownListAbove ? style.show_tooltip_top : '';
  const dropdownClass = `${style.dropdown} ${dropdownExpandedClass} ${hideBorderClass} ${tooltipClass}`;

  const testId = `testId-Dropdown-${id}`;
  return (
    <div
      ref={menuRef}
      className={style.dropdown_wrapper}
      id={testId}
      data-testId={testId}
    >
      {label && (
        <label className={style.label} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={dropdownClass}>
        <div
          id={`${testId}-value`}
          className={`${style.dropdown_selected_item}`}
          onClick={toggleDropdownList}
          role="presentation"
          title={selectedLabel}
        >
          <span className={`${style.dropdown_value}`}>{selectedLabel}</span>
          <span className={style.icon}>
            <ArrowDown />
          </span>
        </div>

        {isOpen && (
          <DropdownList
            isGeo
            id={`${testId}-dropdown-list`}
            options={dropdownOptions}
            onDropdownChange={handleOnDropdownChange}
            onSearchChange={handleOnSearchChange}
            toggleDropdownList={toggleDropdownList}
            maxHeight={maxHeight}
            isDropdownListAbove
          />
        )}
      </div>
    </div>
  );
};

DropdownGeo.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  hideBorder: PropTypes.bool,
  onDropdownChange: PropTypes.func,
  maxHeight: PropTypes.number,
};

export default DropdownGeo;
