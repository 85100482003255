/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel as Crs } from 'react-responsive-carousel';
import Carousel from 'react-simply-carousel';
import classNames from 'classnames';
import style from './SliderFeaturedProducts.module.scss';
import ProductCard from '../ProductCard/ProductCard';
import { arrayInArray } from '../../../../utils';
import IconButton from '../../atoms/IconButton/IconButton';
import { ReactComponent as ArrowLeft } from '../../../../assets/svg/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/ArrowRight.svg';
import { ReactComponent as ArrowLeft2 } from '../../../../assets/svg/ArrowLeft2.svg';
import { ReactComponent as ArrowRight2 } from '../../../../assets/svg/ArrowRight2.svg';
import Typography from '../../atoms/Typography/Typography';
import Container from '../../../layout/Container/Container';

const SliderFeaturedProducts = ({ images, ...props }) => {
  const [currentSlider, setCurrentSlider] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);

  if (!props?.state) {
    return null;
  }

  const next = () => {
    setCurrentSlider(currentSlider + 1);
  };

  const prev = () => {
    setCurrentSlider(currentSlider - 1);
  };

  const updateCurrentSlide = (index) => {
    if (currentSlider !== index) {
      setCurrentSlider(index);
    }
  };
  return (
    <Container>
      <div className={style.featuredProducts}>
        <div className={style.featuredProducts__header}>
          <Typography
            variant={props?.title?.variant}
            style={
              props?.title?.strong
                ? { fontWeight: '700' }
                : { fontWeight: '400' }
            }
          >
            {props?.title?.value}
          </Typography>
        </div>
        <div className={style.featuredProducts__content}>
          <div className={style.featuredProducts__content__feat}>
            <Crs
              className={classNames(style.featuredProducts__sliderBanner, {
                [style.featuredProducts__sliderBanner_hidden]:
                  props?.variant === 'timed',
              })}
              showThumbs={false}
              showStatus={false}
              infiniteLoop
              preventMovementUntilSwipeScrollTolerance
              renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && (
              <IconButton
                onClick={onClickHandler}
                title={label}
                className={classNames(
                  style.featuredProducts__arrowMiddle,
                  style.featuredProducts__arrowMiddle_left,
                )}
                icon={<ArrowLeft2 />}
                size="responsive"
                color="#000"
              />
              )}
              renderArrowNext={(onClickHandler, hasNext, label) => hasNext && (
              <IconButton
                onClick={onClickHandler}
                title={label}
                className={classNames(
                  style.featuredProducts__arrowMiddle,
                  style.featuredProducts__arrowMiddle_right,
                )}
                icon={<ArrowRight2 />}
                size="responsive"
                color="#000"
              />
              )}
            >
              {images?.map((image, index) => (
                <div key={index} className={style.slider__image}>
                  <picture>
                    <source
                      media="(max-width: 900px)"
                      srcSet={image.urlMobile}
                    />
                    <source
                      media="(min-width: 1000px)"
                      srcSet={image.urlDesktop}
                    />
                    <img
                      className={style.slider__image}
                      src={image.urlDesktop}
                      alt={image.alt}
                    />
                  </picture>
                </div>
              ))}
            </Crs>
          </div>
          <Carousel
            activeSlideIndex={activeSlide}
            onRequestChange={setActiveSlide}
            infinite={false}
            center
            disableNavIfAllVisible
            responsiveProps={[
              {
                minWidth: 1024,
                itemsToScroll: 1,
                itemsToShow: 5,
              },
              {
                minWidth: 768,
                maxWidth: 1020,
                itemsToScroll: 1,
                itemsToShow: 3,
              },
              {
                minWidth: 321,
                maxWidth: 767,
                itemsToShow: 4,
                itemsToScroll: 1,
              },
              {
                maxWidth: 320,
                itemsToShow: 2,
                itemsToScroll: 1,
              },
            ]}
            itemsListProps={{
              style: {
                touchAction: 'none',
              },
            }}
            // containerProps={{
            //   style: {
            //     padding: '0 15px',
            //   },
            // }}
            forwardBtnProps={{
              children: (
                <span className={style.featuredProducts__arrows}>
                  <ArrowRight />
                </span>
              ),
              className: 'nextButton',
            }}
            backwardBtnProps={{
              children: (
                <span className={style.featuredProducts__arrows}>
                  <ArrowLeft />
                </span>
              ),
              className: 'prevButton',
            }}
            // itemsToShow={items}
            speed={300}
          >
            {props?.products.map((product, index) => (
              <ProductCard
                key={index}
                name={product.name}
                urlImage={product.urlImage}
                urlBadge={product.urlBadge}
                brand={product.brand}
                rating={product.rating}
                totalReviews={product.totalReviews}
                price={product.price}
                normalPrice={product.normalPrice}
                timerTime={product.timerTime}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </Container>
  );
};

SliderFeaturedProducts.propTypes = {
  state: PropTypes.bool,
  variant: PropTypes.oneOf(['featured', 'timed']),
  title: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
  }),
  images: PropTypes.arrayOf(PropTypes.shape({
    urlMobile: PropTypes.string,
    urlDesktop: PropTypes.string,
    name: PropTypes.string,
    linkto: PropTypes.string,
  })),
  products: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    urlImage: PropTypes.string,
    urlBadge: PropTypes.string,
    brand: PropTypes.string,
    rating: PropTypes.number,
    totalReviews: PropTypes.number,
    price: PropTypes.string,
    normalPrice: PropTypes.string,
    timerTime: PropTypes.number,
  })),
};

export default SliderFeaturedProducts;
