import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputSearch from '../../atoms/InputSearch/InputSearch';
import style from './MultiSelectAsLink.module.scss';
import ButtonLink from '../../atoms/ButtonLink/ButtonLink';
import BadgeIndicator from '../../atoms/BadgeIndicator/BadgeIndicator';

const MultiSelectAsLink = ({ state, list, onInputChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  if (!state) {
    return null;
  }

  const filtered = !searchTerm
    ? list
    : list.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className={style.multiSelectAsLink}>
      <InputSearch
        state={list?.length > 10}
        value={searchTerm}
        onChange={handleChange}
      />
      <ul className={style.multiSelectAsLink__list}>
        {filtered?.map(({
          label, count, url, id,
        }) => (
          <li className={style.multiSelectAsLink__item} key={url}>
            <ButtonLink
              className={style.filterPlp__buttonLink}
              onLinkClick={() => onInputChange({ id, url })}
            >
              {label}
              <BadgeIndicator value={count} />
            </ButtonLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

MultiSelectAsLink.propTypes = {
  state: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onInputChange: PropTypes.func,
};

export default MultiSelectAsLink;
