import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';
import style from './ToggleButton.module.scss';
import Loader from '../Loader/Loader';

const ToggleButton = ({
  children, isHighlighted, ...props
}) => (
  <button
    {...props}
    type="button"
    className={classNames(style.toggleButton, props.className, {
      [style.toggleButton_selected]: isHighlighted,
    })}
  >
    { props.isLoading && (
      <div className={style.toggleButton__loader}>
        <Loader />
      </div>
    )}
    {props.startIcon && <SVG src={props.startIcon} />}
    {children}
  </button>
);

ToggleButton.propTypes = {
  children: PropTypes.string,
  index: PropTypes.number,
  startIcon: PropTypes.string,
  isHighlighted: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ToggleButton;
