/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styles from './ProductImageBoxPDP.module.scss';
import { CloudeFlare, PDPImages } from '../../../../constants/index';
import { hasHttp } from '../../../../utils';
import Modal from '../../organisms/Modal/Modal';
import ImageZoomModal from '../../organisms/ImageZoomModal/ImageZoomModal';

const ProductImageBoxPDP = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [Images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState([]);
  const [isImageBroke, setIsImageBroke] = useState(false);
  const placeholderImageUrl = 'https://images.contentstack.io/v3/assets/blt2f8082df109cfbfb/blt986a90bb4eedcd35/664769a80bbc6249927fa5a5/404.jpeg';

  const ObjectImageBroke = [
    {
      external_id: '691425',
      media_url: placeholderImageUrl,
      position: 0,
    },
    {
      external_id: '691425',
      media_url: placeholderImageUrl,
      position: 1,
    },
  ];

  const renderImages = (imagesArray) => imagesArray?.slice(1).map((item, index) => (
    <li key={index}>
      <button type="button" onClick={() => setCurrentImage(item?.media_url)}>
        <img src={`${item?.media_url}`} alt="Miniatura de producto" />
      </button>
    </li>
  ));

  const renderBadges = (badgesArray) => badgesArray?.map((it) => {
    if (hasHttp(it.value)) {
      return (
        <img
          src={it.value}
          alt={it.type}
          className={`store_in_store_${it.type}`}
        />
      );
    }
    if (it.type === 'services' || it.type === 'warranties') {
      return (
        <div className={styles.auxiliarBadge}>
          <img
            src={it.image}
            alt={it.type}
            className={`store_in_store_${it.type}`}
          />
          <span>{it.value}</span>
        </div>
      );
    }
    if (it.type === 'NEW' || it.type === 'MXN_PROMOTION') {
      return (
        <span className={`${styles.exclusiveBadge} ${it.type}`}>
          {it.value}
        </span>
      );
    }

    return null;
  });

  const handleImageError = () => {
    setIsImageBroke(true);
  };

  useEffect(() => {
    setImages(
      props?.productImages?.result?.length !== 0
        ? props?.productImages?.result
        : ObjectImageBroke,
    );
    setCurrentImage(
      props?.productImages?.result?.length !== 0
        ? props?.productImages?.result[0]?.media_url
        : ObjectImageBroke[0]?.media_url,
    );
  }, [props?.productImages?.result, props?.productId]);

  return (
    <div className={styles.productImageBox__main}>
      <figure>
        <span className={`${styles.badge}`}>
          {props.badges && renderBadges(props.badges)}
        </span>
        <button type="button" onClick={() => setIsOpen(true)}>
          {isImageBroke ? (
            <img src={`${placeholderImageUrl}`} alt="Imagen de respaldo" />
          ) : (
            <img
              src={`${currentImage}`}
              style={{ width: '500px' }}
              alt="Imagen de producto"
              onError={() => handleImageError()}
            />
          )}
        </button>
      </figure>
      <ul className={styles.productImageBox__thumbnails}>
        {renderImages(Images)}
      </ul>
      <Modal
        classNameModal={styles.modalImagesBox}
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      >
        <ImageZoomModal
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          currentImage={currentImage}
          productImages={
            props.productImages ? Images : [`SodimacCO/${props.productId}`]
          }
        />
      </Modal>
    </div>
  );
};

ProductImageBoxPDP.propTypes = {
  productId: PropTypes.string,
  productImages: PropTypes.arrayOf([]),
  badges: PropTypes.arrayOf([]),
};

export default ProductImageBoxPDP;
