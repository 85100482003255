/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Container from '../../../layout/Container/Container';
import style from './InformativeCardsGroup.module.scss';
import Typography from '../../atoms/Typography/Typography';
import InfoCard from '../../molecules/InfoCard/InfoCard';
import useScrollEndDetection from '../../../Hooks/useScrollEndDetection/useScrollEndDetection';

const InformativeCardsGroup = ({
  title, variant, content, state, ...props
}) => {
  const containerRef = useRef(null);
  const { reachedEnd, reachedStart } = useScrollEndDetection(containerRef);

  if (!state) {
    return null;
  }

  const lengthContent = content?.length;

  return (
    <Container>
      <div className={style.infoCardGroup}>
        <div style={{ width: '100%', marginBottom: '30px' }}>
          <Typography
            className={style.infoCardGroup__title}
            variant="delta29"
            style={{ fontWeight: '700', textAlign: variant }}
          >
            {title}
          </Typography>
        </div>
        <div className={style.infoCardGroup__content}>
          {content?.map((infoCard) => (
            <InfoCard
              width={lengthContent <= 6 ? 100 / lengthContent : 98 / 6}
              key={infoCard.id}
              imageurl={infoCard?.imageurl}
              title={infoCard?.title}
              description={infoCard?.description}
              backGroundColor={infoCard.backGroundColor}
              label={infoCard.label}
              link={infoCard.link}
              theme={props.theme}
            />
          ))}
        </div>
      </div>
    </Container>
  );
};

InformativeCardsGroup.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  state: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['left', 'center', 'right']),
  content: PropTypes.arrayOf(
    PropTypes.shape({
      imageurl: PropTypes.string,
      title: PropTypes.shape({
        value: PropTypes.string,
        variant: PropTypes.string,
        strong: PropTypes.bool,
      }),
      description: PropTypes.shape({
        value: PropTypes.string,
        variant: PropTypes.string,
        strong: PropTypes.bool,
      }),
    }),
  ),
};

InformativeCardsGroup.defaultProps = {
  state: false,
  variant: 'vertical',
};

export default InformativeCardsGroup;
