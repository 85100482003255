import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './ToggleButtonList.module.scss';

const ToggleButtonList = ({
  items, renderItem, onHandleChange, initialIndex, className,
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(initialIndex);
  const onChangeSelectedIndex = (index, item) => {
    setSelectedIndex(index);
    onHandleChange({ ...item, index });
  };
  return (
    <div className={classNames(style.toggleButtonList, className)}>
      {items?.map((item, index) => {
        const isHighlighted = index === selectedIndex;
        return renderItem(
          item,
          isHighlighted,
          index,
          onChangeSelectedIndex,
          item.backgroundColor,
        );
      })}
    </div>
  );
};

ToggleButtonList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  initialIndex: PropTypes.number,
  renderItem: PropTypes.func,
  onHandleChange: PropTypes.func,
  className: PropTypes.string,
};

export default ToggleButtonList;
