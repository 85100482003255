import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import HomeTemplate from '../../components/UI/templates/HomeTemplate/HomeTemplate';
import {
  useGetBlobStorageDataQuery,
  useGetThemeDataQuery,
  useGetBlobStorageCampaingQuery,
} from '../../redux/slices/HOME/blobStorageApi';
import { setCampaing, setTheme } from '../../redux/slices/transversalSlice';
import Loader from '../../components/UI/atoms/Loader/Loader';
import style from '../../components/UI/molecules/AlertCartModalBox/AlertCartModalBox.module.scss';

const HomeStorePage = ({ store }) => {
  const {
    data: initialSetUp,
    isLoading,
    error,
  } = useGetBlobStorageDataQuery(store);

  const {
    data: campaing,
  } = useGetBlobStorageCampaingQuery(store);

  const {
    data: theme,
    isLoading: themeLoading,
    error: themeError,
  } = useGetThemeDataQuery(store);

  const dispatch = useDispatch();

  if (isLoading || themeLoading) {
    return (
      <div className={style.loader}>
        <Loader />
      </div>
    );
  }

  if (error || themeError) {
    return (
      <h1>
        Upps, something was wrong!
        {console.log(error)}
      </h1>
    );
  }
  if (theme) {
    dispatch(setTheme(theme));
  }
  if (campaing) {
    dispatch(setCampaing(campaing));
  }

  return <HomeTemplate initialSetUp={initialSetUp} />;
};

HomeStorePage.propTypes = {
  store: PropTypes.string,
};

export default HomeStorePage;
