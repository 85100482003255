/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import PropTypes, { string } from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import styles from './CardFilter.module.scss';

import 'animate.css';
import ArrowDown from '../ArrowDown/ArrowDown';

const CardFilter = ({
  imgUrl,
  caption,
  backgroundColor,
  selection,
  selectionColor,
  onClick,
  type,
  cta,
  text,
  colorText,
  withSubcategory,
  url,
  PLP,
  marcation,
  cardWidthTablet,
  cardWidthDesktop,
  fontColor,
  withoutSelectedState,
}) => {
  const isTablet = useMediaQuery({
    query: '(max-width: 992px)',
  });
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)',
  });

  const handleClick = (event) => {
    onClick(event.currentTarget.value, backgroundColor, cta, url, marcation);
  };

  // eslint-disable-next-line no-nested-ternary
  const widthCard = () => {
    if (isTablet) {
      return `${cardWidthTablet}px`;
    }
    if (isDesktop) {
      return `${cardWidthDesktop}px`;
    }
    return null;
  };

  const styleBackground = (color) => ({
    backgroundColor: `${color}`,
    // width: widthCard(),
  });

  const trueActive = selection === caption;

  const small = type?.toLowerCase() === 'small';

  return (
    <>
      <button
        value={caption}
        style={{ width: widthCard() }}
        className={`${small ? styles.card__small : styles.card__big} ${
          withSubcategory ? styles.card__subcategory : ''
        }`}
        type="button"
        onClick={handleClick}
        data-testid={backgroundColor}
      >
        <div className={PLP ? styles.card__containerPLP : styles.card__container}>
          {!withoutSelectedState && (
          <div
            data-testid={!trueActive ? 'noActive' : 'active'}
            className={`hover ${trueActive ? 'active' : ''}`}
            style={small ? styleBackground(backgroundColor) : {}}
          >
            <div style={small ? { borderTopColor: backgroundColor } : {}} />
          </div>
          )}
          <img style={{ width: '100%' }} className={styles.card__image} src={imgUrl} alt={caption} />

          <span
            style={{
              backgroundColor:
            PLP
              ? ''
              : trueActive ? backgroundColor : '',
              fontSize: isDesktop ? '16px' : '14px',
              color:
              !colorText
                ? text
                : trueActive
                  ? colorText.selected
                  : colorText.unSelected,
              height: '60px',
              display: 'flex',
              // alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className={PLP ? styles.card__captionPLP : styles.card__caption}
          >
            {caption}
          </span>
        </div>
      </button>
      {PLP
          && (
          <>
            <div
              style={{
                display:
                trueActive
                  ? ''
                  : 'none',
                borderColor: text,
              }}
              className={styles.lineColor}
            />
            <ArrowDown
              state={trueActive}
              color={text}
            />
          </>
          )}
    </>
  );
};
CardFilter.propTypes = {
  imgUrl: PropTypes.string,
  url: PropTypes.string,
  caption: PropTypes.string,
  cta: PropTypes.string,
  cardWidthTablet: PropTypes.string,
  cardWidthDesktop: PropTypes.string,
  text: PropTypes.string,
  selection: PropTypes.string,
  type: PropTypes.oneOf(['big', 'small']),
  colorText: PropTypes.shape({
    selected: PropTypes.string,
    unSelected: PropTypes.string,
  }),
  backgroundColor: PropTypes.string,
  onClick: PropTypes.func,
  withSubcategory: PropTypes.bool,
  withoutSelectedState: PropTypes.bool,
  PLP: PropTypes.bool,
  fontColor: PropTypes.string,
  selectionColor: PropTypes.string,
  marcation: PropTypes.string,

};

export default CardFilter;
