import { useMediaQuery } from 'react-responsive';

const useImageMatrix = (
  content,
) => {
  const isTablet = useMediaQuery({
    query: '(min-width: 769px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });
  const lengthContent = content?.length;
  const gridLogic = () => {
    if (isMobile) {
      return {
        width: 'calc(50% - 8px)',
      };
    }
    if (isTablet) {
      return {
        width: `calc(${
          lengthContent <= 5 ? 100 / lengthContent : 98 / (lengthContent / 2)
        }% - 8px)`,
      };
    }
    return null;
  };
  return [gridLogic];
};

export default useImageMatrix;
