import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../atoms/Typography/Typography';
import style from './ContractedService.module.scss';
import ToggleButtonList from '../ToggleButtonList/ToggleButtonList';
import ToggleButton from '../../atoms/ToggleButton/ToggleButton';
import Success from '../../../../assets/svg/CircleSuccess.svg';

const ContractedService = ({
  title, items, isLoading, isSuccess, onHandleChange,
}) => {
  const newItems = [{ id: '', value: 'No la necesito' }].concat(items);
  return (
    <div className={style.root}>
      <Typography className={style.root__title} variant="kappa14">
        {title}
      </Typography>
      <ToggleButtonList
        className={style.root__actions}
        items={newItems}
        initialIndex={0}
        onHandleChange={onHandleChange}
        renderItem={(item, isHighlighted, index, onChangeSelectedIndex) => (
          <ToggleButton
            className={style.root__button}
            key={item.id}
            isHighlighted={item?.isHighlighted || isHighlighted}
            isLoading={isHighlighted && isLoading}
            startIcon={isHighlighted && isSuccess && Success}
            onClick={() => onChangeSelectedIndex(index, item)}
          >
            {item?.value}
          </ToggleButton>
        )}
      />
    </div>
  );
};

ContractedService.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  onHandleChange: PropTypes.func,
};

export default ContractedService;
