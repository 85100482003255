import React from 'react';
import PropTypes from 'prop-types';
import Beta42 from '../../atoms/Beta42/Beta42';
import RatingStars from '../RatingStars/RatingStars';
import Kappa14 from '../../atoms/Kappa14/Kappa14';
import style from './OpinionStatisticsHeader.module.scss';
import Typography2 from '../../atoms/Typography2/Typography2';

const OpinionStatisticsHeader = ({ title, ratingAverage, totalReviews }) => (
  <div className={style.header}>
    <Typography2 variant="epsilon24" style={{ fontWeight: '700' }} className={style.header__title}>{title}</Typography2>
    <div className={style.header__content}>
      <div className={style.header__average}>
        <Beta42 strong>{parseFloat(ratingAverage).toFixed(1)}</Beta42>
        <RatingStars color="#006AC1" rating={parseFloat(ratingAverage)} name={title} showReviews={false} />
      </div>
      <div className={style.header__averageText}>
        <Kappa14 color="rgb(89, 89, 89)">
          Promedio entre
          {' '}
          {totalReviews}
          {' '}
          opiniones
        </Kappa14>
      </div>
    </div>
  </div>
);

OpinionStatisticsHeader.propTypes = {
  ratingAverage: PropTypes.string,
  totalReviews: PropTypes.string,
  title: PropTypes.string,
};

export default OpinionStatisticsHeader;
