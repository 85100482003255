import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ReviewsTemplate.module.scss';
import OpinionStatistics from '../../organisms/OpinionStatistics/OpinionStatistics';
import ReviewsTabsPDP from '../../organisms/ReviewsTabsPDP/ReviewsTabsPDP';
import Typography2 from '../../atoms/Typography2/Typography2';
import Button from '../../atoms/Button/Button';
import ArrowUp from '../../../../assets/svg/ArrowUp.svg';
import ArrowDown from '../../../../assets/svg/ArrowDown.svg';
import { reviewsTemplateStringsData } from './ReviewsTemplate.mocks';

const ReviewsTemplate = ({
  ratingAverage,
  totalReviews,
  statistics,
  buttons,
  data,
  handleAddReview,
}) => {
  const [currHeight, setCurrHeight] = useState(0);
  const [displayState, setdisplayState] = useState(false);
  const heigthEle = useRef(null);
  const isLarge = (heightData) => heightData > 450;
  const { title } = reviewsTemplateStringsData;

  const handleClick = () => {
    setdisplayState(!displayState);
  };

  useEffect(() => {
    const currentHeight = heigthEle.current.offsetHeight;
    setCurrHeight(currentHeight);
    setTimeout(() => {
      if (isLarge(currentHeight)) {
        heigthEle.current.style.height = '460px';
        heigthEle.current.style.overflowY = 'hidden';
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (isLarge(currHeight)) {
      if (displayState) {
        heigthEle.current.style.height = 'auto';
        heigthEle.current.style.overflowY = 'unset';
      } else {
        heigthEle.current.style.height = '460px';
        heigthEle.current.style.overflowY = 'hidden';
      }
    }
  }, [displayState]);

  return (
    <div className={styles.general}>
      <div className={styles.container} ref={heigthEle}>
        <div className={styles.title}>
          <Typography2 variant="epsilon24" style={{ fontWeight: '700' }}>
            {title}
          </Typography2>
        </div>
        <div className={styles.own}>
          <OpinionStatistics
            ratingAverage={ratingAverage}
            totalReviews={totalReviews}
            statistics={statistics}
            handleAddReview={handleAddReview}
          />
        </div>
        <div className={styles.others}>
          <ReviewsTabsPDP buttons={buttons} data={data} />
        </div>
      </div>
      {isLarge(currHeight) && (
        <div className={styles.border}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={displayState ? ArrowUp : ArrowDown}
            onClick={handleClick}
          >
            {displayState ? 'Ver menos' : 'Ver más'}
          </Button>
        </div>
      )}
    </div>
  );
};

ReviewsTemplate.propTypes = {
  ratingAverage: PropTypes.string,
  totalReviews: PropTypes.string,
  handleAddReview: PropTypes.func,
  statistics: PropTypes.arrayOf(PropTypes.shape({})),
  buttons: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.shape({}),
};

export default ReviewsTemplate;
