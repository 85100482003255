/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './ToolTip.module.scss';

const ToolTip = ({ text, children }) => {
  const [viewToolTip, setViewToolTip] = useState(false);

  return (
    <div
      className={styles.ToolTip}
      onMouseEnter={() => setViewToolTip(true)}
      onMouseLeave={() => setViewToolTip(false)}
    >
      <button type="button" className={styles.ToolTip__Button}>
        {text}
      </button>

      {viewToolTip && <div>{children}</div>}
    </div>
  );
};

export default ToolTip;
