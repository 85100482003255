import React from 'react';
import PropTypes from 'prop-types';
import style from './OpinionStatisticsList.module.scss';

const OpinionStatisticsList = ({ items, renderItem }) => (
  <div className={style.list}>
    {items?.map((item) => renderItem(item))}
  </div>
);
OpinionStatisticsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  renderItem: PropTypes.func,
};

export default OpinionStatisticsList;
