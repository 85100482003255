import React, {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AngleUp } from '../../../../assets/svg/AngleUp.svg';
import { ReactComponent as AngleDown } from '../../../../assets/svg/AngleDown.svg';
import style from './Accordion2.module.scss';

const AccordionContext = createContext();

const Accordion2 = ({ initialToggle, children, state }) => {
  const [on, setOn] = useState(initialToggle);
  const toggle = useCallback(() => setOn((prev) => !prev), []);
  const value = useMemo(() => ({ on, toggle }), [on]);
  if (!state) {
    return null;
  }
  return (
    <AccordionContext.Provider value={value}>
      {children}
    </AccordionContext.Provider>
  );
};

const useToggleContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('Toggle compound components cannot be rendered outside the Toggle component');
  }
  return context;
};

const Header = ({ children, ...props }) => {
  const { on, toggle } = useToggleContext();
  return (
    <button
      className={style.accordion__header}
      type="button"
      onClick={toggle}
      {...props}
    >
      {children}
      <div>
        {on ? (<AngleUp />) : (<AngleDown />)}
      </div>
    </button>
  );
};

const Content = ({ children }) => {
  const { on } = useToggleContext();
  return on ? (<div className={style.accordion__content}>{children}</div>) : null;
};

Accordion2.Header = Header;
Accordion2.Content = Content;

Accordion2.propTypes = {
  state: PropTypes.bool,
  children: PropTypes.node,
  initialToggle: PropTypes.bool,
};

Content.propTypes = {
  children: PropTypes.node,
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Accordion2;
