import { useEffect, useState } from 'react';

const useScrollEndDetection = (ref) => {
  const [reachedEnd, setReachedEnd] = useState(false);
  const [reachedStart, setReachedStart] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const container = ref.current;
      if (container) {
        const { scrollLeft } = container;
        const { clientWidth } = container;
        const { scrollWidth } = container;

        const isEndReached = scrollLeft + clientWidth >= scrollWidth;
        const isStartReached = scrollLeft === 0;

        setReachedEnd(isEndReached);
        setReachedStart(isStartReached);
      }
    };

    const container = ref.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [ref]);

  return { reachedEnd, reachedStart };
};

export default useScrollEndDetection;
