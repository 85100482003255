/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaTags = ({
  store, restUrl, page, catId, productId,
}) => {
  const stores = {
    constructor: {
      BaseUrl: 'https://www.homecenter.com.co/homecenter-co/tiendas/constructor/',
      home: {
        title: 'Constructor - Todo para el especialista  | Homecenter',
        description: 'Encuentra todo lo que necesitas para tus proyectos de construcción en Constructor. Pagos Online con Tarjeta Débito, Crédito, CMR, Baloto, Efecty o Cajas en Tienda.',
      },
      plp: {
        title: `${catId} | Homecenter`,
        description: `Compra en Constructor ${catId} para tus proyectos. Pagos Online con Tarjeta Débito, Crédito, CMR, Baloto, Efecty o Cajas en Tienda`,
      },
      pdp: {
        title: `${productId} | Homecenter`,
        description: `Compra al por mayor ${productId} para tus proyectos. Pagos Online con Tarjeta Débito, Crédito, CMR, Baloto, Efecty o Cajas en Tienda`,
      },
    },
    petcenter: {
      BaseUrl: 'https://www.homecenter.com.co/homecenter-co/tiendas/tienda-mascotas-pet-center/',
      home: {
        title: 'Bienvenido a Pet Center tienda de mascotas | Homecenter',
        description: 'En Pet Center tu tienda de mascotas ideal encuentras todo lo que necesitas. Pagos Online con Tarjeta Débito, Crédito, CMR, Baloto, Efecty o Cajas en Tienda',
      },
      plp: {
        title: `${catId} | Homecenter`,
        description: `Compra en Pet Center ${catId} para tus mascotas. Pagos Online con Tarjeta Débito, Crédito, CMR, Baloto, Efecty o Cajas en Tienda`,
      },
      pdp: {
        title: `${productId} | Homecenter`,
        description: `Compra en Pet Center ${productId} para tus mascotas. Pagos Online con Tarjeta Débito, Crédito, CMR, Baloto, Efecty o Cajas en Tienda`,
      },
    },
    carcenter: {
      BaseUrl: 'https://www.homecenter.com.co/homecenter-co/tiendas/servicio-automotriz-carcenter/',
      home: {
        title: 'Bienvenido a Car Center tienda automotriz | Homecenter',
        description: 'En Car Center encuentras todo para tu vehiculo. Pagos Online con Tarjeta Débito, Crédito, CMR, Baloto, Efecty o Cajas en Tienda',
      },
      plp: {
        title: `${catId} | Homecenter`,
        description: `Compra Car Center ${catId} para tu vehiculo. Pagos Online con Tarjeta Débito, Crédito, CMR, Baloto, Efecty o Cajas en Tienda`,
      },
      pdp: {
        title: `${productId} | Homecenter`,
        description: `Compra en Car Center ${productId} para tu vehiculo. Pagos Online con Tarjeta Débito, Crédito, CMR, Baloto, Efecty o Cajas en Tienda`,
      },
    },
    grandesvolumenes: {
      BaseUrl: 'https://www.homecenter.com.co/homecenter-co/tiendas/compras-por-mayor-grandes-volumenes/',
      home: {
        title: 'Comprar al por mayor con Grandes volúmenes| Homecenter',
        description: 'Comprar al por mayor es posible en Homecenter con Grandes Volúmenes. Pagos Online con Tarjeta Débito, Crédito, CMR, Baloto, Efecty o Cajas en Tienda.',
      },
      plp: {
        title: `${catId} | Homecenter`,
        description: `Compra al por mayor ${catId} y ahorra en grande con Homecenter. Pagos Online con Tarjeta Débito, Crédito, CMR, Baloto, Efecty o Cajas en Tienda`,
      },
      pdp: {
        title: `${productId} | Homecenter`,
        description: `Compra al por mayor ${productId} y ahorra en grande con Homecenter. Pagos Online con Tarjeta Débito, Crédito, CMR, Baloto, Efecty o Cajas en Tienda`,
      },
    },
  };

  if (!store) {
    return null;
  }

  return (
    <Helmet>
      <title>{stores[store][page]?.title}</title>
      <link rel="canonical" href={`${stores[store].BaseUrl}${restUrl}`} />
      <meta name="description" content={stores[store][page]?.description} />
    </Helmet>
  );
};
MetaTags.propTypes = {
  store: PropTypes.string,
  restUrl: PropTypes.string,
  page: PropTypes.string,
  catId: PropTypes.string,
  productId: PropTypes.string,
};

export default MetaTags;
