import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Typography.module.scss';

const Typography = ({ children, variant, ...props }) => {
  if (variant === 'gamma35') {
    return (
      <h5
        style={props.style}
        className={classNames(
          style.gamma35,
          props.className,
        )}
      >
        {children}
      </h5>
    );
  }
  if (variant === 'delta29') {
    return (
      <h3
        style={props.style}
        className={classNames(
          style.delta29,
          props.className,
        )}
      >
        {children}
      </h3>
    );
  }
  if (variant === 'epsilon24') {
    return (
      <h4
        style={props.style}
        className={classNames(
          style.epsilon24,
          props.className,
        )}
      >
        {children}
      </h4>
    );
  }
  if (variant === 'h5') {
    return (
      <h4
        style={props.style}
        className={classNames(
          style.epsilon24,
          props.className,
        )}
      >
        {children}
      </h4>
    );
  }
  if (variant === 'h3') {
    return (
      <h3
        style={props.style}
        className={classNames(
          style.epsilon24,
          props.className,
        )}
      >
        {children}
      </h3>
    );
  }
  if (variant === 'zeta20') {
    return (
      <h6
        style={props.style}
        className={classNames(style.zeta20, props.className)}
      >
        {children}
      </h6>
    );
  }
  if (variant === 'omega17') {
    return (
      <p
        style={props.style}
        className={classNames(
          style.omega17,
          props.className,
        )}
      >
        {children}
      </p>
    );
  }
  if (variant === 'kappa14') {
    return (
      <p
        id="Typography-P"
        style={{ ...props.style }}
        className={classNames(
          style.kappa14,
          props.className,
        )}
      >
        {children}
      </p>
    );
  }
  /** A partir de aqui posiblemente se borre */
  if (variant === 'h4') {
    return (
      <h4
        style={props.style}
        className={classNames(
          style.typography,
          style.typography_variantH4,
          props.className,
        )}
      >
        {children}
      </h4>
    );
  }
  if (variant === 'h1') {
    return (
      <h1
        style={props.style}
        className={classNames(
          style.typography_variantBody1,
          props.className,
        )}
      >
        {children}
      </h1>
    );
  }
  if (variant === 'button') {
    return (
      <p
        style={props.style}
        className={classNames(
          style.typography,
          style.typography_variantButton,
          props.className,
        )}
      >
        {children}
      </p>
    );
  }
  if (variant === 'body2') {
    return (
      <h4
        style={props.style}
        className={classNames(
          style.typography,
          style.typography_variantBody2,
          props.className,
        )}
      >
        {children}
      </h4>
    );
  }
  if (variant === 'terminos') {
    return (
      <h5
        style={props.style}
        className={classNames(
          style.typography_terminos,
          props.className,
        )}
      >
        {children}
      </h5>
    );
  }
  return (
    <div
      style={props.style}
      className={classNames(
        style.typography,
        style.typography_variantBody1,
        props.className,
      )}
    >
      {children}
    </div>
  );
};

Typography.propTypes = {
  /**
   * Content of the component
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Applies theme and component used for the root node
   */
  variant: PropTypes.oneOf([
    'gamma35',
    'delta29',
    'epsilon24',
    'zeta20',
    'omega17',
    'kappa14',
    'h4',
    'h1',
  ]),
  /**
   * Styles to add to component
   */
  style: PropTypes.shape({}),
  className: PropTypes.string,
};
Typography.defaultProps = {
  variant: 'omega17',
};

export default React.memo(Typography);
