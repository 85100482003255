/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styles from './GeolocalizationModal.module.scss';
import Modal from '../Modal/Modal';
import GeolocationModalBox from '../../molecules/GeolocationModalBox/GeolocationModalBox';
import { ReactComponent as ThinXmark } from '../../../../assets/svg/ThinXmark.svg';
import { useLazyGetCitiesQuery, useLazyGetNeighborhoodsQuery, useLazyGetStatesQuery } from '../../../../redux/slices/PLP/geolocalizationApi';

// const localizationData = [
//   {
//     label: 'Departamento',
//     id: 'dd-sort-geo-options',
//     options: [
//       {
//         code: 121,
//         label: 'ABEJORRAL',
//       },
//       {
//         code: 1110,
//         label: 'ALTAVISTA',
//       },
//       {
//         code: 1106,
//         label: 'SAN ANTONIO DEL PRADO',
//       },
//       {
//         code: 225,
//         label: 'SONSON',
//       },
//       {
//         code: 226,
//         label: 'SOPETRAN',
//       },
//       {
//         code: 3512,
//         label: 'VEREDA GALICIA',
//       },
//       {
//         code: 2901,
//         label: 'VEREDA LLANADAS ARRIBA',
//       },
//       {
//         code: 3628,
//         label: 'VEREDA SABANETA',
//       },
//       {
//         code: 243,
//         label: 'YONDO',
//       },
//       {
//         code: 244,
//         label: 'ZARAGOZA',
//       },
//     ],
//   },
//   {
//     label: 'Ciudad',
//     id: 'dd-sort-geo-options',
//     options: [
//       {
//         code: 121,
//         label: 'ABEJORRAL',
//       },
//       {
//         code: 1110,
//         label: 'ALTAVISTA',
//       },
//       {
//         code: 1106,
//         label: 'SAN ANTONIO DEL PRADO',
//       },
//       {
//         code: 225,
//         label: 'SONSON',
//       },
//       {
//         code: 226,
//         label: 'SOPETRAN',
//       },
//       {
//         code: 3512,
//         label: 'VEREDA GALICIA',
//       },
//       {
//         code: 2901,
//         label: 'VEREDA LLANADAS ARRIBA',
//       },
//       {
//         code: 3628,
//         label: 'VEREDA SABANETA',
//       },
//       {
//         code: 243,
//         label: 'YONDO',
//       },
//       {
//         code: 244,
//         label: 'ZARAGOZA',
//       },
//     ],
//   },
//   {
//     label: 'Barrio',
//     id: 'dd-sort-geo-options',
//     options: [
//       {
//         code: 121,
//         label: 'ABEJORRAL',
//       },
//       {
//         code: 1110,
//         label: 'ALTAVISTA',
//       },
//       {
//         code: 1106,
//         label: 'SAN ANTONIO DEL PRADO',
//       },
//       {
//         code: 225,
//         label: 'SONSON',
//       },
//       {
//         code: 226,
//         label: 'SOPETRAN',
//       },
//       {
//         code: 3512,
//         label: 'VEREDA GALICIA',
//       },
//       {
//         code: 2901,
//         label: 'VEREDA LLANADAS ARRIBA',
//       },
//       {
//         code: 3628,
//         label: 'VEREDA SABANETA',
//       },
//       {
//         code: 243,
//         label: 'YONDO',
//       },
//       {
//         code: 244,
//         label: 'ZARAGOZA',
//       },
//     ],
//   },
// ];
const GeolocalizationModal = ({ isOpen, onClose }) => {
  const {
    departamento,
    ciudad: { id },
    barrio,
  } = useSelector((state) => state.transversalSlice);

  const [getStates, {
    data: statesData,
    error: statesError,
    isLoading: statesLoading,
  }] = useLazyGetStatesQuery();

  const [
    getCities,
    { data: citiesData, error: citiesError, isLoading: citiesLoading },
  ] = useLazyGetCitiesQuery();

  const [
    getNeighborhoods,
    {
      data: neighborhoodsData,
      error: neighborhoodsError,
      isLoading: neighborhoodsLoading,
    },
  ] = useLazyGetNeighborhoodsQuery();

  useEffect(() => {
    getStates({ selected: departamento });
  }, [departamento]);

  useEffect(() => {
    if (statesData) getCities({ departamento, selected: id });
  }, [departamento, statesData, id]);

  useEffect(() => {
    if (citiesData) { getNeighborhoods({ departamento, cityId: id, selected: barrio }); }
  }, [departamento, statesData, citiesData]);

  if (statesLoading) {
    return <div>Loading...</div>;
  }

  if (statesError) {
    return (
      <div>
        Error
        {' '}
        {statesError.error.error}
      </div>
    );
  }

  return (
    <Modal
      isGeo
      classNameModal={styles.root}
      className={styles.root__modal}
      closeModal={onClose}
      isOpen={isOpen}
      renderCloseButton={() => (
        <button
          className={styles.close__button}
          type="button"
          onClick={onClose}
          aria-label="ThinX"
        >
          <ThinXmark />
        </button>
      )}
    >
      <GeolocationModalBox
        maxHeight={135}
        hideBorder={false}
        statesData={statesData}
        citiesData={citiesData}
        neighborhoodsData={neighborhoodsData}
      />
    </Modal>
  );
};
GeolocalizationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default GeolocalizationModal;
