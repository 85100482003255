/* eslint-disable react/no-array-index-key */
import {
  useEffect, useState, useRef, useLayoutEffect,
} from 'react';
import Carousel from 'react-simply-carousel';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import SectionTitle from '../../atoms/SectionTitle/SectionTitle';
import CardFilter from '../../atoms/CardFilter/CardFilter';
import styles from './GeneralFilterHome.module.scss';
// import Container from '../../../layout/Container/Container';
import { ReactComponent as ArrowLeft } from '../../../../assets/svg/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/ArrowRight.svg';
import { isOverflown, reorderData } from '../../../../utils';
import Container from '../../../layout/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import useWindowSize from '../../../Hooks/useWindowSize/useWindowSize';

const StyledGeneral = styled.div`
  &:after {
    background: ${(props) => `
    linear-gradient(
      90deg,
      ${props.shadowColors}00 0%,
      ${props.shadowColors} 100%
    )
    `};
  }
  &:before {
    background: ${(props) => `
    linear-gradient(
      -90deg,
      ${props.shadowColors}00 0%,
      ${props.shadowColors} 100%
    )
    `};
  }
`;

const GeneralFilterHome = ({
  state,
  size,
  defaultItem,
  data: catData,
  onClick,
  sectionTitle,
  sectionSubtitle,
  cardWidthTablet,
  cardWidthDesktop,
  inlineStyles,
  shadowColors,
  withoutSelectedState,
}) => {
  const ref = useRef(null);
  const windowSize = useWindowSize();
  const [first, setfirst] = useState(true);
  const [storeItems, setStoreItems] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeState, setActiveState] = useState({});
  const [sizeState, setSizeState] = useState(size);
  const [isWidthOverflown, setWidthIsOverflown] = useState(size);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({ left: 0, behavior: 'auto' });
    }
  }, []);

  useEffect(() => {
    setStoreItems(
      first
        ? reorderData(catData, defaultItem)
        : reorderData(storeItems, defaultItem),
    );
    setfirst(false);
  }, [defaultItem]);

  useEffect(() => {
    setWidthIsOverflown(isOverflown(ref.current));
    setActiveState({
      selection: storeItems[0]?.caption,
      color: storeItems[0]?.backgroundColor,
    });
  }, [storeItems]);

  useEffect(() => {
    setSizeState(size);
    setActiveSlide(0);
  }, [size]);

  useLayoutEffect(() => {
    setTimeout(() => {
      setWidthIsOverflown(isOverflown(ref.current));
    }, 1000);
  }, [windowSize]);

  const handleClick = (value, color, cta) => {
    onClick(cta, color);
    const generalFilter = document.getElementById('generalFilter');
    generalFilter.scrollIntoView({ behavior: 'smooth', block: 'start' });

    setActiveState({
      selection: value,
      color,
    });
  };
  const justifiedContent = isWidthOverflown ? 'flex-start' : 'center';
  const small = sizeState?.toLowerCase() === 'small';
  const big = sizeState?.toLowerCase() === 'big';

  const IsMobile = useMediaQuery({
    query: '(max-width: 750px)',
  });

  const renderCarrousel = () => (
    <div>
      <Carousel
        centerMode={IsMobile}
        updateOnItemClick
        disableSwipeByMouse
        activeSlideIndex={activeSlide}
        onRequestChange={setActiveSlide}
        itemsToShow={3}
        itemsToScroll={1}
        forwardBtnProps={{
          className: styles.nextButton,
          children: <ArrowRight />,
        }}
        backwardBtnProps={{
          className: styles.prevButton,
          children: <ArrowLeft />,
        }}
        responsiveProps={[
          {
            itemsToShow: 4,
            itemsToScroll: 1,
            minWidth: 500,
          },
          {
            itemsToShow: 5,
            itemsToScroll: 1,
            minWidth: 875,
          },
          {
            itemsToShow: 6,
            itemsToScroll: 1,
            minWidth: 1125,
          },
          {
            itemsToShow: 7,
            itemsToScroll: 1,
            minWidth: 1300,
          },
          {
            itemsToShow: 7,
            itemsToScroll: 1,
            minWidth: 1600,
          },
        ]}
        speed={400}
      >
        {storeItems.map(({ id, ...rest }, index) => (
          <div
            className={styles.cardFilter__container}
            key={index}
          >
            <CardFilter
              withoutSelectedState={withoutSelectedState}
              cardWidthTablet={cardWidthTablet}
              cardWidthDesktop={cardWidthDesktop}
              selection={activeState.selection}
              key={id}
              onClick={handleClick}
              type={size}
              {...rest}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );

  if (big && catData.length > 6 && !IsMobile) {
    return (
      <>
        <Container>
          <nav
            role="navigation"
            className={styles.categoriesFilter}
            style={inlineStyles}
          >
            <SectionTitle
              text={sectionTitle.text}
              style={{
                color: sectionTitle.color,
                textAlign: sectionTitle.textAlign,
              }}
            />
            {sectionSubtitle && (
            <Typography
              variant="omega17"
              className={styles.sectionSubtitle}
              style={{
                color: sectionSubtitle.color,
                textAlign: sectionSubtitle.textAlign,
              }}
            >
              {sectionSubtitle.text}
            </Typography>
            )}

            <StyledGeneral
              shadowColors={shadowColors}
              className={`${styles.categoriesFilter__general} ${styles.big}`}
              id="generalFilter"
            >
              <div
                className={styles.categoriesFilter__smallLine}
                style={{ backgroundColor: `${activeState.color}` }}
              />
              {renderCarrousel()}
            </StyledGeneral>

          </nav>
        </Container>
        <div
          className={styles.categoriesFilter__bigLine}
          style={{ backgroundColor: `${activeState.color}` }}
        />
      </>
    );
  }
  if (small && catData.length > 8 && !IsMobile) {
    return (
      <nav
        role="navigation"
        className={styles.categoriesFilter}
        style={inlineStyles}
      >
        <Container>
          <SectionTitle
            text={sectionTitle.text}
            style={{
              color: sectionTitle.color,
              textAlign: sectionTitle.textAlign,
            }}
          />
          {sectionSubtitle && (
            <Typography
              variant="omega17"
              className={styles.sectionSubtitle}
              style={{
                color: sectionSubtitle.color,
                textAlign: sectionSubtitle.textAlign,
              }}
            >
              {sectionSubtitle.text}
            </Typography>
          )}
          <StyledGeneral
            shadowColors={shadowColors}
            className={`${styles.categoriesFilter__general} ${styles.small}`}
            id="generalFilter"
          >
            {renderCarrousel()}
          </StyledGeneral>
        </Container>
      </nav>
    );
  }

  if (!state) {
    return null;
  }

  return (

    <>

      <Container>
        <nav
          role="navigation"
          className={styles.categoriesFilter}
          style={inlineStyles}
        >
          <SectionTitle
            text={sectionTitle.text}
            style={{
              color: sectionTitle.color,
              textAlign: sectionTitle.textAlign,
            }}
          />
          {sectionSubtitle && (
          <Typography
            variant="omega17"
            className={styles.sectionSubtitle}
            style={{
              color: sectionSubtitle.color,
              textAlign: sectionSubtitle.textAlign,
            }}
          >
            {sectionSubtitle.text}
          </Typography>
          )}
          <StyledGeneral
            shadowColors={shadowColors}
            className={styles.categoriesFilter__general}
            id="generalFilter"
          >
            <div
              className={styles.categoriesFilter__container}
              ref={ref}
              style={{ justifyContent: justifiedContent }}
            >
              {storeItems.map(({ id, ...rest }) => (
                <div className={styles.cardFilter__container} key={`gen-${id}`}>
                  <CardFilter
                    withoutSelectedState={withoutSelectedState}
                    cardWidthTablet={cardWidthTablet}
                    cardWidthDesktop={cardWidthDesktop}
                    selection={activeState.selection}
                    key={id}
                    onClick={handleClick}
                    type={size}
                    {...rest}
                  />
                </div>
              ))}
            </div>
            {!small && (
            <div
              className={styles.categoriesFilter__line}
              style={{ backgroundColor: `${activeState.color}` }}
            />
            )}
          </StyledGeneral>
        </nav>
      </Container>
      <div
        className={styles.categoriesFilter__bigLine}
        style={{ backgroundColor: `${activeState.color}` }}
      />
    </>

  );
};
GeneralFilterHome.propTypes = {
  /**
   * State "true" to turn on component card and "false" to turn off component
   */
  state: PropTypes.bool,
  /**
   * State "small" to show 8 card and "big" to show 6 card
   */
  size: PropTypes.oneOf(['big', 'small']),
  /**
   * Default item
   */
  defaultItem: PropTypes.number,
  /**
   * Default click
   */
  onClick: PropTypes.func,
  /**
   * Data to populate
   */
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  sectionTitle: PropTypes.string,
  sectionSubtitle: PropTypes.string,
  itemsToShow992: PropTypes.string,
  itemsToShow1400: PropTypes.string,
  cardWidthTablet: PropTypes.string,
  cardWidthDesktop: PropTypes.string,
  shadowColors: PropTypes.string,
  inlineStyles: PropTypes.shape({}),
  withoutSelectedState: PropTypes.bool,
};

export default GeneralFilterHome;
