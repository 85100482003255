/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint jsx-a11y/label-has-for: 0 */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import listenForOutsideClicks from './listener';
import { dropdownUtils } from '../../../../utils/dropdownUtils';
import DropdownList from '../DropdownList/DropdownList';
import style from './Dropdown.module.scss';
import { ReactComponent as ArrowDown } from '../../../../assets/svg/ArrowDown.svg';

const Dropdown = ({
  id,
  label,
  hideBorder,
  options,
  maxHeight,
  dropdownListMaxHeight,
  onDropdownChange,
}) => {
  if (!options || !options.length) {
    return null;
  }

  // const global = useSelector((state) => state.categoriesData);
  // const data = Object.values(global.queries);
  // console.log(data[0].data.data.pagination.currentPage);

  const getLabel = (options) => {
    const { label: selectedValueLabel = '' } = dropdownUtils.getSelectedOption(options);
    return selectedValueLabel;
  };
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [dropdownOptions, setDropdownOptions] = useState(options);
  const [expanded, setExpanded] = useState(false);
  const [showDropdownListAbove, setShowDropdownListAbove] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(getLabel(options));

  const toggle = () => setIsOpen(!isOpen);
  // const isBrowser = () => process.browser;

  const toggleDropdownList = (e = {}) => {
    const dropdownElement = e.target;
    if (dropdownElement) {
      const { top } = dropdownElement.getBoundingClientRect();
      const { innerHeight: windowHeight } = window;
      // eslint-disable-next-line max-len
      const isListOnAbove = windowHeight - (top + dropdownElement.clientHeight)
        < dropdownListMaxHeight;
      if (isListOnAbove !== showDropdownListAbove) {
        setShowDropdownListAbove(isListOnAbove);
      }
    }
    setExpanded(!expanded);
    toggle();
  };

  const handleOnDropdownChange = (selectedValue) => {
    onDropdownChange(selectedValue);
    const updatedOptions = dropdownUtils.onDropdownChange({
      options: dropdownOptions,
      selectedOption: selectedValue,
    });

    setDropdownOptions(updatedOptions);
    toggleDropdownList();
    setSelectedLabel(getLabel(updatedOptions));
  };

  useEffect(
    listenForOutsideClicks(listening, setListening, menuRef, setIsOpen),
  );

  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);

  const dropdownExpandedClass = expanded ? style.expanded : '';
  const hideBorderClass = hideBorder ? '' : style.border;
  const tooltipClass = showDropdownListAbove ? style.show_tooltip_top : '';
  const dropdownClass = `${style.dropdown} ${dropdownExpandedClass} ${hideBorderClass} ${tooltipClass}`;

  const testId = `testId-Dropdown-${id}`;
  return (
    <div
      ref={menuRef}
      className={style.dropdown_wrapper}
      id={testId}
      data-testId={testId}
    >
      {label && (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      )}
      <div className={dropdownClass}>
        <div
          id={`${testId}-value`}
          className={`${style.dropdown_selected_item}`}
          onClick={toggleDropdownList}
          role="presentation"
          title={selectedLabel}
        >
          <span className={`${style.dropdown_value}`}>{selectedLabel}</span>
          <span className={style.icon}>
            <ArrowDown />
          </span>
        </div>

        {isOpen && (
          <DropdownList
            id={`${testId}-dropdown-list`}
            options={dropdownOptions}
            onDropdownChange={handleOnDropdownChange}
            toggleDropdownList={toggleDropdownList}
            maxHeight={maxHeight}
            isDropdownListAbove={showDropdownListAbove}
          />
        )}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  hideBorder: PropTypes.bool,
  onDropdownChange: PropTypes.func,
  dropdownListMaxHeight: PropTypes.number,
  maxHeight: PropTypes.number,
};

export default Dropdown;
