/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import styles from './Select.module.scss';

const Select = ({ onchange, data, selectLabel }) => {
  const handleOnChange = (ev) => {
    onchange(ev.target.value);
  };

  const renderOptions = (opt) => opt?.map((it, index) => (
    <option key={index} value={it.label}>
      {it.label}
    </option>
  ));

  return (
    <div className="select__container">
      <label
        htmlFor="ciudad"
        className={`${styles.kappa14} ${styles.kappa14_strong}`}
      >
        {selectLabel}
      </label>
      <div className={styles.select}>
        <select id="ciudad" onChange={handleOnChange}>
          {renderOptions(data)}
        </select>
      </div>
    </div>
  );
};

Select.propTypes = {
  onchange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  selectLabel: PropTypes.string,
};

export default Select;
