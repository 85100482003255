import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const productDeliveryApi = createApi({
  reducerPath: 'productDeliveryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.homecenter.com.co/s/checkout/v1/',
    prepareHeaders: (headers) => {
      headers.set('x-channel-id', 'WEB');
      headers.set('x-ecomm-name', 'homecenter-co');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProductDeliveryInfo: builder.query({
      query: ({
        priceGroup, region, municipal, sku, deliveryMethod,
      }) => ({
        url: `shipments/delivery-estimates/products?priceGroup=${priceGroup}&state=${region}&city=1&municipal=${municipal}&deliveryMethod=${deliveryMethod}&skuId=${sku}&quantity=1`,
      }),
    }),
  }),
});

export const {
  useGetProductDeliveryInfoQuery,
} = productDeliveryApi;
