import React from 'react';
import PropTypes from 'prop-types';
import style from './ProductImage.module.scss';

const ProductImage = ({ urlImage, name, ...props }) => (
  <div className={style.productImage}>
    {props.urlBadge && (
      <div className={style.productImage__badge}>
        <img className={style.productImage__imageBadge} src={props.urlBadge} alt={name} />
      </div>
    )}
    <img className={style.productImage__image} src={urlImage} alt={name} />
  </div>
);

ProductImage.propTypes = {
  urlImage: PropTypes.string,
  urlBadge: PropTypes.string,
  name: PropTypes.string,
};

export default ProductImage;
