/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './ProductDescription.module.scss';
import Collapse from '../../molecules/Collapse/Collapse';
import Typography from '../../atoms/Typography/Typography';

const ProductDescription = ({ longDescription, state, className }) => {
  if (!state) {
    return null;
  }

  // add fullscreen to iframe
  const isIframeString = longDescription?.split(' ')[0] === '<iframe';

  return (
    <div className={classNames(style.description, className)}>
      <Typography className={style.description__title} variant="zeta20">Descripción</Typography>
      <Collapse>
        <div
          className={style.description__content}
          dangerouslySetInnerHTML={
            { __html: isIframeString ? longDescription.replace('<iframe', '<iframe allowfullscreen') : longDescription }
}
        />
      </Collapse>
    </div>
  );
};

ProductDescription.propTypes = {
  state: PropTypes.bool,
  longDescription: PropTypes.string,
  className: PropTypes.string,
};

export default ProductDescription;
