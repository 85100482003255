/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '../../atoms/Typography/Typography';
import style from './RelatedProductsCardPDP.module.scss';
import { CloudeFlare } from '../../../../constants/index';

const RelatedProductsCardPDP = ({ getPrices, changePrices, ...props }) => {
  const cardInput = useRef(null);
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    getPrices({ value: cardInput.current.value, id: cardInput.current.id });
  }, []);

  const handleCheckboxChange = (event) => {
    const dad = event.target;
    const checkBox = dad.getElementsByTagName('input');
    checkBox[0].checked = !isChecked;
    setIsChecked(checkBox[0].checked);
    changePrices(
      { value: checkBox[0].value, id: checkBox[0].id },
      checkBox[0].checked,
    );
  };
  return (
    <button
      type="button"
      className={style.productsCardPDP__container}
      onClick={handleCheckboxChange}
      disabled={props.disabled}
    >
      <label
        htmlFor={`sscheck-${props.keyIndex}`}
        className={style.productsCardPDP__checkboxLabel}
        aria-label="input"
      >
        <input
          ref={cardInput}
          id={`sscheck-${props.keyIndex}`}
          type="checkbox"
          checked={isChecked}
          value={props?.regularPrice?.price}
        />
        <span />
      </label>
      <figure className={style.productsCardPDP__image}>
        <img
          src={`${CloudeFlare}/${props.productId}/thumbnail`}
          alt={props.name}
        />
      </figure>
      <Typography className={style.productsCardPDP__title}>
        {props.name}
      </Typography>
      <div className={style.productsCardPDP__info}>
        {props.badgeImage && <img src={props.badgeImage} alt="" />}
        {props.regularPrice && (
          <Typography className={style.productsCardPDP__price}>
            {`${props.regularPrice.symbol} ${props.regularPrice.price} ${props.regularPrice.unit}`}
          </Typography>
        )}
        {props.auxPrice && (
          <Typography
            variant="kappa14"
            className={style.productsCardPDP__unitPrice}
          >
            {`${props.auxPrice.symbol} ${props.auxPrice.price} ${
              props.auxPrice.unit ? props.auxPrice.unit : ''
            }`}
          </Typography>
        )}
        {props.unitPrice && (
          <Typography
            className={style.productsCardPDP__unitPrice}
            variant="kappa14"
          >
            {`Normal: ${props.unitPrice.symbol} ${props.unitPrice.price} ${props.unitPrice.unit}`}
          </Typography>
        )}
      </div>
    </button>
  );
};

RelatedProductsCardPDP.propTypes = {
  productId: PropTypes.string,
  disabled: PropTypes.bool,
  getPrices: PropTypes.func,
  changePrices: PropTypes.func,
  keyIndex: PropTypes.number,
  name: PropTypes.string,
  badgeImage: PropTypes.string,
  price: PropTypes.string,
  regularPrice: PropTypes.arrayOf({}),
  unitPrice: PropTypes.arrayOf({}),
  auxPrice: PropTypes.arrayOf({}),
};

export default RelatedProductsCardPDP;
