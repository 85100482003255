import React from 'react';
import PropTypes from 'prop-types';
import RatingStars from '../RatingStars/RatingStars';
import Typography from '../../atoms/Typography/Typography';
import styles from './CustomerReviewsCardPDP.module.scss';
import { ReactComponent as IcoRecommend } from '../../../../assets/svg/Recommend.svg';
import { ReactComponent as IcoNoRecommend } from '../../../../assets/svg/NoRecommend.svg';

const CustomerReviewsCardPDP = ({ ...props }) => (
  <div className={styles.container}>
    <div className={styles.contTop}>
      <RatingStars color="#006AC1" rating={props.ratingAverage} name={props.author} showReviews={false} />
      <Typography variant="omega17" className={styles.date}>
        {props.review.date}
      </Typography>
    </div>
    <div>
      <Typography variant="omega17" className={styles.title}>
        {props.review.title}
      </Typography>
      <Typography variant="omega17" className={styles.message}>
        {props.review.message}
      </Typography>
    </div>
    <Typography className={styles.recommend}>
      {props.recommend ? <IcoRecommend /> : <IcoNoRecommend />}
      {' '}
      {props.recommend ? 'Sí, recomiendo' : 'No recomiendo'}
    </Typography>
    <Typography className={styles.author}>{props.author}</Typography>
  </div>
);

CustomerReviewsCardPDP.propTypes = {
  review: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    date: PropTypes.string,
  }),
  recommend: PropTypes.bool,
  author: PropTypes.string,
  ratingAverage: PropTypes.number,
};

export default CustomerReviewsCardPDP;
