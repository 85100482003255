import React from 'react';
import PropTypes from 'prop-types';
import style from './TableBody.module.scss';

const TableBody = ({ rowsData, renderRows }) => (
  <tbody className={style.root}>
    {rowsData?.map(({ items }, index) => {
      const currentPosition = index + 1;
      const isOdd = currentPosition % 2 !== 0;
      return renderRows(items, isOdd, currentPosition);
    })}
  </tbody>
);

TableBody.propTypes = {
  rowsData: PropTypes.arrayOf(PropTypes.shape({})),
  renderRows: PropTypes.func,
};

export default TableBody;
