import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BLOB_STORAGE } from '../../../constants/index';

export const blobStorageWarrantyApi = createApi({
  reducerPath: 'blobStorageWarrantyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BLOB_STORAGE,
  }),
  endpoints: (builder) => ({
    getBlobStorageWarrantyData: builder.query({
      query: () => '/PDP_WARRANTY_BOX.json',
    }),
  }),
});

export const { useGetBlobStorageWarrantyDataQuery } = blobStorageWarrantyApi;
