/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { ReactComponent as Grid } from '../../../../assets/svg/Grid.svg';
import { ReactComponent as List } from '../../../../assets/svg/List.svg';
import styles from './ToggleGroup.module.scss';
import Omega17 from '../Omega17/Omega17';
// import PropTypes from 'prop-types';

const toogleGroup = ({
  viewOptions, onClick, selection, caption, label,
}) => {
  const handleClick = (event) => {
    onClick(event.currentTarget.value);
  };

  const trueActive = selection === caption;
  const color = { fill: trueActive ? 'black' : 'grey' };
  return (
    <button
      className={`${styles.button} ${trueActive ? 'active' : ''}`}
      type="button"
      onClick={handleClick}
      value={caption}
    >
      {caption === 'GRID' ? <Grid style={color} /> : <List style={color} />}
      {label && <Omega17 color={trueActive ? 'black' : 'grey'}>{label}</Omega17>}
    </button>
  );
};

export default toogleGroup;
