import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { css, styled } from 'styled-components';
import style from './ButtonLink.module.scss';

const IconWrapper = styled.span`
  ${(props) => (Object.keys(props.theme).length > 0 ? css`
  path {
    fill: ${props.theme.pdp.delivery.ico}
  }
  ` : '')
}
`;

const ButtonLink = ({
  onLinkClick, children, icon, className, ...props
}) => (
  <button
    style={
      props.theme
        ? {
          color: props.theme.pdp.delivery.text,
        }
        : { color: props?.color }
    }
    className={classNames(style.buttonLink, className)}
    type="button"
    onClick={onLinkClick}
  >
    {icon && <IconWrapper theme={props.theme}>{icon}</IconWrapper>}
    <Link to="#home">{children}</Link>
  </button>
);

ButtonLink.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  icon: PropTypes.node,
  onLinkClick: PropTypes.func,
  color: PropTypes.string,
};

export default ButtonLink;
