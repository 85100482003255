import React from 'react';
import PropTypes from 'prop-types';
import styles from './BrandImage.module.scss';

const BrandImage = ({ imgBrand, onclick, ...props }) => (
  <button
    type="button"
    onClick={onclick}
    {...props}
    style={{
      cursor: 'pointer',
    }}
  >
    <img className={styles.brand_image} src={imgBrand} alt={props.altbrand} />
  </button>
);

BrandImage.propTypes = {
  imgBrand: PropTypes.string,
  altbrand: PropTypes.string,
  linkto: PropTypes.string,
  onclick: PropTypes.func,
};

export default BrandImage;
