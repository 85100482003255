import React from 'react';
import PropTypes from 'prop-types';
import style from './ProductComparisonSelect.module.scss';
import Button from '../../atoms/Button/Button';
import CardComparison from '../../molecules/CardComparison/CardComparison';
import EmptyCardComparison from '../../molecules/EmptyCardComparison/EmptyCardComparison';
import { productComparisonStringsData } from './ProductComparisonSelect.mocks';
import Alert from '../../atoms/Alert/Alert';
import Delete from '../../../../assets/svg/Delete.svg';

const ProductComparisonSelect = ({
  products, showAlert, onAllClean, onCloseClick, onCompare, ...props
}) => {
  const { cleanButton, compareButton } = productComparisonStringsData;
  const filteredProducts = products.filter(({ isEmpty }) => !isEmpty);
  return (
    <div className={style.root}>
      {showAlert && (
        <Alert className={style.root__alert}>
          Solo puedes comparar hasta 4 productos
        </Alert>
      )}
      <div className={style.root__divider} style={{ backgroundColor: props.dividerColor }} />
      <div className={style.root__body}>
        <div className={style.root__content}>
          {products?.map(({ isEmpty, sku, title }, index) => (isEmpty
            ? (
              <EmptyCardComparison className={style.root__card} quantity={index + 1} />
            ) : (
              <CardComparison
                className={style.root__card}
                key={sku}
                title={title}
                sku={sku}
                onCloseClick={() => onCloseClick(sku)}
              />
            )
          ))}
        </div>
        <div className={style.root__actions}>
          <Button
            variant="outlined"
            color={props.buttonColor}
            startIcon={Delete}
            onClick={onAllClean}
          >
            {cleanButton}
          </Button>
          <Button
            variant="contained"
            color={props.buttonColor}
            onClick={onCompare}
            isDisabled={!(filteredProducts?.length > 1)}
          >
            {compareButton}
          </Button>
        </div>
      </div>
    </div>
  );
};
ProductComparisonSelect.propTypes = {
  buttonColor: PropTypes.oneOf(['black', 'secondary']),
  dividerColor: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  showAlert: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onAllClean: PropTypes.func,
  onCompare: PropTypes.func,
};

export default ProductComparisonSelect;
