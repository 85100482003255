import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Badge.module.scss';

const Badge = ({
  state, className, name, url,
}) => {
  if (!state) {
    return null;
  }
  return (
    <div className={classNames(style.badge, className)}>
      <img src={url} alt={name} />
    </div>
  );
};

Badge.propTypes = {
  state: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
};

export default Badge;
