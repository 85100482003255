import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../atoms/Typography/Typography';
import Beta42 from '../../atoms/Beta42/Beta42';
import RatingStars from '../RatingStars/RatingStars';
import { ReactComponent as ArrowRight2 } from '../../../../assets/svg/ArrowRight2.svg';
import style from './ReviewActions.module.scss';

const ReviewActions = ({
  state, rating, totalReviews, onClick,
}) => {
  if (!state) {
    return null;
  }
  return (
    <div className={style.reviews}>
      <Typography variant="zeta20">Opiniones de clientes</Typography>
      <button type="button" className={style.reviews__action} onClick={onClick}>
        <div className={style.reviews__rating}>
          <Beta42 strong>{parseFloat(rating).toFixed(1)}</Beta42>
          <RatingStars
            color="#006AC1"
            rating={parseFloat(rating)}
            name="opinions"
            showReviews
            totalReviews={totalReviews}
          />
        </div>
        <ArrowRight2 />
      </button>
    </div>
  );
};
ReviewActions.propTypes = {
  state: PropTypes.bool,
  rating: PropTypes.string,
  totalReviews: PropTypes.string,
  onClick: PropTypes.func,
};

export default ReviewActions;
