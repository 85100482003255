// STRINGS
export const productComparisonStringsData = {
  cleanButton: 'Limpiar todo',
  compareButton: 'Comparar',
};

// MOCKS PROPS DATA
export const productComparisonFirstMockData = {
  buttonColor: 'black',
  dividerColor: '#C30039',
  products: [
    {
      isEmpty: true,
    },
    {
      isEmpty: true,
    },
    {
      isEmpty: true,
    },
    {
      isEmpty: true,
    },
  ],
};

export const productComparisonSecondMockData = {
  buttonColor: 'secondary',
  dividerColor: '#5EA1DB',
  products: [
    {
      title: 'Alimento Seco Para Gato Adultos Mirringo 500g',
      sku: '338259',
      isEmpty: false,
    },
    {
      title: 'Organizador De Maleta',
      sku: '123326',
      isEmpty: false,
    },
    {
      title: 'Alimento Seco Para Gato Adulto Activos Carne Cat Chow 8kg',
      sku: '123326',
      isEmpty: false,
    },
    {
      isEmpty: true,
    },
  ],
};
