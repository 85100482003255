import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Kappa14 from '../../atoms/Kappa14/Kappa14';
import { ReactComponent as BoxComparison } from '../../../../assets/svg/BoxComparison.svg';
import style from './EmptyCardComparison.module.scss';
import { cardComparisonStringsData } from './EmptyCardComparison.mocks';

const EmptyCardComparison = ({ quantity, className }) => {
  const { textCard } = cardComparisonStringsData;
  return (
    <div className={classNames(style.root, className)}>
      <BoxComparison />
      <Kappa14 className={style.root__text} color="#8C8C8C">{textCard(quantity)}</Kappa14>
    </div>
  );
};

EmptyCardComparison.propTypes = {
  quantity: PropTypes.string,
  className: PropTypes.string,
};

export default EmptyCardComparison;
