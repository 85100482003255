/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import {
  useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import ReactSimplyCarousel from 'react-simply-carousel';
import {
  TransformWrapper,
  TransformComponent,
} from 'react-zoom-pan-pinch';
import styles from './ImageZoomModal.module.scss';
// import PropTypes from 'prop-types';
import Button from '../../atoms/Button/Button';
import ZoomOut from '../../../../assets/svg/ZoomOut.svg';
import ZoomIn from '../../../../assets/svg/ZoomIn.svg';
import Reset from '../../../../assets/svg/Reset.svg';
import Close from '../../../../assets/svg/Close.svg';
import ArrowLeft2 from '../../../../assets/svg/ArrowLeft2.svg';
import ArrowRight2 from '../../../../assets/svg/ArrowRight2.svg';

const ImageZoomModal = ({
  productImages,
  closeModal,
  isOpen,
  currentImage,
}) => {
  const transformComponentRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [activeImage, setActiveImage] = useState(currentImage);
  const [scale, setScale] = useState('');

  const isScaled = scale.includes('scale(1)');

  const timer = () => {
    setTimeout(() => {
      setScale(
        transformComponentRef.current.instance.contentComponent.style.cssText,
      );
    }, 500);
  };

  const handleClick = (ev) => {
    ev.stopPropagation();
    setActiveImage(ev.target.src);
  };

  // eslint-disable-next-line react/prop-types
  const controls = ({ zoomIn, zoomOut, resetTransform }) => (
    <div className={styles.header}>
      <Button
        isDisabled={isScaled}
        variant="outlined"
        color="primary"
        startIcon={ZoomOut}
        onClick={() => {
          timer();
          zoomOut();
        }}
      />
      <Button
        variant="outlined"
        color="primary"
        startIcon={ZoomIn}
        onClick={() => {
          zoomIn();
          timer();
        }}
      />
      <Button
        isDisabled={isScaled}
        variant="outlined"
        color="primary"
        startIcon={Reset}
        onClick={() => {
          resetTransform();
          timer();
        }}
      />
      <Button startIcon={Close} onClick={closeModal} />
    </div>
  );

  const renderImages = (arr) => (arr?.slice(1)?.map((it, index) => (
    <button key={index} type="button" onClick={handleClick} aria-label="img">
      <img
        src={`${it.media_url}`}
        alt=""
      />
    </button>
  )));

  useEffect(() => {
    timer();
    setActiveImage(currentImage);
  }, [productImages]);

  return (
    <div className={styles.container}>
      <TransformWrapper
        doubleClick={{ disabled: true }}
        ref={transformComponentRef}
      >
        {(utils) => (
          <>
            {controls({ ...utils })}
            <TransformComponent>
              <img src={activeImage} alt="" />
            </TransformComponent>
          </>
        )}
      </TransformWrapper>

      {isOpen && (
        <div className={styles.carousel}>
          {productImages.length > 5 ? (
            <ReactSimplyCarousel
              activeSlideIndex={activeSlideIndex}
              onRequestChange={setActiveSlideIndex}
              infinite={false}
              itemsToShow={4}
              itemsToScroll={1}
              backwardBtnProps={{
                children: <img src={ArrowLeft2} alt="" />,
              }}
              forwardBtnProps={{
                children: <img src={ArrowRight2} alt="" />,
              }}
              activeSlideProps={{
                className: 'carruselActive',
              }}
              updateOnItemClick
              responsiveProps={[
                {
                  itemsToShow: 5,
                  itemsToScroll: 5,
                  minWidth: 768,
                },
              ]}
              speed={400}
              easing="linear"
              itemsListProps={{
                className: 'carrusel',
              }}
              containerProps={{
                className: 'carruselCont',
              }}
            >
              {renderImages(productImages)}
            </ReactSimplyCarousel>
          ) : (
            <section className={styles.notCarousel}>
              {renderImages(productImages)}
            </section>
          )}
        </div>
      )}
      {/* <section className={styles.notCarousel}>
        {renderImages(productImages)}
      </section> */}
    </div>
  );
};

ImageZoomModal.propTypes = {
  productImages: PropTypes.arrayOf({}),
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  currentImage: PropTypes.string,
};

export default ImageZoomModal;
