/* eslint-disable react/no-array-index-key */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ProductDataSheetBoxPDP.module.scss';
import Typography from '../../atoms/Typography/Typography';
import Button from '../../atoms/Button/Button';
import ArrowUp from '../../../../assets/svg/ArrowUp.svg';
import ArrowDown from '../../../../assets/svg/ArrowDown.svg';
// import { sortByAttribute } from '../../../../utils';

const ProductDataSheetBoxPDP = ({ ...props }) => {
  const [currHeight, setCurrHeight] = useState(0);
  const [displayState, setdisplayState] = useState(false);
  const sheetHeight = useRef(null);
  const isLarge = (heightData) => heightData > 385;

  const handleClick = () => {
    setdisplayState(!displayState);
  };

  const renderRows = (dataTable) => dataTable?.map((it, index) => (
    <tr key={index}>
      <td>{it.name}</td>
      <td>{it.values[0]}</td>
    </tr>
  ));

  useEffect(() => {
    const currentHeight = sheetHeight?.current?.offsetHeight;
    setCurrHeight(currentHeight);
    setTimeout(() => {
      if (sheetHeight?.current && isLarge(currentHeight)) {
        sheetHeight.current.style.height = '395px';
        sheetHeight.current.style.overflowY = 'hidden';
      }
    }, 500);
  }, [props.data]);

  useEffect(() => {
    if (isLarge(currHeight)) {
      if (displayState) {
        sheetHeight.current.style.height = 'auto';
        sheetHeight.current.style.overflowY = 'unset';
      } else {
        sheetHeight.current.style.height = '395px';
        sheetHeight.current.style.overflowY = 'hidden';
      }
    }
  }, [displayState]);

  if (!props.data.length > 0) {
    return null;
  }

  return (
    <div className={styles.dataSheetBoxPDP__container}>
      <div className={styles.dataSheetBoxPDP__title}>
        <Typography variant="zeta20">Ficha técnica</Typography>
      </div>
      <div
        ref={sheetHeight}
        className={styles.dataSheetBoxPDP__table__container}
      >
        <table className={styles.dataSheetBoxPDP__table}>
          <tbody>
            {renderRows(props?.data)}
          </tbody>
        </table>
      </div>
      {isLarge(currHeight) && (
        <Button
          variant="outlined"
          color="secondary"
          startIcon={displayState ? ArrowUp : ArrowDown}
          onClick={handleClick}
        >
          {displayState ? 'Ver menos' : 'Ver más'}
        </Button>
      )}
    </div>
  );
};

ProductDataSheetBoxPDP.propTypes = {
  data: PropTypes.arrayOf({}),
};

export default ProductDataSheetBoxPDP;
