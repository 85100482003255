import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './ProgressBar.module.scss';

const ProgressBar = ({ value, color, className }) => (
  <div className={classNames(style.progressBar, className)}>
    <div className={style.progressBar__bar} style={{ width: `${value}%`, backgroundColor: color }} />
  </div>
);

ProgressBar.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default ProgressBar;
