import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { filterArrayByString, getSubcategories } from '../../../utils';
import { BLOB_STORAGE } from '../../../constants/index';

export const blobStorageCategories = createApi({
  reducerPath: 'blobStorageCategories',
  baseQuery: fetchBaseQuery({
    baseUrl: BLOB_STORAGE,
  }),
  endpoints: (builder) => ({
    getBlobCategoriesData: builder.query({
      query: ({ store }) => `/${store}/plp_filtros_categorias.json`,
      transformResponse: (response, meta, { filterByGroup, selectedCatID }) => {
        const filteredCategoriesDataByGroup = response.data.filter(
          ({ group }) => group === filterByGroup,
        );

        let filteredFiltersDataByCta;
        if (response.withFilter) {
          filteredFiltersDataByCta = response.dataFilter.data.filter(
            ({ cta }) => cta === filterByGroup,
          );
        } else {
          filteredFiltersDataByCta = [];
        }

        const subcategoriesData = getSubcategories(
          filterByGroup ? filteredCategoriesDataByGroup : response.data,
          selectedCatID,
        );

        const item = filterArrayByString(
          subcategoriesData?.items,
          'url',
          selectedCatID,
        );

        const newdata = {
          ...response,
          data: subcategoriesData.items,
          isSub: subcategoriesData.hasSub,
          parentData: subcategoriesData.parentData,
          indexCurrentCategory: { selection: item[0]?.cta },
          indexCurrentFilter: {
            selection: item[0]?.group,
            color:
              response.withFilter
              && filteredFiltersDataByCta[0].backgroundColor,
          },
        };

        return newdata;
      },
    }),
  }),
});

export const {
  useGetBlobCategoriesDataQuery,
  useLazyGetBlobCategoriesDataQuery,
} = blobStorageCategories;
