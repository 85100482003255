import React from 'react';
import PropTypes from 'prop-types';
import style from './Table.module.scss';

const Table = ({ children }) => (
  <table className={style.root}>
    {children}
  </table>
);
Table.propTypes = {
  children: PropTypes.node,
};

export default Table;
