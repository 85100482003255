import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import style from './Button.module.scss';

const Button = ({
  isDisabled, children, variant, ...props
}) => (
  <button
    {...props}
    onClick={props.onClick}
    disabled={isDisabled}
    type="button"
    className={classNames(style.button, props.className, {
      [style.button_rounded]: props.rounded,
      [style.button_colorContainedPrimary]:
        variant === 'contained' && props.color === 'primary',
      [style.button_colorContainedSecondary]:
        variant === 'contained' && props.color === 'secondary',
      [style.button_colorContainedBlack]:
        variant === 'contained' && props.color === 'black',
      [style.button_colorOutlinedPrimary]:
        variant === 'outlined' && props.color === 'primary',
      [style.button_colorOutlinedSecondary]:
        variant === 'outlined' && props.color === 'secondary',
      [style.button_colorOutlinedBlack]:
        variant === 'outlined' && props.color === 'black',
      [style.button_colorOutlinedSuccess]:
        variant === 'outlined' && props.color === 'success',
      [style.button_colorTextSecondary]:
        variant === 'text' && props.color === 'secondary',
      [style.button_colorLoadingSecondary]:
        variant === 'loading' && props.color === 'secondary',
      [style.button_close]:
        variant === 'close' && props.color === 'secondary',
    })}
  >
    {props.startIcon && <SVG src={props.startIcon} />}
    <span>{children}</span>
    {props.endIcon && <SVG src={props.endIcon} />}
  </button>
);

Button.propTypes = {
  /**
   * The content of the Button
   */
  children: PropTypes.node,
  /**
   * The varianto of the Button
   */
  variant: PropTypes.oneOf(['contained', 'outlined']),
  rounded: PropTypes.bool,
  isDisabled: PropTypes.bool,
  /**
   * Node placed before the children
   */
  startIcon: PropTypes.node,
  /**
   * Node placed after the children
   */
  endIcon: PropTypes.node,
  /**
   * The color of the button component
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'black', 'success']),
  /**
   * The call to action of the button component
   */
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Button.defaultProps = {
  rounded: false,
};

export default Button;
