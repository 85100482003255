import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TimeDisplay from '../../atoms/TimeDisplay/TimeDisplay';
import style from './Timer.module.scss';
import { convertMillisecondsToTime } from '../../../../utils';

const initialState = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

const Timer = ({ timerTime, ...props }) => {
  const [display, setDisplay] = useState(initialState);

  const currentTimeInMilliseconds = Date.now();

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Difference
      const timeDiff = timerTime - currentTimeInMilliseconds;
      if (timeDiff > 0) {
        // convert to Day, hour, min & sec
        setDisplay(convertMillisecondsToTime(timeDiff));
      } else {
        setDisplay(initialState);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [display]);

  return (
    <div className={style.timer} style={props.style}>
      <span className={style.timer__label}>Termina en:</span>
      <div className={style.timer__displayContent}>
        <TimeDisplay value={display.days} label="DÍAS" />
        <TimeDisplay value={display.hours} label="HORA" />
        <TimeDisplay value={display.minutes} label="MIN" />
        <TimeDisplay value={display.seconds} label="SEC" />
      </div>
    </div>
  );
};

Timer.propTypes = {
  /**
   * Timer time in milliseconds
   */
  timerTime: PropTypes.number,
  style: PropTypes.shape({}),
};

export default Timer;
