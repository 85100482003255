import React from 'react';
import PropTypes from 'prop-types';
import OpinionStatisticsHeader from '../../molecules/OpinionStatisticsHeader/OpinionStatisticsHeader';
import OpinionStatisticsList from '../../molecules/OpinionStatisticsList/OpinionStatisticsList';
import Button from '../../atoms/Button/Button';
import OpinionStatisticsItem from '../../molecules/OpinionStatisticsItem/OpinionStatisticsItem';
import style from './OpinionStatistics.module.scss';
import { opinionStatisticsStringsData } from './OpinionStatistics.mocks';

const OpinionStatistics = ({
  ratingAverage, totalReviews, statistics, handleAddReview,
}) => {
  /* const {
    rating: ratingAverage, totalReviews: totalReviewsHeader, statistics,
  } = useSelector((state) => state.productReview); */
  const { title, buttonLabel } = opinionStatisticsStringsData;
  return (
    <div className={style.opinion}>
      <OpinionStatisticsHeader
        title={title}
        ratingAverage={ratingAverage}
        totalReviews={totalReviews}
      />
      <OpinionStatisticsList
        items={statistics}
        renderItem={({ rating, numberOfPersons, percent }) => (
          <OpinionStatisticsItem
            key={rating}
            rating={rating.toString()}
            numberOfPersons={numberOfPersons.toString()}
            percent={percent}
          />
        )}
      />
      <div className={style.opinion__button}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleAddReview}
        >
          {buttonLabel}

        </Button>
      </div>
    </div>
  );
};

OpinionStatistics.propTypes = {
  ratingAverage: PropTypes.string,
  totalReviews: PropTypes.string,
  handleAddReview: PropTypes.func,
  statistics: PropTypes.arrayOf(PropTypes.shape({})),
};

export default OpinionStatistics;
