/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Star from '../../atoms/Star/Star';
import style from './RatingStars.module.scss';

const paintStar = (rating, color, name) => {
  const starNumber = [];
  for (let i = 1; i <= 5; i++) {
    if (rating >= 1) {
      starNumber.push(<Star key={i} width={100} color={color} star={`${name}${i}`} />);
    } else if (rating > 0 && rating < 1) {
      starNumber.push(<Star key={i} width={rating * 100} color={color} star={`${name}${i}`} />);
    } else {
      starNumber.push(<Star key={i} width={0} stroke={color} star={`${name}${i}`} />);
    }
    rating--;
  }
  return starNumber;
};

const RatingStars = ({ color, ...props }) => (
  <div className={classNames(style.ratingStars, props.className)}>
    {paintStar(props.rating, color, props.name)}
    {props.showReviews && (
      <div className={style.ratingStars__totalReviews}>
        {`(${props.totalReviews || 0})`}
      </div>
    )}
  </div>
);

RatingStars.propTypes = {
  color: PropTypes.string,
  rating: PropTypes.number,
  name: PropTypes.string,
  showReviews: PropTypes.bool,
  totalReviews: PropTypes.number,
  className: PropTypes.string,
};

export default RatingStars;
