/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import DeliveryOptionsInformation from '../DeliveryOptionsInformation/DeliveryOptionsInformation';
import DeliveryItem from '../../molecules/DeliveryItem/DeliveryItem';
import {
  availabilityOtherStoresStringData,
  homeDeliveryExpressInformationStringData,
  homeDeliveryInformationStringData,
  pickupInformationInStoreStringData,
} from './ProductDeliveryModal.mocks';
import { ReactComponent as ThinXmark } from '../../../../assets/svg/ThinXmark.svg';
import StorePickupSteps from '../StorePickupSteps/StorePickupSteps';
import StorePickupStep from '../../molecules/StorePickupStep/StorePickupStep';
import AvailabilityStoreList from '../AvailabilityStoreList/AvailabilityStoreList';
import AvailabilityStoreItem from '../../molecules/AvailabilityStoreItem/AvailabilityStoreItem';
import style from './ProductDeliveryModal.module.scss';
import IconButton from '../../atoms/IconButton/IconButton';

const ProductDeliveryModal = ({
  type, isOpen, onClose, stockList, deliveryData, deliveryStock,
}) => {
  const [selectedAddress, setSelectedAddress] = useState(stockList ? stockList[0]?.list[0]?.googleAddress : '');

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
  };

  return (
    <Modal
      classNameModal={style.root}
      className={style.root__modal}
      isOpen={isOpen}
      closeModal={onClose}
    >
      <IconButton
        className={style.root__xmark}
        icon={<ThinXmark />}
        onClick={onClose}
        color="#000"
        size="small"
      />
      {type === 'HOME_DELIVERY_INFORMATION' && (
        <DeliveryOptionsInformation
          state
          delivery
          data={deliveryData?.data?.homeDelivery?.deliverySlots}
          {...homeDeliveryInformationStringData}
        >
          {({ icon, title, content }) => (
            <DeliveryItem
              icon={icon}
              title={title}
              content={content}
            />
          )}
        </DeliveryOptionsInformation>
      )}
      {type === 'HOME_DELIVERY_ESPRESS_INFORMATION' && (
        <DeliveryOptionsInformation
          state
          {...homeDeliveryExpressInformationStringData}
          pills
        >
          {({ steps }) => (
            <StorePickupSteps
              steps={steps}
              renderStep={(icon, text) => (
                <StorePickupStep
                  key={text}
                  icon={icon}
                >
                  {text}
                </StorePickupStep>
              )}
            />
          )}
        </DeliveryOptionsInformation>
      )}
      { type === 'PICKUP_INFORMATION_IN_STORE' && (
        <DeliveryOptionsInformation
          state
          maps
          stock
          stockData={deliveryStock?.data?.storePickUp?.listOfStores}
          {...pickupInformationInStoreStringData}
        >
          {() => {}}
        </DeliveryOptionsInformation>
      )}
      {type === 'AVAILABILITY_OTHER_STORES' && (
        <DeliveryOptionsInformation
          state
          pills
          maps
          googleAddress={selectedAddress}
          {...availabilityOtherStoresStringData}
          information={stockList}
        >
          {({ list }) => (
            <AvailabilityStoreList
              list={list}
              renderItems={({
                place, isCurrentPlace, quantity, storeAddress, stockStatus, googleAddress,
              }) => (
                <AvailabilityStoreItem
                  key={place}
                  place={place}
                  isCurrentPlace={isCurrentPlace}
                  quantity={quantity}
                  storeAddress={storeAddress}
                  stockStatus={stockStatus}
                  googleAddress={googleAddress}
                  onClickItem={handleSelectAddress}
                />
              )}
            />
          )}
        </DeliveryOptionsInformation>
      )}

    </Modal>
  );
};
ProductDeliveryModal.propTypes = {
  type: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  stockList: PropTypes.arrayOf(PropTypes.shape({})),
  deliveryData: PropTypes.arrayOf(PropTypes.shape({
    homeDelivery: PropTypes.shape({
      deliverySlots: PropTypes.shape({}),
    }),
  })),
  deliveryStock: PropTypes.arrayOf(PropTypes.shape({
    storePickUp: PropTypes.shape({
      listOfStores: PropTypes.shape({}),
    }),
  })),
};

export default ProductDeliveryModal;
