/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import style from './Pagination.module.scss';
import { ReactComponent as ArrowLeft2 } from '../../../../assets/svg/ArrowLeft2.svg';
import { ReactComponent as ArrowLeft3 } from '../../../../assets/svg/ArrowLeft3.svg';
import { ReactComponent as ArrowRight2 } from '../../../../assets/svg/ArrowRight2.svg';
import { ReactComponent as ArrowRight3 } from '../../../../assets/svg/ArrowRight3.svg';
import { createPagesFromTotal } from '../../../../utils';

const Pagination = ({
  count: pageList,
  currentPage: currPageNo,
  perPage: totalPages,
  onChangePaginator,
}) => {
  const [currPageNoD, setCurrPageNoD] = useState(currPageNo);
  const paginationRef = useRef(null);
  const selectedPageRef = useRef(null);

  const global = useSelector((state) => state.categoriesData);
  const data = Object.values(global.queries);

  const pages = createPagesFromTotal(pageList);
  const prevLabel = 'prev';
  const nextLabel = 'next';
  const pageItemWidth = 35;
  const firstPages = 3;
  const lastPages = 2;
  const maxPagesPerSlide = 5;

  const getIncrement = (pageIndex) => {
    let increment = 0;
    if (pageIndex > firstPages && totalPages > maxPagesPerSlide) {
      if (pageIndex > totalPages - lastPages) {
        increment = (pageIndex - (pageIndex - (totalPages - lastPages) + firstPages))
          * pageItemWidth;
      } else {
        increment = (pageIndex - firstPages) * pageItemWidth;
      }
    }
    return increment;
  };

  const getLeftPos = (pageIndex) => {
    let leftPos = 70;
    if (pageIndex <= firstPages || totalPages < maxPagesPerSlide) {
      leftPos = (pageIndex - 1) * pageItemWidth;
    } else if (pageIndex > totalPages - lastPages) {
      leftPos += (pageIndex - (totalPages - lastPages)) * pageItemWidth;
    }
    return leftPos;
  };

  const scrollToPage = (pageIndex, paginationRef, selectedPageRef) => {
    paginationRef.current.style.right = `${getIncrement(pageIndex)}px`;
    selectedPageRef.current.style.left = `${getLeftPos(pageIndex)}px`;
    selectedPageRef.current.style.visibility = 'visible';
    setTimeout(() => {
      if (selectedPageRef && selectedPageRef.current) {
        selectedPageRef.current.style.transition = 'ease-in left 0.1s';
      }
    }, 100);
  };

  const onPageChange = (ev) => {
    if (ev.target) {
      setTimeout(() => {
        onChangePaginator(ev.target.dataset.label);
        setCurrPageNoD(ev.target.dataset.label);
      }, 1000);
    } else {
      setTimeout(() => {
        onChangePaginator(Number(ev));
      }, 1000);
    }
  };

  const navigateToPage = (
    pageIndex,
    paginationRef,
    selectedPageRef,
    client,
  ) => {
    if (paginationRef.current) {
      let newPage = 0;
      if (pageIndex === 'next') {
        newPage = currPageNoD + (currPageNoD < totalPages ? 1 : 0);
      } else if (pageIndex === 'prev') {
        newPage = currPageNoD - (currPageNoD > 1 ? 1 : 0);
      } else {
        newPage = 1 * pageIndex;
      }
      setCurrPageNoD(newPage);
      if (client) {
        onPageChange(newPage);
      }
      scrollToPage(newPage, paginationRef, selectedPageRef);
    }
  };

  const RenderPageIndicies = () => (
    <>
      {pages.map((el) => (
        <li
          key={el}
          className={`${style.page_item} ${style.page_index} ${
            el === currPageNoD ? 'selected' : ''
          }`}
        >
          <button
            data-label={el}
            type="button"
            onClick={(ev) => {
              navigateToPage(
                el,
                paginationRef,
                selectedPageRef,
                onPageChange(ev),
              );
            }}
          >
            {el}
          </button>
        </li>
      ))}
    </>
  );

  useEffect(() => {
    setCurrPageNoD(data[0]?.data?.data?.pagination.currentPage);
  }, [data]);

  useEffect(() => {
    if (currPageNoD > 0) {
      navigateToPage(
        currPageNoD,
        paginationRef,
        selectedPageRef,
        false,
        onPageChange,
      );
    }
  }, [currPageNoD, pageList]);

  if (totalPages > 1) {
    return (
      <div
        className={style.pagination_container}
        data-testid="pagination_container"
      >
        {currPageNoD !== 1 && (
          <div
            className={`${style.page_item} ${style.navigation_arrow}`}
            title={prevLabel}
          >
            <button
              type="button"
              onClick={() => {
                navigateToPage(
                  'prev',
                  paginationRef,
                  selectedPageRef,
                  true,
                  onPageChange,
                );
              }}
              aria-label="ArrowLeft2"
              className={style.pagination_previous_page}
            >
              <ArrowLeft2 className={style.Arrow2} />
              <ArrowLeft3 className={style.Arrow3} />
            </button>
          </div>
        )}
        <div className={style.scroll_area}>
          <ul className={style.page_indicies} ref={paginationRef}>
            {RenderPageIndicies()}
          </ul>
          <div className={style.page_selector} ref={selectedPageRef}>
            {currPageNoD}
          </div>
        </div>

        {currPageNoD !== pages.length && (
          <div
            className={`${style.page_item} ${style.navigation_arrow}`}
            title={nextLabel}
          >
            <button
              type="button"
              onClick={() => {
                navigateToPage(
                  'next',
                  paginationRef,
                  selectedPageRef,
                  true,
                  onPageChange,
                );
              }}
              aria-label="ArrowRight2"
              className={style.pagination_next_page}
            >
              <ArrowRight2 className={style.Arrow2} />
              <ArrowRight3 className={style.Arrow3} />
            </button>
          </div>
        )}
      </div>
    );
  }

  return null;
};

Pagination.propTypes = {
  count: PropTypes.number,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  onChangePaginator: PropTypes.func,
};

export default Pagination;
