import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './ModalPlp.module.scss';

const ModalPlp = ({ state, children, className }) => {
  if (!state) return null;
  return (
    <div className={classNames(style.root, className)}>
      {children}
    </div>
  );
};

ModalPlp.propTypes = {
  state: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ModalPlp;
