import React from 'react';
import PropTypes from 'prop-types';
import styles from './TabBody.module.scss';
import ButtonStyled from '../../atoms/ButtonStyled/Button';
import EmbedVideo from '../../molecules/EmbedVideo/EmbedVideo';

const TabBody = ({
  content,
  image,
  button,
  label,
  selection,
  type,
  isDesktop,
  video,
  ...props
}) => {
  let trueActive;
  if (isDesktop) {
    trueActive = selection === label;
  } else {
    trueActive = selection?.selection === label && selection?.isOpen;
  }
  return (
    <div
      className={`${styles.tab__body} ${!trueActive ? `${styles.hide}` : ''} ${
        type !== 'tab' ? `${styles.tab__body_acc}` : ''
      }`}
    >
      {
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: content }} />
      }
      <figure>
        {image.urlMobile && !video && (
          <img
            className={styles.tab__body__img_desktop}
            src={image.urlDesktop}
            alt={image.alt}
          />
        )}
        {image.urlDesktop && !video && (
          <img
            className={styles.tab__body__img_mobile}
            src={image.urlMobile}
            alt={image.alt}
          />
        )}
        {video && (
          <EmbedVideo
            videoHeigth={{
              desktop: 300,
              mobile: 250,
            }}
            videoUrl={video}
          />
        )}
        {button && (
          <ButtonStyled
            variant="contained"
            color="primary"
            startIcon={button.ico}
            onClick={() => props.onClick(button?.Url)}
            theme={props.theme}
          >
            {button?.label}
          </ButtonStyled>
        )}
      </figure>
    </div>
  );
};

TabBody.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  onClick: PropTypes.func,
  content: PropTypes.string,
  image: PropTypes.shape(),
  video: PropTypes.string,
  button: PropTypes.shape({
    ico: PropTypes.string,
    label: PropTypes.string,
    Url: PropTypes.string,
  }),
  type: PropTypes.oneOf(['tab', 'accordeon']),
  label: PropTypes.string,
  selection: PropTypes.string,
  isDesktop: PropTypes.bool,
};

export default TabBody;
