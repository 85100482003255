import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import Button from '../../atoms/Button/Button';
import styles from './AlertCartModalBox.module.scss';
import { ReactComponent as Close } from '../../../../assets/svg/Close.svg';
import Loader from '../../atoms/Loader/Loader';

const AlertCartModalBox = ({
  labels,
  onClick,
  children,
  type,
  icon,
  isLoading,
  onClickPrimary,
  onClickSecondary,
}) => (
  <div className={styles.container} data-type={type}>
    {isLoading && (
      <div className={styles.loader}>
        <Loader />
      </div>
    )}
    <Button
      onClick={onClick}
      className={styles.close}
      variant="close"
      color="secondary"
    >
      <Close />
    </Button>
    <div
      className={styles.alert}
      style={{
        backgroundColor: labels.backgroundColor,
        color: labels.fontColor,
      }}
    >
      <SVG src={icon} />
      {' '}
      {labels.state}
    </div>
    {children && <div className={styles.children}>{children}</div>}
    <div className={styles.buttons__container}>
      <Button onClick={onClickPrimary} variant="contained" color="primary">
        {labels.primary}
      </Button>
      {labels.secondary && (
      <Button
        onClick={onClickSecondary}
        className={styles.cancelar}
        variant="outlined"
        color="secondary"
      >
        {labels.secondary}
      </Button>
      )}
    </div>
  </div>
);

AlertCartModalBox.propTypes = {
  labels: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
    state: PropTypes.string,
    backgroundColor: PropTypes.string,
    fontColor: PropTypes.string,
  }),
  icon: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onClickPrimary: PropTypes.func,
  onClickSecondary: PropTypes.func,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default AlertCartModalBox;
