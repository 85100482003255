/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../Modal/Modal';
import {
  addToCartExtraAdding,
  addToCartSuccess,
  addToCartSuccessPDPpromo4x3,
} from '../../../../mock/alertModalsPDP';
import AlertCartModalBox from '../../molecules/AlertCartModalBox/AlertCartModalBox';
import AddToCartSuccess from '../../molecules/AlertCartModalBox/AddToCartSuccess/AddToCartSuccess';
import style from './AlertCartModalPlp.module.scss';
import ContractedService from '../../molecules/ContractedService/ContractedService';
import {
  mapBodyProductServicesStructure,
  mapBodyProductSuggestedStructure,
  mapBodyServicesStructure,
  mapBodyToAddProduct,
  useAddToCartMutation,
  useCreateWarrantyMutation,
  useSuggestedToCartMutation,
} from '../../../../redux/slices/Cart/checkoutApi';
import {
  setQuantity,
  setService,
  setSuggestedProduct,
} from '../../../../redux/slices/Cart/cartSlice';
import {
  setAddExtraToCart,
  setAddSuggestedProductToCart,
  setAddWarrantyToCart,
  setLabelsModalPlp,
  setOpenModalPlp,
  setRedirectToCart,
} from '../../../../redux/slices/PDP/alertModalPlpSlice';
import { CART_URL } from '../../../../constants';
import SuggestedProductAlert from '../../molecules/SuggestedProductAlert/SuggestedProductAlert';
import Typography from '../../atoms/Typography/Typography';
import SuggestedProductList from '../../molecules/SuggestedProductList/SuggestedProductList';
import SuggestedProductCard from '../../molecules/SuggestedProductCard/SuggestedProductCard';
import { useLazyGetCrossSellProductIdsQuery } from '../../../../redux/slices/PDP/productDetailsApi';
import MessagePromotion from '../../molecules/MessagePromotion/MessagePromotion';
import { getClostestMultipleofFour, logicalPromoPrice } from '../../../../utils';

const AlertCartModalPlp = ({
  productId, isOpen, onClose,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { quantity, selectedService, suggestedProduct } = useSelector(
    (state) => state.cartSlice,
  );
  const {
    warranty,
    services,
    labels,
    error,
    icon,
    redirect,
    addExtraToCart,
    addAnyServiceToCart,
    addSuggestedProductToCart,
    crossSellProductIds,
    response,
    isPromotion4x3,
    isPDP,
  } = useSelector((state) => state.alertModalPlp);

  const {
    defaultParentCategoryId,
  } = useSelector((state) => state.productDetailsReducer);

  const [createWarranty, { isLoading: isWarrantyLoading, isSuccess }] = useCreateWarrantyMutation();
  const [suggestedToCart, { isLoading: isSuggestedLoading }] = useSuggestedToCartMutation();

  const {
    cartId,
    departamento,
    ciudad: { name: cityName, id: cityId },
  } = useSelector((state) => state.transversalSlice);

  const [
    getCrossSellProductIds,
    {
      data: crossSellProductIdsData,
      isLoading: isLoadingCrossSell,
      isSuccess: isSuccessCrossSell,
    },
  ] = useLazyGetCrossSellProductIdsQuery();

  const [
    addToCart,
    { isLoading: isAddToCartLoading, isSuccess: isAddToCartSuccess },
  ] = useAddToCartMutation();

  const handleContractedService = (item) => {
    const { id } = item;
    dispatch(setService(id));
    if (!addExtraToCart) {
      dispatch(
        setLabelsModalPlp({
          alertLabels:
            id !== '' ? addToCartExtraAdding.labels : addToCartSuccess.labels,
          alertIcon: addToCartExtraAdding.icon,
        }),
      );
    }
    dispatch(setAddWarrantyToCart({ addAnyServiceToCart: id !== '' }));
  };

  const handleSuggestedProduct = (item) => {
    const { productId: id, index } = item;
    dispatch(setSuggestedProduct(id));
    if (!addExtraToCart) {
      dispatch(
        setLabelsModalPlp({
          alertLabels:
            index !== undefined
              ? addToCartExtraAdding.labels
              : addToCartSuccess.labels,
          alertIcon: addToCartExtraAdding.icon,
        }),
      );
    }
    dispatch(
      setAddSuggestedProductToCart({
        addSuggestedProductToCart: index !== undefined,
      }),
    );
  };

  const handleCounterClick = (ev) => {
    dispatch(setQuantity(ev));
    if (!addAnyServiceToCart && !addSuggestedProductToCart) {
      dispatch(
        setLabelsModalPlp({
          alertLabels:
            ev > 0 ? addToCartExtraAdding.labels : addToCartSuccess.labels,
          alertIcon: addToCartExtraAdding.icon,
        }),
      );
    }
    dispatch(setRedirectToCart({ redirect: true }));
    dispatch(setAddExtraToCart({ addExtraToCart: ev > 0 }));
  };

  const handleAddCart = () => {
    if (
      redirect
      && !isPromotion4x3
      && !addExtraToCart
      && !addAnyServiceToCart
      && !addSuggestedProductToCart
    ) {
      dispatch(
        setOpenModalPlp({
          productId: '',
          isOpen: false,
          isError: false,
        }),
      );
      window.location.href = CART_URL;
    } else if (redirect && isPromotion4x3) {
      addToCart({
        cartId,
        body: mapBodyToAddProduct(
          productId,
          isPDP ? getClostestMultipleofFour(quantity) - quantity : 3,
        ),
        isPDP,
        redirect,
      });
    } else if (
      redirect
      && addExtraToCart
      && !addAnyServiceToCart
      && !addSuggestedProductToCart
    ) {
      addToCart({
        cartId,
        body: mapBodyToAddProduct(productId, quantity),
        redirect,
      });
    } else if (redirect && addSuggestedProductToCart && !addExtraToCart) {
      addToCart({
        cartId,
        body: mapBodyToAddProduct(suggestedProduct, 1),
        isPDP,
        redirect,
      });
    } else if (redirect && addSuggestedProductToCart && addExtraToCart) {
      suggestedToCart({
        cartId,
        body: mapBodyProductSuggestedStructure(
          productId,
          suggestedProduct,
          quantity,
        ),
        redirect,
      });
    } else if (
      redirect
      && addAnyServiceToCart
      && !addExtraToCart
      && !addSuggestedProductToCart
    ) {
      createWarranty({
        cartId,
        // body: mapBodyServicesStructure(634574, selectedService),
        body: mapBodyServicesStructure(productId, selectedService),
      });
    } else if (redirect && addAnyServiceToCart && addExtraToCart) {
      createWarranty({
        cartId,
        // body: mapBodyProductServicesStructure(634574, selectedService),
        body: mapBodyServicesStructure(productId, selectedService),
      });
    } else if (error && !redirect) {
      addToCart({
        cartId,
        body: mapBodyToAddProduct(productId, quantity),
        redirect,
      });
    } else if (error && isPromotion4x3) {
      addToCart({
        cartId,
        body: mapBodyToAddProduct(
          productId,
          isPDP ? getClostestMultipleofFour(quantity) - quantity : 3,
        ),
        isPDP,
        redirect,
      });
    }
  };

  const groupIds = (arrayStrings) => arrayStrings.join('-');

  const {
    success: { count, selectorlabel, image },
  } = addToCartSuccess;

  const handleSecondaryButtonCLick = () => {
    if (error) {
      handleAddCart();
    } else if (isPDP && !isPromotion4x3) {
      navigate(`/categories/${defaultParentCategoryId}`);
      onClose();
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (crossSellProductIds.length > 0) {
      getCrossSellProductIds({ productIds: groupIds(crossSellProductIds), departamento, cityId });
    }
    if (!error && isPromotion4x3) {
      const { labels: lbls } = addToCartSuccessPDPpromo4x3(
        quantity,
        getClostestMultipleofFour(quantity),
      );
      dispatch(
        setLabelsModalPlp({
          alertLabels: lbls,
          alertIcon: addToCartExtraAdding.icon,
        }),
      );
    }
  }, [crossSellProductIds]);

  return (
    <Modal
      classNameModal={style.root}
      className={style.root__modal}
      isOpen={isOpen}
      closeModal={onClose}
    >
      <AlertCartModalBox
        labels={labels}
        icon={icon}
        onClick={onClose}
        onClickSecondary={handleSecondaryButtonCLick}
        // onClickSecondary={error ? handleAddCart : onClose}
        onClickPrimary={error ? onClose : handleAddCart}
        isLoading={
          isAddToCartLoading
          || isLoadingCrossSell
          || isWarrantyLoading
          || isSuggestedLoading
        }
      >
        {!error && !isPDP && response && (
          <AddToCartSuccess
            count={count}
            product={response}
            image={image}
            selectorlabel={selectorlabel}
            state={isAddToCartSuccess}
            onClick={handleCounterClick}
            response={response}
            services={services}
            warranty={warranty}
            isPromotion4x3={isPromotion4x3}
          />
        )}

        {!error && response && isPromotion4x3 && (
          <MessagePromotion
            text={`ahorras $${logicalPromoPrice(
              quantity,
              response.variants[0].price[0].price,
            ).toLocaleString('es-CO')}`}
            extra={getClostestMultipleofFour(quantity)}
          />
        )}

        {!error && !isPromotion4x3 && warranty.length > 0 && (
          <ContractedService
            title="¿Quieres agregar una garantía?"
            items={warranty}
            isLoading={isWarrantyLoading}
            isSuccess={isSuccess}
            onHandleChange={handleContractedService}
          />
        )}
        {!error
          && !isPromotion4x3
          && warranty.length === 0
          && services.length > 0 && (
            <ContractedService
              title="¿Quieres agregar un servicio de instalación?"
              items={services}
              isLoading={isWarrantyLoading}
              isSuccess={isSuccess}
              onHandleChange={handleContractedService}
            />
        )}
        {!error
          && !isPromotion4x3
          && warranty.length === 0
          && services.length === 0
          && crossSellProductIds.length > 0
          && isSuccessCrossSell && (
            <div className={style.root__content}>
              <Typography className={style.root__text} variant="kappa14">
                También te puede interesar
              </Typography>
              <SuggestedProductList
                className={style.root__listCard}
                items={crossSellProductIdsData?.productDetailsJson}
                onHandleChange={handleSuggestedProduct}
                renderItem={(
                  item,
                  isHighlighted,
                  index,
                  onChangeSelectedIndex,
                ) => (
                  <SuggestedProductCard
                    {...item}
                    className={style.root__card}
                    key={index}
                    onClick={() => onChangeSelectedIndex(index, item)}
                    isHighlighted={item?.isHighlighted || isHighlighted}
                    isLoading={isHighlighted}
                    startIcon={isHighlighted}
                  />
                )}
              />
            </div>
        )}
      </AlertCartModalBox>
    </Modal>
  );
};

AlertCartModalPlp.propTypes = {
  productId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  response: PropTypes.shape({
    variants: PropTypes.arrayOf({}),
  }),
};
export default AlertCartModalPlp;
