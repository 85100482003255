import React from 'react';
import PropTypes from 'prop-types';
import style from './StorePickupSteps.module.scss';

const StorePickupSteps = ({ steps, renderStep }) => (
  <div className={style.steps}>
    {steps?.map(({ icon, text }) => renderStep(icon, text))}
  </div>
);
StorePickupSteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  renderStep: PropTypes.func,
};

export default StorePickupSteps;
