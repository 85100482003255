import React from 'react';
import PropTypes from 'prop-types';
import CheckboxFilter from '../../atoms/CheckboxFilter/CheckboxFilter';
import RatingStars from '../RatingStars/RatingStars';
import style from './MultiSelectWithRatings.module.scss';

const MultiSelectWithRatings = ({ state, list, onInputChange }) => {
  if (!state) {
    return null;
  }
  return (
    <div className={style.multiSelectRatings}>
      <ul className={style.multiSelectRatings__list}>
        {list?.map(({
          label, count, url, selected,
        }) => (
          <li className={style.multiSelectRatings__item} key={url}>
            <CheckboxFilter
              name={label}
              label={(
                <RatingStars
                  color="#006AC1"
                  rating={parseInt(label, 10)}
                  name={label}
                  showReviews={false}
                />
            )}
              indicator={count}
              checked={selected}
              onHandleChange={(e) => onInputChange({ url, checked: e.target.checked })}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

MultiSelectWithRatings.propTypes = {
  state: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onInputChange: PropTypes.func,
};

export default MultiSelectWithRatings;
