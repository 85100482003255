/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router';
import styles from './FloorCalculator.module.scss';
import Typography from '../../atoms/Typography/Typography';
import { hasHttp } from '../../../../utils';
import ToolTip from './ToolTip';
// import ButtonWithTooltip from '../ButtonWithTooltip/ButtonWithTooltip';

const link = 'https://assets.contentstack.io/v3/assets/blt2f8082df109cfbfb/blt18a07e9ed4670227/6442879db7b7332b233e29f1/guia_como_calcular_m2_SOCO.pdf';

const FloorCalculator = ({ displayName }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const [area, setArea] = useState('');
  const [boxCount, setBoxCount] = useState(0);

  const calculateBoxCount = (areaa) => {
    const areaWithWastage = areaa * 1.1; // Agregar 10% de desperdicio
    const boxCountNumber = Math.ceil(areaWithWastage);
    setBoxCount(boxCountNumber);
  };

  const handleAreaChange = (e) => {
    setArea(e.target.value);
    calculateBoxCount(e.target.value);
  };

  const handleClick = (key) => {
    if (hasHttp(key)) {
      window.open(key, '_blank');
    } else {
      navigate(key);
    }
  };

  const validateName = (name) => {
    const regex = /\bpiso\b/i; // Agregamos la bandera 'i' al final

    if (regex.test(name)) {
      setState(!state);
    }
  };

  useEffect(() => {
    validateName(displayName);
  }, [displayName]);

  if (!state) {
    return null;
  }

  return (
    <div className={styles.FloorCalculator}>
      <div className={styles.FloorCalculator__content}>
        <Typography variant="zeta20" className={styles.FloorCalculator__title}>
          Calcula cuantas cajas necesitas
        </Typography>

        <section className={styles.FloorCalculator__section}>
          <div className={styles.InputGroup}>
            <section className={styles.InputGroup__section}>
              <label htmlFor="area" className={styles.InputGroup__label}>
                <Typography variant="kappa14" className={styles.fontWeigth}>
                  Superficie a cubrir (m2):
                </Typography>
              </label>
              <ToolTip text="@">
                <div className={styles.Tooltip}>
                  <Typography variant="kappa14" className={styles.fontWeigth}>
                    Calcula cuántas cajas necesitas para tu proyecto
                  </Typography>
                  <Typography variant="kappa14" style={{ color: 'rgb(118, 118, 118)' }}>
                    Mide el area según Largo x Ancho = m² ó ingresa los m² que
                    necesitas cubrir. Nosotros adicionaremos un 10% extra de
                    desperdicio en el cálculo que te mostramos.
                  </Typography>
                </div>
              </ToolTip>
            </section>

            <input
              type="number"
              id="area"
              value={area}
              onChange={handleAreaChange}
              placeholder="Ej. 10"
              className={styles.InputGroup__input}
            />
          </div>

          <div className={styles.Result}>
            <Typography variant="omega17" className={styles.fontWeigth}>
              Resultado:
            </Typography>
            <section className={styles.Result__value}>
              <Typography variant="delta29" className={styles.fontWeigth}>
                {boxCount}
              </Typography>
              <Typography variant="kappa14">cajas</Typography>
            </section>
          </div>
        </section>

        <button
          type="button"
          className={styles.Button}
          onClick={() => handleClick(link)}
        >
          <Typography variant="kappa14">Calcula tus m2</Typography>
        </button>
      </div>
    </div>
  );
};

FloorCalculator.propTypes = {
  displayName: PropTypes.string,

};

export default FloorCalculator;
