import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../atoms/Typography/Typography';
import Kappa14 from '../../atoms/Kappa14/Kappa14';
import ButtonLink from '../../atoms/ButtonLink/ButtonLink';
import style from './ReviewActionsEmpty.module.scss';

const ReviewActionsEmpty = ({ state, onShowReviews, onWriteOpinion }) => {
  if (!state) {
    return null;
  }
  return (
    <div className={style.review}>
      <Typography variant="zeta20">Opiniones de clientes</Typography>
      <div className={style.review__action}>
        <button type="button" onClick={onShowReviews}>
          <Kappa14>Aún no tenemos comentarios de otros clientes, ¡Sé el primero!</Kappa14>
        </button>
        <ButtonLink onLinkClick={onWriteOpinion}>Escribir opinión</ButtonLink>
      </div>
    </div>
  );
};
ReviewActionsEmpty.propTypes = {
  state: PropTypes.bool,
  onShowReviews: PropTypes.func,
  onWriteOpinion: PropTypes.func,
};

export default ReviewActionsEmpty;
