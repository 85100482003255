/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from '../../atoms/Button/Button';
import ProductBrand from '../../atoms/ProductBrand/ProductBrand';
import ProductName from '../../atoms/ProductName/ProductName';
import ProductNormalPrice from '../../atoms/ProductNormalPrice/ProductNormalPrice';
import ProductPrice from '../../atoms/ProductPrice/ProductPrice';
import RatingStars from '../../molecules/RatingStars/RatingStars';
import style from './ProductCardHorizontal.module.scss';
import Timer from '../../molecules/Timer/Timer';
import ProductImageVertical from '../../molecules/ProductImageVertical/ProductImageVertical';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import Badge from '../../atoms/Badge/Badge';
import DiscountBadge from '../../atoms/DiscountBadge/DiscountBadge';
import IconButton from '../../atoms/IconButton/IconButton';
import { ReactComponent as AddCar } from '../../../../assets/svg/AddCar.svg';
import { ReactComponent as CmrBadge } from '../../../../assets/svg/CmrBadge.svg';
import { ReactComponent as InternetPrice } from '../../../../assets/svg/InternetPrice.svg';
import { ReactComponent as Success } from '../../../../assets/svg/Success.svg';
// import { ReactComponent as Danger } from '../../../../assets/svg/Danger.svg';
// import multipurposeBadge from '../../../../assets/images/multipurposeBadge.png';
import BadgesContainer from '../BadgesContainer/BadgesContainer';
import Loader from '../../atoms/Loader/Loader';

const ProductCardHorizontal = ({
  isAddToCartLoading,
  name,
  onClick,
  onCheckboxClick,
  onClickSaveList,
  ...props
}) => {
  const oldPrice = props.prices[0].price !== props.prices[props.prices.length - 1].price
    && props.prices[props.prices.length - 1].price;
  return (
    <BadgesContainer
      bankBadge={props.bankBadge}
      eventBadge={props.events}
      garantieBadge={props.garantieBadge}
      discountBadge={props.badges}
      multiPurposeIcon={props.multiPurposeIcon}
    >
      {({
        hasBankBadge,
        hasGarantieBadge,
        hasDiscountBadge,
        hasEventBadge,
        hasMultiPurposeIcon,
      }) => (
        <div className={classNames(style.productVertical)}>
          <div className={style.productVertical__header}>
            <Checkbox
              className={style.productVertical__compare}
              label="Comparar"
              name={name}
              checked={props.isChecked}
              onHandleChange={onCheckboxClick}
            />
            <div className={style.productVertical__imageContainer}>
              <ProductImageVertical
                className={style.productVertical__image}
                sku={props.skuId}
                name={name}
                onClick={() => onClick(props.productId)}
                badges={(
                  <>
                    <Badge
                      state={hasBankBadge}
                      className={style.productVertical__bankBadge}
                      name={props.bankBadge?.description}
                      url={props.bankBadge?.value}
                    />
                    <Badge
                      state={hasGarantieBadge}
                      className={style.productVertical__garantieBadge}
                      name={props.garantieBadge?.description}
                      url={props.garantieBadge?.value}
                    />
                    <DiscountBadge
                      state={hasDiscountBadge}
                      className={style.productVertical__discountBadge}
                      label={props.badges[0]?.value}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className={style.productVertical__content}>
            <div>
              <div className={style.productVertical__contentHeader}>
                <ProductBrand content={props.brand} />
                <Badge
                  state={hasEventBadge}
                  className={style.productVertical__eventBadge}
                  name={props.events[0]?.description}
                  url={props.events[0]?.value}
                />
              </div>
              <ProductName content={name} />
              <RatingStars
                color="#006AC1"
                rating={Math.floor(parseFloat(props.rating))}
                name="myRating"
                totalReviews={props.totalReviews}
              />
              <div className={style.productVertical__availableOptions}>
                {props.variants?.length > 0 && props.variants[0]?.options && (
                  <Link to="/#">+ Opciones Disponibles</Link>
                )}
              </div>
            </div>
            <div className={style.productVertical__priceMobile}>
              <div className={style.productVertical__priceInfo}>
                <div
                  className={classNames(
                    style.productVertical__priceBadge,
                    style.productVertical__priceBadge_right,
                  )}
                >
                  {props.prices[0]?.type === 'CMR' && <CmrBadge />}
                  {props.prices[0]?.type === 'INTERNET' && <InternetPrice />}
                  <DiscountBadge
                    state={
                      props.badges[0]?.value?.length > 0
                      && props.badges[0]?.type === 'DISCOUNT'
                    }
                    label={props.badges[0]?.value}
                  />
                </div>
                <ProductPrice content={props.prices[0].price} />
                {oldPrice && <ProductNormalPrice content={oldPrice} />}
                {props.timerTime && <Timer timerTime={props.timerTime} />}
              </div>
              <div className={style.productVertical__iconButton}>
                <IconButton
                  size="big"
                  icon={isAddToCartLoading ? <Loader /> : <AddCar />}
                  color="#dd2100"
                  onClick={
                    isAddToCartLoading
                      ? null
                      : () => props.onAddCartClick(props.productId)
                  }
                />
              </div>
              <Button
                color="secondary"
                variant="text"
                onClick={onClickSaveList}
              >
                Guardar en Mi lista
              </Button>
            </div>
            <div className={style.productVertical__priceDesktop}>
              {/* Badge */}
              <div className={style.productVertical__productInfo}>
                {props.highlights?.length > 0 && (
                  <ul style={{ fontSize: '14px' }}>
                    {props.highlights?.map(({ key, value }) => (
                      <li>{`- ${key}: ${value}`}</li>
                    ))}
                  </ul>
                )}
                <div className={style.productVertical__priceContainer}>
                  <div
                    className={classNames(
                      style.productVertical__priceBadge,
                      style.productVertical__priceBadge_right,
                    )}
                  >
                    {props.prices[0]?.type === 'CMR' && <CmrBadge />}
                    {props.prices[0]?.type === 'INTERNET' && <InternetPrice />}
                    <DiscountBadge
                      state={
                        props.badges[0]?.value?.length > 0
                        && props.badges[0]?.type === 'DISCOUNT'
                      }
                      label={props.badges[0]?.value}
                    />
                  </div>
                  <ProductPrice content={props.prices[0].price} />
                  {oldPrice && <ProductNormalPrice content={oldPrice} />}
                  {props.timerTime && <Timer timerTime={props.timerTime} />}
                  {hasMultiPurposeIcon && (
                    <div className={style.productVertical__multipurposeBadge}>
                      <img
                        src={props.multiPurposeIcon?.iconUrl}
                        alt="badge multiproposito"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={style.productVertical__addToCart}>
                <div className={style.productVertical__available}>
                  {props.availability?.clickAndCollect && (
                    <div className={style.productVertical__available_item}>
                      <Success />
                      <span>Disponible para despacho</span>
                    </div>
                  )}
                  {props.availability?.clickAndCollect && (
                    <div className={style.productVertical__available_item}>
                      <Success />
                      <span>Disponible para retiro</span>
                    </div>
                  )}
                  {/* <div className={style.productVertical__available_item}>
                    <Danger />
                    <span>Text</span>
                  </div> */}
                </div>
                <section>
                  <div className={style.productVertical__button}>
                    <Button
                      color={isAddToCartLoading ? 'secondary' : 'primary'}
                      variant={isAddToCartLoading ? 'loading' : 'outlined'}
                      onClick={
                        isAddToCartLoading
                          ? null
                          : () => props.onAddCartClick(props.productId)
                      }
                    >
                      {isAddToCartLoading ? (
                        <>
                          <Loader />
                          {' '}
                          Agregando
                          {' '}
                        </>
                      ) : (
                        'Agregar al carro'
                      )}
                    </Button>
                    <Button
                      color="secondary"
                      variant="text"
                      onClick={onClickSaveList}
                    >
                      Guardar en Mi lista
                    </Button>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      )}
    </BadgesContainer>
  );
};

ProductCardHorizontal.propTypes = {
  name: PropTypes.string,
  skuId: PropTypes.string,
  brand: PropTypes.string,
  rating: PropTypes.number,
  totalReviews: PropTypes.number,
  prices: PropTypes.arrayOf(PropTypes.shape({})),
  availability: PropTypes.shape({}),
  highlights: PropTypes.arrayOf(PropTypes.shape({})),
  badges: PropTypes.arrayOf(PropTypes.shape({})),
  variants: PropTypes.arrayOf(PropTypes.shape({})),
  multiPurposeIcon: PropTypes.shape({}),
  events: PropTypes.arrayOf(PropTypes.shape({})),
  normalPrice: PropTypes.string,
  timerTime: PropTypes.number,
  eventBadge: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  bankBadge: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  garantieBadge: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  discountBadge: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  onCheckboxClick: PropTypes.func,
  onAddCartClick: PropTypes.func,
  onClickSaveList: PropTypes.func,
};

export default ProductCardHorizontal;
