import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProductBrand from '../../atoms/ProductBrand/ProductBrand';
import ProductName from '../../atoms/ProductName/ProductName';
import ProductNormalPrice from '../../atoms/ProductNormalPrice/ProductNormalPrice';
import ProductPrice from '../../atoms/ProductPrice/ProductPrice';
import ProductImage from '../../molecules/ProductImage/ProductImage';
import RatingStars from '../../molecules/RatingStars/RatingStars';
import style from './ProductCard.module.scss';
import Timer from '../../molecules/Timer/Timer';
import Button from '../../atoms/Button/Button';

const ProductCard = ({ name, onClick, ...props }) => {
  const addDecimal = (price) => {
    if (price) {
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;
      const rep = '$1.';
      return price.toString().replace(exp, rep);
    }

    return null;
  };

  return (
    <button type="button" onClick={onClick}>
      <div className={classNames(style.productCard, props.className)}>
        <div>
          {(props.discount || props.discount === '0') && (
          <span className={style.saving}>
            {`Ahorra ${Math.floor(
              Number(props.discount),
            )}%`}

          </span>
          )}
          <ProductImage
            urlImage={props?.urlImage}
            urlBadge={props?.urlBadge}
            name={name}
          />
          <ProductBrand content={props?.brand} />
          <ProductName content={name} />
          <RatingStars
            color="#006AC1"
            rating={props?.rating}
            name="myRating"
            totalReviews={props?.totalReviews}
            showReviews
          />
          {/* Badge */}
        </div>
        <div className={style.productCard__button}>
          {props?.type !== 'similar' ? (
            <div className={style.productCard__prices}>
              <ProductPrice content={props.price} />
              {props?.normalPrice && <ProductNormalPrice content={props?.normalPrice} />}
            </div>
          ) : (
            <div className={style.productCard__prices}>
              {props?.price === props?.normalPrice ? (
                <div className={style.price__similar}>{`$ ${addDecimal(props?.price)}`}</div>
              ) : (
                <>
                  <div className={style.price__similar}>{`$ ${addDecimal(props?.price)}`}</div>
                  <div className={style.price__similar__normal}>
                    {`$ ${addDecimal(props?.price)}`}
                  </div>
                </>
              )}
            </div>
          )}
          {props?.timerTime && <Timer timerTime={props?.timerTime} />}
          <Button variant="outlined" color="primary" onClick={onClick}>
            Ver Producto
          </Button>
        </div>
      </div>
    </button>
  );
};

ProductCard.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  discount: PropTypes.string,
  urlImage: PropTypes.string,
  urlBadge: PropTypes.string,
  brand: PropTypes.string,
  rating: PropTypes.number,
  totalReviews: PropTypes.number,
  price: PropTypes.string,
  normalPrice: PropTypes.string,
  timerTime: PropTypes.number,
  className: PropTypes.string,
};

export default ProductCard;
