/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Typography from '../../atoms/Typography/Typography';
import styles from './ProductPriceBoxPDP.module.scss';

const ProductPriceBoxPDP = ({ ...props }) => {
  const priceBox = props?.productUnitPrice?.filter((item) => item.unit === 'caja');
  return (
    <div className={styles.priceProductBox__container}>
      <div className={styles.badgesContainer}>
        {props.discount && (
        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <img className={styles.badge} src={props.badgeImage} alt="" />
          <span className={styles.discount}>{props.discount}</span>
        </div>
        )}

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="h4" className={styles.priceProductBox__price}>
            {props.price}
          </Typography>

          {priceBox && priceBox.length > 0 && (
          <>
            <Typography variant="delta29" className={styles.priceBox}>
              |
            </Typography>
            <Typography variant="delta29" className={styles.priceBox}>
              {`${priceBox[0].price} ${priceBox[0].unit}`}
            </Typography>
          </>

          )}
        </div>
        <Typography
          variant="epsilon24"
          className={styles.priceProductBox__savedTitle}
        >
          {props.savedTitle}
        </Typography>
        {props.subAux && (
        <Typography variant="omega17" className={styles.priceProductBox__subAux}>
          {props.subAux}
        </Typography>
        )}

        <div style={{ display: 'flex', flexDirection: 'row' }}>

          {props.priceType && (
          <Typography
            variant="epsilon24"
            className={styles.priceProductBox__priceType}
          >
            {props?.priceType}
          </Typography>
          )}

          {props?.unitPrice && props.priceType && (
            <Typography variant="epsilon24" className={styles.priceBox}>
              |
            </Typography>
          ) }

          {props?.unitPrice
          && (
            <Typography
              variant="epsilon24"
              className={`${styles.priceProductBox__unitPrice} ${
                props.subAux ? styles.priceProductBox__unitPrice__nosub : ''
              }`}
            >
              {props?.unitPrice}
            </Typography>
          )}
        </div>

        {props.multiPurposeBadgeImage && (
        <div className={`store_in_store_${props.multiPurposeBadgeImage.type}`}>
          <img
            src={props.multiPurposeBadgeImage.value}
            alt={props.multiPurposeBadgeImage.name}
          />
          <Typography
            variant="kappa14"
            style={{ fontWeight: 'bold' }}
          >
            {props.multiPurposeBadgeImage.name}
          </Typography>
        </div>
        )}
      </div>
    </div>
  );
};

ProductPriceBoxPDP.propTypes = {
  badgeImage: PropTypes.string,
  subAux: PropTypes.string,
  discount: PropTypes.string,
  price: PropTypes.string,
  priceType: PropTypes.string,
  savedTitle: PropTypes.string,
  unitPrice: PropTypes.string,
  multiPurposeBadgeImage: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
  }),
  productUnitPrice: PropTypes.shape({}),
};

export default ProductPriceBoxPDP;
