/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-simply-carousel';
import { Carousel as Crs } from 'react-responsive-carousel';
import classNames from 'classnames';
import style from './SliderFeaturedProducts2.module.scss';
import ProductCard2 from '../ProductCard2/ProductCard2';
import IconButton from '../../atoms/IconButton/IconButton';
import { ReactComponent as ArrowLeft } from '../../../../assets/svg/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/ArrowRight.svg';
import { ReactComponent as ArrowLeft2 } from '../../../../assets/svg/ArrowLeft2.svg';
import { ReactComponent as ArrowRight2 } from '../../../../assets/svg/ArrowRight2.svg';

import Typography from '../../atoms/Typography/Typography';
import { useLazyGetSimilarProductDetailsHomeQuery } from '../../../../redux/slices/PDP/insiderApi';

const SliderFeaturedProducts2 = ({
  urls, images, onClick, handleBannersClick, ...props
}) => {
  const [currentSlider, setCurrentSlider] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);

  const [getSimilarProductDetailsHome,
    { data, isSuccess }] = useLazyGetSimilarProductDetailsHomeQuery();

  if (!props?.state) {
    return null;
  }

  useEffect(() => {
    getSimilarProductDetailsHome(urls);
  }, [urls]);

  return (
    <div className={style.featuredProducts}>
      <div className={style.featuredProducts__header}>
        {/* Titulo */}
        <Typography
          variant={props?.title?.variant}
          style={
            props?.title?.strong ? { fontWeight: '700' } : { fontWeight: '400' }
          }
        >
          {props?.title?.value}
        </Typography>
      </div>
      <div className={style.featuredProducts__content}>
        {/* Descripción */}
        <Typography
          variant={props?.content?.variant}
          style={
            props?.content?.strong ? { fontWeight: '700' } : { fontWeight: '400' }
          }
        >
          {props?.content?.value}
        </Typography>
      </div>
      {/* Content Box */}
      <div className={style.featuredProducts__content}>
        {/* Principal Slider Photos  */}
        <div className={style.featuredProducts__content__feat}>
          <Crs
            className={classNames(style.featuredProducts__sliderBanner, {
              [style.featuredProducts__sliderBanner_hidden]:
                props?.variant === 'timed',
            })}
            showThumbs={false}
            showStatus={false}
            infiniteLoop
            preventMovementUntilSwipeScrollTolerance="false"
            renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && (
            <IconButton
              onClick={onClickHandler}
              title={label}
              className={classNames(
                style.featuredProducts__arrowMiddle,
                style.featuredProducts__arrowMiddle_left,
              )}
              icon={<ArrowLeft2 />}
              size="responsive"
              color="#000"
            />
            )}
            renderArrowNext={(onClickHandler, hasNext, label) => hasNext && (
            <IconButton
              onClick={onClickHandler}
              title={label}
              className={classNames(
                style.featuredProducts__arrowMiddle,
                style.featuredProducts__arrowMiddle_right,
              )}
              icon={<ArrowRight2 />}
              size="responsive"
              color="#000"
            />
            )}
          >
            {images?.map((image, index) => (
              <div key={index} className={style.slider__image}>
                <button
                  className={style.featuredProducts__button}
                  style={{ width: '100%' }}
                  type="button"
                  onClick={() => handleBannersClick(image.linkto)}
                >
                  <picture>
                    <source
                      media="(max-width: 900px)"
                      srcSet={image.urlMobile}
                    />
                    <source
                      media="(min-width: 1000px)"
                      srcSet={image.urlDesktop}
                    />
                    <img
                      className={style.slider__image}
                      src={image.urlDesktop}
                      alt={image.alt}
                    />
                  </picture>
                </button>
              </div>
            ))}
          </Crs>
        </div>
        {/* Second Slider Products */}
        {props.embeddedHTML
          && (
          <div id={`${props.cta}-${props.id}`} className={style.featuredProducts__embeddedHTML}>
            {/* Html embebido */}
          </div>
          )}

        {isSuccess && !props.embeddedHTML && (
          <Carousel
            activeSlideIndex={activeSlide}
            onRequestChange={setActiveSlide}
            infinite={false}
            itemsToShow={2}
            center
            disableNavIfAllVisible
            responsiveProps={[
              {
                itemsToShow: 3,
                itemsToScroll: 1,
                minWidth: 460,
                maxWidth: 660,
              },
              {
                itemsToShow: 4,
                itemsToScroll: 1,
                minWidth: 660,
                maxWidth: 860,
              },
              {
                itemsToShow: 5,
                itemsToScroll: 1,
                minWidth: 860,
                maxWidth: 990,
              },

              {
                itemsToShow: 2,
                itemsToScroll: 1,
                minWidth: 990,
              },
              {
                itemsToShow: 3,
                itemsToScroll: 1,
                minWidth: 1050,
              },
              {
                itemsToShow: 4,
                itemsToScroll: 1,
                minWidth: 1250,
              },
              {
                itemsToShow: 5,
                itemsToScroll: 1,
                minWidth: 1500,
              },
            ]}
            itemsListProps={{
              style: {
                touchAction: 'none',
              },
            }}
            forwardBtnProps={{
              children: (
                <span className={style.featuredProducts__arrows}>
                  <ArrowRight />
                </span>
              ),
              className: 'nextButton',
            }}
            backwardBtnProps={{
              children: (
                <span className={style.featuredProducts__arrows}>
                  <ArrowLeft />
                </span>
              ),
              className: 'prevButton',
            }}
            speed={300}
          >
            {data?.data?.map((product, index) => (
              <ProductCard2
                key={index}
                productId={product.item_id}
                discount={product.discount.COP}
                name={product.name}
                urlImage={product.image_url}
                urlBadge={product.urlBadge}
                brand={product.brand}
                rating={product.rating}
                totalReviews={product.totalReviews}
                price={product.price.COP}
                normalPrice={product.original_price.COP}
                timerTime={product.timerTime}
                onClick={() => onClick(product.item_id)}
              />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

SliderFeaturedProducts2.propTypes = {
  state: PropTypes.bool,
  urls: PropTypes.string,
  variant: PropTypes.oneOf(['featured', 'timed']),
  title: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
  }),
  data: PropTypes.shape({}),
  images: PropTypes.arrayOf(PropTypes.shape({
    urlMobile: PropTypes.string,
    urlDesktop: PropTypes.string,
    name: PropTypes.string,
    linkto: PropTypes.string,
  })),
  products: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    urlImage: PropTypes.string,
    urlBadge: PropTypes.string,
    brand: PropTypes.string,
    rating: PropTypes.number,
    totalReviews: PropTypes.number,
    price: PropTypes.string,
    normalPrice: PropTypes.string,
    timerTime: PropTypes.number,
  })),
  embeddedHTML: PropTypes.string,
};

export default SliderFeaturedProducts2;
