import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import style from './DeliveryStock.module.scss';
import Typography from '../../atoms/Typography/Typography';
import ButtonLink from '../../atoms/ButtonLink/ButtonLink';

import { ReactComponent as Truck } from '../../../../assets/svg/DeliveryStandarBlack.svg';
import { ReactComponent as Home } from '../../../../assets/svg/StoreCheckBlack.svg';
import { setOpenModal } from '../../../../redux/slices/PLP/geolocalizationSlice';

const DeliveryStock = ({
  storesQuantity,
  onClickSeeStores,
  onClickPickupInformation,
  onClickDeliveryInformation,
  onClickDeliveryExpressInformation,
}) => {
  const dispatch = useDispatch();

  const {
    ciudad: { name: cityName },
  } = useSelector((state) => state.transversalSlice);

  const availability = (state) => (
    <Typography
      variant="kappa14"
      style={{ color: state ? 'green' : 'red', fontWeight: 'bold' }}
    >
      {state ? 'Disponible' : 'No disponible'}
    </Typography>
  );

  const availabilityButton = (state, text, callback = () => {}) => (
    <ButtonLink
      onLinkClick={state ? callback : () => {}}
      color={state ? '#0072ce' : '#a5a5a6'}
    >
      {text}
    </ButtonLink>
  );

  return (
    <div className={style.DeliveryStock}>
      <div className={style.DeliveryStock__Text}>
        <Typography
          style={{ fontWeight: 'bold', marginLeft: '5px' }}
          variant="omega17"
        >
          Opciones de entrega
          {' '}
        </Typography>
        <Typography variant="kappa14" style={{ marginLeft: '5px' }}>
          para
          {' '}
        </Typography>
        <button
          type="button"
          onClick={() => {
            dispatch(
              setOpenModal({
                isOpen: true,
              }),
            );
          }}
        >
          <Typography
            style={{ fontWeight: 'bold', marginLeft: '5px', color: '#0072ce' }}
            variant="kappa14"
          >
            {cityName}
          </Typography>
        </button>
      </div>

      <div className={style.stock}>
        <div className={style.stock__contentDelivery}>
          <Truck />
          <div className={style.stock__state}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
              {availability(true)}
              <Typography style={{ fontWeight: 'bold' }} variant="omega17">
                Despacho a Domicilio
              </Typography>
            </div>

            <div>
              {availabilityButton(
                true,
                'Ver fechas',
                onClickDeliveryInformation,
              )}
            </div>
          </div>
        </div>

        <div className={style.stock__contentDelivery}>
          <Truck />
          <div className={style.stock__state}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
              {availability(true)}
              <Typography style={{ fontWeight: 'bold' }} variant="omega17">
                Despacho Express
              </Typography>
            </div>

            <div>
              {availabilityButton(
                true,
                'Ver condiciones',
                onClickDeliveryExpressInformation,
              )}
            </div>
          </div>
        </div>

        <div className={style.stock__contentDelivery}>
          <Home />
          <div className={style.stock__state}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
              {availability(true)}
              <Typography style={{ fontWeight: 'bold' }} variant="omega17">
                Retiro en Tienda
              </Typography>
            </div>
            <div>
              {availabilityButton(
                true,
                'Ver tiendas',
                onClickPickupInformation,
              )}
            </div>
          </div>
        </div>

        <div className={style.stock__contentDelivery}>
          <Truck />
          <div className={style.stock__state}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
              {availability(storesQuantity > 0)}

              <Typography style={{ fontWeight: 'bold' }} variant="omega17">
                Stock en Tienda
              </Typography>
            </div>
            <div>
              {availabilityButton(
                storesQuantity > 0,
                'Ver tiendas',
                onClickSeeStores,
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DeliveryStock.propTypes = {
  storesQuantity: PropTypes.string,
  onClickSeeStores: PropTypes.func,
  onClickPickupInformation: PropTypes.func,
  onClickDeliveryInformation: PropTypes.func,
  onClickDeliveryExpressInformation: PropTypes.func,
};

export default DeliveryStock;
