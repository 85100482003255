import { useState, useEffect, useMemo } from 'react';
/* eslint-disable no-unused-vars */
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetProductDetailsQuery,
  useGetProductInventoryQuery,
  // useGetProductPriceQuery,
  useLazyGetParentProductInfoQuery,
  useLazyGetProductDetailsQuery,
  useLazyGetProductInventoryQuery,
} from '../../redux/slices/PDP/productDetailsApi';
import { useGetFeedbackFromReviewsQuery } from '../../redux/slices/PDP/productReviewApi';
import { useGetBlobStorageDataQuery } from '../../redux/slices/HOME/blobStorageApi';
import { useLazyGetAllProductImagesQuery } from '../../redux/slices/PDP/productImagesApi';
import { setFeedbackReview } from '../../redux/slices/PDP/productReviewSlice';
import { useGetBlobStorageWarrantyDataQuery } from '../../redux/slices/PDP/blobStorageWarrantyApi';
import { setStockResult } from '../../redux/slices/PDP/deliverySlice';
import {
  setVariantProduct,
  setSelectorSizeOptions,
  setServices,
  setWarranties,
  setDataSheetBox,
  setDefaultParentCategoryId,
  setSelectorColorOptions,
  setAllVariantsProduct,
} from '../../redux/slices/PDP/productDetailsSlice';
import PDPTemplate from '../../components/UI/templates/PDPTemplate/PDPTemplate';
import AlertCartModalPlp from '../../components/UI/organisms/AlertCartModalPlp/AlertCartModalPlp';
import {
  setAddExtraToCart, setIsPDP, setLabelsModalPlp, setOpenModalPlp, setRedirectToCart,
} from '../../redux/slices/PDP/alertModalPlpSlice';
import { setQuantity } from '../../redux/slices/Cart/cartSlice';
import Loader from '../../components/UI/atoms/Loader/Loader';
import styles from '../../components/UI/molecules/AlertCartModalBox/AlertCartModalBox.module.scss';
import GeolocalizationModal from '../../components/UI/organisms/GeolocalizationModal/GeolocalizationModal';
import { setOpenModal } from '../../redux/slices/PLP/geolocalizationSlice';

const ProductDetailPage = () => {
  const [hasProductInfo, setHasProductInfo] = useState(false);
  const whatssappButton = useMemo(() => document.querySelector('[title="WhatsApp"]'));

  const {
    store,
    departamento,
    ciudad: { name: cityName, id: cityId },
    whatsAppCta,
  } = useSelector((state) => state.transversalSlice);
  const dispatch = useDispatch();
  const { productId } = useParams();

  const [getParentData, {
    data: parentData,
    error: errorParent,
    isLoading: isLoadingParent,
  }] = useLazyGetParentProductInfoQuery();

  const [getProductDetails, {
    data: product, error,
    isLoading,
  }] = useLazyGetProductDetailsQuery();

  const {
    data: homeStore,
    isLoading: storeIsLoading,
    error: storeError,
  } = useGetBlobStorageDataQuery(store);

  const [getImages, {
    data: productImages,
    isLoading: imagesAreLoading,
    error: imagesError,
  }] = useLazyGetAllProductImagesQuery();

  // const { data: productPrice, isSuccess: isSuccessPrice } = useGetProductPriceQuery(productId);

  const [getInventory, {
    data: stockResult,
    isLoading: isLoadingStock,
    error: isErrorStock,
  }] = useLazyGetProductInventoryQuery(productId);

  const {
    data: warrantyData,
    isLoading: warrantyIsLoading,
    error: warrantyError,
  } = useGetBlobStorageWarrantyDataQuery();

  const {
    data: feedbackReview,
    isLoading: feedbackReviewIsLoading,
    error: feedbackReviewError,
  } = useGetFeedbackFromReviewsQuery(productId);

  const { isOpen: isOpenGeoModal } = useSelector(
    (state) => state.geolocalization,
  );

  const {
    productId: skuToAdd,
    isOpenModal,
  } = useSelector((state) => state.alertModalPlp);

  const {
    variantProduct,
  } = useSelector((state) => state.productDetailsReducer);

  useEffect(() => {
    if (departamento && cityId) {
      getParentData({ productId, departamento, cityId });
      getInventory({ productId, departamento, cityId });
    }
  }, [departamento, cityId, productId]);

  useEffect(() => {
    getImages({
      productId,
      businessUnit: 'sodimac',
      country: 'CO',
    });
  }, [productId]);

  useEffect(() => {
    if (feedbackReview) {
      dispatch(setFeedbackReview(feedbackReview));
    }
  }, [feedbackReview]);

  useEffect(() => {
    if (parentData?.data?.results?.length > 0) {
      setHasProductInfo(true);
      if (parentData && departamento && cityId) {
        const {
          data: { results },
        } = parentData;
        const { skuId, productId: pId } = results[0];

        // skuId es la variante
        // productId es el original
        if (skuId === pId) {
          getProductDetails({ productId: skuId, departamento, cityId });
        } else {
          getProductDetails({ productId: pId, departamento, cityId });
        }
      }
    } else {
      setHasProductInfo(false);
    }
  }, [parentData, departamento, cityId]);

  useEffect(() => {
    if (product) {
      // Data del producto original:
      const {
        data: { results },
      } = parentData;
      const { skuId, productId: pId } = results[0];
      // producto
      const { result } = product;
      dispatch(setAllVariantsProduct(result?.variants));
      dispatch(setSelectorSizeOptions(result));
      dispatch(setSelectorColorOptions(result));
      dispatch(setServices(result));
      dispatch(setWarranties(result));
      dispatch(setDataSheetBox(result));
      dispatch(setDefaultParentCategoryId(result));
      dispatch(setIsPDP(true));
      dispatch(
        setVariantProduct(
          result?.variants?.find((variant) => variant.id === skuId),
        ),
      );
      if (stockResult) {
        dispatch(setStockResult(stockResult));
      }
    }
  }, [product, stockResult]);

  useEffect(() => {
    if (whatssappButton) {
      const divs = whatssappButton.querySelectorAll('div');
      console.log(divs);
      divs.forEach((div) => {
        div.style.zIndex = '6';
      });
      const imgs = whatssappButton.querySelectorAll('img');
      imgs.forEach((img) => {
        img.style.zIndex = '6'; // Aplicar más estilos o atributos según sea necesario
      });
    }
  }, [whatssappButton]);
  useEffect(() => {
    if (whatssappButton && product) {
      whatssappButton.setAttribute(
        'href',
        `${whatsAppCta} ${product?.result?.name}`,
      );
    }
  }, [product]);

  if (
    isLoading
    || storeIsLoading
    || imagesAreLoading
    || warrantyIsLoading
    || feedbackReviewIsLoading
    || isLoadingStock
    || isLoadingParent
  ) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  if (
    error
    || storeError
    || imagesError
    || warrantyError
    || feedbackReviewError
    || isErrorStock
    || errorParent
  ) {
    return (
      <div>
        {`Uups Ocurrio algo ${
          error.error.data
          || storeError.error.data
          || imagesError.error.data
          || warrantyError.error.data
          || feedbackReviewError.error.data
          || isErrorStock.error.data
          || errorParent.error.data
        }`}
      </div>
    );
  }

  if (!hasProductInfo && !isLoadingParent) {
    return <div>{`No se encontro el producto ${productId}`}</div>;
  }

  return (
    <>
      {Object.keys(variantProduct).length > 0 && feedbackReview && (
        <PDPTemplate
          productId={productId}
          similiarProductsSize={homeStore?.similiarProductsSize}
          productImages={productImages}
          product={product?.result}
          warrantyData={warrantyData}
        />
      )}
      {isOpenModal && (
        <AlertCartModalPlp
          showProductInfo={!!skuToAdd}
          productId={skuToAdd}
          isOpen={isOpenModal}
          onClose={() => {
            dispatch(
              setOpenModalPlp({
                productId: '',
                isOpen: false,
                isError: false,
              }),
            );
            dispatch(
              setLabelsModalPlp({
                alertLabels: {},
                alertIcon: '',
              }),
            );
            dispatch(setRedirectToCart({ redirect: false }));
            dispatch(setAddExtraToCart({ addExtraToCart: false }));
            // dispatch(setQuantity(1));
          }}
        />
      )}
      {isOpenGeoModal && (
        <GeolocalizationModal
          productId
          isOpen
          onClose={() => {
            dispatch(
              setOpenModal({
                isOpen: false,
              }),
            );
          }}
        />
      )}
    </>
  );
};

export default ProductDetailPage;
