import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Button from '../../atoms/Button/Button';
import styles from './CounterSelectorBox.module.scss';
import useCounterSelectorBox from './useCounterSelectorBox';

const CounterSelectorBox = ({
  onClick,
}) => {
  const [first, setfirst] = useState(true);
  const [count,
    handleIncrement,
    handleDecrement,
    handleChange,
    handleBlur] = useCounterSelectorBox();

  useEffect(() => {
    if (!first) {
      onClick(count);
    }
    setfirst(false);
  }, [count]);

  return (
    <div className={styles.productSelector__container}>
      <div className={styles.productSelector__amount}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleDecrement}
          data-testid="decrease"
          isDisabled={count === 0}
        >
          -
        </Button>
        <label htmlFor="amount">
          <span>Cantidad</span>
          <input
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            id="amount"
            data-testid="amount"
            value={count}
          />
        </label>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleIncrement}
          data-testid="increase"
        >
          +
        </Button>
      </div>
    </div>
  );
};

CounterSelectorBox.propTypes = {
  onClick: PropTypes.func,
};

export default CounterSelectorBox;
