import { useState } from 'react';

const useCounterSelectorBox = () => {
  const [count, setCount] = useState(0);

  const handleIncrement = () => {
    setCount(() => count + 1);
  };
  const handleDecrement = () => {
    if (count > 0) {
      setCount(() => count - 1);
    }
  };
  const handleChange = (evento) => {
    const regex = /[^0-9]/;
    if (
      evento.target.value === ''
      // || evento.target.value === '0'
      || regex.test(evento.target.value)
    ) {
      setCount('');
    } else {
      setCount(Number(evento.target.value));
    }
  };
  const handleBlur = (evento) => {
    if (evento.target.value === '') {
      setCount(0);
    } else {
      setCount(Number(evento.target.value));
    }
  };

  return [
    count,
    handleIncrement,
    handleDecrement,
    handleChange,
    handleBlur,
  ];
};

export default useCounterSelectorBox;
