/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import TabButtonMap from '../../atoms/TabButtonMap/TabButtonMap';
import Typography from '../../atoms/Typography/Typography';
import Select from '../../atoms/Select/Select';
import TabBodyMaps from '../TabBodyMaps/TabBodyMaps';
import styles from './TabsMaps.module.scss';
import Container from '../../../layout/Container/Container';

const TabsMaps = ({
  data,
  state,
  defaultCity,
  sectionTitle,
  button,
  selectLabel,
  ...props
}) => {
  const ciudades = data?.ciudades;
  const initialActiveState = {
    value: '',
    isOpen: true,
  };

  const isDesktopOrmobile = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const [allStoresData, setAllStoresData] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [storesData, setStoresData] = useState(ciudades && ciudades[0]);
  const [activeState, setActiveState] = useState(initialActiveState);
  const [city, setCity] = useState(defaultCity);
  const [mapPosition, setMapPosition] = useState();
  const [reorderedCities, setReorderedCities] = useState(ciudades);

  const depuratedCityData = (value) => value.find((item) => item.label === defaultCity);
  const trueCity = (dt) => dt?.find((item) => item.label === city);

  const selectStore = (cities, label) => cities.find((item) => item.nombre === label);

  const reorderData = (rawData, item) => {
    const dataRaw = [...rawData];
    if (dataRaw?.length === 0) {
      return dataRaw;
    }
    const one = dataRaw?.splice(item, 1);
    const reorderedData = [one[0], ...dataRaw];
    return reorderedData;
  };

  const findCity = (item) => item.label === defaultCity;
  const cityIndex = (ci) => ci?.findIndex(findCity);

  const joinAllStores = (alldata) => {
    const allStores = alldata.filter((item) => item.tiendas);
    const allStoresNew = [];
    allStores.forEach((item) => {
      item.tiendas.forEach((el) => {
        allStoresNew.push(el);
      });
    });
    return allStoresNew;
  };

  useEffect(() => {
    if (ciudades) {
      setAllStoresData(joinAllStores(ciudades));
      if (defaultCity !== '') {
        setReorderedCities(reorderData(ciudades, cityIndex(ciudades)));
        setStoresData(depuratedCityData(ciudades));
      }
    }
  }, [ciudades]);

  useEffect(() => {
    if (allStoresData.length > 0) {
      setSelectedStore(
        selectStore(allStoresData, storesData?.tiendas[0]?.nombre),
      );
      setActiveState((prev) => ({ ...prev, value: storesData?.tiendas[0]?.nombre }));
    }
  }, [allStoresData]);

  useEffect(() => {
    if (city) {
      setStoresData(trueCity(reorderedCities));
      const actualCityData = trueCity(reorderedCities);
      setSelectedStore(actualCityData.tiendas[0]);
      setMapPosition(0);
    }
  }, [city]);

  useEffect(() => {
    setActiveState((prev) => ({ ...prev, value: storesData?.tiendas[0]?.nombre }));
  }, [storesData]);

  const handleClick = (value, index) => {
    setMapPosition(index);
    setActiveState((prev) => ({ value, isOpen: !prev.isOpen || mapPosition !== index }));
    setSelectedStore(selectStore(allStoresData, value));
  };
  const handleOnChange = (value) => {
    setCity(value);
  };

  const renderMobile = (items) => items?.map((item, index) => (
    <div className={styles.tabs__item} key={index} style={{ order: index }}>
      <TabButtonMap
        id={item.idl}
        address={item.direccion}
        time={item.horario}
        label={item.nombre}
        onClick={(value) => handleClick(value, index)}
        selection={activeState?.value}
        theme={props?.theme}
      />
    </div>
  ));
  const renderDesktop = (tiendas) => (
    <TabBodyMaps
      buttonLabel={button?.label}
      buttonUrl={button?.linkTo}
      buttonIcon={button?.ico}
      selection={activeState}
      mapData={tiendas}
      selectedMap={selectedStore}
      isDektop
      theme={props?.theme}
    />
  );

  if (!state) {
    return null;
  }

  return (
    <Container>
      <div
        className={styles.section__container}
        style={
          props.backgroundImage
            ? { backgroundImage: props.theme.primary.text }
            : { backgroundColor: props.theme.primary.primary }
}
      >
        <Typography
          variant="epsilon2"
          style={{
            color: props.theme.primary.text,
            fontWeight: 'bold',
            marginBlock: '10px',
          }}
        >
          {sectionTitle}
        </Typography>
        <Select
          onchange={handleOnChange}
          data={reorderedCities}
          selectLabel={selectLabel}
        />
        <div className={styles.tabs__mobile}>
          {renderMobile(storesData?.tiendas)}
          {!isDesktopOrmobile && (
            <TabBodyMaps
              buttonLabel={button?.label}
              buttonUrl={button?.linkTo}
              buttonIcon={button?.ico}
              selection={activeState?.isOpen}
              mapData={allStoresData}
              selectedMap={selectedStore}
              order={mapPosition}
              theme={props?.theme}
            />
          )}
        </div>
        {isDesktopOrmobile && (
          <div className={styles.tabs__desktop}>
            {renderDesktop(allStoresData)}
          </div>
        )}
      </div>
    </Container>
  );
};

TabsMaps.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    ciudades: PropTypes.array,
  }),
  state: PropTypes.bool,
  defaultCity: PropTypes.string,
  sectionTitle: PropTypes.string,
  selectLabel: PropTypes.string,
  backgroundImage: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    linkTo: PropTypes.string,
    ico: PropTypes.string,
  }),
};
export default TabsMaps;
