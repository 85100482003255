import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Checkbox.module.scss';

const Checkbox = ({
  label, checked, onHandleChange, ...props
}) => {
  const isChecked = checked || false;
  // const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleChange = (event) => {
    // setIsChecked((prev) => !prev);
    onHandleChange(event);
  };
  return (
    <div className={classNames(style.checkboxFilter, props.className)}>
      <input
        id={props.name}
        type="checkbox"
        name={props.name}
        checked={isChecked}
        onChange={onHandleChange && handleChange}
        className={classNames({ [style.checkboxFilter__input]: isChecked })}
      />
      <label className={style.checkboxFilter__label} htmlFor={props.name}>
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onHandleChange: PropTypes.func,
  className: PropTypes.string,
};

export default Checkbox;
