import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Kappa14.module.scss';

const Kappa14 = ({
  children, color, strong, className,
}) => (
  <p
    className={classNames(style.kappa14, className)}
    style={{ color: color || '', fontWeight: strong ? '900' : '' }}
  >
    {children}
  </p>
);

Kappa14.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  strong: PropTypes.bool,
  className: PropTypes.string,
};

export default Kappa14;
