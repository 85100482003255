/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import EmbedVideo from '../../molecules/EmbedVideo/EmbedVideo';
import SmallHeader from '../SmallHeader/SmallHeader';
import Tabs from '../Tabs/Tabs';
import Container from '../../../layout/Container/Container';
import style from './SectionGroup.module.scss';
import Advertising from '../Advertising/Advertising';
import Typography from '../../atoms/Typography/Typography';

const SectionGroup = ({
  state, backgroundColor, ...props
}) => {
  const { store } = useSelector((State) => State.transversalSlice);

  if (!state) {
    return null;
  }
  return (
    <div
      className={classNames(style.sectionGroup, {
        [style.sectionGroup_paddingTop]: !props.smallHeader?.state,
      })}
      style={{ marginTop: store === 'carcenter' ? '43px' : '0px', backgroundColor: backgroundColor || '' }}
    >
      <SmallHeader
        state={props.smallHeader?.state}
        name={props.smallHeader?.name}
        logo={props.smallHeader?.logo}
        background={props.smallHeader?.background}
        align={props.smallHeader?.align}
        style={{
          marginBottom: `${props.marginBottom}px`,
        }}
        withoutBreadcrumbs
      />
      <Container>
        {props.embedVideo?.state && (
          <>
            <Typography
              variant={props.embedVideo?.title?.variant}
              style={{
                marginBottom: '20px',
                fontWeight: props.embedVideo?.title?.strong ? '700' : '400',
                color: props.embedVideo?.title?.color,
              }}
            >
              {props.embedVideo?.title?.value}
            </Typography>
            <EmbedVideo
              videoHeigth={props.embedVideo?.videoHeigth}
              videoUrl={props.embedVideo?.videoUrl}
              style={{
                marginBottom: `${props.marginBottom}px`,
              }}
            />
          </>
        )}
        <Tabs
          state={props.servicesTabs?.state}
          title={props.servicesTabs?.title}
          data={props.servicesTabs?.data}
          type={props.servicesTabs?.type}
          icons={props.servicesTabs?.icons}
          size={props.servicesTabs?.size}
          style={props.servicesTabs?.styles}
          theme={props.theme}
          onClick={props.onClick}
        />
        <Advertising
          state={props.advertising?.state}
          imageUrlDesktop={props.advertising?.imageUrlDesktop}
          imageUrlMobile={props.advertising?.imageUrlMobile}
          name={props.advertising?.name}
          url={props.advertising?.url}
          ext={props.advertising?.ext}
          style={{ marginBottom: `${props.marginBottom}px` }}
        />
      </Container>
    </div>
  );
};

SectionGroup.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  state: PropTypes.bool,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  marginBottom: PropTypes.number,
  smallHeader: PropTypes.shape({
    state: PropTypes.bool,
    name: PropTypes.string,
    logo: PropTypes.string,
    background: PropTypes.string,
    align: PropTypes.string,
  }),
  embedVideo: PropTypes.shape({
    state: PropTypes.bool,
    videoUrl: PropTypes.string,
    videoHeigth: PropTypes.shape({}),
  }),
  servicesTabs: PropTypes.shape({
    state: PropTypes.bool,
    title: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        ico: PropTypes.string,
        body: PropTypes.shape({
          content: PropTypes.string,
          image: PropTypes.shape({
            url: PropTypes.string,
            alt: PropTypes.string,
          }),
          bottonUrl: PropTypes.string,
        }),
      }),
    ),
    styles: PropTypes.shape(PropTypes.shape({})),
    type: PropTypes.string,
    icons: PropTypes.bool,
    button: PropTypes.shape({
      label: PropTypes.string,
      ico: PropTypes.string,
    }),
    size: PropTypes.string,
  }),
  advertising: PropTypes.shape({
    state: PropTypes.bool,
    imageUrlDesktop: PropTypes.string,
    imageUrlMobile: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    ext: PropTypes.bool,
  }),
};

export default SectionGroup;
