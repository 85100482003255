import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tangibleApi = createApi({
  reducerPath: 'tangibleApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.tangiblee.com' }),
  endpoints: (builder) => ({
    getImpressions: builder.query({
      query: (id) => `/api/tngimpr?ids=${id}&domain=www.homecenter.com.co/homecenter-co/`,
      catchError: (error) => error,
    }),
  }),
});

export const { useGetImpressionsQuery } = tangibleApi;
