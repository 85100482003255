import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PDPImages } from '../../../constants/index';
// import { cleanJson } from '../../../utils';

export const productImagesApi = createApi({
  reducerPath: 'productImages',
  baseQuery: fetchBaseQuery({
    baseUrl: PDPImages,
  }),
  endpoints: (builder) => ({
    getAllProductImages: builder.query({
      query: ({ productId, businessUnit, country }) => ({
        url: `/${productId}`,
        params: {
          businessUnit,
          country,
        },
      }),
      // transformResponse: (response) => cleanJson(response),
    }),
  }),
});

export const {
  useGetAllProductImagesQuery,
  useLazyGetAllProductImagesQuery,
} = productImagesApi;
