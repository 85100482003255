import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const cartApi = createApi({
  reducerPath: 'cartApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.homecenter.com.co/s/checkout/v1/carts/',
  }),
  //   tagTypes: ['Post'],
  keepUnusedDataFor: 1000,
  endpoints: (builder) => ({
    addProductToCart: builder.query({
      // eslint-disable-next-line no-unused-vars
      query: ({ productId, quantity }) => ({
        url: '?cartId=36848015-0d06-4516-bb41-8a60ac499536',
        method: 'POST',
        headers: {
          'x-channel-id': 'WEB',
          'x-ecomm-name': 'homecenter-co',
          'Content-Type': 'application/json',
        },
        body: {
          data: {
            cart: {
              cartLines: [
                {
                  cartLineNumber: productId,
                  compatibilityGroup: 0,
                  item: {
                    variantId: productId,
                  },
                  quantity: {
                    quantityNumber: quantity,
                    quantityUnit: 'und',
                  },
                  priceGroup: '10',
                },
              ],
            },
          },
          metadata: {
            zoneId: '1',
            priceGroup: '10',
          },
        },
      }),
    }),
  }),
});

export const { useLazyAddProductToCartQuery } = cartApi;
