import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const m2Api = createApi({
  reducerPath: 'm2Api',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://www.homecenter.com.co/s/search/v1/soco/category/' }),
  endpoints: (builder) => ({
    getProductsM2: builder.query({
      query: ({ categoryId, currentpage }) => `products?categoryId=${categoryId}&priceGroup=10&zone=1&currentpage=${currentpage}&channel=kiosk&sortBy=_score,desc`,
      transformResponse: (response) => response?.data?.results,
    }),
  }),
});

export const {
  useGetProductsM2Query,
  useLazyGetProductsM2Query,
} = m2Api;
