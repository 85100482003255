import React from 'react';
import PropTypes from 'prop-types';

const TableHead = ({ headData, renderRow, ...props }) => (
  <thead {...props}>
    {headData?.map(({ items }, index) => {
      const currentPosition = index + 1;
      const isOdd = currentPosition % 2 !== 0;
      return renderRow(items, isOdd, currentPosition);
    })}
  </thead>
);
TableHead.propTypes = {
  headData: PropTypes.arrayOf(PropTypes.shape({})),
  renderRow: PropTypes.func,
};

export default TableHead;
