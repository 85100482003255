/* eslint-disable no-unused-vars */
import { useEffect, useMemo } from 'react';
import {
  Routes, Route, useSearchParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import {
  setStore,
  setBarrio,
  setCiudad,
  setDepartamento,
  setCartId,
  setWhatsAppCta,
  setLocation,
  setMainCategory,
  setSelectedFilter,
  setSmallHeader,
} from './redux/slices/transversalSlice';
import HomeStorePage from './pages/HomeStorePage/HomeStorePage';
import ProductsListPage from './pages/ProductsListPage/ProductsListPage';
import ProductDetailPage from './pages/ProductDetailPage/ProductDetailPage';
import './App.css';
import { mapBodyToAddProduct, useAddCartLinesMutation } from './redux/slices/Cart/checkoutApi';
import getInfoStore from './utils/getInfoStore';

const App = () => {
  const [addCartLines] = useAddCartLinesMutation();
  const [searchParams] = useSearchParams();

  // const arrayPageStore = pageStore.split('/');

  const store = 'constructor';
  // const store = searchParams.get('store');

  const dataStore = getInfoStore(store);

  const whatssappButton = useMemo(() => document.querySelector('[title="WhatsApp"]'));

  const dispatch = useDispatch();

  const { whatsAppCta } = useSelector((state) => state.transversalSlice);

  useEffect(() => {
    if (whatssappButton && whatsAppCta === '') {
      const href = whatssappButton.getAttribute('href');
      dispatch(setWhatsAppCta(href));
    }
  }, [whatssappButton]);

  useEffect(() => {
    const cookieDepartamento = Cookies.get('useLocation');
    const cookieCiudad = Cookies.get('comuna');
    const cookieNombreCiudad = Cookies.get('ZONE_NAME');
    const cookieBarrio = Cookies.get('municipal');
    const cookieLocation = Cookies.get('usrLocation');
    const cookieCartId = Cookies.get('cartId');

    dispatch(setStore(store));

    if (dataStore) {
      dispatch(setMainCategory(''));
      dispatch(
        setSelectedFilter({
          selection: dataStore?.generalFilterSetup?.defaultCategory.cta,
          color: dataStore?.generalFilterSetup?.defaultCategory.backgroundColor,
        }),
      );
      dispatch(setSmallHeader(dataStore?.smallHeader));
    }

    if (cookieDepartamento) {
      dispatch(setDepartamento(cookieDepartamento));
    } else {
      dispatch(setDepartamento('1'));
    }

    if (cookieBarrio) {
      dispatch(setBarrio(cookieBarrio));
    } else {
      dispatch(setBarrio('5166'));
    }

    if (cookieLocation) {
      dispatch(setLocation(cookieLocation));
    } else {
      dispatch(setLocation('2'));
    }

    if (cookieCiudad && cookieNombreCiudad) {
      dispatch(setCiudad({ name: cookieNombreCiudad, id: cookieCiudad }));
    } else {
      dispatch(setCiudad({ name: 'ABEJORRAL', id: '121' }));
    }

    if (cookieCartId
    ) {
      dispatch(setCartId(cookieCartId));
    } else {
      addCartLines(mapBodyToAddProduct('000000', 1))
        .then((res) => {
          const cardId = res.data.data.cart.cartId;
          dispatch(setCartId(cardId));
          Cookies.set('cartId', cardId);
        });
    }
  }, [dataStore]);

  useEffect(() => {
    if (window.s) {
      window.s.account = 'flblascocatest';
      window.s.trackingServer = 'sodimac.sc.omtrdc.net';
      localStorage.setItem('searchforSiS', '');
    }
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={<HomeStorePage store={store} />}
      />
      <Route
        path="/categories/:catId/filter?/:filter?"
        element={<ProductsListPage />}
      />
      <Route
        path="/collection/:catId/filter?/:filter?"
        element={<ProductsListPage />}
      />
      <Route
        path="/product/:productId"
        element={<ProductDetailPage />}
      />
      <Route path="*" element={<>error 404</>} />
    </Routes>
  );
};

export default App;
