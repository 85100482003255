/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

// get value from attributes warranty details response
const getValue = (attributes) => attributes[0]?.values[0];

// get attributes from variants warranty details response
const getAttributesFromVariants = (variants) => variants[0]?.attributes;

// get attributes from variants warranty details response
const getBadgesFromVariants = (res) => res?.variants[0]?.badges;

// get price from variants warranty details repsonse
const getPriceFromResponse = (price) => price[0]?.price;

// get symbol from variants warranty details repsonse
const getSymbolFromResponse = (price) => price[0]?.symbol;

// map warranty details response
const mapResponseDetails = (warranties) => {
  const mappedWarraties = warranties?.map(({ id, variants, price }) => ({
    id,
    value: `${getValue(getAttributesFromVariants(variants))} ${getSymbolFromResponse(price)} ${getPriceFromResponse(price)}`,
  }));
  return mappedWarraties;
};

const is4x3promotion = (badges) => (Object.keys(badges).length !== 0
  ? Object.keys(badges).includes('mxnBadge')
  : false);

// filter services details response by showDetail field
const filterByShowDetail = (serviceDetails) => serviceDetails
  .filter(({ showDetail }) => showDetail);

const alertModalPlpSlice = createSlice({
  name: 'alertModalPlpStates',
  initialState: {
    response: null,
    productId: '',
    warranty: [],
    services: [],
    isPDP: false,
    isPromotion4x3: false,
    isOpenModal: false,
    labels: {},
    icon: '',
    error: false,
    redirect: false,
    addExtraToCart: false,
    addAnyServiceToCart: false,
    addSuggestedProductToCart: false,
    crossSellProductIds: [],
  },
  reducers: {
    setOpenModalPlp: (state, { payload }) => {
      const { productId, isOpen, isError } = payload;
      state.productId = productId;
      state.isOpenModal = isOpen;
      state.error = isError;
    },
    setLabelsModalPlp: (state, { payload }) => {
      const { alertLabels, alertIcon } = payload;
      state.labels = alertLabels;
      state.icon = alertIcon;
    },
    setAddExtraToCart: (state, { payload }) => {
      const { addExtraToCart } = payload;
      state.addExtraToCart = addExtraToCart;
    },
    setAddWarrantyToCart: (state, { payload }) => {
      const { addAnyServiceToCart } = payload;
      state.addAnyServiceToCart = addAnyServiceToCart;
    },
    setAddSuggestedProductToCart: (state, { payload }) => {
      const { addSuggestedProductToCart } = payload;
      state.addSuggestedProductToCart = addSuggestedProductToCart;
    },
    setRedirectToCart: (state, { payload }) => {
      const { redirect } = payload;
      state.redirect = redirect;
    },
    setIsPDP: (state, { payload }) => {
      state.isPDP = payload;
    },
    setProductDetailsResponse: (state, { payload }) => {
      const { result } = payload;
      const { warrantyDetails, serviceDetails } = result;
      state.response = result;
      state.warranty = mapResponseDetails(warrantyDetails);
      state.services = mapResponseDetails(filterByShowDetail(serviceDetails));
      // state.isPromotion4x3 = is4x3promotion(getBadgesFromVariants(result));
    },
    setCrossSellProductIds: (state, { payload }) => {
      const {
        data: {
          data: {
            cart: { crossSellProductIds },
          },
        },
      } = payload;
      state.crossSellProductIds = crossSellProductIds;
    },
  },
});

export const {
  setOpenModalPlp,
  setLabelsModalPlp,
  setProductDetailsResponse,
  setRedirectToCart,
  setAddExtraToCart,
  setAddWarrantyToCart,
  setCrossSellProductIds,
  setAddSuggestedProductToCart,
  setIsPDP,
} = alertModalPlpSlice.actions;
export default alertModalPlpSlice.reducer;
