/* eslint-disable react/no-array-index-key */
import { PropTypes } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import Typography from '../../atoms/Typography/Typography';
import DropdownGeo from '../../atoms/DropdownGeo/DropdownGeo';
import style from './GeolocationModalBox.module.scss';
import Button from '../../atoms/Button/Button';
import { setBarrio, setCiudad, setDepartamento } from '../../../../redux/slices/transversalSlice';
import { setOpenModal } from '../../../../redux/slices/PLP/geolocalizationSlice';

const GeolocationModalBox = ({
  statesData,
  citiesData,
  neighborhoodsData,
  maxHeight,
  hideBorder,
}) => {
  const dispatch = useDispatch();
  return (
    <div className={style.DropdownGeo}>
      <Typography
        variant="omega17"
        style={{ fontWeight: 'bold', fontSize: '17px' }}
      >
        ¿Dónde quieres comprar?
      </Typography>
      <Typography variant="kappa14">
        Te mostraremos productos disponibles para la ubicación que selecciones.
      </Typography>
      <DropdownGeo
        id="states-geo"
        isGeo
        options={statesData}
        label="Departamento"
        maxHeight={maxHeight}
        hideBorder={hideBorder}
        onDropdownChange={({ value }) => {
          dispatch(setDepartamento(value)); // TODO Save on local state
        }}
      />
      <DropdownGeo
        id="cities-geo"
        isGeo
        options={citiesData}
        label="Ciudad"
        maxHeight={maxHeight}
        hideBorder={hideBorder}
        onDropdownChange={({ label, value }) => {
          dispatch(setCiudad({ name: label, id: value })); // TODO Save on local state
        }}
      />
      <DropdownGeo
        id="neighborhood-geo"
        isGeo
        options={neighborhoodsData}
        label="Barrio"
        maxHeight={maxHeight}
        hideBorder={hideBorder}
        onDropdownChange={({ value }) => {
          dispatch(setBarrio(value)); // TODO Save on local state
        }}
      />
      <Button
        className={style.DropdownGeo__button}
        variant="contained"
        onClick={() => dispatch(
          setOpenModal({
            isOpen: false,
          }),
        )}
        color="secondary" // TODO Save to the transversal state onClick
      >
        Guardar Ubicación
      </Button>
    </div>
  );
};

GeolocationModalBox.propTypes = {
  statesData: PropTypes.shape(PropTypes.shape({})),
  citiesData: PropTypes.shape(PropTypes.shape({})),
  neighborhoodsData: PropTypes.shape(PropTypes.shape({})),
  maxHeight: PropTypes.number,
  hideBorder: PropTypes.bool,
};

export default GeolocationModalBox;
