/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../atoms/Dropdown/Dropdown';
import ToggleGroup from '../../atoms/ToggleGroup/ToggleGroup';
import Pagination from '../../atoms/Pagination/Pagination';
import style from './SortByFilterPLP.module.scss';

const SortByFilterPLP = ({
  selectData,
  paginationData,
  viewOptions,
  onDropdownChange,
  onChangePaginator,
  onToogleGrid,
}) => (
  <div className={style.orderby_filter_container}>
    <div className={style.orderby_group}>
      <Dropdown
        options={selectData}
        hideBorder={false}
        maxHeight={260}
        {...selectData}
        onDropdownChange={onDropdownChange}
      />
      <div className={style.orderby_arrange}>
        <ToggleGroup viewOptions={viewOptions} onToogleGrid={onToogleGrid} />
      </div>
    </div>
    {paginationData && (
      <Pagination {...paginationData} onChangePaginator={onChangePaginator} />
    )}
  </div>
);

SortByFilterPLP.propTypes = {
  selectData: PropTypes.shape(),
  viewOptions: PropTypes.arrayOf([
    {
      type: PropTypes.string,
      columns: PropTypes.number,
      default: PropTypes.bool,
      visible: PropTypes.bool,
    },
  ]),
  onDropdownChange: PropTypes.func,
  onChangePaginator: PropTypes.func,
  onToogleGrid: PropTypes.func,
  categoryId: PropTypes.string,
  paginationData: PropTypes.shape({
    pageList: PropTypes.number,
    currPageNo: PropTypes.number,
    totalPages: PropTypes.number,
  }),
};

export default SortByFilterPLP;
