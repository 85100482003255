import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { styled } from 'styled-components';
import { useNavigate } from 'react-router';
import style from './InfoCard.module.scss';
import Typography from '../../atoms/Typography/Typography';
import { hasHttp } from '../../../../utils';

const ButtonWrapper = styled.button`
  span {
    color: ${(props) => props.theme.primary.text};
    background-color: ${(props) => props.theme.primary.primary};
  }
  &:hover {
    span {
      background-color: ${(props) => props.theme.primary.shaded};
    }
  }
`;
const InfoCard = ({
  link,
  label,
  backGroundColor,
  onClick,
  imageurl,
  width,
  ...props
}) => {
  const navigate = useNavigate();

  const handleClick = (key) => {
    if (hasHttp(key)) {
      window.open(key, '_blank');
    } else {
      navigate(key);
    }
  };

  return (
    <ButtonWrapper
      theme={props.theme}
      style={{
        backgroundColor: backGroundColor,
        width: '100%',
        cursor: 'pointer',
      }}
      type="button"
      data-testid="info-card"
      className={classNames(style.infoCard)}
      onClick={() => handleClick(link)}
    >
      <div className={classNames(style.infoCard__top)}>
        {imageurl && (
          <img
            src={imageurl}
            alt={props.title?.value}
            className={style.infoCard__image}
          />
        )}
        {props.title && (
          <Typography
            variant={props.title?.variant}
            style={{ fontWeight: props.title?.strong ? 'bold' : 'normal' }}
            className={style.infoCard__title}
          >
            {props.title?.value}
          </Typography>
        )}
      </div>
      {props.description && (
        <Typography
          variant={props.description?.variant}
          className={style.infoCard__description}
        >
          {props.description?.value}
        </Typography>
      )}
      {label && (
        // <span className={style.infoCard__label}>{label}</span>
        <button
          type="button"
          onClick={() => handleClick(link)}
          className={style.infoCard__label}
        >
          {label}
        </button>
      )}
    </ButtonWrapper>
  );
};

InfoCard.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  /**
   * backGroundColor of the Info Card component
   */
  backGroundColor: PropTypes.string,
  /**
   * label of the Info Card component
   */
  label: PropTypes.string,
  /**
   * label of the Info Card component
   */
  link: PropTypes.string,
  /**
   * Url image of the Info Card component
   */
  imageurl: PropTypes.string,
  /**
   * Width Info Card component
   */
  width: PropTypes.string,
  /**
   * onClick
   */
  onClick: PropTypes.func,
  /**
   * Title of the Info Card component
   */
  title: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
  }),
  /**
   * Description of the Info Card component
   */
  description: PropTypes.shape({
    value: PropTypes.string,
    variant: PropTypes.string,
    strong: PropTypes.bool,
  }),
};

export default InfoCard;
