import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionTitle.module.scss';

const SectionTitle = ({ text }) => <h3 className={styles.title}>{text}</h3>;

SectionTitle.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

export default SectionTitle;
