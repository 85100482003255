import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './AvailabilityStoreList.module.scss';

import Pager from '../../molecules/Pager/Pager';
import { arrayInArray } from '../../../../utils';

const AvailabilityStoreList = ({ list, renderItems }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const pages = arrayInArray(list, 5);
  return (
    <div className={style.list}>
      <div className={style.list__content}>
        {pages[pageIndex]?.map((item) => renderItems(item))}
      </div>
      <Pager
        state={pages?.length > 1}
        index={pageIndex}
        maxPages={pages?.length}
        onChange={(page) => setPageIndex(page - 1)}
      />
    </div>
  );
};

AvailabilityStoreList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  renderItems: PropTypes.func,
};
export default AvailabilityStoreList;
