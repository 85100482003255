import React from 'react';
import PropTypes from 'prop-types';
import style from './BackButton.module.scss';
import { ReactComponent as ArrowLeft2 } from '../../../../assets/svg/ArrowLeft2.svg';

const BackButton = ({ onBackClick }) => (
  <div className={style.backButton}>
    <button
      className={style.backButton__button}
      type="button"
      onClick={onBackClick}
    >
      <ArrowLeft2 />
      <span className={style.backButton__label}>Volver</span>
    </button>
  </div>
);

BackButton.propTypes = {
  onBackClick: PropTypes.func,
};

export default BackButton;
