/* eslint-disable no-return-assign */
import { createSlice } from '@reduxjs/toolkit';
import { numberOfDaysPast } from '../../../utils';

/* const mapProductReview = (productReview) => {
  if (productReview) {
    const batchedValues = Object.values(productReview.BatchedResults);
    const mapBatchedValues = batchedValues
      .map((el) => el.Results);
    const flatBatchedValues = mapBatchedValues.flat();
    const hash = {};
    const noDuplicateResults = flatBatchedValues
      .filter((el) => (hash[el.Id] ? false : hash[el.Id] = true));
    const mapNoDuplicateResults = noDuplicateResults.map((result) => {
      if (result.ReviewText && result.Title) {
        return {
          title: result.Title,
          description: result.ReviewText,
          personName: result.UserNickname || 'Anónimo',
          date: `hace ${numberOfDaysPast(result.SubmissionTime)} Días`,
          rate: result.Rating,
        };
      }
      return null;
    });
    const filterNullsResults = mapNoDuplicateResults.filter((el) => el !== null);
    let ratings = [
      { rating: 1, numberOfPersons: 0, percent: 0 },
      { rating: 2, numberOfPersons: 0, percent: 0 },
      { rating: 3, numberOfPersons: 0, percent: 0 },
      { rating: 4, numberOfPersons: 0, percent: 0 },
      { rating: 5, numberOfPersons: 0, percent: 0 },
    ];
    const totalOfPersons = filterNullsResults.length;
    filterNullsResults.forEach((result) => {
      const newRatings = ratings
        .map((el) => (el.rating === result.rate
          ? ({
            ...el,
            numberOfPersons: el.numberOfPersons + 1,
            percent: ((el.numberOfPersons + 1) * 100) / totalOfPersons,
          })
          : el));
      ratings = [...newRatings];
    });
    const sum = ratings.reduce((prev, curr) => prev + (curr.rating * curr.numberOfPersons), 0);
    const rating = sum > 0 ? sum / filterNullsResults.length : 0;
    const result = {
      data: filterNullsResults,
      statistics: ratings,
      rating: rating?.toString(),
      totalReviews: (filterNullsResults.length)?.toString(),
    };
    return result;
  }
  return {};
}; */

const mapReviewStatistics = (response) => {
  let ratings = [
    { rating: 5, numberOfPersons: 0, percent: 0 },
    { rating: 4, numberOfPersons: 0, percent: 0 },
    { rating: 3, numberOfPersons: 0, percent: 0 },
    { rating: 2, numberOfPersons: 0, percent: 0 },
    { rating: 1, numberOfPersons: 0, percent: 0 },
  ];
  if (response && (response.TotalResults !== 0)) {
    const productOrder = response?.Includes?.ProductsOrder[0];
    const products = response?.Includes?.Products[productOrder];
    const totalOfPersons = products?.FilteredReviewStatistics?.TotalReviewCount;
    const ratingDestribution = products?.FilteredReviewStatistics?.RatingDistribution;
    ratingDestribution?.forEach((result) => {
      const newRatings = ratings.map((el) => ((el.rating === result.RatingValue) ? ({
        ...el,
        numberOfPersons: result.Count,
        percent: (result.Count * 100) / totalOfPersons,
      }) : el));
      ratings = [...newRatings];
    });
    return ratings;
  }
  return ratings;
};

const getTotalReviews = (response) => {
  if (response && (response.TotalResults !== 0)) {
    const productOrder = response?.Includes?.ProductsOrder[0];
    const products = response?.Includes?.Products[productOrder];
    return products?.FilteredReviewStatistics?.TotalReviewCount.toString();
  }
  return '0';
};

const getRatingAverage = (response) => {
  if (response && (response.TotalResults !== 0)) {
    const productOrder = response?.Includes?.ProductsOrder[0];
    const products = response?.Includes?.Products[productOrder];
    return products?.FilteredReviewStatistics?.AverageOverallRating.toString();
  }
  return '0';
};

const mapFeedbackResult = (response) => {
  const mappedResults = response?.Results?.map(({
    Title, SubmissionTime, ReviewText, IsRecommended, UserNickname, Rating,
  }) => ({
    review: {
      title: Title,
      date: `hace ${numberOfDaysPast(SubmissionTime)} Días`,
      message: ReviewText,
    },
    recommend: (IsRecommended === null) ? true : IsRecommended,
    author: UserNickname,
    ratingAverage: Rating,
  }));
  if (mappedResults?.length > 0) {
    return mappedResults;
  }
  return [];
};

const getPositiveFeedbackResult = (feedbacks) => {
  const filteredFeedbacks = feedbacks?.filter(({ recommend }) => recommend || (recommend === null));
  if (filteredFeedbacks?.length > 0) {
    return filteredFeedbacks;
  }
  return [];
};

const getNegativeFeedbackResult = (feedbacks) => {
  const filteredFeedbacks = feedbacks?.filter(
    ({ recommend }) => !recommend && (recommend !== null),
  );
  if (filteredFeedbacks?.length > 0) {
    return filteredFeedbacks;
  }
  return [];
};

const mapFeedbackToData = (result) => ({
  Todas: result,
  Positivas: getPositiveFeedbackResult(result),
  Negativas: getNegativeFeedbackResult(result),
});

const mapFeedbackToButtons = (result) => ([
  { label: 'Todas', amount: result?.length },
  { label: 'Positivas', amount: getPositiveFeedbackResult(result)?.length },
  { label: 'Negativas', amount: getNegativeFeedbackResult(result)?.length },
]);

const productReviewSlice = createSlice({
  name: 'productReviewStates',
  initialState: {
    reviewResponse: null,
    feedbackResponse: null,
    reviews: {
      ratingAverage: '0',
      totalReviews: '0',
      statistics: null,
      buttons: [],
      data: {},
    },
    isOpenReviewModal: false,
  },
  reducers: {
    setFeedbackReview: (state, action) => {
      state.feedbackResponse = action.payload;
      state.reviews = {
        ratingAverage: getRatingAverage(action.payload),
        totalReviews: getTotalReviews(action.payload),
        statistics: mapReviewStatistics(action.payload),
        hasReviews: !(action.payload?.TotalResults === 0),
        buttons: mapFeedbackToButtons(mapFeedbackResult(action.payload)),
        data: mapFeedbackToData(mapFeedbackResult(action.payload)),
      };
    },
    setIsOpenReviewModal: (state, action) => {
      state.isOpenReviewModal = action.payload;
    },
  },
});

export const {
  setIsOpenReviewModal,
  setFeedbackReview,
} = productReviewSlice.actions;
export default productReviewSlice.reducer;
