/* eslint-disable no-unused-vars */
import { useDispatch } from 'react-redux';
import {
  useGetBlobStorageCampaingQuery,
  useGetBlobStorageDataQuery,
  useGetThemeDataQuery,
} from '../redux/slices/HOME/blobStorageApi';
import { setCampaing, setTheme } from '../redux/slices/transversalSlice';

const getInfoStore = (store) => {
  const dispatch = useDispatch();

  const {
    data: initialSetUp,
    isLoading,
    error,
  } = useGetBlobStorageDataQuery(store);

  const {
    data: theme,
    isLoading: themeLoading,
    error: themeError,
  } = useGetThemeDataQuery(store);

  const { data: campaing } = useGetBlobStorageCampaingQuery(store);

  if (theme) {
    dispatch(setTheme(theme));
  }
  if (campaing) {
    dispatch(setCampaing(campaing));
  }

  return initialSetUp;
};

export default getInfoStore;
