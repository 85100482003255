/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
// import { PRODUCT_LIST, SLIDER_FEATURED_PRODUCTS } from '../../../../mock/data';
import Container from '../../../layout/Container/Container';
import SectionTitle from '../../atoms/SectionTitle/SectionTitle';
import SliderFeaturedProducts2 from '../SliderFeaturedProducts2/SliderFeaturedProducts2';
import style from './FeaturedProductsSection.module.scss';

const FeaturedProductsSection = ({
  featuredProducts,
  cta,
  onClick,
  handleBannersClick,
  inlineStyles,
  sectionTitle,
  id,
}) => {
  const state = Object.keys(featuredProducts).length === 0;

  if (!state) {
    return (
      <div className={style.featuredProductsSection} style={inlineStyles}>
        <Container>
          {sectionTitle && (
          <div className={style.featuredProductsSection__title}>
            <SectionTitle text={sectionTitle.text} style={sectionTitle.styles} />
          </div>
          )}
          <SliderFeaturedProducts2
            state
            embeddedHTML
            cta={cta}
            id={id}
            variant={featuredProducts.variant}
            title={featuredProducts.title}
            content={featuredProducts.content}
            urls={featuredProducts.products}
            onClick={onClick}
            handleBannersClick={handleBannersClick}
            images={featuredProducts.images}
          />
        </Container>
        {/* <div className={style.featuredProductsSection__footer} /> */}
      </div>
    );
  }

  return null;
};

FeaturedProductsSection.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf({}),
  }),
  inlineStyles: PropTypes.arrayOf({}),
  sectionTitle: PropTypes.arrayOf({}),
  cta: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.number,
  handleBannersClick: PropTypes.func,
  featuredProducts: PropTypes.shape({
    setup: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.string,
    variant: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    images: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    products: PropTypes.array,
  }),
};

export default FeaturedProductsSection;
