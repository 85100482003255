/* eslint-disable no-plusplus */
import { createSlice } from '@reduxjs/toolkit';
import { CloudeFlare } from '../../../constants';

// filter Empty items from the list
const filterEmptyItems = (list) => list.filter(({ isEmpty }) => !isEmpty);

// add item to list
const addProductComparison = (list, item) => [...list, item];

// add "isEmpty" to complete the list
const getArrayWithDefaults = (input) => {
  const result = [];
  for (let i = 0; i < 4; i++) {
    if (input[i] && input[i].isEmpty === false) {
      result.push(input[i]);
    } else {
      result.push({ isEmpty: true });
    }
  }
  return result;
};

// add "isEmpty" to complete the list for product comparison
const getArrayWithDefaultsCompare = (input) => {
  const result = [{ value: '', isEmpty: true }];
  for (let i = 0; i < 4; i++) {
    if (input[i] && input[i].isEmpty === false) {
      result.push(input[i]);
    } else {
      result.push({ isEmpty: true });
    }
  }
  return result;
};

// filter item from the list
const filterProductBySku = (list, skuId) => list.filter(({ sku }) => !(sku === skuId));

// filter item by id
const filterProductById = (list, skuId) => list.filter(({ value }) => !(value.id === skuId));

// filter by group AttributesSortedMap
const filterAttributesByGroup = (attributes) => attributes.filter(({ group }) => group === 'AttributesSortedMap');

// map to head data structure
const mapToHeadData = (data) => {
  const oldPrice = data?.prices[0].price !== data.prices[data.prices.length - 1].price
    && data.prices[data.prices.length - 1].price;
  const newData = {
    id: data?.skuId,
    name: data?.displayName,
    urlImage: `${CloudeFlare}/${data?.skuId}/thumbnail`,
    brand: data?.brand,
    rating: parseFloat(data?.rating),
    totalReviews: parseFloat(data?.totalReviews),
    price: data?.prices[0].price,
    normalPrice: oldPrice && `${oldPrice}`,
  };
  return newData;
};

// map attributes to specific data
export const mapToSpecificationsData = (data) => {
  const newData = data.map((el) => ({
    items: el,
  }));
  return newData;
};

// join specifications in an array
const joinSpecificationsData = (oldData, newData) => {
  const groupData = [...oldData, ...newData];
  return groupData;
};

// group specifications
const groupObjectsByAttribute = (input) => {
  const output = [];

  input.forEach((obj) => {
    const groupIndex = output.findIndex((group) => group[0].value === obj.name);

    if (groupIndex !== -1) {
      output[groupIndex].push({ value: obj.values[0] });
    } else {
      output.push([{ value: obj.name }, { value: obj.values[0] }]);
    }
  });

  return output;
};

// fill and add values
const fillAndAddValues = (range, input) => {
  const output = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= range) {
      output.push(input[i - 1] || { value: '-' });
    } else {
      output.push({ value: '' });
    }
  }

  return output;
};

// map to specification structure
const mapToFlatStructure = (offset, data) => {
  const newData = data?.map((el) => {
    const newValues = fillAndAddValues(offset, el);
    return newValues;
  });
  return newData;
};

// handle show box comparison
const handleShowBoxComparison = (list) => {
  if (list.length >= 1) {
    return true;
  }
  return false;
};

// handle show alert
const handleShowAlert = (list) => {
  if (list.length === 4) {
    return true;
  }
  return false;
};

const initialState = {
  productsComparison: [
    { isEmpty: true },
    { isEmpty: true },
    { isEmpty: true },
    { isEmpty: true },
  ],
  addedProducts: [],
  showAlert: false,
  showBoxComparison: false,
  showProductComparison: false,
  headData: [{
    items: [
      { value: '', isEmpty: true },
      { value: '', isEmpty: true },
      { value: '', isEmpty: true },
      { value: '', isEmpty: true },
      { value: '', isEmpty: true },
    ],
  }],
  specificationsData: [],
  auxAttributeData: [],
};

const productComparisonSlice = createSlice({
  name: 'productComparisonStates',
  initialState,
  reducers: {
    setProductComparison: (state, { payload }) => {
      const { name, id, isEmpty } = payload;
      state.showAlert = handleShowAlert(state.addedProducts);
      state.productsComparison = getArrayWithDefaults(
        addProductComparison(
          filterEmptyItems(state.productsComparison),
          { title: name, sku: id, isEmpty },
        ),
      );
      state.addedProducts = filterEmptyItems(state.productsComparison);
      state.showBoxComparison = handleShowBoxComparison(state.addedProducts);
    },
    deleteProductComparison: (state, { payload }) => {
      const { sku } = payload;
      state.productsComparison = getArrayWithDefaults(
        filterProductBySku(filterEmptyItems(
          state.productsComparison,
        ), sku),
      );
      state.addedProducts = filterEmptyItems(state.productsComparison);
      state.showBoxComparison = handleShowBoxComparison(state.addedProducts);
      state.showAlert = handleShowAlert(state.addedProducts);
      state.specificationsData = mapToSpecificationsData(mapToFlatStructure(
        state.addedProducts.length + 1,
        groupObjectsByAttribute(state.auxAttributeData),
      ));
    },
    setHeadData: (state, { payload }) => {
      const { result, value, isEmpty } = payload;
      const { attributes } = result;
      state.headData[0].items = getArrayWithDefaultsCompare(
        addProductComparison(filterEmptyItems(
          state.headData[0].items,
        ), { value: mapToHeadData(value), isEmpty }),
      );
      state.auxAttributeData = joinSpecificationsData(
        state.auxAttributeData,
        filterAttributesByGroup(attributes),
      );
      state.specificationsData = mapToSpecificationsData(mapToFlatStructure(
        state.addedProducts.length + 1,
        groupObjectsByAttribute(state.auxAttributeData),
      ));
      state.responseData = groupObjectsByAttribute(state.auxAttributeData);
    },
    deleteHeadData: (state, { payload }) => {
      const { sku } = payload;
      state.headData[0].items = getArrayWithDefaultsCompare(
        filterProductById(filterEmptyItems(state.headData[0].items), sku),
      );
    },
    setShowAlert: (state, action) => {
      state.showAlert = action.payload;
    },
    setShowProductComparison: (state, action) => {
      state.showProductComparison = action.payload;
    },
    resetStates: () => initialState
    ,
  },
});

export const {
  setProductComparison,
  deleteProductComparison,
  setHeadData,
  deleteHeadData,
  setShowAlert,
  setShowProductComparison,
  resetStates,
} = productComparisonSlice.actions;
export default productComparisonSlice.reducer;
