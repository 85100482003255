import { configureStore } from '@reduxjs/toolkit';
import { blobStorageApi } from './slices/HOME/blobStorageApi';
import { blobStorageWarrantyApi } from './slices/PDP/blobStorageWarrantyApi';
import { blobStorageCategories } from './slices/PLP/blobStorageCategoriesApi';
import { m2Api } from './slices/PLP/m2Api';
import { categoriesData } from './slices/PLP/categoriesApi';
import { productDetailsApi } from './slices/PDP/productDetailsApi';
import { geolocalizationApi } from './slices/PLP/geolocalizationApi';
import { insiderApi } from './slices/PDP/insiderApi';
import { productImagesApi } from './slices/PDP/productImagesApi';
import { productReviewApi } from './slices/PDP/productReviewApi';
import { productInventoryApi } from './slices/PDP/productInventoryApi';
import { checkoutApi } from './slices/Cart/checkoutApi';
import { cartApi } from './slices/Cart/cartApi';
import { miduApi } from './slices/PDP/miduApi';
import { productDeliveryApi } from './slices/PDP/productDeliveryApi';
import { tangibleApi } from './slices/PDP/tangibleApi';
import cartSliceReducer from './slices/Cart/cartSlice';
import transversalSliceReducer from './slices/transversalSlice';
import productDetailsState from './slices/PDP/productDetailsSlice';
import productReviewReducer from './slices/PDP/productReviewSlice';
import alertModalPlpReducer from './slices/PDP/alertModalPlpSlice';
import deliveryReducer from './slices/PDP/deliverySlice';
import productComparisonReducer from './slices/PLP/productComparisonSlice';
import geolocalizationModalSlice from './slices/PLP/geolocalizationSlice';

const store = configureStore({
  reducer: {
    cartSlice: cartSliceReducer,
    transversalSlice: transversalSliceReducer,
    productDetailsReducer: productDetailsState,
    productReview: productReviewReducer,
    delivery: deliveryReducer,
    productComparison: productComparisonReducer,
    geolocalization: geolocalizationModalSlice,
    alertModalPlp: alertModalPlpReducer,
    [blobStorageApi.reducerPath]: blobStorageApi.reducer,
    [blobStorageWarrantyApi.reducerPath]: blobStorageWarrantyApi.reducer,
    [blobStorageCategories.reducerPath]: blobStorageCategories.reducer,
    [categoriesData.reducerPath]: categoriesData.reducer,
    [productDetailsApi.reducerPath]: productDetailsApi.reducer,
    [geolocalizationApi.reducerPath]: geolocalizationApi.reducer,
    [insiderApi.reducerPath]: insiderApi.reducer,
    [productImagesApi.reducerPath]: productImagesApi.reducer,
    [productReviewApi.reducerPath]: productReviewApi.reducer,
    [productInventoryApi.reducerPath]: productInventoryApi.reducer,
    [checkoutApi.reducerPath]: checkoutApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [miduApi.reducerPath]: miduApi.reducer,
    [tangibleApi.reducerPath]: tangibleApi.reducer,
    [productDeliveryApi.reducerPath]: productDeliveryApi.reducer,
    [m2Api.reducerPath]: m2Api.reducer,
  },
  // eslint-disable-next-line max-len
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(blobStorageApi.middleware)
    .concat(blobStorageWarrantyApi.middleware)
    .concat(blobStorageCategories.middleware)
    .concat(productDetailsApi.middleware)
    .concat(geolocalizationApi.middleware)
    .concat(insiderApi.middleware)
    .concat(productImagesApi.middleware)
    .concat(productReviewApi.middleware)
    .concat(productInventoryApi.middleware)
    .concat(checkoutApi.middleware)
    .concat(cartApi.middleware)
    .concat(miduApi.middleware)
    .concat(tangibleApi.middleware)
    .concat(productDeliveryApi.middleware)
    .concat(categoriesData.middleware)
    .concat(m2Api.middleware),
});

export default store;
