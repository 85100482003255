import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './TableCellHead.module.scss';

const TableCellHead = ({ children, isSticky, ...props }) => (
  <th
    {...props}
    className={classNames(style.root, {
      [style.root_positionSticky]: isSticky,
    })}
  >
    {children}
  </th>
);
TableCellHead.propTypes = {
  children: PropTypes.node,
  isSticky: PropTypes.bool,
};

export default TableCellHead;
