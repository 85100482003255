import React from 'react';
import PropTypes from 'prop-types';
import style from './ProductNormalPrice.module.scss';

const ProductNormalPrice = ({ content }) => (
  <p className={style.productNormalPrice}>
    Normal:
    <br />
    {`$${content} UND`}
  </p>
);

ProductNormalPrice.propTypes = {
  content: PropTypes.string,
};

ProductNormalPrice.defaultProps = {
  content: '0.000',
};

export default ProductNormalPrice;
