/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import CmrBadge from '../../../assets/svg/CmrBadge.svg';
import InternetPrice from '../../../assets/svg/InternetPrice.svg';
import PrecioAlPorMayor from '../../../assets/svg/PrecioAlPorMayor.svg';
import { convertStringIntoNumber as convert } from '../../../utils';

const usePrices = ({ price, badges }) => {
  const initial = {
    price: price[0]?.price,
    symbol: price[0]?.symbol,
    unit: price[0]?.unit,
  };
  const [badgeImage, setBadgeImage] = useState('');
  const [multiPurposeBadgeImage, setMultiPurposeBadgeImage] = useState('');
  const [discount, setDiscount] = useState('');
  const [saving, setSaving] = useState('');
  const [prices, setPrices] = useState([]);
  const [regularPrice, setRegularPrice] = useState(initial);
  const [unitPrice, setUnitPrice] = useState({});
  const [auxPrice, setAuxPrice] = useState({});
  const [savingText, setSavingText] = useState('');
  const [subAux, setSubAux] = useState(null);
  const TYPES = ['INTERNET', 'CMR', 'M2', 'NORMAL'];

  const calculateSavings = (normal, saved) => convert(normal) - convert(saved);

  const filterByType = (array, string) => array?.filter((item) => item.type === string);

  const isThereBadge = (object, string) => Object.entries(object)?.filter(([key]) => key.includes(string));

  useEffect(() => {
    setBadgeImage('');
    setMultiPurposeBadgeImage('');
    setDiscount('');
    setSaving('');
    setPrices([]);
    setRegularPrice(initial);
    setUnitPrice('');
    setAuxPrice('');
    setSavingText('');
    setSubAux('');
    const unidad = filterByType(price, TYPES[2]);
    const normal = filterByType(price, TYPES[3]);
    const multiPurposeBadge = isThereBadge(badges, 'multiPurposeBadge');
    const priceBadge = isThereBadge(badges, 'priceBadge');
    const thereAreUnitsAndNormal = Object.keys(unidad).length > 0 && Object.keys(normal).length > 0;

    const filtered = price.filter((item) => {
      if (item.type === TYPES[3]) {
        setPrices((prevState) => [...prevState, item.price]);
        if (multiPurposeBadge.length) {
          setMultiPurposeBadgeImage(multiPurposeBadge[0][1]);
        }
        if (priceBadge.length) {
          setBadgeImage(PrecioAlPorMayor);
        }
      }
      return TYPES.includes(item.type);
    });

    switch (filtered[0].type) {
      case TYPES[0]: // INTERNET
        setBadgeImage(InternetPrice);
        setDiscount(badges.discountBadge.value);
        setPrices((prevState) => [...prevState, filtered[0].price]);
        setUnitPrice(
          (thereAreUnitsAndNormal && normal[0])
            || (unidad.length < 1 && normal[0]),
        );
        setAuxPrice(thereAreUnitsAndNormal && unidad[0]);
        break;
      case TYPES[1]: // CMR
        setBadgeImage(CmrBadge);
        setDiscount(badges.discountBadge.value);
        setPrices((prevState) => [...prevState, filtered[0].price]);
        setSavingText(`Ahorra con ${TYPES[1]}`);
        setUnitPrice(unidad.length > 0 ? normal[0] : null);
        setSubAux('Otros medios de pago');
        setAuxPrice(thereAreUnitsAndNormal && unidad[0]);
        break;
      case TYPES[2]: // M2
        setUnitPrice(filtered[0]);
        setBadgeImage(PrecioAlPorMayor);
        break;
      case TYPES[3]: // NORMAL
        setAuxPrice(
          (unidad.length < 1 && null) || (thereAreUnitsAndNormal && unidad[0]),
        );
        setUnitPrice(unidad.length < 1 && null);
        break;
      default:
        setBadgeImage(null);
        break;
    }
  }, [price, badges]);

  useEffect(() => {
    if (prices.length > 1) {
      setSaving(
        `Ahorra $${calculateSavings(prices[0], prices[1]).toLocaleString(
          'es-CO',
        )}`,
      );
    }
  }, [prices]);

  return {
    badgeImage,
    discount,
    saving,
    unitPrice,
    auxPrice,
    subAux,
    multiPurposeBadgeImage,
    regularPrice,
  };
};

export default usePrices;
