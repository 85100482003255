import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const productInventoryApi = createApi({
  reducerPath: 'productInventoryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.bazaarvoice.com/',
  }),
  endpoints: (builder) => ({
    getProductInventory: builder.query({
      query: (sku) => `data/batch.json?passkey=te6izc8u11ucvcxkbhpi70cig&apiversion=5.5&displaycode=20033-es_co&resource.q0=products&filter.q0=id%3Aeq%3A${sku}&stats.q0=reviews&filteredstats.q0=reviews&filter_reviews.q0=contentlocale%3Aeq%3Aes_CO&filter_reviewcomments.q0=contentlocale%3Aeq%3Aes_CO&resource.q1=reviews&filter.q1=isratingsonly%3Aeq%3Afalse&filter.q1=productid%3Aeq%3A${sku}&filter.q1=contentlocale%3Aeq%3Aes_CO&sort.q1=rating%3Adesc&stats.q1=reviews&filteredstats.q1=reviews&include.q1=authors%2Cproducts%2Ccomments&filter_reviews.q1=contentlocale%3Aeq%3Aes_CO&filter_reviewcomments.q1=contentlocale%3Aeq%3Aes_CO&filter_comments.q1=contentlocale%3Aeq%3Aes_CO&limit.q1=8&offset.q1=0&limit_comments.q1=3&resource.q2=reviews&filter.q2=productid%3Aeq%3A${sku}&filter.q2=contentlocale%3Aeq%3Aes_CO&limit.q2=1&resource.q3=reviews&filter.q3=productid%3Aeq%3A${sku}&filter.q3=isratingsonly%3Aeq%3Afalse&filter.q3=issyndicated%3Aeq%3Afalse&filter.q3=rating%3Agt%3A3&filter.q3=totalpositivefeedbackcount%3Agte%3A3&filter.q3=contentlocale%3Aeq%3Aes_CO&sort.q3=totalpositivefeedbackcount%3Adesc&include.q3=authors%2Creviews%2Cproducts&filter_reviews.q3=contentlocale%3Aeq%3Aes_CO&limit.q3=1&resource.q4=reviews&filter.q4=productid%3Aeq%3A${sku}&filter.q4=isratingsonly%3Aeq%3Afalse&filter.q4=issyndicated%3Aeq%3Afalse&filter.q4=rating%3Alte%3A3&filter.q4=totalpositivefeedbackcount%3Agte%3A3&filter.q4=contentlocale%3Aeq%3Aes_CO&sort.q4=totalpositivefeedbackcount%3Adesc&include.q4=authors%2Creviews%2Cproducts&filter_reviews.q4=contentlocale%3Aeq%3Aes_CO&limit.q4=1`,
    }),
  }),
});

export const { useGetProductInventoryQuery } = productInventoryApi;
